import { Modal } from '@wmg-ae/legato';
import React from 'react';

const ExternalModalPopup: React.FC<any> = ({ isOpen }) => {
  return (
    <Modal
      icon="spinner-third fa-spin"
      title="Transfer In Progress"
      isOpen={isOpen}
      onClose={() => {}}
    >
      <div className="deleteFile">
        Please wait file(s) are being uploaded to WMG, do not close this window
        until transfer is completed.
      </div>
    </Modal>
  );
};

export default ExternalModalPopup;

import { Icon, Table } from '@wmg-ae/legato';
import React, { useMemo } from 'react';

import { headerColumns } from './constant';
import { getContentIcon } from '../dashboard/helper';

const ExternalTable: React.FC<any> = ({
  dashboardRecords,
  download_transfer_spec,
  nodeToken,
  handleActionMenu,
}) => {
  const folderClick = (isFolder: any, row: any, index: any) => {};
  let tableColumnnsUpdated = headerColumns.map((column: any) => {
    const newCoulmn = {
      dataIndex: column.dataIndex,
      key: column.key,
      title: column.title,
      width: column.width,
      render:
        column.key === 'filename'
          ? (value: any, row: any, index: any) => {
              const isFolder = row.type.includes('folder');
              let splitvalue = value;
              if (value && !isFolder && value.includes('.')) {
                const lastIndexDot = value.lastIndexOf('.');
                splitvalue = value.slice(0, lastIndexDot);
              }
              return (
                <div className="row">
                  <div className="col contentIconWrapper">
                    <span onClick={() => folderClick(isFolder, row, index)}>
                      <Icon
                        className={`contentIconStyle ${
                          isFolder ? 'iconPointer' : ''
                        }`}
                        name={getContentIcon(isFolder, row.share)}
                        prefix="fal"
                      />
                    </span>
                  </div>
                  <div
                    className={`col contentlabel ${
                      isFolder ? 'iconPointer' : ''
                    }`}
                    onClick={() => folderClick(isFolder, row, index)}
                  >
                    {splitvalue}
                  </div>
                </div>
              );
            }
          : column.filename,
      sortable: column.sortable,
      filterSearch: column.filterSearch,
    };
    return newCoulmn;
  });
  let tableRecords = useMemo(
    () =>
      dashboardRecords.map((row: any, idx: number) => {
        // const isFolder = row.type === 'folder' || row.type === 'sfolder';

        const newRow = {
          // key: row.id,
          key: row.package_id,
          type: row.type,
          // parent_id: row.parent_id,
          // content_id: row.content_id,
          filename: row.name,
          sentBy: row.recipient_emails || row.user_email || '',
          // share: row.is_shared,
          // allow_access_to_workspace_users: row.allow_access_to_workspace_users,
          // fileType: row.content_type,
          // duration: row.duration || '',
          // owner: row.user_name,
          // lastUpdate: row.last_updated_timestamp
          //   ? new Date(row.last_updated_timestamp).toLocaleDateString()
          //   : '',
          // status: row.status,
          download_transfer_spec,
          nodeToken,
          user_id: row.user_id,
          dropbox_name: row.dropbox_name,
          dropbox_id: row.dropbox_id,
          package_name: row.package_name,
          package_id: row.package_id,
          parent_package_file_id: row.parent_package_file_id,
          workspace_id: row.workspace_id,
          expirydate: new Date(row.expiry_date).toLocaleDateString(),
          rowActions: (
            <div className="container">
              <div className="row rowActionsIcons">
                <div
                  className="col"
                  onClick={() => {
                    handleActionMenu(row);
                  }}
                >
                  <Icon
                    className="ellipseIconStyle"
                    name="download"
                    prefix="fal"
                  />
                </div>
                <div className="col"></div>
              </div>
            </div>
          ),
        };
        return newRow;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dashboardRecords, nodeToken]
  );

  return (
    <div className="marginUpdate">
      <Table
        columns={tableColumnnsUpdated}
        data={tableRecords}
        // defaultSort={{ direction: 'DESC', key: 'lastUpdate' }}
        condensed={true}
        transparent={false}
        rowKey={(row: any) => {
          return row.key;
        }}
      />
    </div>
  );
};

export default ExternalTable;

import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaConfig } from '../auth/oktaConfig';

import Dashboard from '../app/dashboard/DashboardConnector';
import ExternalUpload from '../app/external/ExternalUpload';
import ExternalPackage from '../app/external-package/ExternalPackage';
// import NotFound from '../app/external/NotFound';

const CALLBACK_PATH = '/login/callback';
const oktaAuth = new OktaAuth(oktaConfig);

const Routes: React.FC<any> = () => {
  const history = useHistory();
 
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <SecureRoute path="/" exact component={Dashboard} />
        <SecureRoute path="/home" exact component={Dashboard} />
        <Route path={CALLBACK_PATH} exact component={LoginCallback} />
        <SecureRoute
          path="/upload/int/content/*"
          exact
          component={ExternalUpload}
        />
        <Route path="/upload/ext/content/*" exact component={ExternalUpload} />
        <Route path="/ext/package/*" exact component={ExternalPackage} />
        <SecureRoute path="*" component={Dashboard} />

        {/* <Redirect from="*" to="/" /> */}
        {/* <SecureRoute path="*" component={NotFound} /> */}
      </Switch>
    </Security>
  );
};

export default Routes;

import { connect, ConnectedProps } from 'react-redux';
import Dashboard from './Dashboard';
import {
  getToken,
  updateLoggedInUser,
  changeTab,
  getAnRLabelData,
  getDashboardRecords,
  getWorkspaceTiles,
  loadSelectedWorkspace,
  submitShareContent,
  submitUpload,
  deleteFile,
  handleRequestLink,
  getNodeToken,
  generateTransferSpec,
  toggleAlert,
  getMyLinks,
  updateFilterKey,
  deleteReqLink,
  getQueryEmails,
  readComment,
  readActivity,
  sendRequestAssetMail,
  markAsRead,
  copyLink,
  activateLink,
  postComments,
  createFolder,
  postInviteCollaborator,
  downloadSuccess,
  getContentInfo,
  updateContentDetails,
  updateVaultStatus,
  registerAssets,
  getLibStudioData,
  moveToNewFolder,
  approveFile,
  DeclineFile,
  DeclineStudioFile,
  postProfileDetails,
  setprofileSettings,
  terminateReqLink,
  modifyReqSubmit,
  getProfile,
  SendForApprovalFile,
  RequestRevisionFile,
  DeclineInRegistrationFile,
  getAssetPermission,
  setTransferOpen,
  setDownloadCID,
  setUploadCount,
  submitShareExternal,
} from './store/DashboardStore';
import { RootState, AppDispatch } from '../../store';

const mapStateToProps = ({ dashboard }: RootState) => {
  const {
    token,
    currentUser,
    breadcrumbList,
    id,
    labelLogo,
    labelTitle,
    dashboardRecords,
    workspaceList,
    alertDetails,
    upload_transfer_spec,
    download_transfer_spec,
    nodeToken,
    workspace,
    fileFilterKey,
    myLinkRecords,
    notifications,
    comments,
    activitiesRecords,
    emailList,
    userIdList,
    homeId,
    createFolderMsg,
    contentInfoDetails,
    validWorkspace,
    transferOpen,
    profileSettings,
    assetPermission,
    downloadCID,
    uploadCount,
  } = dashboard;

  return {
    token,
    currentUser,
    breadcrumbList,
    tab_id: id,
    labelLogo,
    labelTitle,
    dashboardRecords,
    workspaceList,
    alertDetails,
    upload_transfer_spec,
    download_transfer_spec,
    nodeToken,
    workspace,
    fileFilterKey,
    myLinkRecords,
    notifications,
    comments,
    activitiesRecords,
    emailList,
    userIdList,
    homeId,
    createFolderMsg,
    contentInfoDetails,
    validWorkspace,
    profileSettings,
    assetPermission,
    transferOpen,
    downloadCID,
    uploadCount,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getToken: (email: string) => dispatch(getToken(email)),
  updateLoggedInUser: (user: any) => dispatch(updateLoggedInUser(user)),
  changeTab: (tabId: string) => dispatch(changeTab(tabId)),
  getAnRLabelData: () => dispatch(getAnRLabelData()),
  getDashboardRecords: (details: any) => dispatch(getDashboardRecords(details)),
  getWorkspaceTiles: (data: any) => dispatch(getWorkspaceTiles(data)),
  loadSelectedWorkspace: (ws: any) => dispatch(loadSelectedWorkspace(ws)),
  handleRequestLink: (params: any) => dispatch(handleRequestLink(params)),
  submitUpload: (response: string) => dispatch(submitUpload(response)),
  submitShareContent: (data: any) => dispatch(submitShareContent(data)),
  deleteFile: (data: any) => dispatch(deleteFile(data)),
  getNodeToken: () => dispatch(getNodeToken()),
  uploadTS: (nodeToken: any) => dispatch(generateTransferSpec(nodeToken)),
  toggleAlert: (data: any) => dispatch(toggleAlert(data)),
  getMyLinks: (link_type: any) => dispatch(getMyLinks(link_type)),
  updateFilterKey: (key: string) => dispatch(updateFilterKey(key)),
  deleteLink: (data: any) => dispatch(deleteReqLink(data)),
  getQueryEmails: (data: any) => dispatch(getQueryEmails(data, 0)),
  readComment: (data: any) => dispatch(readComment(data)),
  readActivity: (data: any) => dispatch(readActivity(data)),
  sendRequestAssetMail: (data: any, resent: boolean) =>
    dispatch(sendRequestAssetMail(data, resent)),
  terminateReqLink: (data: any) => dispatch(terminateReqLink(data)),
  markAsRead: (id: any) => dispatch(markAsRead(id)),
  copyLink: () => dispatch(copyLink()),
  activateLink: () => dispatch(activateLink()),
  postComments: (data: any) => dispatch(postComments(data)),
  createFolder: (details: any) => dispatch(createFolder(details)),
  postInviteCollaborator: (data: any) => dispatch(postInviteCollaborator(data)),
  downloadSuccess: (data: any) => dispatch(downloadSuccess(data)),
  getContentInfo: (id: string) => dispatch(getContentInfo(id)),
  updateContentDetails: (data: any) => dispatch(updateContentDetails(data)),
  updateVaultStatus: (data: any) => dispatch(updateVaultStatus(data)),
  registerAssets: (data: any) => dispatch(registerAssets(data)),
  getLibStudioData: (data: any) => dispatch(getLibStudioData(data)),
  moveToNewFolder: (data: any) => dispatch(moveToNewFolder(data)),
  approveFile: (data: any) => dispatch(approveFile(data)),
  declineFile: (data: any) => dispatch(DeclineFile(data)),
  declineStudioFile: (data: any) => dispatch(DeclineStudioFile(data)),
  postProfileDetails: (data: any) => dispatch(postProfileDetails(data)),
  setprofileSettings: (data: any) => dispatch(setprofileSettings(data)),
  handleModifyReqSubmit: (data: any) => dispatch(modifyReqSubmit(data)),
  getProfile: () => dispatch(getProfile()),
  SendForApprovalFile: (data: any) => dispatch(SendForApprovalFile(data)),
  RequestRevisionFile: (data: any) => dispatch(RequestRevisionFile(data)),
  DeclineInRegistrationFile: (data: any) =>
    dispatch(DeclineInRegistrationFile(data)),
  getAssetPermission: (data: any) => dispatch(getAssetPermission(data)),
  setTransferOpen: (data: any) => dispatch(setTransferOpen(data)),
  setDownloadCID: (data: any) => dispatch(setDownloadCID(data)),
  setUploadCount: (data: any) => dispatch(setUploadCount(data)),
  submitShareExternal: (data: any) => dispatch(submitShareExternal(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export type DashboardProps = ConnectedProps<typeof connector>;
export default connector(Dashboard);

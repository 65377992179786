import {
  Button,
  InputGroup,
  Modal,
  MultiSelect,
  Tag,
  Textarea,
  // Tooltip,
} from '@wmg-ae/legato';
import React, { useState } from 'react';
// import { fieldValidation } from '../../helper';

const ShareToLibrary: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  emailList,
  getQueryEmails,
}) => {
  const [Notes, setNotes] = useState('');
  const [selectedOption, setselectedOption] = useState<any>([]);

  const removeEmailCheck = (email: string) => {
    const newArr = [
      ...selectedOption.filter((item: any) => item.label !== email),
    ];
    setselectedOption([...newArr]);
  };

  const submitLibrary = () => {
    handleSubmit({
      emailList: selectedOption.map((option: any) => option.label),
      name: 'Share to Library by ',
      sharingTo: 'library',
      allFields: { notes: Notes },
    });

    // Reset all
    setTimeout(() => {
      setNotes('');
      setselectedOption([]);
    }, 100);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Share To Library"
      onClose={handleClose}
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            colorType="primary"
            // disabled={selectedOption.length === 0}
            onClick={submitLibrary}
          >
            Share
          </Button>
        </div>
      }
    >
      <div className="modalBody">
        <div className="row">
          <div className="col">
            <InputGroup
              label="Who to notify?"
              validationIconPlacement="label"
              icon="error"
              // validation={fieldValidation.required}
            >
              {/* <Tooltip
                containerStyle="tint"
                colorType="white"
                message={'Some information will be here'}
                placement="top"
                visible
              > */}
              <MultiSelect
                isClearable
                disabled={false}
                value={selectedOption}
                list={emailList}
                placeholder="Enter email"
                searchInputPlaceholder="Search"
                getTitle={(el: any) => el.label}
                getKey={(el: any) => el.label}
                isFilterable={true}
                onSelect={(key: any) => {
                  setselectedOption(key);
                }}
                onFilter={(l: ReadonlyArray<any>, term: any) =>
                  l.filter((f) =>
                    f.label
                      .toLocaleLowerCase()
                      .includes(term.toLocaleLowerCase())
                  )
                }
              />
              {/* </Tooltip> */}
            </InputGroup>
            <div className="uploadTagComponent">
              {selectedOption.map((email: any, idx: number) => (
                <Tag
                  key={idx}
                  text={email.label}
                  colorType="dark"
                  containerStyle="tint"
                  removable
                  onClickIcon={() => removeEmailCheck(email.label)}
                  tooltip={false}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="row pt20">
          <div className="col">
            <InputGroup label="Notes" validation={undefined}>
              <Textarea
                // disabled={!!displayLinkURL}
                // containerStyle="outline"
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                // onChange={function noRefCheck() {}}
                placeholder="Enter any additional information regarding file(s)"
                size="lg"
                value={Notes}
                // defaultValue={notes}
                // validation={{
                //   message: "Additional message text",
                //   pending: false,
                //   required: false,
                //   type: "error",
              />
              {/* <span>{500 - Notes.length} Characters left.</span> */}
            </InputGroup>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareToLibrary;

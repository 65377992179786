import { Button, Icon, Input } from '@wmg-ae/legato';
import React from 'react';
import './AnrFilters.sass';

const AnRFilters: React.FC<any> = ({
  fileFilterKey,
  updateFilterKey,
  exportPdf,
}) => {
  return (
    <div className="filter_contaier">
      <Input
        className="filter_input"
        onChange={(e) => updateFilterKey(e.target.value)}
        placeholder="Search by package name, artist or title"
        suffixIcon="search"
        value={fileFilterKey}
      />
      {/* <Icon className="filter_slider" name="sliders-up" /> */}
      <Button
        className="export_pdf"
        label="Export PDF"
        containerStyle={'link'}
        icon={<Icon name={'file-pdf'} prefix="fas" />}
        onClick={exportPdf}
      />
    </div>
  );
};

export default AnRFilters;

import { Button, Checkbox, Label, Modal } from '@wmg-ae/legato';
import React from 'react';
import './UserProfile.sass';
import '../../../../../shared-components/spacingStyles.sass';

import '../../modals/modalStyles.sass';

const Userprofile: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  currentUser,
  profileSettings,
  setprofileSettings,
  getProfile,
}) => {
  const {
    invited_to_workspace,
    received_package_deleted,
    invited_shared_inbox,
  } = profileSettings;
  const handleInputChange = (field: any, value: any) => {
    setprofileSettings({ ...profileSettings, [field]: value });
  };
  const handleCancel = () => {
    getProfile();
    handleClose();
  };
  return (
    <div>
      <Modal
        footer={
          <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
            <div style={{ flex: 1 }} />
            <Button
              colorType="secondary-black"
              containerStyle="outline"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Save</Button>
          </div>
        }
        onClose={handleCancel}
        shouldCloseOnOverlayClick
        title="Account Settings"
        isOpen={isOpen}
      >
        <div className="container">
          <div className="row profile">
            <div className="col-10">
              <div className="row  pt11">Name: {currentUser.fullName}</div>
              <div className="row  pb12 py30">Email: {currentUser.email}</div>
            </div>
          </div>
          <div className="row pt20 NsTitle">
            <div className="col px0">
              <Label size="lg" text="Notification Setting" />
            </div>
          </div>
          <div className="row pt20 checkBoxes">
            <div className="col px0 lgt-checkbox-group lgt-checkbox-group--vertical">
              <Checkbox
                label="Email me when I am invited to a workspace"
                name="tst"
                onChange={(e: any) =>
                  handleInputChange(
                    'invited_to_workspace',
                    (e.target.checked && 'y') || 'n'
                  )
                }
                checked={invited_to_workspace === 'y'}
              />
              <Checkbox
                label="Email me when a package I received is deleted"
                name="tst"
                onChange={(e: any) =>
                  handleInputChange(
                    'received_package_deleted',
                    (e.target.checked && 'y') || 'n'
                  )
                }
                checked={received_package_deleted === 'y'}
              />
              <Checkbox
                label="Email me when I am invited to a shared inbox"
                name="tst"
                onChange={(e: any) =>
                  handleInputChange(
                    'invited_shared_inbox',
                    (e.target.checked && 'y') || 'n'
                  )
                }
                checked={invited_shared_inbox === 'y'}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Userprofile;

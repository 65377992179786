import React from 'react';
import {
  Button,
  Checkbox,
  Datepicker,
  Input,
  InputGroup,
  Label,
  MultiSelect,
  Textarea,
} from '@wmg-ae/legato';

import { AudioList, shareStudioOptions, StudioOptions } from './constant';
import { fieldValidation, regexPattern } from '../dashboard/helper';

const StudioExternal: React.FC<any> = ({
  email,
  uploadStatus,
  handleInputChange,
  inputFields,
  inputValidate,
  setInputFields,
  setInputValidate,
}) => {
  const {
    artist,
    title,
    package_name,
    notes,
    selectedOption,
    selectedStudioOption,
    check,
    upc,
    isrc,
    register_asset_due_date,
    studio_info,
    watermark_wbs,
    watermark_approved_by,
    watermark_job_description,
    audio_edit_due_date,
    audio_edit_job_description,
    audio_edit_send_ref_email,
    audio_edit_wbs,
    mastering_send_ref_email,
    mastering_due_date,
    mastering_wbs,
  } = inputFields;

  const { Register, Watermarking, Audio, Mastering, Other } = StudioOptions;

  const { required } = fieldValidation;

  const optionSelected = (option: any) => {
    return selectedStudioOption.some((item: any) => item.label === option);
  };

  // function handleInputChange(arg0: string, value: string): void {
  //   setInputValidate({});
  //   throw new Error('Function not implemented.');
  // }
  const handleOptionChange = (option: any) => {
    if (!uploadStatus) {
      const removeOption = selectedStudioOption.filter(
        (item: any) => item.label !== option
      );
      //clearRemovedData(option);
      switch (option) {
        case Register:
          setInputFields({
            ...inputFields,
            upc: '',
            isrc: '',
            register_asset_due_date: null,
            studio_info: '',
            selectedStudioOption: removeOption,
          });

          break;
        case Watermarking:
          setInputFields({
            ...inputFields,
            watermark_wbs: '',
            watermark_approved_by: '',
            watermark_job_description: '',
            selectedStudioOption: removeOption,
          });
          setInputValidate({
            ...inputValidate,
            watermark_approved_by: required,
          });
          break;
        case Audio:
          setInputFields({
            ...inputFields,
            audio_edit_due_date: null,
            audio_edit_job_description: '',
            audio_edit_send_ref_email: '',
            audio_edit_wbs: '',
            selectedStudioOption: removeOption,
          });
          setInputValidate({
            ...inputValidate,
            audio_edit_wbs: required,
            audio_edit_send_ref_email: required,
          });
          break;
        case Mastering:
          setInputFields({
            ...inputFields,
            mastering_send_ref_email: '',
            mastering_due_date: null,
            mastering_wbs: '',
            selectedStudioOption: removeOption,
          });
          setInputValidate({
            ...inputValidate,
            mastering_send_ref_email: required,
          });
          break;
        default:
          break;
      }
    }
    // handleInputChange('selectedStudioOption', removeOption);
  };

  return (
    <div>
      <h1 className="extUploadText">Upload Asset(s) to WMG Studio</h1>
      {/* <p className="extLibraryCaption">
        Your assets will be shared directly with studio team
      </p> */}
      <Label text="Send To:" />
      <p className="extUploadpText extEmailText">{email}</p>
      <div className="removePadding">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <InputGroup
                label="Artist"
                validation={required}
                validationIconPlacement="label"
                icon="error"
                className="test1"
              >
                <Input
                  className="extInputMargin"
                  onChange={(e: any) =>
                    handleInputChange('artist', e.target.value)
                  }
                  placeholder="Enter Artist "
                  value={artist}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            <div className="col-6">
              <InputGroup
                label="Title"
                validation={required}
                validationIconPlacement="label"
                icon="error"
                className="test1"
              >
                <Input
                  className="extInputMargin"
                  onChange={(e: any) =>
                    handleInputChange('title', e.target.value)
                  }
                  placeholder="Enter title for asset(s)"
                  value={title}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="removePadding">
        <div className="container">
          <div className="row">
            <div className="col">
              <InputGroup
                label="Package Name"
                validation={required}
                validationIconPlacement="label"
                icon="error"
                className="test1"
              >
                <Input
                  className="extInputMargin"
                  onChange={(e: any) =>
                    handleInputChange('package_name', e.target.value)
                  }
                  placeholder="Enter Package Name"
                  value={package_name}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            <div className="col-6">
              <InputGroup
                label="Audio Type"
                // validation={required}
                validationIconPlacement="label"
                icon="error"
                className="test1 col"
              >
                <MultiSelect
                  disabled={uploadStatus}
                  value={selectedOption}
                  list={AudioList}
                  placeholder="Select type of audio"
                  getTitle={(el: any) => el.label}
                  getKey={(el: any) => el.label}
                  // isFilterable={true}
                  onSelect={(key: any) => {
                    handleInputChange('selectedOption', key);
                  }}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="removePadding">
        <div className="container">
          <div className="row">
            <InputGroup
              label="Select what you need from studio"
              validation={required}
              validationIconPlacement="label"
              icon="error"
              className="test1"
            >
              <MultiSelect
                disabled={uploadStatus}
                value={selectedStudioOption}
                list={shareStudioOptions}
                placeholder="Select at least one"
                getTitle={(el: any) => el.label}
                getKey={(el: any) => el.label}
                // isFilterable={true}
                onSelect={(key: any) => {
                  handleInputChange('selectedStudioOption', key);
                }}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      {/* Register Assets: */}
      {/* {selectedStudioOption.includes(Register) && ( */}
      {optionSelected(Register) && (
        <div className="sectionWrapper">
          <div className="row">
            <div className="col-11">
              <Label size="lg" text="Register Asset(s) | Final Audio" />
            </div>
            <div className="col textRight">
              <Button
                className="trashBttn"
                colorType="danger"
                containerStyle="link"
                icon="trash-can"
                size="sm"
                onClick={() => handleOptionChange(Register)}
              ></Button>
            </div>
          </div>

          <div className="row">
            <div className="col-6 pt20">
              <InputGroup label="UPC Number">
                <Input
                  maxLength={13}
                  onChange={(e) => {
                    (regexPattern.isDigit.test(e.target.value) ||
                      !e.target.value.length) &&
                      handleInputChange('upc', e.target.value);
                  }}
                  placeholder="Enter UPC Number"
                  size="md"
                  disabled={uploadStatus}
                  value={upc}
                />
              </InputGroup>
            </div>
            <div className="col-6 pt20">
              <InputGroup label="ISRC Number">
                <Input
                  maxLength={13}
                  onChange={(e) =>
                    (regexPattern.isAlphaNum.test(e.target.value) ||
                      !e.target.value.length) &&
                    handleInputChange('isrc', e.target.value)
                  }
                  placeholder="Enter ISRC Number"
                  size="md"
                  disabled={uploadStatus}
                  value={isrc}
                />
              </InputGroup>
            </div>
            <div className="col-6 pt20">
              <InputGroup label="Due Date">
                <Datepicker
                  placeholder="MM/DD/YYYY"
                  containerStyle="outline"
                  onChange={(d: any) =>
                    handleInputChange('register_asset_due_date', d)
                  }
                  includeDateIntervals={[
                    {
                      start: new Date(),
                      end: new Date(
                        new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                      ),
                    },
                  ]}
                  selected={register_asset_due_date}
                  disabled={uploadStatus}
                  size="md"
                />
              </InputGroup>
            </div>
            <div className="col-6 pt20">
              <InputGroup label="Studio Information">
                <Input
                  onChange={(e) =>
                    handleInputChange('studio_info', e.target.value)
                  }
                  placeholder="Enter studio information"
                  size="md"
                  disabled={uploadStatus}
                  value={studio_info}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      )}

      {/* Watermarking: */}
      {/* {selectedStudioOption.includes(Register) && ( */}
      {optionSelected(Watermarking) && (
        <div className="sectionWrapper">
          <div className="row">
            <div className="col-11">
              <Label size="lg" text="Watermark" />
            </div>
            <div className="col textRight">
              <Button
                className="trashBttn"
                colorType="danger"
                containerStyle="link"
                icon="trash-can"
                size="sm"
                onClick={() => handleOptionChange(Watermarking)}
              ></Button>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pt20">
              <InputGroup label="WBS Number">
                <Input
                  onChange={(e) =>
                    handleInputChange('watermark_wbs', e.target.value)
                  }
                  placeholder="Enter WBS number"
                  size="md"
                  disabled={uploadStatus}
                  value={watermark_wbs}
                />
              </InputGroup>
            </div>
            <div className="col-6 pt20">
              <InputGroup
                label="Approved By"
                validation={inputValidate.watermark_approved_by}
              >
                <Input
                  onChange={(e) =>
                    handleInputChange('watermark_approved_by', e.target.value)
                  }
                  placeholder="Enter approver's email"
                  size="md"
                  disabled={uploadStatus}
                  value={watermark_approved_by}
                />
              </InputGroup>
            </div>
            <div className="pt20">
              <InputGroup label="Job Description">
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    handleInputChange(
                      'watermark_job_description',
                      e.target.value
                    )
                  }
                  placeholder="Give information to studio about the task"
                  size="md"
                  disabled={uploadStatus}
                  value={watermark_job_description}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      )}

      {/* Audio Edit: */}
      {/* {selectedStudioOption.includes(Register) && ( */}
      {optionSelected(Audio) && (
        <div className="sectionWrapper">
          <div className="row">
            <div className="col-11">
              <Label size="lg" text="Audio Edit" />
            </div>
            <div className="col textRight">
              <Button
                className="trashBttn"
                colorType="danger"
                containerStyle="link"
                icon="trash-can"
                size="sm"
                onClick={() => handleOptionChange(Audio)}
              ></Button>
            </div>
          </div>
          <div className="row">
            <div className="col-6 pt20">
              <InputGroup
                label="Send Reference to"
                validation={inputValidate.audio_edit_send_ref_email}
              >
                <Input
                  onChange={(e) =>
                    handleInputChange(
                      'audio_edit_send_ref_email',
                      e.target.value
                    )
                  }
                  placeholder="Enter refered person’s email"
                  size="md"
                  value={audio_edit_send_ref_email}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            <div className="col-6 pt20">
              <InputGroup
                label="WBS#"
                validation={inputValidate.audio_edit_wbs}
              >
                <Input
                  onChange={(e) =>
                    handleInputChange('audio_edit_wbs', e.target.value)
                  }
                  placeholder="Enter WBS number"
                  size="md"
                  value={audio_edit_wbs}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            <div className="col-6 pt20">
              <InputGroup label="Due Date">
                <Datepicker
                  placeholder="MM/DD/YYYY"
                  containerStyle="outline"
                  onChange={(d: any) =>
                    handleInputChange('audio_edit_due_date', d)
                  }
                  includeDateIntervals={[
                    {
                      start: new Date(),
                      end: new Date(
                        new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                      ),
                    },
                  ]}
                  selected={audio_edit_due_date}
                  size="md"
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            <div className="col-6 pt20">
              <InputGroup label="Job Description">
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    handleInputChange(
                      'audio_edit_job_description',
                      e.target.value
                    )
                  }
                  placeholder="Enter your job description"
                  disabled={uploadStatus}
                  size="md"
                  value={audio_edit_job_description}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      )}

      {/* Mastering: */}
      {/* {selectedStudioOption.includes(Register) && ( */}
      {optionSelected(Mastering) && (
        <div className="sectionWrapper">
          <div className="row">
            <div className="col-11">
              <Label size="lg" text="Mastering" />
            </div>
            <div className="col textRight">
              <Button
                className="trashBttn"
                colorType="danger"
                containerStyle="link"
                icon="trash-can"
                size="sm"
                onClick={() => handleOptionChange(Mastering)}
              ></Button>
            </div>
          </div>
          <div className="row ">
            <div className="col-6 pt20">
              <InputGroup
                label="Send Reference to"
                validation={inputValidate.mastering_send_ref_email}
              >
                <Input
                  onChange={(e) =>
                    handleInputChange(
                      'mastering_send_ref_email',
                      e.target.value
                    )
                  }
                  placeholder="Enter refered person’s email"
                  size="md"
                  disabled={uploadStatus}
                  value={mastering_send_ref_email}
                />
              </InputGroup>
            </div>
            <div className="col-6 pt20">
              <InputGroup label="Due Date">
                <Datepicker
                  containerStyle="outline"
                  placeholder="MM/DD/YYYY"
                  onChange={(d: any) =>
                    handleInputChange('mastering_due_date', d)
                  }
                  includeDateIntervals={[
                    {
                      start: new Date(),
                      end: new Date(
                        new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                      ),
                    },
                  ]}
                  selected={mastering_due_date}
                  size="md"
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            <div className="pt20">
              <InputGroup label="WBS#">
                <Input
                  onChange={(e) =>
                    handleInputChange('mastering_wbs', e.target.value)
                  }
                  placeholder="Enter WBS number"
                  size="md"
                  value={mastering_wbs}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      )}

      <div className="removePadding pt20">
        <div className="container">
          <div className="row">
            <InputGroup
              label="Notes"
              validation={optionSelected(Other) && required}
            >
              <Textarea
                className="extInputMargin"
                onChange={(e: any) =>
                  handleInputChange('notes', e.target.value)
                }
                placeholder="Enter notes"
                size="lg"
                value={notes}
                disabled={uploadStatus}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <Checkbox
        label="I Agree to WMG SUN"
        name="tandp"
        className="extInputMargin"
        checked={check}
        onChange={(e: any) => handleInputChange('check', e.target.checked)}
        disabled={uploadStatus}
      />
      <p className="positionTermString">
        <a
          href="https://www.wmg.com/terms-of-use"
          aria-disabled={uploadStatus}
          className="termString"
          target="_blank"
          rel="noopener noreferrer"
        >
          term and conditions
        </a>
      </p>
    </div>
  );
};

export default StudioExternal;

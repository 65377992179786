import { Button, Modal } from '@wmg-ae/legato';
import React from 'react'

const MyLinkTerminate: React.FC<any> = ({ isOpen, handleClose, handleSubmit }) => {
  const terminateMsg =
    'Terminating link means whoever has this link will not be able to upload assets through it.  Are you sure you still want to terminate link?';
  
  return (
    <Modal
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            No, Cancel
          </Button>
          <Button colorType="danger" onClick={handleSubmit}>
            Yes, Terminate
          </Button>
        </div>
      }
      onClose={handleClose}
      title="Terminate Link Confirmation"
      isOpen={isOpen}
    >
      <p className="DeleteLinkMsg">{terminateMsg}</p>
    </Modal>
  );
};

export default MyLinkTerminate
import { Button, Icon, Select } from '@wmg-ae/legato';
import React from 'react';
import { LinkTypeDownload } from '../constant';
import './AnrFilters.sass';

const MyLinkFilters: React.FC<any> = ({
  filterKey,
  updateFilterKey,
  linkType,
  setlinkType,
}) => {
  return (
    <div className="mylinks_filter_container">
      {/* <Input
        className="filter_input"
        onChange={(e) => updateFilterKey(e.target.value)}
        placeholder="Search by receipient email or expiry date"
        suffixIcon="search"
        value={filterKey}
      /> */}
      {/* <Icon className="filter_slider" name="sliders-up" /> */}
      <Button
        className="link_type"
        label="Link Type :"
        containerStyle={'link'}
      />
      <Select
        list={LinkTypeDownload}
        getKey={(el: any) => el.title}
        getTitle={(el: any) => el.title}
        onSelect={(el: any) => setlinkType(el.title)}
        value={linkType}
      >
        <Button
          className="linkdropdownbtn"
          icon={"chevron-down"}
          containerStyle={'link'}
          secondaryIcon={<Icon name={(linkType === 'Upload' && 'upload') || 'download'} prefix="fas" />}
          value={linkType}
        >
          {linkType}
        </Button>
      </Select>
    </div>
  );
};

export default MyLinkFilters;

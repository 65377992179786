import React from 'react';
// import { Activities } from '../../constant';
import ActivitiesTabContent from './ActivitiesTabContent';
// import { Activities } from '../../constant';
import { getActivityTypes } from '../../helper';

const EditFileTab3: React.FC<any> = ({ activitiesRecords }) => {
  const filteredActivitiesRecords = activitiesRecords.filter((item: any) => {
    return getActivityTypes.includes(item.action);
  });
  return (
    <div className="pt20">
      {filteredActivitiesRecords.map((activity: any) => (
        <ActivitiesTabContent
          key={activity.key}
          activity={activity}
          notification={activity.notifications}
        />
      ))}
    </div>
  );
};

export default EditFileTab3;

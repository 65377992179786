import React from 'react';
import uploadfailure from '../../assets/uploadfailed.svg';

const ExternalError: React.FC<any> = ({ type, description }) => {
  return (
    <div className="extUploadMessage extUploadFailure">
      <img src={uploadfailure} alt="upload" />
      <h1 className="extH1">{type}</h1>
      <p className="extUploadpText InvalidFailureText">
        {description}
      </p>
    </div>
  );
};

export default ExternalError;

import {
  Button,
  Datepicker,
  Input,
  InputGroup,
  Label,
  Modal,
  MultiSelect,
  Tag,
  Textarea,
} from '@wmg-ae/legato';
import React, { useEffect, useState } from 'react';
import { shareStudioOptions, StudioOptions } from '../../constant';
import { regexPattern, fieldValidation } from '../../helper';
import '../../../../shared-components/spacingStyles.sass';
import './modalStyles.sass';

const { required, error } = fieldValidation;

const ShareStudio: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  studioRow,
  getQueryEmails,
  emailList,
  contentInfoDetails,
}) => {
  const { Register, Watermark, Audio, Mastering } = StudioOptions;
  const [inputFields, setInputFields] = useState<any>({
    artistName: '',
    title: '',
    notifyUsers: '',
    selectedStudioOption: [],
    notes: '',
    regAssetUpc: '',
    regAssetIsrc: '',
    regAssetDueDate: '',
    regAssetStudioInfo: '',
    watermarkingWbs: '',
    watermarkingApprovedBy: '',
    watermarkingJD: '',
    audioEditJD: '',
    audioEditDueDate: '',
    audioEditSendRef: '',
    audioEditWbs: '',
    masteringSendRef: '',
    masteringDueDate: '',
    masteringWbs: '',
  });
  const [inputValidate, setInputValidate] = useState<any>({
    artistName: required,
    title: required,
    selectedStudioOption: required,
    watermarkingApprovedBy: required,
    audioEditSendRef: required,
    masteringSendRef: required,
  });
  // const [notifyUsersTags, setNotifyUsersTags] = useState([]);
  // const [notifyValidation, setNotifyValidation] = useState<any>(required);

  // const [isCheckVisible, setisCheckVisible] = useState(false);
  const [optionsShareStudio, setoptionsShareStudio] =
    useState(shareStudioOptions);
  // const [selectedCount, setselectedCount] = useState(
  //   optionsSelected.filter((value) => value.isOpen).length
  // );

  const [selectedOption, setselectedOption] = useState<any>([]);
  const removeEmailCheck = (email: string) => {
    const newArr = [
      ...selectedOption.filter((item: any) => item.label !== email),
    ];
    setselectedOption([...newArr]);
  };

  useEffect(() => {
    contentInfoDetails &&
      contentInfoDetails.content_id === studioRow.content_id.toString() &&
      setInputFields({
        ...inputFields,
        artistName: contentInfoDetails.artist || '',
        title: contentInfoDetails.title || '',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentInfoDetails, studioRow.content_id]);

  const validateFields = (fieldName: string, newVal: any) => {
    switch (fieldName) {
      case 'artistName':
      case 'title': {
        setInputValidate({
          ...inputValidate,
          [fieldName]: newVal ? required : error,
        });
        break;
      }
      case 'audioEditSendRef':
      case 'masteringSendRef': {
        const isValidMail = regexPattern.email.test(newVal);
        setInputValidate({
          ...inputValidate,
          [fieldName]: isValidMail ? required : error,
        });
        break;
      }
      case 'watermarkingApprovedBy':
        const isValidMailWmg = regexPattern.wmgEmail.test(newVal);
        setInputValidate({
          ...inputValidate,
          [fieldName]: isValidMailWmg ? required : error,
        });
        break;

      default:
        break;
    }
  };

  const handleFieldsChange = (fieldName: string, newVal: any) => {
    let formattedValue = newVal;
    switch (fieldName) {
      case 'regAssetDueDate':
      case 'audioEditDueDate':
      case 'masteringDueDate':
        break;
      case 'selectedStudioOption':
        let newArr = [...optionsShareStudio];
        let b: any = [];
        b = newVal.map((e: any) => e.title);
        newArr = newArr.map((element: any) => ({
          ...element,
          isOpen: b.includes(element.title),
        }));
        setoptionsShareStudio(newArr);
        break;
      case 'regAssetUpc':
        formattedValue = newVal;
        break;
      default:
        formattedValue = newVal.trimStart();
        break;
    }

    setInputFields({ ...inputFields, [fieldName]: formattedValue });
    const fieldsWithValidation = [
      'artistName',
      'title',
      'watermarkingApprovedBy',
      'audioEditSendRef',
      'masteringSendRef',
      // 'notifyUsers', // This is covered separately, merge it later
    ];
    if (fieldsWithValidation.includes(fieldName)) {
      validateFields(fieldName, formattedValue);
    }
  };

  // const handleNotifyChange = (evt: any) => {
  //   const mailList = evt.target.value.trim();
  //   const splitList = mailList.replaceAll(' ', '').split(',');
  //   let validList: any = [];
  //   handleFieldsChange('notifyUsers', mailList);

  //   splitList.map((mailId: string) => {
  //     if (regexPattern.email.test(mailId)) validList = [...validList, mailId];
  //     return mailId;
  //   });
  //   setNotifyUsersTags(validList);
  //   setNotifyValidation(validList.length ? required : error);
  // };

  const handleModalClose = () => {
    handleClose();
  };

  const handleOptionChange = (e: any, index: number) => {
    let newArr = [...optionsShareStudio];
    const isChecked = e.target.checked;
    newArr[index].isOpen = isChecked;
    const removeOption = inputFields.selectedStudioOption.filter(
      (item: any) => item.title !== e.option
    );
    switch (e.option) {
      case Register:
        setInputFields({
          ...inputFields,
          upc: '',
          isrc: '',
          register_asset_due_date: null,
          studio_info: '',
          selectedStudioOption: removeOption,
        });

        break;
      case Watermark:
        setInputFields({
          ...inputFields,
          watermark_wbs: '',
          watermark_approved_by: '',
          watermark_job_description: '',
          selectedStudioOption: removeOption,
        });
        setInputValidate({
          ...inputValidate,
          watermark_approved_by: required,
        });
        break;
      case Audio:
        setInputFields({
          ...inputFields,
          audio_edit_due_date: null,
          audio_edit_job_description: '',
          audio_edit_send_ref_email: '',
          audio_edit_wbs: '',
          selectedStudioOption: removeOption,
        });
        setInputValidate({
          ...inputValidate,
          audio_edit_wbs: required,
          audio_edit_send_ref_email: required,
        });
        break;
      case Mastering:
        setInputFields({
          ...inputFields,
          mastering_send_ref_email: '',
          mastering_due_date: null,
          mastering_wbs: '',
          selectedStudioOption: removeOption,
        });
        setInputValidate({
          ...inputValidate,
          mastering_send_ref_email: required,
        });
        break;
      default:
        break;
    }
    setoptionsShareStudio(newArr);
  };

  const submitShare = () => {
    handleSubmit({
      emailList: selectedOption.map((email: any) => email.label),
      name: 'Share to Studio by ', // ADD/APPEND logged in user and timestamp in API Params
      sharingTo: 'studio',
      file_names: [studioRow.name],
      content_id: [studioRow.content_id],
      allFields: inputFields,
      contentIdType: [{ id: studioRow.content_id, type: studioRow.type }],
      homeId: studioRow.parent_id,
    });

    // Clear all input fields and reset fields validation status
    setTimeout(() => {
      // setNotifyUsersTags([]);
      setInputFields({
        artistName: '',
        title: '',
        selectedStudioOption: [],
        notifyUsers: '',
        notes: '',
        regAssetUpc: '',
        regAssetIsrc: '',
        regAssetDueDate: '',
        regAssetStudioInfo: '',
        watermarkingWbs: '',
        watermarkingApprovedBy: '',
        watermarkingJD: '',
        audioEditJD: '',
        audioEditDueDate: '',
        audioEditSendRef: '',
        audioEditWbs: '',
        masteringSendRef: '',
        masteringDueDate: '',
        masteringWbs: '',
      });
    }, 100);
  };

  const submitBttnDisabled =
    !inputFields.artistName ||
    !inputFields.title ||
    // !notifyUsersTags.length ||
    !(inputFields.selectedStudioOption.length > 0) ||
    // (optionsShareStudio[0].isOpen && !inputFields.regAssetDueDate) ||
    (optionsShareStudio[1].isOpen &&
      !(
        inputValidate.watermarkingApprovedBy === required &&
        inputFields.watermarkingApprovedBy
      )) ||
    (optionsShareStudio[2].isOpen &&
      !(
        inputValidate.audioEditSendRef === required &&
        inputFields.audioEditSendRef
      )) ||
    // (optionsShareStudio[2].isOpen && !inputFields.audioEditWbs) ||
    (optionsShareStudio[3].isOpen &&
      !(
        inputValidate.masteringSendRef === required &&
        inputFields.masteringSendRef
      )) ||
    (optionsShareStudio[4].isOpen && !inputFields.notes);

  return (
    <div>
      <Modal
        className=""
        footer={
          <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
            <div style={{ flex: 1 }} />
            <Button
              colorType="secondary-black"
              containerStyle="outline"
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              colorType="primary"
              onClick={submitShare}
              disabled={submitBttnDisabled}
            >
              Share
            </Button>
          </div>
        }
        isOpen={isOpen}
        // isOpen={isOpen || true} // TODO: IN PROGRESS, remove later
        onClose={handleModalClose}
        size={'lg'}
        title="Share to Studio"
      >
        <div className="modalBody shareStudio pr17">
          <div className="row">
            <InputGroup label="General Info" children={undefined}></InputGroup>
          </div>

          <div className="row pt20">
            <div className="col-6">
              <InputGroup
                label="Artist Name"
                validation={inputValidate.artistName}
              >
                <Input
                  onChange={(e) =>
                    handleFieldsChange('artistName', e.target.value)
                  }
                  placeholder="Enter Artist Name"
                  value={inputFields.artistName}
                />
              </InputGroup>
            </div>

            <div className="col-6">
              <InputGroup label="Title" validation={inputValidate.title}>
                <Input
                  onChange={(e) => handleFieldsChange('title', e.target.value)}
                  placeholder="Enter Title"
                  value={inputFields.title}
                />
              </InputGroup>
            </div>
          </div>

          <div className="row pt20">
            <InputGroup
              label="Select what do you need from studio"
              validation={required}
            >
              <MultiSelect
                isClearable
                disabled={false}
                value={inputFields.selectedStudioOption}
                list={optionsShareStudio}
                placeholder="Select at least one"
                getTitle={(el: any) => el.title}
                getKey={(el: any) => el.title}
                // isFilterable={true}
                onSelect={(key: any) => {
                  handleFieldsChange('selectedStudioOption', key);
                }}
              />
            </InputGroup>
          </div>
          {/* Register Assets: */}
          {optionsShareStudio[0].isOpen && (
            <div className="sectionWrapper">
              <div className="row">
                <div className="col-11">
                  <Label size="lg" text="Register Asset(s) | Final Audio" />
                </div>
                <div className="col textRight">
                  <Button
                    className="trashBttn"
                    colorType="danger"
                    containerStyle="link"
                    icon="trash-can"
                    size="sm"
                    onClick={() =>
                      handleOptionChange(
                        { target: { checked: false }, option: Register },
                        0
                      )
                    }
                  ></Button>
                </div>
              </div>

              <div className="row">
                <div className="col-6 pt20">
                  <InputGroup label="UPC Number">
                    <Input
                      className="number"
                      maxLength={13}
                      onChange={(e) => {
                        (regexPattern.isDigit.test(e.target.value) ||
                          !e.target.value.length) &&
                          handleFieldsChange('regAssetUpc', e.target.value);
                      }}
                      placeholder="Enter UPC Number"
                      size="md"
                      value={inputFields.regAssetUpc}
                    />
                  </InputGroup>
                </div>
                <div className="col-6 pt20">
                  <InputGroup label="ISRC Number">
                    <Input
                      maxLength={13}
                      onChange={(e) =>
                        handleFieldsChange('regAssetIsrc', e.target.value)
                      }
                      placeholder="Enter ISRC Number"
                      size="md"
                      value={inputFields.regAssetIsrc}
                    />
                  </InputGroup>
                </div>
                <div className="col-6 pt20">
                  <InputGroup label="Due Date">
                    <Datepicker
                      placeholder="MM/DD/YYYY"
                      containerStyle="outline"
                      onChange={(d: any) =>
                        handleFieldsChange('regAssetDueDate', d)
                      }
                      selected={inputFields.regAssetDueDate}
                      size="md"
                      includeDateIntervals={[
                        {
                          start: new Date(),
                          end: new Date(
                            new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                          ),
                        },
                      ]}
                    />
                  </InputGroup>
                </div>
                <div className="col-6 pt20">
                  <InputGroup label="Studio Information">
                    <Input
                      onChange={(e) =>
                        handleFieldsChange('regAssetStudioInfo', e.target.value)
                      }
                      placeholder="Enter studio information"
                      size="md"
                      value={inputFields.regAssetStudioInfo}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          )}

          {/* Watermarking: */}
          {optionsShareStudio[1].isOpen && (
            <div className="sectionWrapper">
              <div className="row">
                <div className="col-11">
                  <Label size="lg" text="Watermark" />
                </div>
                <div className="col textRight">
                  <Button
                    className="trashBttn"
                    colorType="danger"
                    containerStyle="link"
                    icon="trash-can"
                    size="sm"
                    onClick={() =>
                      handleOptionChange(
                        { target: { checked: false }, option: Watermark },
                        1
                      )
                    }
                  ></Button>
                </div>
              </div>
              <div className="row">
                <div className="col-6 pt20">
                  <InputGroup label="WBS Number">
                    <Input
                      onChange={(e) =>
                        handleFieldsChange('watermarkingWbs', e.target.value)
                      }
                      placeholder="Enter WBS number"
                      size="md"
                      value={inputFields.watermarkingWbs}
                    />
                  </InputGroup>
                </div>
                <div className="col-6 pt20">
                  {' '}
                  <InputGroup
                    label="Approved By"
                    validation={inputValidate.watermarkingApprovedBy}
                  >
                    <Input
                      onChange={(e) =>
                        handleFieldsChange(
                          'watermarkingApprovedBy',
                          e.target.value
                        )
                      }
                      placeholder="Enter approver's email"
                      size="md"
                      value={inputFields.watermarkingApprovedBy}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 pt20">
                  <InputGroup label="Job Description">
                    <Input
                      maxLength={100}
                      onChange={(e) =>
                        handleFieldsChange('watermarkingJD', e.target.value)
                      }
                      placeholder="Give information to studio about the task"
                      size="md"
                      value={inputFields.watermarkingJD}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          )}

          {/* Audio Edit: */}
          {optionsShareStudio[2].isOpen && (
            <div className="sectionWrapper">
              <div className="row">
                <div className="col-11">
                  <Label size="lg" text="Audio Edit" />
                </div>
                <div className="col textRight">
                  <Button
                    className="trashBttn"
                    colorType="danger"
                    containerStyle="link"
                    icon="trash-can"
                    size="sm"
                    onClick={() =>
                      handleOptionChange(
                        { target: { checked: false }, option: Audio },
                        2
                      )
                    }
                  ></Button>
                </div>
              </div>
              <div className="row">
                <div className="col-6 pt20">
                  <InputGroup
                    label="Send Reference to"
                    validation={inputValidate.audioEditSendRef}
                  >
                    <Input
                      onChange={(e) =>
                        handleFieldsChange('audioEditSendRef', e.target.value)
                      }
                      placeholder="Enter email"
                      size="md"
                      value={inputFields.audioEditSendRef}
                    />
                  </InputGroup>
                </div>

                <div className="col-6 pt20">
                  <InputGroup label="WBS#" validation={undefined}>
                    <Input
                      onChange={(e) =>
                        handleFieldsChange('audioEditWbs', e.target.value)
                      }
                      placeholder="Enter WBS number for audio edit"
                      size="md"
                      value={inputFields.audioEditWbs}
                    />
                  </InputGroup>
                </div>
                <div className="col-6 pt20">
                  <InputGroup label="Due Date">
                    <Datepicker
                      placeholder="MM/DD/YYYY"
                      containerStyle="outline"
                      onChange={(d: any) =>
                        handleFieldsChange('audioEditDueDate', d)
                      }
                      selected={inputFields.audioEditDueDate}
                      size="md"
                      includeDateIntervals={[
                        {
                          start: new Date(),
                          end: new Date(
                            new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                          ),
                        },
                      ]}
                    />
                  </InputGroup>
                </div>
                <div className="col-6 pt20">
                  <InputGroup label="Job Description">
                    <Input
                      maxLength={100}
                      onChange={(e) =>
                        handleFieldsChange('audioEditJD', e.target.value)
                      }
                      placeholder="Enter your job description"
                      size="md"
                      value={inputFields.audioEditJD}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          )}

          {/* Mastering: */}
          {optionsShareStudio[3].isOpen && (
            <div className="sectionWrapper">
              <div className="row">
                <div className="col-11">
                  <Label size="lg" text="Mastering" />
                </div>
                <div className="col textRight">
                  <Button
                    className="trashBttn"
                    colorType="danger"
                    containerStyle="link"
                    icon="trash-can"
                    size="sm"
                    onClick={() =>
                      handleOptionChange(
                        { target: { checked: false }, option: Mastering },
                        3
                      )
                    }
                  ></Button>
                </div>
              </div>
              <div className="row ">
                <div className="col-6 pt20">
                  <InputGroup
                    label="Send Reference To"
                    validation={inputValidate.masteringSendRef}
                  >
                    <Input
                      onChange={(e) =>
                        handleFieldsChange('masteringSendRef', e.target.value)
                      }
                      placeholder="Enter email"
                      size="md"
                      value={inputFields.masteringSendRef}
                    />
                  </InputGroup>
                </div>
                <div className="col-6 pt20">
                  <InputGroup label="Due Date">
                    <Datepicker
                      containerStyle="outline"
                      placeholder="MM/DD/YYYY"
                      onChange={(d: any) =>
                        handleFieldsChange('masteringDueDate', d)
                      }
                      selected={inputFields.masteringDueDate}
                      size="md"
                      includeDateIntervals={[
                        {
                          start: new Date(),
                          end: new Date(
                            new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                          ),
                        },
                      ]}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 pt20">
                  <InputGroup label="WBS#">
                    <Input
                      onChange={(e) =>
                        handleFieldsChange('masteringWbs', e.target.value)
                      }
                      placeholder="Enter WBS number for mastering"
                      size="md"
                      value={inputFields.masteringWbs}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          )}

          <div className="row pt20">
            <InputGroup label="Who to notify ?">
              <MultiSelect
                isClearable
                disabled={false}
                value={selectedOption}
                list={emailList}
                placeholder="Enter email"
                searchInputPlaceholder="Search"
                getTitle={(el: any) => el.label}
                getKey={(el: any) => el.label}
                isFilterable={true}
                onSelect={(key: any) => {
                  setselectedOption(key);
                }}
                onFilter={(l: ReadonlyArray<any>, term: any) =>
                  l.filter((f) =>
                    f.label
                      .toLocaleLowerCase()
                      .includes(term.toLocaleLowerCase())
                  )
                }
              />
            </InputGroup>
            <div className="uploadTagComponent">
              {selectedOption.map((email: any, idx: number) => (
                <Tag
                  key={idx}
                  text={email.label}
                  colorType="dark"
                  containerStyle="tint"
                  removable
                  onClickIcon={() => removeEmailCheck(email.label)}
                  tooltip={false}
                />
              ))}
            </div>
          </div>

          <div className="row pt20">
            <div className="col">
              <InputGroup
                label="Notes"
                validation={optionsShareStudio[4].isOpen ? required : {}}
              >
                <Textarea
                  maxLength={500}
                  onChange={(e) => handleFieldsChange('notes', e.target.value)}
                  placeholder="Enter notes"
                  size="lg"
                  value={inputFields.notes}
                />
                {/* <span>{500 - inputFields.notes.length} Characters left.</span> */}
              </InputGroup>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShareStudio;

import React, { useState } from 'react';
import './DashboardTabs.sass';

import { Button, Tabs } from '@wmg-ae/legato';
import { ITabItem } from '@wmg-ae/legato/build/components/Tabs/types';
import UploadFiles from './modals/UploadFile';

const DashboardTabs: React.FC<any> = ({
  changeTab,
  toggleReqLinkModal,
  submitUpload,
  upload_transfer_spec,
  nodeToken,
  getQueryEmails,
  emailList,
  homeId,
  breadcrumbOptions,
  isLabelUser,
  allowAccess,
  tab_id,
  setTransferOpen,
  increaseUploadCount,
  currentUser,
  workspace,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const toggleUploadFileModal = (status = true) => {
    setIsUploadModalOpen(status);
  };

  return (
    <>
      <div className="AnR_Tab_container">
        {tab_id === '1' && (
          <Tabs
            className="Anr_Tabs"
            mode={'in-page'}
            list={[
              { id: '1', title: 'Assets' },
              { id: '2', title: 'My Links' },
              // { id: "3", title: "Deleted Files (10)"},
            ]}
            onClick={(clickedItem: ITabItem): void => {
              // console.log("Tabs..", clickedItem);
              changeTab(clickedItem.id);
            }}
            initialTabId={'1'}
          />
        )}
        {tab_id === '2' && (
          <Tabs
            className="Anr_Tabs"
            mode={'in-page'}
            list={[
              { id: '1', title: 'Assets' },
              { id: '2', title: 'My Links' },
              // { id: "3", title: "Deleted Files (10)"},
            ]}
            onClick={(clickedItem: ITabItem): void => {
              // console.log("Tabs..", clickedItem);
              changeTab(clickedItem.id);
            }}
            initialTabId={'2'}
          />
        )}
        <div className="Tab_End_Buttons">
          <Button
            containerStyle={'outline'}
            icon="upload"
            colorType="secondary-black"
            label="Upload File"
            className="upload_buttons"
            onClick={() => toggleUploadFileModal()}
          />
          <Button
            icon="chain"
            label="Generate Link"
            className="upload_buttons"
            onClick={() => toggleReqLinkModal(true)}
          />
        </div>
      </div>

      <div>
        <UploadFiles
          isOpen={isUploadModalOpen}
          handleClose={() => toggleUploadFileModal(false)}
          submitUpload={submitUpload}
          upload_transfer_spec={upload_transfer_spec}
          nodeToken={nodeToken}
          getQueryEmails={getQueryEmails}
          emailList={emailList}
          homeId={homeId}
          breadcrumbOptions={breadcrumbOptions}
          isLabelUser={isLabelUser}
          allowAccess={allowAccess}
          setTransferOpen={setTransferOpen}
          increaseUploadCount={increaseUploadCount}
          currentUser={currentUser}
          workspace={workspace}
        />
      </div>
    </>
  );
};

export default DashboardTabs;

import {
  Button,
  InputGroup,
  Modal,
  Radio,
  MultiSelect,
  Tag,
  Icon,
  Input,
  Textarea,
} from '@wmg-ae/legato';
import { Connect } from '@ibm-aspera/connect-sdk-js';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import '../../../../shared-components/spacingStyles.sass';
import './modalStyles.sass';
import { fieldValidation } from '../../helper';
import { AudioList } from '../../../external/constant';
const baseURL = process.env.REACT_APP_API_ENDPOINT;
const apiKey = { 'x-api-key': process.env.REACT_APP_GATEWAY_KEY || '' };

const ApiURL = {
  createFolder: `${baseURL}/asset-operations/create`,
};
const UploadFiles: React.FC<any> = ({
  isOpen,
  handleClose,
  submitUpload,
  nodeToken,
  upload_transfer_spec,
  emailList,
  homeId,
  breadcrumbOptions,
  isLabelUser,
  allowAccess,
  setTransferOpen,
  increaseUploadCount,
  currentUser,
  workspace,
}) => {
  const [isFile, setisFile] = useState(true);
  const [everyOne, setEveryOne] = useState('N');
  const [uploadMore, setuploadMore] = useState(false);
  const [uploadInProgress, setuploadInProgress] = useState(false);
  const [uploadSucces, setuploadSucces] = useState(true);
  const [uploadPause, setuploadPause] = useState(false);
  const [folderCid, setFolderCid] = useState<any>(null);
  const [inputFields, setInputFields] = useState<any>({
    artist: '',
    title: '',
    package_name: '',
    audio_type_array: [],
    notes: '',
    upc: 'null',
    isrc: '',
    version: '',
    engineer: '',
    studio_name: '',
    producer: '',
    register_asset_due_date: '',
    studio_info: '',
    watermark_wbs: '',
    watermark_approved_by: '',
    watermark_job_description: '',
    audio_edit_due_date: '',
    audio_edit_job_description: '',
    audio_edit_send_ref_email: '',
    audio_edit_wbs: '',
    mastering_send_ref_email: '',
    mastering_due_date: '',
    mastering_wbs: '',
    meta_tasks: '',
    selectedAudioOption: [],
  });

  const {
    artist,
    title,
    package_name,
    notes,
    selectedAudioOption,
  } = inputFields;

  const handleInputChange = (field: string, value: any) => {
    setInputFields({ ...inputFields, [field]: value });
  };
  let connectClient = new Connect();
  connectClient.initSession();

  const [selectedOption, setselectedOption] = useState<any>([]);

  const removeEmailCheck = (email: string) => {
    if (!uploadStatus) {
      let newArr = selectedOption;
      newArr = newArr.filter((item: any) => item.label !== email);
      setselectedOption([...newArr]);
    }
  };

  const [uploadStatus, setuploadStatus] = useState(false);
  const [fileNames, setfileNames] = useState<any>([]);
  const [uploadReqId, setuploadReqId] = useState('');

  const extractFiles = (data: any) => {
    setuploadMore(false);
    const { files } = data.dataTransfer;
    // const files = data.target.files;
    let newFileList: any = [];
    if (files.length > 0) {
      // getAllTransfers(0);
      files.map((file: any) => {
        // console.log(file)
        const extensions = isFile
          ? file.name.split(/[\\/]/).slice(-1).pop().split('.').slice(-1).pop()
          : '';
        // console.log(typeof(extensions));
        if (extensions !== 'exe' && extensions !== 'zip') {
          newFileList = [...newFileList, { source: file.name }];
        }
        return file;
      });
      // for (let i = 0; i < files.length; i++) {
      //   newFileList.push({ source: files[i].name });
      // }
      if (package_name === '') {
        let value = files[0].name.split(/[\\/]/).slice(-1)[0];
        if (isFile) {
          const lastIndexDot = value.lastIndexOf('.');
          value = value.slice(0, lastIndexDot);
        }
        handleInputChange(
          'package_name',
          value
        );
      }
      setfileNames(newFileList);
    }
  };
  const handleUpload = () => {
    // submitUpload();
    uploadFile();
  };

  const getAllTransfersCallback = (transfersInfo: any) => {
    // const { iteration_token, result_count, transfers } = transfersInfo;
    const { transfers } = transfersInfo;
    for (let i = transfers.length - 1; i >= 0; i--) {
      if (transfers[i].uuid === uploadReqId) {
        // console.log(transfers[i].status, transfers[i].percentage);
        // console.log(transfers[i]);
        if (transfers[i].status === 'completed') {
          let removeEventListener = connectClient.removeEventListener();
          if (removeEventListener) {
            setuploadStatus(false);
            setuploadMore(true);
            setuploadSucces(true);
            inputFields.audio_type_array = inputFields.selectedAudioOption.map(
              (item: any) => item.label
            );

            submitUpload({
              upload: 'success',
              email:
                (!!selectedOption.length &&
                  selectedOption.map((email: any) => email.label)) ||
                [],
              fileNames: transfers[i].transfer_spec.paths,
              homeId: homeId,
              folderCid: folderCid,
              folderFileName: package_name,
              allow_access_to_workspace_users: (isLabelUser && everyOne) || 'N',
              currentLevel: breadcrumbOptions[breadcrumbOptions.length - 1],
              type: isFile,
              additional_metadata: { ...inputFields },
            });
            clearData();
          }
        } else if (transfers[i].status === 'failed') {
          let removeEventListener = connectClient.removeEventListener();
          if (removeEventListener) {
            connectClient.stopTransfer(uploadReqId);
            setuploadStatus(false);
            setuploadSucces(false);
            submitUpload({
              upload: 'failure',
              email: selectedOption,
              fileNames: transfers[i].transfer_spec.paths,
              homeId: homeId,
              folderCid: folderCid,
              folderFileName: package_name,
              allow_access_to_workspace_users: (isLabelUser && everyOne) || 'N',
              currentLevel: breadcrumbOptions[breadcrumbOptions.length - 1],
              type: isFile,
            });
            clearData();
            setTransferOpen({ isOpen: false, action: '' });
          }
        }
      }
      // console.log(transfers[i].status);
    }
    // setemail('');
    // console.log(transfersInfo);
  };

  const getAllTransfers = () => {
    const iterationToken = 0;
    /**
     * Get information about all of the transfers in Connect's activity window.
     */
    connectClient.getAllTransfers(
      {
        success: getAllTransfersCallback,
      },
      iterationToken
    );
  };
  const uploadFile = async () => {
    let transferSpec = { ...upload_transfer_spec };
    transferSpec.paths = fileNames;
    transferSpec.token = `Bearer ${nodeToken}`;
    transferSpec.tags = { ...upload_transfer_spec.tags };
    transferSpec.tags.aspera = { ...upload_transfer_spec.tags.aspera };
    transferSpec.tags.aspera.aspera_ui = {
      ...upload_transfer_spec.tags.aspera.aspera_ui,
    };
    transferSpec.tags.aspera.node = {
      ...upload_transfer_spec.tags.aspera.node,
    };
    transferSpec.tags.aspera.node.file_id = homeId;
    transferSpec.tags.aspera.aspera_ui.transfer_name = `Uploading ${fileNames[0]}`;
    // console.log(transferSpec);
    setTransferOpen({isOpen:true,action:'upload'});
    setuploadInProgress(true);
    createFolder(transferSpec, { package_name, artist, title, isUnique: true })
    // console.log(`Transfer started: ${response}`);
  };
  const asperaClientUpload = (transferSpec: any, asperaConnectSettings: any, details: any) => {
    const { folderCIDToDelete, name } = details;
    connectClient
      .startTransferPromise(transferSpec, asperaConnectSettings)
      .then((response: any) => {
        setuploadReqId(response.transfer_specs[0].uuid);
        setuploadStatus(true);
        increaseUploadCount();
        handleClose();
      })
      .catch((err: any) => {
        setselectedOption([]);
        setfileNames([
          { source: 'Unable to connect with Aspera, try again later' },
        ]);
        clearData();
        handleClose();
        setuploadStatus(false);
        setTransferOpen({ isOpen: false, action: '' });
        setuploadSucces(false);
        submitUpload({
          upload: 'failure',
          email: selectedOption,
          fileNames: [name],
          homeId: homeId,
          folderCid: folderCIDToDelete,
          folderFileName: name,
          allow_access_to_workspace_users: (isLabelUser && everyOne) || 'N',
          currentLevel: breadcrumbOptions[breadcrumbOptions.length - 1],
          type: isFile,
        })
        console.log(err);
      });
  }
  const createFolder =
    (upload_transfer_spec: any, details: any) => {
      const { package_name, artist, title, isUnique } = details;
      var folderCIDToDelete: any;
      var name = package_name;
      if (!isUnique) {
        const options = { hour12: false };
        const today = (new Date()).toLocaleTimeString('es-ES', options);
        name = name + '-' + today;
      }
      const params = {
        token: `Bearer ${nodeToken}`, // Do not remove 'Bearer '
        remote_host: upload_transfer_spec.remote_host,
        access_key: upload_transfer_spec.tags.aspera.node.access_key,
        home_file_id: homeId,
        folder_name: name,
        user_email: currentUser.email,
        user_id: workspace.user_id,
        user_name: currentUser.fullName,
        workspace_id: workspace.workspace_id,
        creating_at_root: homeId === workspace.home_file_id,
        owner_role: currentUser.roleName[0],
        allow_access_to_workspace_users: (isLabelUser && everyOne) || 'N',
        additional_metadata: {
          artist,
          title,
          package_name: name,
        }
      };
      const config = {
        method: 'post',
        url: ApiURL.createFolder,
        data: params,
        headers: apiKey,
      };

      axios(config)
        .then(({ data }): any => {
          if (!data.error && !data.hasOwnProperty('error') && data.data.content_id) {
            // console.log(newtransferSpec);
            setFolderCid(data.data.content_id);
            setInputFields({ ...inputFields, package_name: name });
            folderCIDToDelete = data.data.content_id;
            upload_transfer_spec.tags.aspera.node.file_id = data.data.content_id.toString();
            // const asperaConnectSettings = {};
            const asperaConnectSettings = { allow_dialogs: false };
            asperaClientUpload(upload_transfer_spec, asperaConnectSettings, { folderCIDToDelete, name })
          }
          else {
            createFolder(upload_transfer_spec, { ...details, isUnique: false })
          }
        })
        .catch((error: any) => {
          console.log('createFolder API Error.... ', error);
          setuploadStatus(false);
          setTransferOpen({ isOpen: false, action: '' });
          setuploadSucces(false);
          submitUpload({
            upload: 'failure',
            email: selectedOption,
            fileNames: [name],
            homeId: homeId,
            folderCid: folderCIDToDelete,
            folderFileName: name,
            allow_access_to_workspace_users: (isLabelUser && everyOne) || 'N',
            currentLevel: breadcrumbOptions[breadcrumbOptions.length - 1],
            type: isFile,
          })
          clearData();
          handleClose();
        });
    };
  const upload = async () => {
    const options = {
      allowMultipleSelection: true,
      allowedFileTypes: [
        {
          filter_name: 'All files',
          extensions: ['*'],
        },
      ],
    };
    connectClient
      .showSelectFileDialogPromise(options)
      .then(extractFiles)
      .catch((error: any) => {
        console.error('Unable to select files', error);
        setfileNames([
          { source: 'Unable to connect with Aspera, try again later' },
        ]);
      });
  };
  const uploadFolder = async () => {
    const options = {
      allowMultipleSelection: true,
      allowedFileTypes: [
        {
          filter_name: 'All files',
          extensions: ['*'],
        },
      ],
    };
    connectClient
      .showSelectFolderDialogPromise(options)
      .then(extractFiles)
      .catch((error: any) => {
        console.error('Unable to select folders', error);
        setfileNames([
          { source: 'Unable to connect with Aspera, try again later' },
        ]);
      });
  };
  useEffect(() => {
    if (uploadStatus)
      connectClient.addEventListener('transfer', () => {
        getAllTransfers();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus]);

  const OpenUpload = () => {
    if (isFile) {
      upload();
    } else {
      uploadFolder();
    }
  };

  const handleDone = () => {
    clearData();
    handleClose();
  };

  const clearData = () => {
    setfileNames([]);
    setuploadInProgress(false);
    setisFile(true);
    setselectedOption([]);
    setuploadMore(false);
    setuploadSucces(true);
    setuploadPause(false);
    setEveryOne('N');
    setFolderCid(null)
    setInputFields({
      artist: '',
      title: '',
      package_name: '',
      audio_type_array: [],
      notes: '',
      upc: 'null',
      isrc: '',
      version: '',
      engineer: '',
      studio_name: '',
      producer: '',
      register_asset_due_date: '',
      studio_info: '',
      watermark_wbs: '',
      watermark_approved_by: '',
      watermark_job_description: '',
      audio_edit_due_date: '',
      audio_edit_job_description: '',
      audio_edit_send_ref_email: '',
      audio_edit_wbs: '',
      mastering_send_ref_email: '',
      mastering_due_date: '',
      mastering_wbs: '',
      meta_tasks: '',
      selectedAudioOption: [],
    })
  };

  const handlePauseCancel = (cancel: any) => {
    if (cancel && !uploadStatus && !uploadInProgress) {
      // setOpen(false);
      handleDone();
    } else if (cancel && uploadStatus && uploadInProgress) {
      // setOpen(false);
      handleClose();
    } else if (!cancel && !uploadPause) {
      connectClient.stopTransfer(uploadReqId);
      setuploadPause(true);
    } else if (!cancel && uploadPause) {
      connectClient.resumeTransfer(uploadReqId, {});
      setuploadPause(false);
    }
  };

  const removeFiles = (file: any) => {
    let newArr = fileNames;
    newArr = newArr.filter((item: any) => item.source !== file);
    setfileNames([...newArr]);
  };

  const uploadBttnDisabled =
    artist.length &&
    title.length &&
    package_name.length &&
    fileNames.length

  return (
    <Modal
      isOpen={isOpen}
      size={'lg'}
      footer={
        <div className="row mx0">
          <div className="col-10" />
          <Button
            label="cancel"
            className="col-1 extCancelBtn btnHeight"
            containerStyle="outline"
            colorType="info"
            onClick={() => handlePauseCancel(true)}
          />
          <Button
            label="Upload"
            className="col-1 btnHeight"
            disabled={!uploadBttnDisabled || uploadStatus || uploadInProgress}
            onClick={handleUpload}
          />
        </div>
      }
      icon="upload"
      onClose={() => handlePauseCancel(true)}
      title="Upload File(s)"
    >
      <div className="uploadModal pr17">
        <div className="row">
          <div className="col-6">
            <InputGroup
              label="Artist"
              validation={fieldValidation.required}
              validationIconPlacement="label"
              icon="error"
              className="test1"
            >
              <Input
                className="extInputMargin"
                onChange={(e: any) => handleInputChange('artist', e.target.value)}
                placeholder="Enter Artist "
                suffixIcon="user"
                value={artist}
                disabled={uploadStatus || uploadInProgress}
              />
            </InputGroup>
          </div>
          <div className="col-6">
            <InputGroup
              label="Title"
              validation={fieldValidation.required}
              validationIconPlacement="label"
              icon="error"
              className="test1"
            >
              <Input
                className="extInputMargin"
                onChange={(e: any) => handleInputChange('title', e.target.value)}
                placeholder="Enter title for asset(s)"
                value={title}
                disabled={uploadStatus || uploadInProgress}
              />
            </InputGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <InputGroup
              label="Package Name"
              validation={fieldValidation.required}
              validationIconPlacement="label"
              icon="error"
              className="test1"
            >
              <Input
                className="extInputMargin"
                onChange={(e: any) => handleInputChange('package_name', e.target.value)}
                placeholder="Enter Package Name"
                value={package_name}
                disabled={uploadStatus || uploadInProgress}
              />
            </InputGroup>
          </div>
          <div className="col-6">
            <InputGroup
              label="Audio Type"
              validationIconPlacement="label"
              icon="error"
              className="test1 col"
            >
              <MultiSelect
                disabled={uploadStatus || uploadInProgress}
                value={selectedAudioOption}
                list={AudioList}
                placeholder="Select type of audio"
                getTitle={(el: any) => el.label}
                getKey={(el: any) => el.label}
                // isFilterable={true}
                onSelect={(key: any) => {
                  handleInputChange('selectedAudioOption', key);
                }}
              />
            </InputGroup>
          </div>
        </div>
        <InputGroup label="Who to notify?">
          <MultiSelect
            isClearable
            disabled={uploadStatus || uploadInProgress}
            value={selectedOption}
            list={emailList}
            placeholder="Enter email"
            searchInputPlaceholder="Search"
            getTitle={(el: any) => el.label}
            getKey={(el: any) => el.label}
            isFilterable={true}
            onSelect={(key: any) => {
              setselectedOption(key);
            }}
            onFilter={(l: ReadonlyArray<any>, term: any) =>
              l.filter((f) =>
                f.label.toLocaleLowerCase().includes(term.toLocaleLowerCase())
              )
            }
          />
        </InputGroup>
        <div className="uploadTagComponent">
          {selectedOption.map((email: any, idx: number) => (
            <Tag
              key={idx}
              text={email.label}
              colorType="dark"
              containerStyle="tint"
              removable
              onClickIcon={() => removeEmailCheck(email.label)}
              tooltip={false}
            />
          ))}
        </div>

        {isLabelUser && (
          <div className="uploadLabels">
            <InputGroup label="Who can access these assets within the label ?">
              <br />
              <Radio
                className="pr17"
                checked={everyOne === 'N'}
                label="Only Me"
                name="assetsAccessRadio"
                onChange={() => {
                  setEveryOne('N');
                }}
                disabled={uploadStatus || uploadInProgress}
              />
              <Radio
                label="Everyone"
                checked={everyOne === 'Y'}
                name="assetsAccessRadio"
                onChange={() => {
                  setEveryOne('Y');
                }}
                disabled={uploadStatus || !allowAccess || uploadInProgress}
              />
            </InputGroup>
          </div>
        )}

        <div className="uploadLabels">
          <InputGroup label="Notes" validation={undefined}>
            <Textarea
              onChange={(e: any) => handleInputChange('notes', e.target.value)}
              placeholder="Enter notes"
              size="lg"
              value={notes}
              disabled={uploadStatus || uploadInProgress}
            />
          </InputGroup>
        </div>

        <div className="uploadfileLabels">
          <InputGroup label="Select what you want to upload?">
            <br />
            <Radio
              className="pr17"
              checked={isFile}
              label="Files"
              name="filesAccessRadio"
              onChange={() => {
                setisFile(true);
              }}
              disabled={!!fileNames.length}
            />
            <Radio
              label="Folders"
              checked={!isFile}
              onChange={() => {
                setisFile(false);
              }}
              name="filesAccessRadio"
              disabled={!!fileNames.length}
            />
          </InputGroup>
        </div>

        {!fileNames.length && (
          <>
            <div className="UploaderButton">
              <Button
                containerStyle={'outline'}
                colorType={'info'}
                onClick={OpenUpload}
              >
                Browse Files
              </Button>
            </div>
            <div className="uploadInfo">
              <Icon name={'circle-info'} prefix="fas" />
              <p className="uploadInfoText">
                Due to security reasons, no exe or zip files allowed.
              </p>
            </div>
          </>
        )}

        {!!fileNames.length && (
          <div className="uploadingFiles">
            {!uploadMore && (
              <div>
                <p className="col uploadInfoText">{`Uploading ${fileNames.length
                  } ${isFile ? 'file' : 'folder'}${fileNames.length > 1 ? 's' : ''
                  }.`}</p>
              </div>
            )}
            {uploadMore && (
              <div className="container">
                {uploadSucces && (
                  <div className="row">
                    <Icon name={'circle-check'} className=" col-1 greenIcon" />
                    <p className="col greenText uploadInfoText">{`${fileNames.length
                      } ${isFile ? 'file' : 'folder'}${fileNames.length > 1 ? 's' : ''
                      } uploaded.`}</p>
                  </div>
                )}
                {!uploadSucces && (
                  <div className="row">
                    <Icon
                      prefix="fas"
                      name={'triangle-exclamation'}
                      className=" col-1 redIcon"
                    />
                    <p className="col greenText uploadInfoText">{`${fileNames.length
                      } ${isFile ? 'file' : 'folder'}${fileNames.length > 1 ? 's' : ''
                      } failed.`}</p>
                  </div>
                )}
              </div>
            )}
            <div className="container">
              {fileNames &&
                fileNames.map((filename: any, index: number) => (
                  <div className="row" key={index}>
                    <p className="uploadFileNames col-11">
                      {filename.source.split('\\').slice(-1).pop()}
                    </p>
                    {!uploadSucces && (
                      <div className="col">
                        <Icon
                          prefix="fas"
                          name={'triangle-exclamation'}
                          className="redIcon mr10"
                        />
                        <Icon name={'xmark'} />
                      </div>
                    )}
                    {uploadSucces && (
                      <Button
                        containerStyle={'link'}
                        className="col xmark"
                        icon="xmark"
                        disabled={uploadStatus || uploadInProgress}
                        colorType={'secondary-black'}
                        onClick={() => removeFiles(filename.source)}
                      ></Button>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UploadFiles;

import React, { useEffect, useState } from 'react';
import './NotificationDialog.sass';
import { getTimeLapse } from '../../helper';

const NotificationDialog: React.FC<any> = ({ notifications, markAsRead }) => {
  const [noticeList, setNoticeList] = useState(notifications);
  useEffect(() => {
    const timeInterval = setInterval(() => {
      // console.log('Updating timelapse every second!');
      setNoticeList([...notifications]);
    }, 1000);
    return () => clearInterval(timeInterval);
  }, [notifications]);

  return (
    <div className="modalNotification" data-testid="notification-modal">
      <div className="noti_head">
        <h5 className="noti">Notifications</h5>
        <div className="noti_mark">
          <span onClick={() => markAsRead('all')}>Mark all as read</span>
        </div>
      </div>
      <div className="noti_cont">
        {noticeList.map((content: any) => {
          return (
            <div
              className="row notificationRow mx0"
              key={content.messageid}
              onClick={() => markAsRead(content.messageid)}
            >
              <div className="col-9 pr0 title">{content.title}</div>
              <div className="col-3 pl0 timelapse textRight">
                {getTimeLapse(content.timestamp)}
                {/* {content.newTimeLapse} */}
              </div>
              <div className="col-12 message">{content.msg}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NotificationDialog;

import React, { useEffect, useState } from 'react';
import ExtenalHeader from './ExtenalHeader';

// import { newMockData } from './constant';
import ExternalTable from './ExternalTable';
import { Connect, ConnectInstaller } from '@ibm-aspera/connect-sdk-js';

import './ExternalPackage.sass';
import Footer from '../dashboard/components/Footer';
import axios from 'axios';
import { Alert, Icon } from '@wmg-ae/legato';
import ExternalError from './ExternalError';
import ExternalLoginDownload from './ExternalLoginDownload';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const apiKey = { 'x-api-key': process.env.REACT_APP_GATEWAY_KEY || '' };
const ApiURL = {
  AocTokenLink: `${baseURL}/aoc-token?email=`,
  nodeTokenLink: `${baseURL}/node-token?`,
  verifyLink: `${baseURL}/package-link?token=`,
  getPackageDetails: `${baseURL}/get_package_details?package_id=`,
  AnRDetails: `${baseURL}/workspace`,
  TerminateLink: (id: any) => `${baseURL}/terminate-link?id=${id}&link_type=download`,
  verifyPassword: `${baseURL}/external-link-password`,
  writeNotification: `${baseURL}/notification`,
  sendReqEmail: `${baseURL}/email-service`,
};
const alertType = { DANGER: 'danger', SUCCESS: 'success', WARNING: 'warning' };
const ExternalPackage: React.FC<any> = () => {
  const [Response, setResponse] = useState<any>({});
  const [Row, setRow] = useState<any>([{}]);
  const [isLoginOpen, setisLoginOpen] = useState<any>(true);
  const [isInValid, setisInValid] = useState<any>({ status: false, attemptsLeft: 5 });
  const [isExpired, setisExpired] = useState(false);
  const [alertDetails, setAlertDetails] = useState<any>({ show: false, type: '', title: '' });

  useEffect(() => {
    if (alertDetails.show) {
      setTimeout(() => setAlertDetails({ show: false, type: '', title: '' }), 7000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertDetails.show]);
  useEffect(() => {
    const URL = window.location.href;
    if (URL.indexOf('?token=') !== -1) {
      const Token = URL.substring(URL.indexOf('?token=') + 7);

      const config = {
        url: ApiURL.verifyLink + Token,
        method: 'get',
        headers: apiKey,
      };
      axios(config)
        .then(({ data }) => {
          if (data.msg === 'Link is valid') {
            const {
              owner_email,
              home_node_id,
              package_id,
              workspace_id,
              workspace_name,
              workspace_logo,
              file_name,
              file_type,
              id,
              expiry_date,
              link_type,
              recipient_emails,
              content_id,
            } = data.data;
            setResponse({
              owner_email,
              home_node_id,
              package_id,
              workspace_id,
              workspace_name,
              workspace_logo,
              file_name,
              file_type,
              id,
              recipient_emails,
              content_id,
            });
            getAocToken(
              owner_email,
              package_id,
              home_node_id,
              file_name,
              file_type,
              workspace_id,
              workspace_logo,
              expiry_date,
            );
            setisLoginOpen(link_type === 1 && true)
            setValidLink('Valid');
          } else {
            setLoading(false);
            setValidLink('Invalid');
          }
          // getAocToken(owner_email, 'R50n98MgJA', home_node_id);
          // getAocToken(owner_email, 'R-w4f0zz4A', home_node_id);
        })
        .catch((err: any) => {
          const { data } = err.response;
          if (data.msg && data.msg === 'Link is expired') {
            const {
              owner_email,

              workspace_name,
              workspace_logo,
              recipient_emails,
              id,
            } = data.data;

            setResponse({
              owner_email,
              workspace_name,
              workspace_logo,
              recipient_emails,
              id,
            });
            setLogo(workspace_logo);
            setLoading(false);
            setValidLink('Expired');
          } else {
            setLoading(false);
            setValidLink('Invalid');
          }
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [token, setToken] = useState('');
  const [nodeToken, setNodeToken] = useState('');
  const [Loading, setLoading] = useState(true);
  const [validLink, setValidLink] = useState('Invalid');
  const [logo, setLogo] = useState('');

  let connectClient = new Connect();
  const connectInstaller = new ConnectInstaller();
  const onStatusEvent = (eventType: any, data: any) => {
    // console.log(eventType, data);
    // # Exclude transfer type events
    if (eventType === Connect.EVENT.TRANSFER) return;

    // # Check Connect status:
    // #   FAILED --> Connect not installed
    // #   RUNNING --> Connect installed and responding
    // #   EXTENSION_INSTALL -> Connect extension not installed
    // # See: https://ibm.github.io/aspera-connect-sdk-js/global.html#STATUS
    if (data === Connect.STATUS.FAILED) connectInstaller.showDownload();
    else if (data === Connect.STATUS.EXTENSION_INSTALL)
      connectInstaller.showExtensionInstall();
    else if (data === Connect.STATUS.RUNNING) connectInstaller.connected();
  };

  // # Setup Connect status event listener
  // # See: https://ibm.github.io/aspera-connect-sdk-js/Connect.html#addEventListener
  connectClient.addEventListener(Connect.EVENT.STATUS, onStatusEvent);

  // # Initialize Connect
  connectClient.initSession();

  const packageDownload = (data: any) => {
    // setDownloadInProgress(true);
    setAlertDetails({ show: true, type: alertType.SUCCESS, title: 'Download is in progress!'})
    let URL =
      process.env.REACT_APP_API_ENDPOINT +
      `/transfer-spec-pkg?user_id=${data.user_id}`;
    URL += `&home_node_id=${Response.home_node_id}&workspace_id=${data.workspace_id}`;
    URL += `&parent_package_file_id=${data.parent_package_file_id}&package_id=${data.package_id}`;
    URL += `&package_name=${data.package_name}&dropbox_id=${data.dropbox_id}`;
    URL += `&dropbox_name=${data.dropbox_name}`;

    const apiKey = { 'x-api-key': process.env.REACT_APP_GATEWAY_KEY || '' };
    const config = {
      method: 'get',
      url: URL,
      headers: { 'X-Amz-Security-Token': token, ...apiKey },
    };
    // console.log(data);
    const { name } = data;
    // let slicedFilename = name;
    // if (name && name.includes('.')) {
    //   const lastIndexDot = name.lastIndexOf('.');
    //   slicedFilename = name.slice(0, lastIndexDot);
    // }
    axios(config)
      .then(({ data }): any => {
        // console.log(data);
        let x = { ...data };
        x.paths = [
          {
            // destination:
            //   'Share to Studio by Vasanthkumar_Cheepurupalli@wmg_com 2022-11-15T11_37_18_171Z',
            source: name,
            // source: Response.file_name,
          },
        ];
        x.token = `Bearer ${nodeToken}`;
        // const asperaConnectSettings = {};
        const asperaConnectSettings = { allow_dialogs: false };
        connectClient
          .startTransferPromise(x, asperaConnectSettings)
          .then((response: any) => {
            // console.log(response.transfer_specs);
            setDownloadClick(true);
            setDownloadUUId([
              ...downloadUUId,
              response.transfer_specs[0].uuid,
            ]);
          })
          .catch((error) => {
            // console.log(error);
            // setDownloadInProgress(false);
            setDownloadClick(false);
            setAlertDetails({ show: true, type: alertType.DANGER, title: 'Failed to download ' + Response.file_name + '!' })
          });
      })
      .catch();
  };
  const getWorkspaceLogo = (params: any) => {
    const { workspace_id, token, responseLogo } = params;
    const config = {
      method: 'get',
      url: ApiURL.AnRDetails,
      headers: { 'X-Amz-Security-Token': token, ...apiKey },
    };
    axios(config)
      .then(({ data }: any) => {
        if (data && !data.code && data.length) {
          const respData: any = data.filter(
            (option: any) => option.id === workspace_id.toString()
          );
          if (respData && respData.length) {
            const { image_url } = respData[0];
            setLogo(image_url);
          } else {
            setLogo(responseLogo);
          }
        } else {
          setLogo(responseLogo);
        }
      })
      .catch((err: any) => {});
  };

  const getNodeToken = (token: string, email: string, nodeId: string) => {
    const config = {
      method: 'get',
      url: `${ApiURL.nodeTokenLink}email=${email}&home_node_id=${nodeId}`,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    axios(config)
      .then(({ data }): any => {
        // console.log(data);
        setNodeToken(data.access_token);
      })
      .catch((error): any => {
        console.log('Error generating Node Token:', error);
        setLoading(false);
        setValidLink('Invalid');
      });
  };
  const getPackageDetails = (
    token: any,
    packageID: any,
    fname: any,
    ftype: any,
    expiry_date: any,
  ) => {
    const config = {
      method: 'get',
      url: ApiURL.getPackageDetails + packageID,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };

    axios(config)
      .then(({ data }): any => {
        const {
          sender,
          node_id,
          workspace_id,
          // file_id,
          contents_file_id,
          id,
          name,
          recipients,
        } = data;
        setRow([
          {
            user_id: sender.id,
            home_node_id: node_id,
            workspace_id,
            parent_package_file_id: contents_file_id,
            // parent_package_file_id: file_id,
            package_id: id,
            package_name: name,
            dropbox_id: recipients[0].id,
            dropbox_name: recipients[0].name,
            name: fname,
            type: ftype,
            user_email: sender.email,
            expiry_date,
          },
        ]);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log('access Token API Error => ', error);
        setLoading(false);
        setValidLink('Invalid');
      });
  };

  const getAocToken = (
    email: any,
    packageID: any,
    nodeId: any,
    name: any,
    type: any,
    workspace_id: any,
    workspace_logo: any,
    expiry_date: any,
  ) => {
    const config = {
      method: 'get',
      url: ApiURL.AocTokenLink + email,
      headers: apiKey,
    };

    axios(config)
      .then(({ data }): any => {
        setToken(data.access_token);
        getNodeToken(data.access_token, email, nodeId);
        getPackageDetails(data.access_token, packageID, name, type, expiry_date);
        getWorkspaceLogo({
          workspace_id: workspace_id,
          token: data.access_token,
          responseLogo: workspace_logo,
        });
      })
      .catch((error: any) => {
        console.log('access Token API Error => ', error);
        setLoading(false);
        setValidLink('Invalid');
      });
  };
  const downloadSuccess = () => {
    const config = {
      method: 'get',
      url: ApiURL.TerminateLink(Response.id),
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => {
        console.log(data);
      })
      .catch((err: any) => {});
  };

  const [downloadUUId, setDownloadUUId] = useState<any>([]);
  const [downloadClick, setDownloadClick] = useState(false);
  // const [downloadInProgress, setDownloadInProgress] = useState(false);
  const getAllTransfersCallback = (transfersInfo: any) => {
    // const { iteration_token, result_count, transfers } = transfersInfo;

    const { transfers } = transfersInfo;
    for (let i = transfers.length - 1; i >= 0; i--) {
      const downloadCheck = downloadUUId.includes(transfers[i].uuid);
      // console.log(downloadUUId);
      if (downloadCheck) {
        // console.log(transfers[i].status, transfers[i].percentage);
        // console.log(
        //   downloadCheck,
        //   downloadCID,
        //   !downloadCID.includes(downloadCheck[0].content_id[0])
        // );
        if (transfers[i].status === 'completed') {
          let removeEventListener = connectClient.removeEventListener();
          if (removeEventListener) {
            // setDownloadInProgress(false);
            setDownloadClick(false);
            setAlertDetails({ show: true, type: alertType.SUCCESS, title: Response.file_name + ' is downloaded successfully in Downloads folder!' })
            createNotification({
              content_id: Response.content_id,
              owner_email: Response.owner_email || Row[0].user_email,
              owner_user_id: Row[0].user_id,
              owner_user_name: Response.owner_email.split('@')[0].replace('.', ' '),
              receipient_email: Response.recipient_emails || Row[0].dropbox_name,
              file_name: Response.file_name,
              action: 'download'
            })
            sendMail({
              username: Row[0].dropbox_name,
              recipient_email: [Row[0].user_email],
              email_type: 'download',
              workspace_name: Response.workspace_name,
            });
            downloadSuccess();
            setResponse({
              owner_email: Response.owner_email,
              workspace_name: Response.workspace_name,
              workspace_logo: logo,
              recipient_emails: Response.recipient_emails,
              id: Response.id,
            });
            setLogo(logo);
            setLoading(false);
            setValidLink('Expired');
          }
        } else if (transfers[i].status === 'failed') {
          let removeEventListener = connectClient.removeEventListener();
          if (removeEventListener)
            // setDownloadInProgress(false);
            setDownloadClick(false);
            setAlertDetails({ show: true, type: alertType.DANGER, title: 'Failed to download ' + Response.file_name + '!' })
        }
      }
      // console.log(transfers[i].status);
    }

    // setemail('');
    // console.log(transfersInfo);
  };

  const getAllTransfers = () => {
    // console.log('ui');
    const iterationToken = 0;
    /**
     * Get information about all of the transfers in Connect's activity window.
     */
    connectClient.getAllTransfers(
      {
        success: getAllTransfersCallback,
      },
      iterationToken
    );
  };

  useEffect(() => {
    if (downloadUUId.length > 0 && downloadClick) {
      connectClient.addEventListener('transfer', () => {
        getAllTransfers();
      });
    }
    // else {
    //   connectClient.removeEventListener();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadUUId, downloadClick]);

  const createNotification = (notification: any) => {
    const config = {
      method: 'post',
      url: ApiURL.writeNotification,
      data: {
        action: notification.action,
        content_id: [notification.content_id],
        email_ids: [notification.owner_email],
        user_id: notification.owner_user_id,
        user_name: notification.owner_user_name,
        user_image: '',
        msg_body:
          notification.receipient_email + ' downloaded ' + notification.file_name,
        fields_changed: '',
      },
      headers: apiKey,
    };

    axios(config)
      .then(({ data }): any => { })
      .catch((error: any) => {
        console.log('createNotification API Error....', error);
      });
  };

  const sendMail = (params: any) => {
    const config = {
      method: 'post',
      url: ApiURL.sendReqEmail,
      data: params,
      headers: apiKey,
    };

    axios(config)
      .then(({ data }): any => {
        if (!data.error) {
          //console.log(data);
        }
      })
      .catch((error: any) => {
        console.log('Who to notify mail API Error.... ', error);
      });
  };

  const verifyPassword = (params: any) => {
    params = { ...params, id: (Response.id).toString(), type: 'download' }
    const config = {
      method: 'post',
      url: ApiURL.verifyPassword,
      data: params,
      headers: apiKey,
    };
    axios(config).then(({ data }): any => {
      if (data.msg === 'valid password') {
        setisLoginOpen(false);
        setisExpired(false);
        setisInValid({ status: false, attemptsLeft: 5 });
      }
      else if (data.msg === 'invalid password') {
        setisInValid({ status: true, attemptsLeft: data.attemptsLeft });
      }
      else if (data.msg === 'Link expired') {
        setisExpired(true);
        setisInValid({ status: false, attemptsLeft: 0 });
        setResponse({
          owner_email: Response.owner_email,
          workspace_name: Response.workspace_name,
          workspace_logo: Response.workspace_logo,
          recipient_emails: Response.recipient_emails,
          id: Response.id,
        });
        setLogo(logo);
        setValidLink('Expired');
      }
    });
  };

  return (
    <div>
      {!Loading && validLink === 'Valid' && isLoginOpen && (
        <div>
          <div className="minHeight">
            <ExtenalHeader logo={logo} title={Response.workspace_name} />
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4">
                <ExternalLoginDownload
                  verifyPassword={verifyPassword}
                  isInValid={isInValid}
                  isExpired={isExpired}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
      {!Loading && validLink === 'Valid' && !isLoginOpen && (
        <div>
          <div className="minHeight">
            <ExtenalHeader logo={logo} title={Response.workspace_name} />
            <ExternalTable
              dashboardRecords={Row}
              download_transfer_spec={''}
              nodeToken={nodeToken}
              handleActionMenu={packageDownload}
            />
          </div>
          <Footer />
        </div>
      )}
      {!Loading && validLink === 'Invalid' && (
        <div>
          <div className="minHeight">
            <ExtenalHeader logo={logo} title={Response.workspace_name} />
            <ExternalError
              type={'Invalid'}
              description={
                'The link you entered does not exsits or has been deleted. Please make sure you are entering a valid link.'
              }
            />
          </div>
          <Footer />
        </div>
      )}
      {!Loading && validLink === 'Expired' && (
        <div>
          <div className="minHeight">
            {/* {logo && Response.workspace_name && ( */}
              <ExtenalHeader logo={logo} title={Response.workspace_name} />
            {/* )} */}
            <ExternalError
              type={'Expired'}
              description={'The following link has been expired'}
            />
          </div>
          <Footer />
        </div>
      )}
      {Loading && (
        <div className="LoadingExternalAnimation">
          <Icon name="spinner-third" className="fa-spin fa-10x" />
        </div>
      )}
      {/* {downloadInProgress && (
        <div>
          <Modal
            icon="download"
            title="Download In Progress"
            isOpen={downloadInProgress}
            onClose={() => {setDownloadInProgress(false)}}
          >
            <div className="downloadInprogress">
            <Icon name="spinner-third" className="fa-spin fa-4x" />
            </div>
          </Modal>
        </div>
      )} */}
      {alertDetails.show && (
        <Alert
          className="alertStyle"
          colorType={alertDetails.type}
          containerStyle="solid"
          dissmisable
          icon={
            alertDetails.type === 'success'
              ? 'check'
              : 'hexagon-exclamation'
          }
          // isVisible={alertDetails.show}
          title={alertDetails.title}
          onCloseAlert={() =>
            setAlertDetails({ show: false, type: '', title: '' })
          }
        />
      )}
    </div>
  );
};

export default ExternalPackage;

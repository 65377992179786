import React, { useState, createRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {
  Badge,
  Button,
  Dropdown,
  Icon,
  IconNav,
  Logo,
  Select,
} from '@wmg-ae/legato';
import { accountOptions } from '../constant';
import NotificationDialog from './notifications/NotificationDialog';
import useOutsideClick from '../../../utils/useOutSideClick';
import AnRTabs from './DashboardTabs';
import './Header.sass';

const Header: React.FC<any> = ({
  breadcrumbOptions,
  labelLogo,
  labelTitle,
  changeTab,
  toggleReqLinkModal,
  submitUpload,
  folderJump,
  upload_transfer_spec,
  nodeToken,
  notifications,
  getQueryEmails,
  emailList,
  homeId,
  markAsRead,
  isLabelUser,
  workspaceList,
  getLibStudioData,
  validWorkspace,
  currentUser,
  postProfileDetails,
  profileSettings,
  setprofileSettings,
  getProfile,
  toggleAccountModal,
  allowAccess,
  tab_id,
  setTransferOpen,
  increaseUploadCount,
  workspace,
}) => {
  const { authState, oktaAuth } = useOktaAuth();

  const handleAuth = () => {
    !authState || !authState.isAuthenticated
      ? oktaAuth.signInWithRedirect()
      : oktaAuth.signOut();
  };

  // const handleSettingModalSubmit = () => {
  //   postProfileDetails(profileSettings);
  //   setIsSettingModalOpen(false);
  // };

  const [isNotificationOpen, setisNotificationOpen] = useState(false);
  const [isProfileOpen, setisProfileOpen] = useState(false);
  // const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);

  const ref = createRef<HTMLDivElement>();

  useOutsideClick(ref, () => {
    if (isNotificationOpen) {
      setisNotificationOpen(false);
    }
  });

  const profileRef = createRef<HTMLDivElement>();
  useOutsideClick(profileRef, () => {
    if (isProfileOpen) {
      setisProfileOpen(false);
    }
  });

  const getWorkspaceDropdown = () => {
    let workspace = [];
    if (workspaceList && workspaceList.length) {
      workspace = workspaceList.map((option: any, index: any) => ({
        key: index.toString(),
        title: option.name,
        workspace: option,
      }));
    }
    return workspace;
  };

  const handleProfile = (option: any) => {
    const { title } = option;
    // console.log(title);
    if (title === 'Logout') handleAuth();
    if (title === 'Account Settings') {
      toggleAccountModal();
    }
  };

  const handleWorkspace = (option: any) => {
    const { workspace } = option;
    setTransferOpen({ isOpen: true, action: 'fetch' });
    getLibStudioData(workspace);
    // if (title === 'Logout') handleAuth();
    // if (title === 'Profile Settings') {
    //   setIsSettingModalOpen(true);
    // }
  };

  let dropdownbreadcrumb = [];
  if (breadcrumbOptions.length > 5) {
    dropdownbreadcrumb = [...breadcrumbOptions];
    dropdownbreadcrumb = dropdownbreadcrumb
      .slice(0, breadcrumbOptions.length - 5)
      .reverse();
  }

  const handleBellClick = () => {
    if (notifications.length) {
      setisNotificationOpen(!isNotificationOpen);
    }
  };

  return (
    <div className="headerWrapper">
      <div className="header_container">
        {labelLogo && isLabelUser && (
          <Logo
            className="label_logo"
            logo={labelLogo}
            appName={'Secure Upload Network'}
            appContext={labelTitle}
          />
        )}

        {labelLogo && !isLabelUser && (
          <div className="label_logo">
            <Logo
              logo={labelLogo}
              appName={'WMG SUN'}
              appContext={labelTitle}
            />
            <div style={{ display: 'flex', position: 'absolute', top: '71px' }}>
              <div className="aravind" style={{ width: '75px' }}></div>
              <Select
                className="selectDropDown"
                name="flavor"
                placeholder="Find Flavors"
                getKey={(el: any) => el.title}
                getTitle={(el: any) => el.title}
                list={getWorkspaceDropdown()}
                onSelect={(el: any) => handleWorkspace(el)}
                isFilterable
                searchInputPlaceholder="Search"
                onFilter={(l: ReadonlyArray<any>, term: any) =>
                  l.filter((f) =>
                    f.title
                      .toLocaleLowerCase()
                      .includes(term.toLocaleLowerCase())
                  )
                }
                value={labelTitle}
                getElement={(el: any) => (
                  <div className="lgt-mock-item container">
                    <div className="row selectedRow">
                      <img
                        src={el.workspace.logo}
                        alt="logo"
                        width="40"
                        height="40"
                        className="col-3"
                      />
                      <p className="col-7 px0 selectedPtag">{el.title}</p>
                      <div className="col-2">
                        <Badge
                          colorType="primary"
                          iconName="check"
                          rounded
                          size="xs"
                          text={el.workspace.badgeCount}
                        />
                      </div>
                    </div>
                  </div>
                )}
              >
                {({ isOpen, ref }: any) => (
                  <>
                    <Button
                      className="buttonVanish"
                      containerStyle="link"
                      colorType="secondary-black"
                      icon={(isOpen && 'chevron-up') || 'chevron-down'}
                      ref={ref}
                    >
                      {labelTitle}
                    </Button>
                  </>
                )}
              </Select>
            </div>
          </div>
        )}

        {!labelLogo && <div className="label_logo" />}

        {validWorkspace && (
          <>
            <div className="tabsContainer">
              <AnRTabs
                changeTab={changeTab}
                tab_id={tab_id}
                toggleReqLinkModal={toggleReqLinkModal}
                submitUpload={submitUpload}
                upload_transfer_spec={upload_transfer_spec}
                nodeToken={nodeToken}
                getQueryEmails={getQueryEmails}
                emailList={emailList}
                homeId={homeId}
                breadcrumbOptions={breadcrumbOptions}
                isLabelUser={isLabelUser}
                allowAccess={allowAccess}
                setTransferOpen={setTransferOpen}
                increaseUploadCount={increaseUploadCount}
                currentUser={currentUser}
                workspace={workspace}
              />
            </div>

            <div className="icon_container">
              <div className="notification" ref={ref}>
                <span data-testid="notification" onClick={handleBellClick}>
                  <IconNav
                    icon="bell"
                    isActive={isNotificationOpen}
                    onClick={handleBellClick}
                    quantity={notifications.length}
                    quantityProps={{
                      activeColorType: 'white',
                      activeContainerStyle: 'solid',
                      defaultColorType: 'primary',
                      defaultContainerStyle: 'solid',
                      maxQuantity: 99,
                      maxQuantityText: '99+',
                    }}
                  />
                </span>
                {isNotificationOpen && !!notifications.length && (
                  <NotificationDialog
                    notifications={notifications}
                    markAsRead={markAsRead}
                  />
                )}
              </div>
              <span
                ref={profileRef}
                onClick={() => setisProfileOpen(!isProfileOpen)}
              >
                <Dropdown
                  className="left_drop showIconNav"
                  list={accountOptions}
                  onSelect={(e) => handleProfile(e)}
                  placement="bottomLeft"
                >
                  <IconNav icon="bars" isActive={isProfileOpen} />
                </Dropdown>
              </span>
            </div>
          </>
        )}
      </div>

      {tab_id === '1' && breadcrumbOptions.length > 1 && validWorkspace && (
        <ol className="breadcrumb">
          {breadcrumbOptions.length > 5 && (
            <li className="breadHover">
              <Dropdown
                list={dropdownbreadcrumb}
                onSelect={(option: any) => folderJump(option)}
              >
                <Icon
                  className="ellipseIconStyle"
                  name="ellipsis-h"
                  prefix="fal"
                />
              </Dropdown>
            </li>
          )}
          {breadcrumbOptions.slice(-5).map((option: any, idx: number) => (
            <li
              key={idx}
              className="breadHover"
              onClick={() => folderJump(option)}
            >
              {option.title}
            </li>
          ))}
        </ol>
      )}
    </div>
  );
};

export default Header;

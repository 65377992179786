import React, { createRef, useState } from 'react';
import { Dropdown, IconNav, Logo } from '@wmg-ae/legato';
import wmgLogo from '../../../assets/blackWMGlogo.svg';
// import LibTabs from './LibTabs';
import { accountOptions } from '../../dashboard/constant';
import useOutsideClick from '../../../utils/useOutSideClick';
import { useOktaAuth } from '@okta/okta-react';

const WorkspaceHeader: React.FC<any> = ({
  profileSettings,
  postProfileDetails,
  setprofileSettings,
  currentUser,
  getProfile,
  toggleAccountModal,
}) => {
  //const [isAccountOpen, setisAccountOpen] = useState(false);
  const [isProfileOpen, setisProfileOpen] = useState(false);
  //const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();

  const handleAuth = () => {
    !authState || !authState.isAuthenticated
      ? oktaAuth.signInWithRedirect()
      : oktaAuth.signOut();
  };

  const profileRef = createRef<HTMLDivElement>();
  useOutsideClick(profileRef, () => {
    if (isProfileOpen) {
      setisProfileOpen(false);
    }
  });

  const handleProfile = (option: any) => {
    const { title } = option;
    // console.log(title);
    if (title === 'Logout') handleAuth();
    if (title === 'Account Settings') {
      toggleAccountModal();
    }
  };

  // const handleAccount = (option: any) => {
  //   const { title } = option;
  //   if (title === 'Logout') handleAuth();
  //   if (title === 'Account Settings') {
  //     // setIsSettingModalOpen(true);
  //   }
  // };

  // const accountRef = createRef<HTMLDivElement>();
  // useOutsideClick(accountRef, () => {
  //   if (isAccountOpen) {
  //     setisAccountOpen(false);
  //   }
  // });

  return (
    <div>
      <div className="header_container">
        <Logo
          appContext="Secure Upload Network"
          appName="WMG SUN"
          className="label_logo"
          logo={wmgLogo}
          theme="light"
        />
        {/* <LibTabs /> */}
        <div className="icon_container">
          <span
            ref={profileRef}
            onClick={() => setisProfileOpen(!isProfileOpen)}
          >
            <Dropdown
              className="left_drop showIconNav"
              list={accountOptions}
              onSelect={(e) => handleProfile(e)}
              placement="bottomRight"
            >
              <IconNav icon="bars" isActive={isProfileOpen} />
            </Dropdown>
          </span>
        </div>
      </div>
    </div>
  );
};
export default WorkspaceHeader;

import React, { useEffect, useState } from 'react';
import {
  Button,
  Datepicker,
  Dropdown,
  Icon,
  Input,
  InputGroup,
  Modal,
  Radio,
  Textarea,
  // Tooltip,
} from '@wmg-ae/legato';
import { getDaysDifference, regexPattern, fieldValidation } from '../../helper';

import '../../../../shared-components/spacingStyles.sass';
import './modalStyles.sass';

const RequestAssets: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  isLinkTab,
  fillData,
  currentUser,
}) => {
  const [receipentList, setReceipentList] = useState<any>('');
  // const [receipentTags, setReceipentTags] = useState<any>([]);
  const [notes, setNotes] = useState('');
  const [displayLinkURL, setDisplayLinkURL] = useState('');
  const [isTerminateLink, setIsTerminateLink] = useState(false);
  const [linkExpiryDate, setLinkExpiryDate] = useState('');
  const [daysCount, setDaysCount] = useState(0);
  const [LinkType, setLinkType] = useState('0');
  const [AccessType, setAccessType] = useState('0');
  const [LinkTerminate, setLinkTerminate] = useState('no');
  // const [LinkValidation, setLinkValidation] = useState<any>({});
  const [emailValidation, setEmailValidation] = useState<any>(
    fieldValidation.required
  );
  const [DateBoxValidation, setDateBoxValidation] = useState<any>(
    fieldValidation.required
  );
  const [isShowInputBox, setsShowInputBox] = useState('No');
  const [isShowPassword, setisShowPassword] = useState(false);
  const [password, setPassword] = useState('');

  const handleReceipent = (evt: any) => {
    // console.log('onchange input === ', `'${evt.target.value}'`);
    const mailList = evt.target.value.trim();
    // const splitList = mailList.replaceAll(' ', '').split(',');
    // let validList: any = [];
    setReceipentList(mailList);
    // splitList.map((mailId: string) => {
    //   if (regexPattern.email.test(mailId)) {
    //     validList = [...validList, mailId];
    //   }
    //   return mailId;
    // });
    // if (regexPattern.email.test(mailList)) {
    //   validList = [mailList];
    // }
    if (regexPattern.email.test(mailList)) {
      setEmailValidation(fieldValidation.required);
      // setReceipentTags(validList);
      // console.log('Valid joined === ', validList.join(','));
    } else {
      setEmailValidation(fieldValidation.error);
      // setReceipentTags([]);
    }
  };

  // TODO: IN PROGRESS, DO NOT DELETE
  // const handleKeyDown = (evt: any) => {
  //   // console.log('keydown target value === ', evt.target.value);
  //   // console.log('key === ', `'${evt.key}'`);
  //   // Detect Enter and Spacebar key, test for valid input and create tags then clear input
  //   if ((evt.key === 'Enter' || evt.key === ' ') && receipentList) {
  //     // console.log('ok');
  //     console.log('tag created for === ', `'${receipentList}'`);
  //     setReceipentTags([...receipentTags, receipentList]);
  //     setReceipentList('');
  //   }
  //   console.log('All tags === ', receipentTags);

  //   // else {
  //   //   console.log("set value...");
  //   //   setReceipentList(evt.target.value);
  //   // }
  // };

  const updateNotes = (evt: any) => {
    // console.log('notes === ', evt.target.value);
    setNotes(evt.target.value);
  };

  const updateTerminate = (key: string) => {
    setLinkTerminate(key);
    setIsTerminateLink(key === 'yes');
    // Clear Date selection
    if (key !== 'yes') {
      setLinkExpiryDate('');
    }
  };

  const updateExpiryDate = (newDateSelected: any) => {
    setLinkExpiryDate(newDateSelected);
    let daysLapse = 0;
    if (isTerminateLink && newDateSelected) {
      daysLapse = getDaysDifference(new Date(), newDateSelected);
    }
    setDaysCount(daysLapse > 0 ? daysLapse : 0); // 365 or 0 ?

    if (newDateSelected instanceof Date) {
      setDateBoxValidation(fieldValidation.required);
      // setLinkValidation({});
    } else {
      setDateBoxValidation(fieldValidation.error);
      // setLinkValidation({ type: 'error' });
    } // For Never, no expiry date, send 0
  };

  const generateLink = () => {
    // setDisplayLinkURL(window.location.origin + '...');
    setDisplayLinkURL('Link Generated Successfully!');
  };

  const setRequirePassOption = (e: any) => {
    setsShowInputBox(e.title);
    if (e.title === 'Yes')
      setLinkType('1')
    else
      setLinkType('0')
  }

  const handleSendLink = () => {
    const UrlType = LinkType === '1' ? 'ext' : 'ext';
    const apiParams = {
      access_level: AccessType,
      link_type: LinkType,
      notes,
      timelapse: daysCount,
      url: `${window.location.origin}/upload/${UrlType}/content/`,
      // recipient_email: receipentTags[0], // need to change from array to String
      recipient_email: receipentList,
      email_type: 'link',
      expiry_date: linkExpiryDate,
      password: password,
    };

    isLinkTab && handleSubmit({ ...apiParams, id: fillData.id, link: fillData.link });
    !isLinkTab && handleSubmit(apiParams);

    // Reset all input fields after submit
    // setTimeout(() => {
    //   setReceipentList('');
    //   // setReceipentTags([]);
    //   setNotes('');
    //   setAccessType('0');
    //   setLinkType('1');
    //   updateTerminate('no');
    //   setDisplayLinkURL('');
    // }, 100);
  };

  useEffect(() => {
    if (isLinkTab) {
      const { email, Note, accessType, linkType, update } = fillData;
      // setReceipentList('');
      // setReceipentTags([email]);
      setNotes(Note);
      setAccessType(accessType);
      setLinkType(linkType);
      if (linkType === '1')
        setsShowInputBox('Yes')
      else
        setsShowInputBox('No')
      updateTerminate(update);
      setReceipentList(email);
      setDisplayLinkURL('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fillData, isLinkTab]);
  const disabledGenerateLinkBttn =
    !regexPattern.email.test(receipentList) ||
    (isShowInputBox === 'Yes' && password.length <= 0) ||
    (isTerminateLink && !linkExpiryDate);
  // !receipentList || !notes || (isTerminateLink && !linkExpiryDate);
  // console.log('disabledGenerateLinkBttn === ', disabledGenerateLinkBttn);
  return (
    <Modal
      isOpen={isOpen}
      size={'lg'}
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            colorType="primary"
            disabled={!displayLinkURL}
            onClick={handleSendLink}
          >
            Send Link
          </Button>
        </div>
      }
      icon="link"
      onClose={handleClose}
      title="Generate Asset Request Link"
    >
      <div className="modalBody pr17">
        <div className="row">
          <div className="col">
            <InputGroup
              label="Receipent"
              validation={emailValidation}
              validationIconPlacement="label"
              icon="error"
              className="test1"
            >
              <Input
                disabled={!!displayLinkURL || isLinkTab}
                onChange={handleReceipent}
                // onKeyDownCapture={handleKeyDown}
                placeholder="Enter receipent email"
                suffixIcon="envelope"
                value={receipentList}
              />
              {/* <span>
                [Note: Enter comma (,) separated and valid email id's]
              </span> */}
              {/* {!!receipentTags.length && (
                <div className="mailTagStyle">
                  {receipentTags.map((email: any, idx: number) => (
                    <Tag key={idx} text={email} removable={false} />
                  ))}
                </div>
              )} */}
            </InputGroup>
          </div>
        </div>
        <div className="row pt20">
          <div className="col">
            <InputGroup label="Notes" validation={undefined}>
              <Textarea
                disabled={!!displayLinkURL}
                // containerStyle="outline"
                onChange={updateNotes}
                placeholder="Enter notes"
                size="lg"
                value={notes}
              />
              {/* <span>{notes && 500 - notes.length} Characters left.</span> */}
            </InputGroup>
          </div>
        </div>
        {(currentUser.isLabelUser || currentUser.isLabelManager) && (
          <div className="row pt20">
            <div className="col">
              <InputGroup label="Who can access these assets within label ?">
                <br />
                <Radio
                  disabled={!!displayLinkURL}
                  label="Only Me"
                  name="accessRadio"
                  className="pr17"
                  checked={AccessType === '0'}
                  onChange={() => setAccessType('0')}
                />
                <Radio
                  disabled={!!displayLinkURL}
                  label="Everyone"
                  name="accessRadio"
                  checked={AccessType === '1'}
                  onChange={() => setAccessType('1')}
                />
              </InputGroup>
            </div>
          </div>
        )}

        <div className="row pt10">
          <div className="col">
            <InputGroup label="Require Password ?"
              validation={isShowInputBox === 'Yes' ? fieldValidation.required : {}}
              validationIconPlacement='label'
              icon="error"
              className="test1"
            >
              {/* <Tooltip
                message="You can generate a private link be selecting 'yes'"
                colorType="dark"
                className="tooltip-index"
              > */}
              <div
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  marginLeft: '5px',
                }}
              >
                <Dropdown
                  onSelect={(e) => setRequirePassOption(e)}
                  list={[
                    { key: '1', title: 'No' },
                    { key: '2', title: 'Yes' },
                  ]}
                  placement={'bottomRight'}
                >
                  <Button
                    icon="chevron-down"
                    containerStyle={'link'}
                    colorType={'info'}
                    value={isShowInputBox}
                    disabled={!!displayLinkURL}
                  >
                    {isShowInputBox}
                  </Button>
                </Dropdown>
              </div>
              {isShowInputBox === 'Yes' && (<div className="pass-wrapper">
                <Input
                  onChange={(e: any) =>
                    setPassword(e.target.value)
                  }
                  // suffixIcon={(isShowPassword && 'eye-slash') || 'eye'}
                  validation={fieldValidation.required}
                  placeholder="Enter Password"
                  type={(isShowPassword && 'text') || 'password'}
                  value={password}
                  disabled={!!displayLinkURL}
                />
                <Icon
                  className="icon"
                  name={(isShowPassword && 'eye-slash') || 'eye'}
                  prefix="fal"
                  onClick={() => setisShowPassword(!isShowPassword)}
                />
              </div>)}
              {/* </Tooltip> */}

              {/* <br />
              <Radio
                disabled={!!displayLinkURL || isLinkTab}
                label="No"
                name="signinRadio"
                className="pr17"
                checked={LinkType === '1'}
                onChange={() => setLinkType('1')}
              />
              <Radio
                disabled={!!displayLinkURL || isLinkTab}
                label="Yes"
                name="signinRadio"
                checked={LinkType === '0'}
                onChange={() => setLinkType('0')}
              /> */}
            </InputGroup>
          </div>
        </div>
        <div className="row pt20">
          <div className="col">
            <InputGroup label="Set Link Expiry">
              <br />
              <Radio
                className="pr17"
                disabled={!!displayLinkURL}
                label="After 365 Days"
                name="terminateRadio"
                checked={LinkTerminate === 'no'}
                onChange={() => updateTerminate('no')}
              />
              <Radio
                disabled={!!displayLinkURL}
                label="Set Custom Date"
                name="terminateRadio"
                checked={LinkTerminate === 'yes'}
                onChange={() => updateTerminate('yes')}
              />
              {/* <div className="lgt-radio-group lgt-radio-group--horizontal">
                <Radio
                  checked
                  label="Never"
                  name="terminateRadio"
                  className="pr17"
                />
                <Radio label="Set Date" name="terminateRadio" />
              </div> */}
            </InputGroup>
          </div>
        </div>
        {isTerminateLink && (
          <div className="row pt20">
            <div className="col-5">
              <Datepicker
                containerStyle="outline"
                disabled={!!displayLinkURL}
                includeDateIntervals={[
                  {
                    start: new Date(),
                    end: new Date(
                      new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                    ),
                  },
                ]}
                placeholder="MM/DD/YYYY"
                // excludeDateIntervals={[
                //   {
                //     // Need Beginning/Min Date whichever calendar supports
                //     start: new Date('1000-01-01T08:59:05.249Z'),
                //     // end: new Date(),
                //     end: new Date(
                //       new Date().setDate(new Date().getDate() - 1) // After 3 months
                //     ),
                //   },
                //   {
                //     start: new Date(
                //       new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                //     ),
                //     // Need Ending Date/Max Date whichever calendar supports
                //     end: new Date('9000-01-01T08:59:05.249Z'),
                //   },
                // ]}
                onChange={updateExpiryDate}
                selected={linkExpiryDate}
                size="md"
                validation={DateBoxValidation}
              />
            </div>
          </div>
        )}
        <div className="row pt20">
          <div className="col-9 linkBox">
            {/* <p className="linkBttn">my link here...</p> */}
            {displayLinkURL && <p className="linkSuccess">{displayLinkURL}</p>}
          </div>
          <div className="col-3 textRight">
            {displayLinkURL ? (
              <Button
                colorType="primary"
                containerStyle="link"
                className="linkBttn"
                onClick={() => setDisplayLinkURL('')}
              >
                Edit Link Details
              </Button>
            ) : (
              <Button
                colorType="primary"
                containerStyle="link"
                className="linkBttn"
                disabled={disabledGenerateLinkBttn}
                onClick={generateLink}
              >
                Generate Link
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RequestAssets;

import React from 'react';
import uploadfailure from '../../assets/uploadfailed.svg';

const ExternalUploadFailure: React.FC = () => {
  return (
    <div className="extUploadMessage extUploadFailure">
      <img src={uploadfailure} alt="upload" />
      <p className="extUploadpText uploadFailureText">
        {`File upload failed due to unexpected error. Please try again or contact support`}
      </p>
    </div>
  );
};

export default ExternalUploadFailure;

import React, { useEffect, useState } from 'react';
import { Connect, ConnectInstaller } from '@ibm-aspera/connect-sdk-js';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { Alert, Badge, Button } from '@wmg-ae/legato';
import { DashboardProps } from './DashboardConnector';
import AnRFilters from './components/AnRFilters';
import AnRTabContent from './components/AnRTabContent';
import Footer from './components/Footer';
import Header from './components/Header';
import WorkspaceHeader from './components/WorkspaceHeader';
// import UploadFile from "./components/upload/UploadFile";
import RequestAssets from './components/modals/RequestAssets';
import MoveToFolder from './components/modals/MoveToFolder';
import ShareStudio from './components/modals/ShareStudio';
import ShareToLibrary from './components/modals/ShareToLibrary';
import DeleteFile from './components/modals/DeleteFile';
import AsperaConnect from './components/modals/AsperaConnect';
import RequestRevision from './components/modals/RequestRevision';
import InviteCollaborators from './components/modals/InviteCollaborators';
import EditFile from './components/modals/EditFile';
import VaultAsset from './components/modals/VaultAsset';
import RegiserInStudio from './components/modals/RegiserInStudio';
import RegisterDecline from './components/modals/RegisterDecline';
import TransferInProgress from './components/modals/TransferInProgress';

import { getUserDetails } from './helper';

import './dashboard.sass';
import ApproveInPending from './components/modals/ApproveInPending';
import DeclineInPending from './components/modals/DeclineInPending';
import Userprofile from './components/notifications/UserProfile/Userprofile';
import { headerColumns, studioColumns } from './constant';
import ShareToExternal from './components/modals/ShareToExternal';

const Dashboard: React.FC<DashboardProps> = ({
  token,
  getToken,
  currentUser,
  breadcrumbList,
  tab_id,
  labelLogo,
  labelTitle,
  dashboardRecords,
  workspaceList,
  loadSelectedWorkspace,
  alertDetails,
  handleRequestLink,
  submitUpload,
  deleteFile,
  changeTab,
  getAnRLabelData,
  getDashboardRecords,
  getWorkspaceTiles,
  submitShareContent,
  submitShareExternal,
  updateLoggedInUser,
  getNodeToken,
  uploadTS,
  upload_transfer_spec,
  nodeToken,
  workspace,
  toggleAlert,
  fileFilterKey,
  updateFilterKey,
  getMyLinks,
  myLinkRecords,
  download_transfer_spec,
  notifications,
  deleteLink,
  getQueryEmails,
  emailList,
  userIdList,
  comments,
  activitiesRecords,
  readComment,
  readActivity,
  homeId,
  sendRequestAssetMail,
  terminateReqLink,
  markAsRead,
  copyLink,
  activateLink,
  postComments,
  createFolder,
  createFolderMsg,
  postInviteCollaborator,
  downloadSuccess,
  getContentInfo,
  contentInfoDetails,
  updateContentDetails,
  updateVaultStatus,
  getLibStudioData,
  validWorkspace,
  moveToNewFolder,
  approveFile,
  declineFile,
  declineStudioFile,
  postProfileDetails,
  profileSettings,
  setprofileSettings,
  handleModifyReqSubmit,
  getProfile,
  SendForApprovalFile,
  RequestRevisionFile,
  DeclineInRegistrationFile,
  transferOpen,
  setTransferOpen,
  registerAssets,
  getAssetPermission,
  assetPermission,
  downloadCID,
  setDownloadCID,
  uploadCount,
  setUploadCount,
}) => {
  // console.log('uiuiui', downloadCID);
  const history = useHistory();

  window.addEventListener('popstate', () => {
    const x: any = history.location.state;
    if (history.action === 'POP') {
      if (
        currentUser &&
        (currentUser.isLibraryUser || currentUser.isStudioUser)
      ) {
        // Display Workspace Tiles for Library Users and Studio Users
        if (x && x.tiles) {
          window.location.reload();
        } else {
          setShowDashboardTable(true);
          setShowWorkspaceTiles(false);
          if (x) {
            if (tab_id === '2') {
              changeTab('1');
            }
            x.Wid === workspace.workspace_id && getDashboardRecords(x);
          }
        }
      } else {
        if (x) {
          if (tab_id === '2') {
            changeTab('1');
          }
          getDashboardRecords(x);
        }
      }
    }
  });

  let connectClient = new Connect();
  const connectInstaller = new ConnectInstaller();
  const onStatusEvent = (eventType: any, data: any) => {
    // console.log(eventType, data);
    // # Exclude transfer type events
    if (eventType === Connect.EVENT.TRANSFER) return;

    // # Check Connect status:
    // #   FAILED --> Connect not installed
    // #   RUNNING --> Connect installed and responding
    // #   EXTENSION_INSTALL -> Connect extension not installed
    // # See: https://ibm.github.io/aspera-connect-sdk-js/global.html#STATUS
    if (data === Connect.STATUS.FAILED) connectInstaller.showDownload();
    else if (data === Connect.STATUS.EXTENSION_INSTALL)
      connectInstaller.showExtensionInstall();
    else if (data === Connect.STATUS.RUNNING) connectInstaller.connected();
  };

  // # Setup Connect status event listener
  // # See: https://ibm.github.io/aspera-connect-sdk-js/Connect.html#addEventListener
  connectClient.addEventListener(Connect.EVENT.STATUS, onStatusEvent);

  // # Initialize Connect
  connectClient.initSession();

  const { authState, oktaAuth } = useOktaAuth();

  // Keep 2 separate states for Tiles and Dashboard for now. If required later can be merged.
  const [showWorkspaceTiles, setShowWorkspaceTiles] = useState(false);
  const [showDashboardTable, setShowDashboardTable] = useState(false);

  const [modalStatus, setModalStatus] = useState({
    aspera: false,
    vaultAsset: false,
    moveFolder: false,
    editDetails: false,
    reqAsset: false,
    studio: false,
    library: false,
    external: false,
    delete: false,
    invite: false,
    revision: false,
    register: false,
    regDecline: false,
    approve: false,
    decline: false,
    account: false,
  });
  const [currFolderList, setCurrFolderList] = useState<any>([]);
  const [excludedSrcName, setExcludedSrcName] = useState('');
  const [currActionRow, setCurrActionRow] = useState<any>({});

  const [libraryRow, setlibraryRow] = useState<any>([]);

  const [isShared, setisShared] = useState(false);
  const [selectedAnRRecords, setSelectedAnRRecords] = useState<any>([]); // To store id of selected records
  // const [DownloadRecords, setDownloadRecords] = useState<any>([]); // To store id of selected records

  const [isDeleteAllow, setIsDeleteAllow] = useState(true);
  const [isShareAllow, setIsShareAllow] = useState(true);
  const [count, setcount] = useState(0);
  const [BulkDeleteId, setBulkDeleteId] = useState<any>([]);
  const [downloadProgress, setdownloadProgress] = useState<any>([]);

  const { allow_access_to_workspace_users } =
    breadcrumbList[breadcrumbList.length - 1];
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      getToken('');
    } else {
      oktaAuth.getUser().then((info: any) => {
        getToken(info.preferred_username || '');
        const currUserDetails = getUserDetails(info);
        updateLoggedInUser(currUserDetails);

        if (currUserDetails.isLibraryUser || currUserDetails.isStudioUser) {
          // Display Workspace Tiles for Library Users and Studio Users
          setShowDashboardTable(false);
          setShowWorkspaceTiles(true);
          history.push('/tiles', { tiles: 'studio' });
        } else {
          // Hide Workspace Title and display Dashboard directly
          setShowWorkspaceTiles(false);
          setShowDashboardTable(true);
          // history.push('/');
          //  history.push('/home');
          // history.push('/page');
        }
        // history.push('/home');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, oktaAuth]); // Update if authState changes

  // Load Dashboard directly for A&R User, A&R Manager
  useEffect(() => {
    if (!!token && (currentUser.isLabelUser || currentUser.isLabelManager)) {
      if (!!token)
        history.push('/table', {
          cid: 0,
          navId: 1,
          name: '',
          allow_access_to_workspace_users: 'Y',
          Wid: workspace.workspace_id,
          // browserBack: 1,
          // backBread: breadcrumbList,
        });
      getAnRLabelData();
    } else if (
      !!token &&
      (currentUser.isLibraryUser || currentUser.isStudioUser)
    ) {
      getWorkspaceTiles(currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, currentUser]);

  // useEffect(() => {
  //   if (!!token) history.push('/Assets', {
  //     cid: 0,
  //     navId: 1,
  //     name:'',
  //     // browserBack: 1,
  //     // backBread: breadcrumbList,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);

  // TODO: ADDED TO AUTO HIDE ALERT AFTER sometime. Remove it after Grawl component update
  useEffect(() => {
    if (alertDetails.show) {
      setTimeout(() => toggleAlert({ show: false, type: '', title: '' }), 7000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertDetails.show]);

  const packageDownload = (rowdata: any) => {
    toggleAlert({ show: true, type: 'success', title: 'Download is in progress!' });
    const data = rowdata[0];
    let URL =
      process.env.REACT_APP_API_ENDPOINT +
      `/transfer-spec-pkg?user_id=${data.user_id}`;
    URL += `&home_node_id=${workspace.home_node_id}&workspace_id=${data.workspace_id}`;
    URL += `&parent_package_file_id=${data.parent_package_file_id}&package_id=${data.package_id}`;
    URL += `&package_name=${data.package_name}&dropbox_id=${data.dropbox_id}`;
    URL += `&dropbox_name=${data.dropbox_name}`;

    const apiKey = { 'x-api-key': process.env.REACT_APP_GATEWAY_KEY || '' };
    const config = {
      method: 'get',
      url: URL,
      headers: { 'X-Amz-Security-Token': token, ...apiKey },
    };
    // console.log(data);
    const { name } = data;
    // let slicedFilename = name;
    // if (name && name.includes('.')) {
    //   const lastIndexDot = name.lastIndexOf('.');
    //   slicedFilename = name.slice(0, lastIndexDot);
    // }
    axios(config)
      .then(({ data }): any => {
        // console.log(data);
        let x = { ...data };
        x.paths = [
          {
            // destination: slicedFilename + ` ${new Date().toISOString()}`,
            source: name,
          },
        ];
        x.token = `Bearer ${nodeToken}`;
        const asperaConnectSettings = { allow_dialogs: false };
        connectClient
          .startTransferPromise(x, asperaConnectSettings)
          .then((response: any) => {
            setdownloadProgress([
              ...downloadProgress,
              {
                content_id: rowdata.map((row: any) => row.content_id),
                uuid: response.transfer_specs[0].uuid,
                donloading: true,
                downloadCount: name,
              },
            ]);
          })
          .catch((error) => {
            // console.log(error);
          });
      })
      .catch();
  };

  useEffect(() => {
    // console.log(downloadCID);
    if (!downloadCID.length) {
      setdownloadProgress([]);
    }
  }, [downloadCID])


  const handleSettingModalSubmit = () => {
    postProfileDetails(profileSettings);
    toggleModal('account', false);
  };

  const getFolderNames = (allRecords: any, filterKey: string) => {
    return allRecords
      .filter(
        (record: any) =>
          record.type.includes('folder') && record.name !== filterKey
      )
      .map((item: any) => ({ name: item.name, cid: item.content_id }));
  };

  useEffect(() => {
    if (excludedSrcName && dashboardRecords.length) {
      setCurrFolderList(getFolderNames(dashboardRecords, excludedSrcName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardRecords]);

  const handleTileClick = (tile: any) => {
    console.log('Workspace id === ', tile);
    // TRIGGER API HERE TO LOAD Dashboard table for Library User and Studio User based on selected workspace.
    // API TO LOAD DASHBOARD TABLE for Library, Studio
    setShowWorkspaceTiles(false);
    history.push('/table', {
      cid: 0,
      navId: 1,
      name: '',
      allow_access_to_workspace_users: 'Y',
      Wid: workspace.workspace_id,
      // browserBack: 1,
      // backBread: breadcrumbList,
    });
    // loadSelectedWorkspace({
    //   user_id: '495301' || tile.user_id,
    //   home_file_id: '605' || tile.home_file_id,
    //   home_node_id: '46634' || tile.home_node_id,
    //   workspace_id: 86138 || tile.workspace_id,
    // });
    setTimeout(() => {
      // getDashboardRecords({ cid: 0 });
      getLibStudioData(tile);
    }, 100);
    setShowDashboardTable(true);
  };

  const toggleModal = (key: string, flag = true) => {
    setModalStatus({ ...modalStatus, [key]: flag });
    if (!flag) setCurrActionRow({});
  };

  const exportPdf = () => {
    let selectedRecords = selectedAnRRecords.length
      ? filterDashboardRecords()
      : [...updatedRecords];
    const pdfHeaders = currentUser.isStudioUser
      ? studioColumns.map((header: any) => header.title).filter((x) => x)
      : headerColumns.map((header: any) => header.title).filter((x) => x);

    const pdfData = selectedRecords.map((row: any) => {
      return {
        artist: row.artist,
        title: row.title,
        fileName: row.name,
        task: row.tasks || '',
        sentBy: row.recipient_emails || row.user_email || '',
        owner: row.user_name,
        lastupdate: row.last_updated_timestamp
          ? new Date(row.last_updated_timestamp).toLocaleDateString()
          : '',
        status: row.status,
      };
    });

    const buildtablebody = (data: any[]) => {
      let body: any = [];

      data.map((row: any) => {
        body = [
          ...body,
          [
            row.artist,
            row.title,
            row.fileName,
            ...(currentUser.isStudioUser ? [row.task] : []),
            row.sentBy,
            row.owner,
            row.lastupdate,
            row.status,
          ],
        ];
        return row;
      });
      return body;
    };

    const doc = new jsPDF('portrait', 'pt', 'A4');
    const title = 'Content Details';

    doc.setFontSize(15);
    doc.text(title, doc.internal.pageSize.getWidth() / 2, 40, {
      align: 'center',
    });

    autoTable(doc, {
      startY: 50,
      theme: 'grid',
      head: [pdfHeaders],
      headStyles: {
        fillColor: [30, 255, 255],
        textColor: [0, 0, 0],
      },
      columnStyles: currentUser.isStudioUser
        ? {
          0: { cellWidth: 60 },
          1: { cellWidth: 60 },
          2: { cellWidth: 62 },
          3: { cellWidth: 60 },
          4: { cellWidth: 87 },
          5: { cellWidth: 63 },
          6: { cellWidth: 61.28 },
          7: { cellWidth: 57 },
        }
        : {
          0: { cellWidth: 70 },
          1: { cellWidth: 70 },
          2: { cellWidth: 75 },
          3: { cellWidth: 110 },
          4: { cellWidth: 66 },
          5: { cellWidth: 64.28 },
          6: { cellWidth: 60 },
        },
      body: buildtablebody(pdfData),
    });
    doc.save('WMG SUN');
  };

  const handleBulkDownload = () => {
    // need to change bulk download transferspec

    filterDashboardRecords().map((row: any) => {
      setDownloadCID(row.content_id);
      if (!row.package_id) {
        handleDownload([
          {
            name: row.name,
            content_id: row.content_id,
            home_file_id: row.home_file_id,
          },
        ]);
      } else {
        packageDownload([row]);
      }
      return 0;
    });
  };

  const bulkShareLibrary = () => {
    const selectedRows = filterDashboardRecords().map((row: any) => ({
      name: row.name,
      content_id: row.content_id,
      type: row.type,
      parent_id: row.parent_id,
    }));
    setlibraryRow(selectedRows);
    const filteredRecords = filterDashboardRecords().filter(
      (item: any) => item.status !== 'Requested Revision'
    );
    if (filteredRecords.length === selectedRows.length) {
      setIsShareAllow(true);
      toggleModal('library');
    } else {
      setIsShareAllow(false);
    }
  };

  const handleBulkDelete = (e: any) => {
    const selectedRows = filterDashboardRecords().map((row: any) => ({
      user_email: row.user_email,
      content_id: row.content_id,
      name: row.name,
    }));
    setBulkDeleteId(selectedRows);
    const filteredRecords = selectedRows.filter(
      (item: any) => item.user_email === currentUser.email
    );
    if (filteredRecords.length === selectedRows.length) {
      setcount(filteredRecords.length);
      setIsDeleteAllow(true);
      toggleModal('delete');
    } else {
      setIsDeleteAllow(false);
    }
  };

  const handleDownload = (rows: any) => {
    // console.log(download_transfer_spec)
    toggleAlert({ show: true, type: 'success', title: 'Download is in progress!' });
    let paths: any = [];
    rows.map((row: any) => {
      paths = [...paths, { source: row.name }];
      return row;
    });
    const downloadCount =
      rows.length > 1
        ? `${rows[0].name} + ${rows.length - 1} more`
        : rows[0].name;
    let transferSpec = { ...download_transfer_spec };
    transferSpec.paths = paths; // changes req
    transferSpec.token = `Bearer ${nodeToken}`;
    transferSpec.tags = { ...download_transfer_spec.tags };
    transferSpec.tags.aspera = { ...download_transfer_spec.tags.aspera };
    transferSpec.tags.aspera.node = {
      ...download_transfer_spec.tags.aspera.node,
    };
    transferSpec.tags.aspera.node.file_id = rows[0].home_file_id;
    // breadcrumbList.length > 1
    //   ? rows[0].parent_id
    //   : download_transfer_spec.tags.aspera.node.file_id;
    transferSpec.tags.aspera.aspera_ui = {
      ...download_transfer_spec.tags.aspera.aspera_ui,
    };
    transferSpec.tags.aspera.files = {
      ...download_transfer_spec.tags.aspera.files,
    };
    transferSpec.tags.aspera.files.files_transfer_action = 'download_file';
    transferSpec.tags.aspera.aspera_ui.transfer_name = `Downloading ${downloadCount}`; //change req

    const asperaConnectSettings = { allow_dialogs: false };
    connectClient
      .startTransferPromise(transferSpec, asperaConnectSettings)
      .then((response: any) => {
        setdownloadProgress([
          ...downloadProgress,
          {
            content_id: rows.map((row: any) => row.content_id),
            uuid: response.transfer_specs[0].uuid,
            donloading: true,
            downloadCount,
          },
        ]);
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
        toggleAlert({ show: true, type: 'danger', title: 'Download got failed!' });
      });
  };

  const getAllTransfersCallback = (transfersInfo: any) => {
    // const { iteration_token, result_count, transfers } = transfersInfo;

    const { transfers } = transfersInfo;
    for (let i = transfers.length - 1; i >= 0; i--) {
      const downloadCheck = downloadProgress.filter(
        (item: any) => item.uuid === transfers[i].uuid
      );
      // console.log(downloadProgress);
      if (downloadCheck.length > 0) {
        // console.log(transfers[i].status, transfers[i].percentage);
        // console.log(
        //   downloadCheck,
        //   downloadCID,
        //   !downloadCID.includes(downloadCheck[0].content_id[0])
        // );
        if (transfers[i].status === 'completed') {
          let removeEventListener = connectClient.removeEventListener();
          if (removeEventListener)
            toggleAlert({ show: true, type: 'success', title: transfers[i].title + ' is downloaded successfully in Downloads folder!' });

          downloadSuccess(downloadCheck);
          // toggleAlert({ show: true, type: 'success', title: Response.file_name + ' downloaded successfully!' })
        } else if (transfers[i].status === 'failed') {
          let removeEventListener = connectClient.removeEventListener();
          if (removeEventListener)
            toggleAlert({ show: true, type: 'danger', title: transfers[i].title + ' failed to download!' });
        }
      }
      // console.log(transfers[i].status);
    }

    // setemail('');
    // console.log(transfersInfo);
  };

  const getAllTransfers = () => {
    // console.log('ui');
    const iterationToken = 0;
    /**
     * Get information about all of the transfers in Connect's activity window.
     */
    connectClient.getAllTransfers(
      {
        success: getAllTransfersCallback,
      },
      iterationToken
    );
  };

  useEffect(() => {

    if (downloadProgress.length > 0) {
      connectClient.addEventListener('transfer', () => {
        getAllTransfers();
      });
    } else {
      connectClient.removeEventListener();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadProgress]);

  const handleContentUpdate = (data: any) => {
    updateContentDetails({ ...data, content_id: currActionRow.content_id });
    setTimeout(() => toggleModal('editDetails', false), 10);
  };

  const handleActionMenu = (actionMenu: any, row: any, c: any) => {
    // Setting the selected row. This is common for all the action, use same state
    setCurrActionRow(row);

    switch (actionMenu) {
      case 'Share to Studio': {
        // if (!row.type.includes('folder')) {
        getContentInfo(row.content_id);
        // }
        setTimeout(() => toggleModal('studio'), 100);
        break;
      }
      case 'Share to Library': {
        toggleModal('library');
        setlibraryRow([
          {
            name: row.name,
            content_id: row.content_id,
            type: row.type,
            parent_id: row.parent_id,
          },
        ]);
        break;
      }
      case 'Share to External': {
        toggleModal('external');
        break;
      }
      case 'Delete': {
        setisShared(row.status === 'In Library' || row.status === 'In Studio');
        toggleModal('delete');
        setBulkDeleteId([{ content_id: row.content_id, name: row.name, }]);
        setcount(0);
        break;
      }
      case 'Request Revision': {
        toggleModal('revision');
        break;
      }
      case 'Invite Collaborator': {
        toggleModal('invite');
        break;
      }
      case 'Move to Folder': {
        toggleModal('moveFolder');
        setExcludedSrcName(row.name);
        setCurrFolderList(getFolderNames(dashboardRecords, row.name));
        break;
      }
      case 'Vault Asset': {
        toggleModal('vaultAsset');
        break;
      }
      case 'Register': {
        toggleModal('register');
        break;
      }
      case 'Decline Register Request': {
        toggleModal('regDecline');
        break;
      }
      case 'Send for Approval': {
        SendForApprovalFile({
          content_id: row.content_id,
          files_name: row.name,
          type: row.type,
          parentId: row.parent_id,
        });
        break;
      }
      case 'View/Edit Details': {
        if (!row.type.includes('folder')) {
          getContentInfo(row.content_id);
        }
        getAssetPermission({
          content_id: row.content_id,
          user_id: row.user_id,
        });
        readComment(row.content_id);
        readActivity(row.content_id);
        setTimeout(() => toggleModal('editDetails'), 100);
        break;
      }
      case 'Download': {
        setDownloadCID(row.content_id);
        if (!row.package_id) {
          handleDownload([row]);
        } else {
          packageDownload([row]);
        }
        //
        break;
      }
      case 'Approve': {
        toggleModal('approve');
        break;
      }
      case 'Decline': {
        toggleModal('decline');
        break;
      }

      default:
        break;
    }
  };
  const onFolderClick = (data: any) => {
    history.push(`/table`, {
      ...data,
      browserBack: 1,
      backBread: breadcrumbList,
    });
    getDashboardRecords(data);
  };

  const handleMoveFolder = (target: any) => {
    moveToNewFolder({
      srcName: currActionRow.name,
      srcCid: currActionRow.content_id,
      targetCid: target.cid,
      targetName: target.name,
    });
    setTimeout(() => {
      toggleModal('moveFolder', false);
      setCurrFolderList([]);
    }, 10);
  };

  const handleLinkSubmit = (apiParams: any) => {
    handleRequestLink(apiParams);
    toggleModal('reqAsset', false);
  };

  const handleRegisterSubmit = (apiParams: any) => {
    registerAssets({
      cid: currActionRow.content_id.toString(),
      fname: currActionRow.name,
      note: `${currActionRow.name} has been registered`,
      name: `wmgsun_${currActionRow.name}`,
    });
    toggleModal('register', false);
  };

  const handleApproveSubmit = (apiParams: any) => {
    toggleModal('approve', false);
  };

  const handleDeclineSubmit = (apiParams: any) => {
    toggleModal('decline', false);
  };

  const handleRegisterDeclineSubmit = (apiParams: any) => {
    toggleModal('regDecline', false);
  };

  const handleShareStudioSubmit = (data: any) => {
    submitShareContent(data);
    toggleModal('studio', false);
  };

  const handleShareLibrarySubmit = (data: any) => {
    //console.log(libraryRow);
    submitShareContent({
      ...data,
      file_names: libraryRow.map((row: any) => row.name),
      content_id: libraryRow.map((row: any) => row.content_id),
      contentIdType: libraryRow.map((row: any) => ({
        id: row.content_id,
        type: row.type,
      })),
      homeId: libraryRow[0].parent_id,
    });
    toggleModal('library', false);
  };

  const handleShareExternalSubmit = (data: any) => {
    submitShareExternal(data);
    toggleModal('external', false);
  };

  const handleBulkDeleteFileSubmit = () => {
    const id = BulkDeleteId.map((item: any) => item.content_id);
    const name = BulkDeleteId.map((item: any) => item.name);
    deleteFile({ id, name });
    toggleModal('delete', false);
  };

  const handleRrFileSubmit = () => {
    toggleModal('revision', false);
  };

  const handleInviteCollaboratorSubmit = () => {
    toggleModal('invite', false);
  };

  const onFolderJump = (option: any) => {
    // No action on last breadcrumb click
    if (option.key < breadcrumbList.length) {
      history.push('/table', {
        cid: option.cid,
        name: '',
        navId: option.key,
        allow_access_to_workspace_users: allow_access_to_workspace_users,
        browserBack: 1,
        backBread: breadcrumbList.slice(0, option.key),
        Wid: workspace.workspace_id,
      });
      getDashboardRecords({
        cid: option.cid,
        name: '',
        navId: option.key,
        allow_access_to_workspace_users: allow_access_to_workspace_users,
      });
    }
  };

  const filterDashboardRecords = () => {
    return dashboardRecords.filter((item: any) =>
      selectedAnRRecords.includes(item.id)
    );
  };

  const handleSelectedRows = (evt: any) => {
    setSelectedAnRRecords(evt);
    //   Need to Refactor the Code***
    //setting this value to true for tooltip
    setIsDeleteAllow(true);
    setIsShareAllow(true);
  };

  const handleVaultSubmit = () => {
    updateVaultStatus({
      cid: currActionRow.content_id,
      fname: currActionRow.name,
    });
    toggleModal('vaultAsset', false);
  };

  // const filteredRecords = fileFilterKey
  //   ? dashboardRecords.filter((row: any) =>
  //       row.name.toLowerCase().includes(fileFilterKey.toLowerCase())
  //     )
  //   : dashboardRecords;
  const [updatedRecords, setUpdatedRecords] = useState<any>([]);
  useEffect(() => {
    const filteredRecords = fileFilterKey
      ? dashboardRecords.filter((row: any) =>
        (row.name || '').toLowerCase().includes(fileFilterKey.toLowerCase()) ||
        (row.title || '').toLowerCase().includes(fileFilterKey.toLowerCase()) ||
        (row.artist || '').toLowerCase().includes(fileFilterKey.toLowerCase()) ||
        (row.package_name || '').toLowerCase().includes(fileFilterKey.toLowerCase())
      )
      : dashboardRecords;
    setUpdatedRecords(filteredRecords);
  }, [fileFilterKey, dashboardRecords])


  const increaseUploadCount = () => {
    setUploadCount(uploadCount + 1);
  }

  return (
    <div className="dashboardContainer">
      <div className="notchWrapper justify-content-center">
        {(currentUser.isLabelManager) && (
          <Button
            label="You are logged in as A&R Manager"
            className="lgt-bg-g-shade2 lgt-brdr-g-shade2"
          />
        )}
        {(currentUser.isLabelUser) && (
          <Button
            label="You are logged in as A&R user"
            className="lgt-bg-g-shade2 lgt-brdr-g-shade2"
          />
        )}
        {currentUser.isLibraryUser && (
          <Button
            label="You are logged in as Library user"
            className="lgt-bg-j-core lgt-brdr-j-core"
          />
        )}
        {currentUser.isStudioUser && (
          <Button
            label="You are logged in as Studio user"
            className="lgt-bg-i-core lgt-brdr-i-core"
          />
        )}
      </div>

      {showWorkspaceTiles && (
        <>
          <WorkspaceHeader
            profileSettings={profileSettings}
            postProfileDetails={postProfileDetails}
            setprofileSettings={setprofileSettings}
            currentUser={currentUser}
            getProfile={getProfile}
            toggleAccountModal={() => toggleModal('account')}
          />
          <div className="row row-cols-auto mx0 workspaceWrapper">
            {workspaceList.map((wsTile: any, idx: any) => {
              // const wsLogo = wsTile.logo;
              // import wsLogo from `${wsTile.logo}`;

              return (
                // <Tooltip
                //   colorType="primary"
                //   containerStyle="solid"
                //   message="Some information will be here"
                //   placement="bottom"
                //   title=""
                // >
                <div
                  key={idx}
                  className="col libTiles"
                  onClick={() => handleTileClick(wsTile)}
                  title={wsTile.name}
                >
                  {!!wsTile.badgeCount && (
                    <div
                      style={{
                        alignItems: 'right',
                        marginLeft: 125,
                        marginTop: 10,
                      }}
                    >
                      <Badge
                        colorType="primary"
                        iconName="check"
                        rounded
                        size="xs"
                        text={wsTile.badgeCount}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: (wsTile.badgeCount && '5px') || '35px',
                    }}
                  >
                    <img
                      // src={wsTile.logo}
                      // src="https://api.ibmaspera.com/api/v1/user_images/44403?oh=uQ9PCZ3nkt_QxrVcxo61v8DvBO-vrHITbxEF6aCWPYGLlpEv5zcyXzKQpSGsJvTAIAbfxbgpX5dryBj5FQcWTg"
                      src={wsTile.logo}
                      height={99}
                      width={99}
                      alt={wsTile.name}
                    // title={wsTile.name}
                    />
                  </div>
                </div>
                // </Tooltip>
              );
            })}
          </div>

          <Footer />
        </>
      )}

      {showDashboardTable && (
        <>
          <Header
            breadcrumbOptions={breadcrumbList}
            labelLogo={labelLogo}
            labelTitle={labelTitle}
            changeTab={changeTab}
            tab_id={tab_id}
            toggleReqLinkModal={() => toggleModal('reqAsset')}
            submitUpload={submitUpload}
            uploadToken={getNodeToken}
            uploadTS={uploadTS}
            nodeToken={nodeToken}
            upload_transfer_spec={upload_transfer_spec}
            increaseUploadCount={increaseUploadCount}
            folderJump={onFolderJump}
            notifications={notifications}
            getQueryEmails={getQueryEmails}
            emailList={emailList}
            homeId={homeId}
            markAsRead={markAsRead}
            isLabelUser={currentUser.isLabelUser || currentUser.isLabelManager}
            workspaceList={workspaceList}
            getLibStudioData={getLibStudioData}
            validWorkspace={validWorkspace}
            currentUser={currentUser}
            postProfileDetails={postProfileDetails}
            profileSettings={profileSettings}
            setprofileSettings={setprofileSettings}
            getProfile={getProfile}
            toggleAccountModal={() => toggleModal('account')}
            allowAccess={allow_access_to_workspace_users === 'Y'}
            setTransferOpen={setTransferOpen}
            workspace={workspace}
          />
          {validWorkspace && (
            <>
              {tab_id === '1' && (
                <AnRFilters
                  fileFilterKey={fileFilterKey}
                  updateFilterKey={updateFilterKey}
                  exportPdf={exportPdf}
                />
              )}

              <AnRTabContent
                currentUser={currentUser}
                tab={tab_id}
                dashboardRecords={updatedRecords}
                handleActionMenu={handleActionMenu}
                onFolderClick={onFolderClick}
                getMyLinks={getMyLinks}
                myLinkRecords={myLinkRecords}
                deleteLink={deleteLink}
                handleSelectedRows={handleSelectedRows}
                selectedAnRRecords={selectedAnRRecords}
                handleBulkDownload={handleBulkDownload}
                download_transfer_spec={download_transfer_spec}
                nodeToken={nodeToken}
                bulkShareLibrary={bulkShareLibrary}
                handleBulkDelete={handleBulkDelete}
                isDeleteAllow={isDeleteAllow}
                isShareAllow={isShareAllow}
                sendRequestAssetMail={sendRequestAssetMail}
                terminateReqLink={terminateReqLink}
                username={currentUser.fullName}
                copyLink={copyLink}
                activateLink={activateLink}
                handleLinkSubmit={handleLinkSubmit}
                handleModifyReqSubmit={handleModifyReqSubmit}
              />
            </>
          )}
          {!validWorkspace && <div className="emptyDashboard"></div>}
          <Footer />

          {modalStatus.reqAsset && (
            <RequestAssets
              isOpen={modalStatus.reqAsset}
              handleClose={() => toggleModal('reqAsset', false)}
              handleSubmit={handleLinkSubmit}
              isLinkTab={false}
              fillData={undefined}
              currentUser={currentUser}
            />
          )}

          {modalStatus.moveFolder && (
            <MoveToFolder
              createFolder={createFolder}
              currFolderList={currFolderList}
              createFolderMsg={createFolderMsg}
              isOpen={modalStatus.moveFolder}
              handleClose={() => {
                toggleModal('moveFolder', false);
                setCurrFolderList([]);
              }}
              currActionRow={currActionRow}
              handleSubmit={handleMoveFolder}
            />
          )}

          {modalStatus.library && (
            <ShareToLibrary
              getQueryEmails={getQueryEmails}
              isOpen={modalStatus.library}
              handleClose={() => toggleModal('library', false)}
              handleSubmit={handleShareLibrarySubmit}
              emailList={emailList}
            />
          )}

          {modalStatus.studio && (
            <ShareStudio
              isOpen={modalStatus.studio}
              handleClose={() => toggleModal('studio', false)}
              handleSubmit={handleShareStudioSubmit}
              studioRow={currActionRow}
              getQueryEmails={getQueryEmails}
              emailList={emailList}
              contentInfoDetails={contentInfoDetails}
            />
          )}
          {modalStatus.external && (
            <ShareToExternal
              isOpen={modalStatus.external}
              handleClose={() => toggleModal('external', false)}
              handleSubmit={handleShareExternalSubmit}
              externalRow={currActionRow}
            />
          )}

          {modalStatus.delete && (
            <DeleteFile
              isOpen={modalStatus.delete}
              handleClose={() => toggleModal('delete', false)}
              handleSubmit={handleBulkDeleteFileSubmit}
              sharedStatus={isShared}
              count={count}
              condition={!isDeleteAllow}
            />
          )}

          {modalStatus.revision && (
            <RequestRevision
              isOpen={modalStatus.revision}
              handleClose={() => toggleModal('revision', false)}
              handleSubmit={handleRrFileSubmit}
              currRow={currActionRow}
              RequestRevisionFile={RequestRevisionFile}
              userIdList={userIdList}
            />
          )}

          {modalStatus.invite && (
            <InviteCollaborators
              isOpen={modalStatus.invite}
              handleClose={() => toggleModal('invite', false)}
              handleSubmit={handleInviteCollaboratorSubmit}
              postInviteCollaborator={postInviteCollaborator}
              row={currActionRow}
              userIdList={userIdList}
            />
          )}

          {modalStatus.aspera && (
            <AsperaConnect
              isOpen={modalStatus.aspera}
              handleClose={() => toggleModal('aspera', false)}
            />
          )}

          {modalStatus.editDetails && (
            <EditFile
              isOpen={modalStatus.editDetails}
              handleClose={() => toggleModal('editDetails', false)}
              handleSubmit={handleContentUpdate}
              handleActionMenu={handleActionMenu}
              row={currActionRow}
              comments={comments}
              postComments={postComments}
              activitiesRecords={activitiesRecords}
              contentInfoDetails={contentInfoDetails}
              currentUser={currentUser}
              assetPermission={assetPermission}
            />
          )}

          {modalStatus.vaultAsset && (
            <VaultAsset
              isOpen={modalStatus.vaultAsset}
              handleClose={() => toggleModal('vaultAsset', false)}
              handleSubmit={handleVaultSubmit}
            />
          )}

          {modalStatus.register && (
            <RegiserInStudio
              isOpen={modalStatus.register}
              handleClose={() => toggleModal('register', false)}
              handleSubmit={handleRegisterSubmit}
              currActionRow={currActionRow}
            />
          )}

          {modalStatus.regDecline && (
            <RegisterDecline
              isOpen={modalStatus.regDecline}
              handleClose={() => toggleModal('regDecline', false)}
              handleSubmit={handleRegisterDeclineSubmit}
              DeclineInRegistrationFile={DeclineInRegistrationFile}
              currActionRow={currActionRow}
            />
          )}

          {modalStatus.approve && (
            <ApproveInPending
              isOpen={modalStatus.approve}
              handleClose={() => toggleModal('approve', false)}
              handleSubmit={handleApproveSubmit}
              approveFile={approveFile}
              row={currActionRow}
            />
          )}

          {modalStatus.decline && (
            <DeclineInPending
              isOpen={modalStatus.decline}
              handleClose={() => toggleModal('decline', false)}
              handleSubmit={handleDeclineSubmit}
              declineFile={declineFile}
              declineStudioFile={declineStudioFile}
              row={currActionRow}
            />
          )}

          {alertDetails.show && (
            <Alert
              className="alertStyle"
              colorType={alertDetails.type}
              containerStyle="solid"
              dissmisable
              icon={
                alertDetails.type === 'success'
                  ? 'check'
                  : 'hexagon-exclamation'
              }
              // isVisible={alertDetails.show}
              title={alertDetails.title}
              onCloseAlert={() =>
                toggleAlert({ show: false, type: '', title: '' })
              }
            />
          )}
        </>
      )}
      {modalStatus.account && (
        <Userprofile
          isOpen={modalStatus.account}
          handleClose={() => toggleModal('account', false)}
          handleSubmit={handleSettingModalSubmit}
          currentUser={currentUser}
          profileSettings={profileSettings}
          setprofileSettings={setprofileSettings}
          getProfile={getProfile}
        />
      )}
      {transferOpen.isOpen && (
        <TransferInProgress
          isOpen={transferOpen.isOpen}
          setTransferOpen={setTransferOpen}
          action={transferOpen.action}
        />
      )}
    </div>
  );
};

export default Dashboard;

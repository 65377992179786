import { Button, Icon, Input, InputGroup, Label } from '@wmg-ae/legato';
import React, { useState } from 'react';

const ExternalLoginDownload: React.FC<any> = ({ verifyPassword, isInValid, isExpired }) => {
  const [isShowPassword, setisShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [isLoading, setisLoading] = useState(false);

  const isVerifyLogin = (password: any) => {
    setisLoading(true)
    verifyPassword({ password })
    setTimeout(() => {
      setisLoading(false)
    }, 2000);
  }
  return (
    <>
      <h1 className="extUploadText">Download Asset(s)</h1>
      <div className='extUploadLogin'>
        <Icon name="lock-keyhole" className="pt20 fa-5x" />
        <Label
          className='pt20'
          text='This Page Content is Protected.'></Label>
        <p>To view, please enter the password</p>
      </div>
      <InputGroup label='Enter Password :' validation={isLoading ? { pending: true } : isInValid.status ? { } : { required: true }}>
        {!isLoading && (isInValid.status || isExpired)  && (<Button colorType={'danger'} containerStyle={'link'} size={'md'} secondaryIcon={<Icon name={'warning'} prefix="fas" />}><b>Attempts Remaining : </b>{isInValid.attemptsLeft}</Button>)}
        <div className="pass-wrapper">
          <Input
            onChange={(e: any) =>
              setPassword(e.target.value)
            }
            placeholder="Enter Key"
            type={(isShowPassword && 'text') || 'password'}
            value={password}
          />
          <Icon
            className="icon"
            name={(isShowPassword && 'eye-slash') || 'eye'}
            prefix="fal"
            onClick={() => setisShowPassword(!isShowPassword)}
          />
        </div>
        {isInValid.status && isInValid.attemptsLeft !== 0 && !isLoading && (<Button colorType={'danger'} containerStyle={'link'} size={'xs'} secondaryIcon={<Icon name={'warning'} prefix="fas" />} prefix="fas"><b>Warning :</b> After 5 unsuccessful attempts the link will be made expired</Button>)}
        {isInValid.status && isInValid.attemptsLeft === 0 && !isLoading && (<Button colorType={'danger'} containerStyle={'link'} size={'xs'} secondaryIcon={<Icon name={'warning'} prefix="fas" />} prefix="fas">Too many failed attempts. New link needs to be generated to upload.</Button>)}
        <div className='extUploadLogin pt20'>
          <Button
            label="Verify"
            className="col-2 btnHeight"
            onClick={() => isVerifyLogin(password)}
            disabled={!password.length}
          />
        </div>
      </InputGroup>
    </>
  );
};

export default ExternalLoginDownload;

import React from 'react';
import {
  Checkbox,
  Input,
  InputGroup,
  Label,
  MultiSelect,
  Textarea,
} from '@wmg-ae/legato';

import { AudioList } from './constant';
import { fieldValidation, regexPattern } from '../dashboard/helper';

const LibraryExternal: React.FC<any> = ({
  email,
  uploadStatus,
  handleInputChange,
  inputFields,
}) => {
  const {
    artist,
    title,
    upc,
    notes,
    // isrc,
    selectedOption,
    // version,
    // engineer,
    studio_name,
    // producer,
    package_name,
    check,
  } = inputFields;

  const { required } = fieldValidation;

  return (
    <div>
      <h1 className="extUploadText">Upload Asset(s) to WMG Library</h1>
      {/* <p className="extLibraryCaption">
        Your assets will be shared directly with library team
      </p> */}
      <Label text="Send To:" />
      <p className="extUploadpText extEmailText">{email}</p>
      <div className="removePadding">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <InputGroup
                label="Artist"
                validation={required}
                validationIconPlacement="label"
                icon="error"
                className="test1"
              >
                <Input
                  className="extInputMargin"
                  onChange={(e: any) =>
                    handleInputChange('artist', e.target.value)
                  }
                  placeholder="Enter Artist "
                  value={artist}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            <div className="col-6">
              <InputGroup
                label="Title"
                validation={required}
                validationIconPlacement="label"
                icon="error"
                className="test1"
              >
                <Input
                  className="extInputMargin"
                  onChange={(e: any) =>
                    handleInputChange('title', e.target.value)
                  }
                  placeholder="Enter title for asset(s)"
                  value={title}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="removePadding">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <InputGroup
                label="Package Name"
                validation={required}
                validationIconPlacement="label"
                icon="error"
                className="test1"
              >
                <Input
                  className="extInputMargin"
                  onChange={(e: any) =>
                    handleInputChange('package_name', e.target.value)
                  }
                  placeholder="Enter Package Name "
                  value={package_name}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            <div className="col-6">
              <InputGroup
                label="Audio Type"
                validationIconPlacement="label"
                icon="error"
                className="test1 col"
              >
                <MultiSelect
                  disabled={uploadStatus}
                  value={selectedOption}
                  list={AudioList}
                  placeholder="Select type of audio"
                  getTitle={(el: any) => el.label}
                  getKey={(el: any) => el.label}
                  // isFilterable={true}
                  onSelect={(key: any) => {
                    handleInputChange('selectedOption', key);
                  }}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="removePadding">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <InputGroup
                label="UPC Number"
                // validation={required}
                validationIconPlacement="label"
                icon="error"
                className="test1"
              >
                <Input
                  className="extInputMargin"
                  maxLength={13}
                  onChange={(e) => {
                    (regexPattern.isDigit.test(e.target.value) ||
                      !e.target.value.length) &&
                      handleInputChange('upc', e.target.value);
                  }}
                  placeholder="Enter UPC number"
                  value={upc}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            <div className="col-6">
              <InputGroup label="Studio Information" className="test1">
                <Input
                  className="extInputMargin"
                  onChange={(e: any) =>
                    handleInputChange('studio_name', e.target.value)
                  }
                  placeholder="Enter Studio name"
                  value={studio_name}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            {/* <div className="col-6">
              <InputGroup
                label="ISRC"
                // validation={required}
                validationIconPlacement="label"
                icon="error"
                className="test1"
              >
                <Input
                  className="extInputMargin"
                  maxLength={13}
                  onChange={(e) =>
                    (regexPattern.isAlphaNum.test(e.target.value) ||
                      !e.target.value.length) &&
                    handleInputChange('isrc', e.target.value)
                  }
                  placeholder="Enter ISRC number"
                  value={isrc}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div> */}
          </div>
        </div>
      </div>
      <div className="removePadding">
        <div className="container">
          <div className="row">
            {/* <div className="col-6">
              <InputGroup label="Version" className="test1 col">
                <Input
                  className="extInputMargin"
                  onChange={(e: any) =>
                    handleInputChange('version', e.target.value)
                  }
                  placeholder="Enter Version number"
                  value={version}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div> */}
          </div>
        </div>
      </div>
      <div className="removePadding">
        <div className="container">
          <div className="row">
            {/* <div className="col-6">
              <InputGroup label="Engineer" className="test1">
                <Input
                  className="extInputMargin"
                  onChange={(e: any) =>
                    handleInputChange('engineer', e.target.value)
                  }
                  placeholder="Enter Engineer name"
                  value={engineer}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div> */}
          </div>
        </div>
      </div>
      {/* <InputGroup label="Producer" className="test1">
        <Input
          className="extInputMargin"
          onChange={(e: any) => handleInputChange('producer', e.target.value)}
          placeholder="Enter Producer name"
          value={producer}
          disabled={uploadStatus}
        />
      </InputGroup> */}
      <InputGroup label="Notes" validation={undefined}>
        <Textarea
          className="extInputMargin"
          onChange={(e: any) => handleInputChange('notes', e.target.value)}
          placeholder="Enter notes"
          size="lg"
          value={notes}
          disabled={uploadStatus}
        />
      </InputGroup>

      <Checkbox
        label="I Agree to WMG SUN"
        name="tandp"
        className="extInputMargin"
        checked={check}
        onChange={(e: any) => handleInputChange('check', e.target.checked)}
        disabled={uploadStatus}
      />
      <p className="positionTermString">
        <a
          href="https://www.wmg.com/terms-of-use"
          aria-disabled={uploadStatus}
          className="termString"
          target="_blank"
          rel="noopener noreferrer"
        >
          term and conditions
        </a>
      </p>
    </div>
  );
};

export default LibraryExternal;

import React, { useEffect, useState } from 'react';
import { Button, Icon, Input, InputGroup, Modal } from '@wmg-ae/legato';
import { fieldValidation } from '../../helper';

const MoveToFolder: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  createFolder,
  currFolderList,
  createFolderMsg,
  currActionRow,
}) => {
  const [showCreateFolder, setShowcreateFolder] = useState(false);
  const [createBttnDisabled, setCreateBttnDisabled] = useState(false);
  const [newFoldername, setNewFoldername] = useState('');
  const [selectedFolder, setSelectedFolder] = useState<any>({});
  const [validateFolderName, setValidateFolderName] = useState<any>(
    fieldValidation.required
  );

  useEffect(() => {
    if (!createFolderMsg.title) {
      setNewFoldername('');
      setShowcreateFolder(false);
      setValidateFolderName(fieldValidation.required);
      setCreateBttnDisabled(false);
    } else {
      if (createFolderMsg.icon !== 'check') {
        setSelectedFolder({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFolderMsg.title]);

  useEffect(() => {
    if (currFolderList.length) {
      currFolderList.map((list: any) => {
        if (list.name === selectedFolder.name) setSelectedFolder(list);
        return list;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currFolderList]);

  const handleFolderName = (e: any) => {
    const newName = e.target.value.trimStart();
    setNewFoldername(newName);
    setValidateFolderName(
      newName ? fieldValidation.required : fieldValidation.error
    );
    setCreateBttnDisabled(!newName);
  };

  return (
    <Modal
      isOpen={isOpen}
      size={'lg'}
      footer={
        <div className="moveFolderFooter">
          {!!createFolderMsg.title && (
            <div className="row mx0 folderMsgWrapper">
              <div className="col-12 px0">
                <span className="newFolderIcon">
                  <Icon
                    className={
                      createFolderMsg.icon === 'check'
                        ? 'lgt-clr-f-core'
                        : 'lgt-clr-ada-core'
                    }
                    name={createFolderMsg.icon}
                    prefix="fal"
                  />
                </span>
                <span>{createFolderMsg.title}</span>
              </div>
            </div>
          )}
          {showCreateFolder ? (
            <div className="row mx0">
              <div className="col-12 px0">
                <InputGroup
                  label="New Folder Name"
                  validation={validateFolderName}
                >
                  <Input
                    disabled={!!createFolderMsg.title}
                    onChange={handleFolderName}
                    placeholder="Enter folder name"
                    value={newFoldername}
                  />
                </InputGroup>
              </div>
              <div className="col-12 textRight pt20 px0 folderBttnWrapper">
                <Button
                  colorType="secondary-black"
                  containerStyle="outline"
                  onClick={() => setShowcreateFolder(false)}
                >
                  Cancel
                </Button>
                <Button
                  colorType="primary"
                  disabled={
                    !newFoldername ||
                    !!createFolderMsg.title ||
                    createBttnDisabled
                  }
                  onClick={() => {
                    createFolder({name: newFoldername, artist: currActionRow.artist, title: currActionRow.title, allow_access: currActionRow.allow_access_to_workspace_users});
                    setCreateBttnDisabled(true);
                    setSelectedFolder({ name: newFoldername, cid: '' });
                  }}
                >
                  Create
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}
            >
              <Button
                colorType="primary"
                containerStyle="link"
                icon="plus"
                label="Create a new folder"
                onClick={() => setShowcreateFolder(true)}
                size="md"
              />
              <div style={{ flex: 1 }} />
              <Button
                colorType="secondary-black"
                containerStyle="outline"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                colorType="primary"
                disabled={!selectedFolder.name}
                onClick={() => handleSubmit(selectedFolder)}
              >
                Move To Selected Folder
              </Button>
            </div>
          )}
        </div>
      }
      // icon="folder-blank"
      onClose={handleClose}
      title="Move to folder"
    >
      <div className="modalBody moveFolderBody">
        <div className="row">
          {/* <div className="col-12">
            WORK IN PROGRESS: Move to folder... currFolderList
          </div>
          <div className="col-12">Assets / My Album</div> */}
          {!currFolderList.length && (
            <div className="col-12 textCenter">
              <span>No folders found!</span>
            </div>
          )}

          {!!currFolderList.length && (
            <div className="col-12">
              <ul className="folderListWrapper">
                {currFolderList.map((opt: any, idx: number) => {
                  return (
                    <li
                      className={
                        opt.name === selectedFolder.name ? 'selectedFolder' : ''
                      }
                      key={idx}
                      onClick={() => setSelectedFolder(opt)}
                    >
                      {opt.name === selectedFolder.name && (
                        <span className="checkIcon">
                          <Icon name="check" prefix="fal" />
                        </span>
                      )}
                      <span className="newFolderIcon">
                        <Icon name="folder-blank" prefix="fal" />
                      </span>
                      {opt.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MoveToFolder;

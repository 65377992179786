import { definedRoles } from './constant';

export const getStatusClassname = (statusText) => {
  switch (statusText.toLowerCase()) {
    case 'for review':
    case 'new':
      return 'lgt-bg-h-shade1 lgt-brdr-h-shade1';
    case 'in review':
      return 'lgt-bg-h-tint1 lgt-brdr-h-tint1';
    case 'in studio':
      return 'lgt-bg-i-core lgt-brdr-i-core';
    case 'in library':
      return 'lgt-bg-d-shade2 lgt-brdr-d-shade2';
    case 'vaulted':
      return 'lgt-bg-c-shade1 lgt-brdr-c-shade1';
    case 'active':
      return 'lgt-bg-f-shade1 lgt-brdr-f-shade1';
    case 'expired':
      return 'lgt-bg-b-shade1 lgt-brdr-b-shade1';
    // case 'registered': // This color code is there by default
    //   return 'lgt-bg-f-core';

    default:
      return '';
  }
};

const MS_PER_DAY = 24 * 60 * 69 * 1000;
export const getDaysDifference = (date1, date2) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY) + 1;
};

export const getContentIcon = (isFolder, share) => {
  if (isFolder) {
    return share === 'y' ? 'folder-user' : 'folder';
  } else {
    return share === 'y' ? 'file-user' : 'file';
  }
};
// export const getContentIcon = (
//   isFolder,
//   share,
//   allow_access_to_workspace_users
// ) => {
//   if (allow_access_to_workspace_users === 'Y') {
//     return 'user-group';
//   }
//   if (isFolder) {
//     return share === 'y' ? 'folder-user' : 'folder';
//   } else {
//     return share === 'y' ? 'file-user' : 'file';
//   }
// };

export const getActivityIcon = (action) => {
  switch (action) {
    case 'upload':
      return 'upload';
    case 'download':
    case 'internaldownload':
      return 'download';
    case 'sharetoexternal':
    case 'sharetolibrary':
    case 'sharetostudio':
      return 'share-from-square';
    case 'request revision':
      return 'file-import';
    case 'update':
    case 'edit':
      return 'floppy-disk-pen';
    case 'vault':
      return 'vault';
    case 'Invite collaborator/shared':
      return 'user-group';
    case 'Approve':
      return 'file-check';
    case 'declineForStudio':
    case 'declineForRevision':
      return 'file-xmark';
    case 'register':
      return 'memo-circle-check';
    case 'DeclineRegister':
      return 'memo-circle-info';
    case 'mail':
      return 'envelope';

    default:
      return 'file';
  }
};

export const getActivityMsg = (action) => {
  switch (action) {
    case 'upload':
      return 'File was uploaded by ';
    case 'download':
    case 'internaldownload':
      return 'File was downloaded by ';
    case 'sharetostudio':
      return 'File was shared to studio by ';
    case 'sharetolibrary':
      return 'File was shared to library by ';
    case 'sharetoexternal':
      return 'File was shared to external user ';
    case 'request revision':
      return 'A revision was requested for this file by ';
    case 'edit':
    case 'update':
      return 'File details were updated by ';
    case 'vault':
      return 'File was moved to vault by ';
    case 'Invite collaborator/shared':
      return ' was invited as a collaborator by ';
    case 'Approve':
      return 'Studio updates were approved by ';
    case 'declineForStudio':
    case 'declineForRevision':
      return 'Studio updates were declined by- ';
    case 'register':
      return 'This file was registered by ';
    case 'DeclineRegister':
      return 'File registration was declined by ';

    default:
      // return 'No change for the file by ';
      return 'File was updated by ';
  }
};

export const getActivityTypes = [
  'upload',
  'download',
  'internaldownload',
  'sharetostudio',
  'sharetolibrary',
  'request revision',
  'edit',
  'update',
  'vault',
  'Invite collaborator/shared',
  'Approve',
  'declineForStudio',
  'declineForRevision',
  'register',
  'DeclineRegister',
  'sharetoexternal',
];

export const regexPattern = {
  email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  isDigit: /^[0-9]+$/,
  isAlphaNum: /^[0-9a-zA-Z]+$/,
  wmgEmail: /^[a-zA-Z0-9._-]+@wmg.com$/,
  wmgTestEmail: /^[a-zA-Z0-9._-]+@music.wmgis.com$/,
};

export const fieldValidation = {
  required: { required: true },
  error: { type: 'error' },
};

export const getTimeLapse = (timestamp) => {
  // 'Sat Sep 17 2022 02:04:01 GMT+0530 (India Standard Time)'; // Sample timestamp
  const SEC_1 = 1 * 1000;
  const MIN_1 = 60 * SEC_1;
  const HOUR_1 = 60 * MIN_1;
  const DAY_1 = 24 * HOUR_1;

  const oldTime = new Date(timestamp).getTime();
  // 0 * DAY_1 +
  // 2 * HOUR_1 +
  // 192 * MIN_1 +
  // 10 * SEC_1;
  const currTime = new Date().getTime();
  const timeDiff = currTime - oldTime;

  if (timeDiff < SEC_1) return '1 sec ago';
  if (timeDiff < MIN_1) return `${Math.floor(timeDiff / SEC_1)} sec ago`;
  if (timeDiff < HOUR_1) return `${Math.floor(timeDiff / MIN_1)} min ago`;
  // if (timeDiff < DAY_1) return `${Math.floor(timeDiff / HOUR_1)} hour ago`;
  if (timeDiff < DAY_1) return `${(timeDiff / HOUR_1).toFixed(1)} hour ago`; // Upto 1 digit decimal
  if (timeDiff >= DAY_1) return `${Math.floor(timeDiff / DAY_1)} day ago`;
};

export const getUserDetails = (info) => {
  // DON'T DELETE, this is for stubbing in local
  // const groups = [
  //   // 'ENT_Okta_WEASUN_Dev_LabelUser', // A&R User
  //   // 'ENT_Okta_WEASUN_Dev_LibraryUser', // Library User
  //   // 'ENT_Okta_WEASUN_Dev_StudioUser', // Studio User
  //   // 'ENT_Okta_WEASUN_Dev_LabelManager', // A&R Manager
  //   // 'ENT_Okta_WEASUN_Staging_LabelUser', // Staging Env, A&R
  // ];

  // FOR LOCAL TESTING: Uncomment below line and comment next line. Also uncomment groups declared above
  // const { given_name, name, preferred_username } = info;
  const { given_name, name, preferred_username, groups } = info;

  return {
    givenName: given_name,
    fullName: name.split(', ').reverse().join(' '),
    email: preferred_username,
    roles: groups,
    roleName: groups.map((role) => {
      const roleSplit = role.split('_');
      return roleSplit[roleSplit.length - 1];
    }),
    isLabelUser:
      groups.includes(definedRoles.devLabelUser) ||
      groups.includes(definedRoles.stageLabelUser) ||
      groups.includes(definedRoles.prodLabelUser),
    isLibraryUser:
      groups.includes(definedRoles.devLibraryUser) ||
      groups.includes(definedRoles.stageLibraryUser) ||
      groups.includes(definedRoles.prodLibraryUser),
    isStudioUser:
      groups.includes(definedRoles.devStudioUser) ||
      groups.includes(definedRoles.stageStudioUser) ||
      groups.includes(definedRoles.prodStudioUser),
    isLabelManager:
      groups.includes(definedRoles.devLabelManager) ||
      groups.includes(definedRoles.stageLabelManager) ||
      groups.includes(definedRoles.prodLabelManager),
  };
};

export const sort_by_key = (array, key) => {
  return array.sort((a, b) => {
    let y = a[key];
    let x = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const sort_by_key_desc = (array, key) => {
  return array.sort((a, b) => {
    let y = a[key];
    let x = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  }).reverse();
};

import { Logo } from '@wmg-ae/legato';
import React from 'react';

const ExtenalHeader: React.FC<any> = ({ logo, title }) => {
  return (
    <div className="headerWrapper">
      <div className="header_container">
        <Logo
          className="label_logo"
          logo={logo}
          appName={'Secure Upload Network'}
          appContext={title}
        />
      </div>
    </div>
  );
};

export default ExtenalHeader;

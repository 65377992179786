import {
  Button,
  Icon,
  InputGroup,
  Label,
  Modal,
  Radio,
  Textarea,
} from '@wmg-ae/legato';
import React, { useState } from 'react';
import './modalStyles.sass';
import '../../../../shared-components/spacingStyles.sass';

const DeclineInPending: React.FC<any> = ({
  handleClose,
  handleSubmit,
  isOpen,
  declineFile,
  declineStudioFile,
  row,
}) => {
  const { content_id, name, type, parent_id } = row;
  const [notes, setnotes] = useState('');
  const [isRequested, setisRequested] = useState(false);
  const handleDecline = () => {
    declineFile({
      content_id: row.content_id,
      name: row.name,
      notes,
      status: 'Requested Revision',
      recipient_email: [row.recipient_emails || row.user_email],
      userName: row.user_name,
      asset_name: [row.name],
      url: row.url,
      token: row.split_part,
    });
    handleSubmit();
    setisRequested(false);
  };

  const handleStudioDecline = () => {
    declineStudioFile({
      content_id: content_id,
      name,
      notes,
      status: 'In Studio',
      type,
      parent_id,
    });
    handleSubmit();
    setisRequested(false);
  };
  const handleNotes = (e: any) => {
    let notes1 = e.target.value;
    setnotes(notes1);
  };
  const handleButton = (e: any) => {
    if (e) {
      setisRequested(true);
    }
  };
  const handleStudio = (e: any) => {
    if (e) {
      setisRequested(false);
    }
  };
  return (
    <Modal
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {!isRequested && (
            <Button colorType="primary" onClick={handleStudioDecline}>
              Submit
            </Button>
          )}
          {isRequested && (
            <Button colorType="primary" onClick={handleDecline}>
              Submit
            </Button>
          )}
        </div>
      }
      onClose={handleClose}
      shouldCloseOnOverlayClick
      title="Decline Approval Request"
      isOpen={isOpen}
    >
      <div>
        <div>
          <Label size="lg" text="Reason" />
        </div>
        <div className="lgt-radio-group lgt-radio-group--horizontal pt20">
          <Radio
            label="Needs further work from WMG studio"
            onChange={(e) => handleStudio(e)}
            name="bin"
            defaultChecked
          />
          <Radio
            label="Needs revision"
            name="bin"
            onChange={(e) => handleButton(e)}
          />
        </div>
        {isRequested && (
          <div className="pt20">
            <Icon name="info-circle" /> A request for revision will be sent to
            the sender of this file. (New file needed)
          </div>
        )}
        <div className="row pt20">
          <div className="col">
            <InputGroup label="Notes">
              <Textarea
                onChange={(e) => handleNotes(e)}
                placeholder="Enter any additional detail."
                size="lg"
                value={notes}
              />
            </InputGroup>
          </div>
        </div>
        <div></div>
      </div>
    </Modal>
  );
};

export default DeclineInPending;

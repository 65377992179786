import React from 'react';
import {
  Checkbox,
  Input,
  InputGroup,
  Label,
  MultiSelect,
  Textarea,
} from '@wmg-ae/legato';

import { AudioList } from './constant';
import { fieldValidation } from '../dashboard/helper';

const LabelExternal: React.FC<any> = ({
  email,
  uploadStatus,
  handleInputChange,
  inputFields,
  isOpenLogin,
}) => {
  const { artist, package_name, title, notes, check, selectedOption } =
    inputFields;
  const { required } = fieldValidation;

  return (
    <div>
      <h1 className="extUploadText">Upload Asset(s)</h1>
      <Label text="Send To:" />
      <p className="extUploadpText extEmailText">{email}</p>
      <InputGroup
        label="Artist"
        validation={required}
        validationIconPlacement="label"
        icon="error"
        className="test1"
      >
        <Input
          className="extInputMargin"
          //disabled={!!displayLinkURL}
          onChange={(e: any) => handleInputChange('artist', e.target.value)}
          // onKeyDownCapture={handleKeyDown}
          placeholder="Enter Artist "
          suffixIcon="user"
          value={artist}
          disabled={uploadStatus}
        />
      </InputGroup>
      <InputGroup
        label="Title"
        validation={required}
        validationIconPlacement="label"
        icon="error"
        className="test1"
      >
        <Input
          className="extInputMargin"
          //disabled={!!displayLinkURL}
          onChange={(e: any) => handleInputChange('title', e.target.value)}
          // onKeyDownCapture={handleKeyDown}
          placeholder="Enter title for asset(s)"
          // suffixIcon="envelope"
          value={title}
          disabled={uploadStatus}
        />
      </InputGroup>
      <div className="removePadding">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <InputGroup
                label="Package Name"
                validation={required}
                validationIconPlacement="label"
                icon="error"
                className="test1 col"
              >
                <Input
                  className="extInputMargin"
                  onChange={(e: any) =>
                    handleInputChange('package_name', e.target.value)
                  }
                  placeholder="Enter Package Name"
                  value={package_name}
                  disabled={uploadStatus}
                />
              </InputGroup>
            </div>
            <div className="col-6">
              <InputGroup
                label="Audio Type"
                validationIconPlacement="label"
                icon="error"
                className="test1 col"
              >
                <MultiSelect
                  disabled={uploadStatus}
                  value={selectedOption}
                  list={AudioList}
                  placeholder="Select type of audio"
                  getTitle={(el: any) => el.label}
                  getKey={(el: any) => el.label}
                  // isFilterable={true}
                  onSelect={(key: any) => {
                    handleInputChange('selectedOption', key);
                  }}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
      <InputGroup label="Notes" validation={undefined}>
        <Textarea
          className="extInputMargin"
          onChange={(e: any) => handleInputChange('notes', e.target.value)}
          placeholder="Enter notes"
          size="lg"
          value={notes}
          disabled={uploadStatus}
        />
      </InputGroup>
      <Checkbox
        label="I Agree to WMG SUN"
        name="tandp"
        className="extInputMargin"
        checked={check}
        onChange={(e: any) => handleInputChange('check', e.target.checked)}
        disabled={uploadStatus}
      />
      <p className="positionTermString">
        <a
          href="https://www.wmg.com/terms-of-use"
          aria-disabled={uploadStatus}
          className="termString"
          target="_blank"
          rel="noopener noreferrer"
        >
          term and conditions
        </a>
      </p>
    </div>
  );
};

export default LabelExternal;

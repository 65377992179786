import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Table } from '@wmg-ae/legato';
import {
  MyLinkCloumns,
  MyLinkLibStudio,
  // MyLinkData,
  MyLinkActive,
  MyLinkExpired,
  MyLinkActiveDownload,
  MyLinkExpiredDownload,
} from '../constant';

import MyLinkDelete from './modals/MyLinkDelete';
import MyLinkTerminate from './modals/MyLinkTerminate';
import RequestAssets from './modals/RequestAssets';
import MyLinkFilters from './MyLinkFilters';
import { sort_by_key } from '../helper';
import { TTableSortDirection } from '@wmg-ae/legato/build/components/Table/types';

const MyLinks: React.FC<any> = ({
  getMyLinks,
  myLinkRecords,
  deleteLink,
  sendRequestAssetMail,
  username,
  terminateReqLink,
  copyLink,
  activateLink,
  handleLinkSubmit,
  currentUser,
  handleModifyReqSubmit,
}) => {
  const [linkType, setlinkType] = useState('Upload');
  const [isLinkDeleteOpen, setisLinkDeleteOpen] = useState(false);
  const [isLinkTerminateOpen, setisLinkTerminateOpen] = useState(false);
  const [isLinkGenerateOpen, setisLinkGenerateOpen] = useState(false);
  // const [selectedId, setselectedId] = useState('');
  const [selectedParams, setselectedParams] = useState<any>({});
  const [reqLinkData, setreqLinkData] = useState<any>({});
  const [filterKey, setfilterKey] = useState('');

  useEffect(() => {
    getMyLinks(linkType.toLowerCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkType]);

  const toggleMyLinkDeleteModal = (status = true): void => {
    setisLinkDeleteOpen(status);
  };

  const toggleMyLinkTerminateModal = (status = true): void => {
    setisLinkTerminateOpen(status);
  };

  const toggleMyLinkGenarateModal = (status = true): void => {
    setisLinkGenerateOpen(status);
  };

  const handleTerminateLink = () => {
    toggleMyLinkTerminateModal(false);
    terminateReqLink(selectedParams);
  };

  const handleDeleteLink = () => {
    toggleMyLinkDeleteModal(false);
    deleteLink(selectedParams);
  };

  const handleModifySubmit = (apiParams: any) => {
    toggleMyLinkGenarateModal(false);
    handleModifyReqSubmit(apiParams);
  };

  const handleLinkAction = (data: any, row: any) => {
    const {
      url,
      token,
      id,
      recipient_emails,
      notes,
      access_level,
      link_type,
      expiry_date,
      owner_email,
    } = row;
    const link = `${url}?token=${token}`;
    // console.log(row);
    const params = {
      email_type: 'link',
      recipient_email: [recipient_emails],
      username,
      link,
    };
    const modifyParams = {
      id,
      owner_email,
      linkType: linkType.toLowerCase(), //TODO: change according to backend response
    };
    const generateData = {
      email: recipient_emails,
      Note: notes,
      accessType: access_level.toString(),
      linkType: link_type.toString(),
      update: expiry_date === null ? 'no' : 'yes',
      date: expiry_date,
      id,
      link,
    };
    setreqLinkData(generateData);
    setselectedParams(modifyParams);
    // setselectedId(id);
    switch (data.title) {
      case 'Resend Link': {
        sendRequestAssetMail(params, true);
        break;
      }
      case 'Copy Link': {
        navigator.clipboard.writeText(link);
        copyLink();
        break;
      }
      case 'Terminate Link': {
        toggleMyLinkTerminateModal();
        break;
      }
      case 'Delete': {
        toggleMyLinkDeleteModal();
        break;
      }
      case 'Generate Link': {
        toggleMyLinkGenarateModal();
        break;
      }
      default:
        break;
    }
    // console.log(data, row);
  };

  // const [updatedRecords, setUpdatedRecords] = useState<any>([]);
  // useEffect(() => {
  //  const filteredRecords = filterKey
  //     ? myLinkRecords.filter((row: any) =>
  //         (row.recipient_emails || '').toLowerCase().includes(filterKey.toLowerCase()) ||
  //         (new Date(row.expiry_date).toLocaleDateString()|| '').toLowerCase().includes(filterKey.toLowerCase())
  //       )
  //     : myLinkRecords;
  //   setUpdatedRecords(filteredRecords);
  // }, [filterKey,myLinkRecords])

  let tableRecords: any = myLinkRecords.map((row: any, idx: number) => {
    const {
      is_expired,
      recipient_emails,
      url,
      token,
      access_level,
      link_type,
      timelapse,
      expiry_date,
    } = row;
    const Permissions = linkType === 'Upload' ? access_level === 0 ? 'Only Me' : 'Everyone' : 'Not Applicable';
    const Sign = link_type === 1 ? 'Yes' : 'No';
    const expTime =
      timelapse === 0 ? 'Never' : new Date(expiry_date).toLocaleDateString();
    const status =
      is_expired !== null && is_expired >= 0 ? 'Active' : 'Expired';
    const DropDownLinkUpload = status === 'Active' ? MyLinkActive : MyLinkExpired;
    const DropDownLinkDownload = status === 'Active' ? MyLinkActiveDownload : MyLinkExpiredDownload;
    const DropDownLink = linkType === 'Upload' ? DropDownLinkUpload : DropDownLinkDownload;
    const newRow = {
      key: row.id,
      RecipientEmail: recipient_emails, //need to discuss with UX team
      Permissions: Permissions,
      Sign: Sign,
      Time: expTime,
      status: status,
      Link: url + '?token=' + token,
      Url: url,
      Token: token,
      rowActions: (
        <Dropdown
          list={DropDownLink}
          placement={'bottomRight'}
          onSelect={(key: any) => handleLinkAction(key, row)}
        >
          <Button
            containerStyle="link"
            icon="ellipsis-h"
            colorType="secondary-black"
          />
        </Dropdown>
      ),
    };
    return newRow;
  });

  const tableColumn =
    ((currentUser.isStudioUser || currentUser.isLibraryUser) &&
      MyLinkLibStudio) ||
    MyLinkCloumns;

  let tableColumnsUpdated = tableColumn.map((column: any) => {
    const newCoulmn = {
      dataIndex: column.dataIndex,
      key: column.key,
      title: column.title,
      width: column.width,
      render:
        column.key === 'Link'
          ? (value: any, row: any, index: any) => {
            return (
              <div className="text-three-dots"> {`${row.Url}?token=${row.Token}`}</div>)
          } : undefined,
      sorterFunction: column.key === 'Time' ? (key: React.Key, direction: TTableSortDirection) => {
        let sortOrder = direction === 'ASC' ? 1 : -1;
        return function (a: any, b: any) {
          let result = new Date(a[key]) < new Date(b[key]) ? -1 : new Date(a[key]) > new Date(b[key]) ? 1 : 0;
          return result * sortOrder;
        };
      } : undefined,
      sortable: column.sortable,
      filterSearch: column.filterSearch,
    };
    return newCoulmn;
  });

  return (
    <div>
      <MyLinkFilters
        filterKey={filterKey}
        updateFilterKey={setfilterKey}
        linkType={linkType}
        setlinkType={setlinkType}
      />
      <Table
        data={sort_by_key(tableRecords, 'key')}
        columns={tableColumnsUpdated}
        condensed={true}
        transparent={false}
        // getSelectedRowsKeys={() => {}}
        pagination={{
          position: ['bottomCenter'],
        }}
        // striped='odd'
      />
      <MyLinkDelete
        handleClose={() => toggleMyLinkDeleteModal(false)}
        isOpen={isLinkDeleteOpen}
        handleSubmit={handleDeleteLink}
      />
      <MyLinkTerminate
        handleClose={() => toggleMyLinkTerminateModal(false)}
        isOpen={isLinkTerminateOpen}
        handleSubmit={handleTerminateLink}
      />
      {isLinkGenerateOpen && (
        <RequestAssets
          isOpen={isLinkGenerateOpen}
          handleClose={() => toggleMyLinkGenarateModal(false)}
          handleSubmit={handleModifySubmit}
          isLinkTab={true}
          fillData={reqLinkData}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

export default MyLinks;

import { Modal } from '@wmg-ae/legato';
import React from 'react';

const TransferInProgress: React.FC<any> = ({
  isOpen,
  setTransferOpen,
  action,
}) => {
  let activityMsg = '';
  switch (action) {
    case 'upload': {
      activityMsg =
        'File(s) are being uploaded. You will receive a notification once complete.';
      break;
    }
    case 'fetch': {
      activityMsg =
        'File(s) are being fetched from the workspace. They will be displayed once fetched.';
      break;
    }
    case 'approve': {
      activityMsg =
        'File(s) are being sent for approval. You will receive a notification once complete.';
      break;
    }
    case 'decline': {
      activityMsg =
        'File(s) decline is in progress. You will receive a notification once complete.';
      break;
    }
    case 'sharelibrary': {
      activityMsg =
        'File(s) are being transferred to library. You will receive a notification once complete.';
      break;
    }
    case 'sharestudio': {
      activityMsg =
        'File(s) are being transferred to studio. You will receive a notification once complete.';
      break;
    }
    case 'shareexternal': {
      activityMsg =
        'File(s) are being shared. You will receive a notification once complete.';
      break;
    }
    case 'register': {
      activityMsg =
        'File(s) are being registered. You will receive a notification once complete.';
      break;
    }
    case 'requestrevision': {
      activityMsg =
        'File(s) are being sent for revision. You will receive a notification once complete.';
      break;
    }
    case 'collaborator': {
      activityMsg =
        'File(s) are being shared to collaborator(s). You will receive a notification once complete.';
      break;
    }
    default: {
      activityMsg =
        'Please wait file(s) are being transfer to WMG, do not close this window until transfer is completed. You will be shown a success messages once transfer is completed';
      break;
    }
  }
  return (
    <Modal
      icon="spinner-third fa-spin"
      title="Transfer In Progress"
      isOpen={isOpen}
      // shouldCloseOnOverlayClick
      onClose={() => {
        setTransferOpen({ isOpen: false, action: '' });
      }}
    >
      <div className="deleteFile">{activityMsg}</div>
    </Modal>
  );
};

export default TransferInProgress;

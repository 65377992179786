import { Button, Modal } from '@wmg-ae/legato';
import React from 'react';

const ApproveInPending: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  approveFile,
  row,
}) => {
  const { content_id, name } = row;
  const handleApprove = () => {
    approveFile({
      content_id: content_id,
      name,
      status: 'Approved',
      email: row.user_email,
      fullName: row.user_name,
      user_id: `${row.user_id}`,
    });
    handleSubmit();
  };
  return (
    <Modal
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            No, Cancel
          </Button>
          <Button colorType="primary" onClick={handleApprove}>
            Yes, Approve
          </Button>
        </div>
      }
      onClose={handleClose}
      shouldCloseOnOverlayClick
      title="Approval Confirmation"
      isOpen={isOpen}
    >
      <div>Are you sure you want to approve this file?</div>
    </Modal>
  );
};

export default ApproveInPending;

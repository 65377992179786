import React from 'react';
import uploadfailure from '../../assets/uploadfailed.svg';

const InvalidLink: React.FC<any> = ({ handleReqLink, sendLink }) => {
  return (
    <div className="extUploadMessage extUploadFailure">
      <img src={uploadfailure} alt="upload" />
      <h1 className="extH1">Invalid Link</h1>
      <p className="extUploadpText InvalidFailureText">
        The link you entered does not exsits or has been deleted. Please make
        sure you are entering a valid link.
      </p>
    </div>
  );
};

export default InvalidLink;

import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Connect, ConnectInstaller } from '@ibm-aspera/connect-sdk-js';

import axios from 'axios';
import { Button, Icon, InputGroup, Logo, Radio } from '@wmg-ae/legato';
import Footer from '../dashboard/components/Footer';
import ExternalUploadSuccess from './ExternalUploadSuccess';
import ExternalUploadFailure from './ExternalUploadFailure';

import './ExternalUpload.sass';
import ExpiredLink from './ExpiredLink';
import InvalidLink from './InvalidLink';

import FAQ from './FAQ';

import LabelExternal from './LabelExternal';
import LibraryExternal from './LibraryExternal';

import { userRole, StudioOptions } from './constant';
import StudioExternal from './StudioExternal';
import wmgLogo from '../../assets/blackWMGlogo.svg';
import { fieldValidation, regexPattern } from '../dashboard/helper';
import ExternalModalPopup from './ExternalModalPopup';
import ExternalLogin from './ExternalLogin';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const apiKey = { 'x-api-key': process.env.REACT_APP_GATEWAY_KEY || '' };

const ApiURL = {
  ReqLink: `${baseURL}/external-link/`,
  AocTokenLink: `${baseURL}/aoc-token?email=`,
  nodeTokenLink: `${baseURL}/node-token?`,
  uploadTransferSpecLink: `${baseURL}/transfer-spec?type=send&`,
  InsertUpload: `${baseURL}/asset-metadata`,
  AnRDetails: `${baseURL}/workspace`,
  PostComments: `${baseURL}/comments`,
  downloadPermissionLink: `${baseURL}/set-permissions`,
  EmailService: `${baseURL}/email-service`,
  shareAssets: `${baseURL}/asset-operations`,
  checkTransferStatus: `${baseURL}/check_transfer_status`,
  endTransfer: `${baseURL}/end_transfer`,
  writeNotification: `${baseURL}/notification`,
  insertPackage: `${baseURL}/insert-package-metadata`,
  deleteCid: `${baseURL}/remove-asset`,
  sendReqEmail: `${baseURL}/email-service`,
  createFolder: `${baseURL}/asset-operations/create`,
  verifyPassword: `${baseURL}/external-link-password`,
};

type AsperaConnection =
  | 'Connected'
  | 'NotConnected'
  | 'NoExtension'
  | 'NoApplication';

const ExternalUpload = () => {
  const { authState, oktaAuth } = useOktaAuth();

  const [inputFields, setInputFields] = useState<any>({
    artist: '',
    title: '',
    package_name: '',
    audio_type_array: [],
    upc: '',
    notes: '',
    isrc: '',
    version: '',
    engineer: '',
    studio_name: '',
    producer: '',
    register_asset_due_date: null,
    studio_info: '',
    watermark_wbs: '',
    watermark_approved_by: '',
    watermark_job_description: '',
    audio_edit_due_date: null,
    audio_edit_job_description: '',
    audio_edit_send_ref_email: '',
    audio_edit_wbs: '',
    mastering_send_ref_email: '',
    mastering_due_date: null,
    mastering_wbs: '',
    meta_tasks: '',
    check: false,
    selectedOption: [],
    selectedStudioOption: [],
    isFile: true,
  });

  const [sendLink, setSendLink] = useState(false);

  const {
    artist,
    title,
    package_name,
    // upc,
    // isrc,
    check,
    isFile,
    selectedStudioOption,
    notes,
    watermark_approved_by,
    audio_edit_send_ref_email,
    mastering_send_ref_email,
    audio_edit_wbs,
  } = inputFields;

  const { LabelUser, LibraryUser, LabelManager, StudioUser } = userRole;
  const { Register, Watermarking, Audio, Mastering, Other } = StudioOptions;

  const { required, error } = fieldValidation;

  const [inputValidate, setInputValidate] = useState<any>({
    watermark_approved_by: required,
    audio_edit_send_ref_email: required,
    mastering_send_ref_email: required,
    audio_edit_wbs: required,
  });
  const validateFields = (fieldName: string, newVal: any) => {
    switch (fieldName) {
      case 'audio_edit_send_ref_email':
      case 'mastering_send_ref_email': {
        const isValidMail = regexPattern.email.test(newVal);
        setInputValidate({
          ...inputValidate,
          [fieldName]: isValidMail ? required : error,
        });
        break;
      }
      case 'watermark_approved_by':
        const isValidMailWmg = regexPattern.wmgEmail.test(newVal);
        setInputValidate({
          ...inputValidate,
          [fieldName]: isValidMailWmg ? required : error,
        });
        break;

      default:
        break;
    }
  };

  const handleInputChange = (field: string, value: any) => {
    setInputFields({ ...inputFields, [field]: value });
    const fieldsWithValidation = [
      'watermark_approved_by',
      'audio_edit_send_ref_email',
      'mastering_send_ref_email',
      'audio_edit_wbs',
    ];
    if (fieldsWithValidation.includes(field)) {
      validateFields(field, value);
    }
  };

  const [uploadMore, setuploadMore] = useState(false);
  const [uploadPause, setuploadPause] = useState(false);

  const [isAsperaConnected, setIsAsperaConnected] =
    useState<AsperaConnection>('NotConnected');

  let connectClient = new Connect();

  const connectInstaller = new ConnectInstaller();
  const onStatusEvent = (eventType: any, data: any) => {
    // console.log(eventType, data);

    if (eventType === Connect.EVENT.TRANSFER) return;

    if (data === Connect.STATUS.FAILED) {
      connectInstaller.showDownload();
      setIsAsperaConnected('NoApplication');
    } else if (data === Connect.STATUS.EXTENSION_INSTALL) {
      connectInstaller.showExtensionInstall();
      setIsAsperaConnected('NoExtension');
    } else if (data === Connect.STATUS.RUNNING) {
      connectInstaller.connected();
      setIsAsperaConnected('Connected');
    }
  };

  // # Setup Connect status event listener
  // # See: https://ibm.github.io/aspera-connect-sdk-js/Connect.html#addEventListener
  connectClient.addEventListener(Connect.EVENT.STATUS, onStatusEvent);

  // # Initialize Connect
  connectClient.initSession();

  const [Id, setId] = useState(0);
  const [recipientEmail, setRecipientEmail] = useState('');
  const [Response, setResponse] = useState({
    owner_email: '',
    workspace_name: '',
    home_file_id: '',
    home_node_id: '',
    user_id: '',
    workspace_id: '',
    link_type: 1,
    owner_role: '',
    access_level: '',
  });
  const [logo, setLogo] = useState('');
  const [LoggedUser, setLoggedUser] = useState({ email: '', name: '' });
  const [NodeToken, setNodeToken] = useState('');
  const [AocToken, setAocToken] = useState('');
  const [ExternalToken, setExternalToken] = useState('');

  const [transferSpec, settransferSpec] = useState<any>({});
  const [Loading, setLoading] = useState(true);

  const [fileNames, setfileNames] = useState<any>([]);
  const [oldFileNames, setOldFileNames] = useState<any>([]);
  const [uploadReqId, setuploadReqId] = useState('');
  const [isFAQ, setisFAQ] = useState(false);
  const [successCount, setSuccessCount] = useState(1);

  const [uploadApiResponse, setuploadApiResponse] = useState(false);
  const [validLink, setValidLink] = useState('Invalid');
  const [uploadStatus, setuploadStatus] = useState(false);
  const [openAsperClient, setopenAsperClient] = useState(false);
  const [uploadComplete, setuploadComplete] = useState(false);
  const [reqLinkParams, setReqLinkParams] = useState<any>({});
  const [isLoginOpen, setisLoginOpen] = useState<any>(true);
  const [isInValid, setisInValid] = useState<any>({ status: false, attemptsLeft: 5 });
  const [isExpired, setisExpired] = useState(false);
  const [createPackage, setCreatePackage] = useState<any>(true);
  const [folderCid, setfolderCid] = useState<any>(undefined);

  if (!authState || !authState.isAuthenticated) {
    // When user isn't authenticated, forget any user info
  } else {
    const URL = window.location.href;
    if (
      URL.indexOf('/int/') !== -1 &&
      URL.indexOf('/ext/') === -1 &&
      LoggedUser.email === ''
    ) {
      oktaAuth.getUser().then((info: any) => {
        setLoggedUser({ email: info.preferred_username, name: info.name });
        // console.log(info.preferred_username);
      });
    }
  }

  const handleReqLink = () => {
    const config = {
      method: 'post',
      url: ApiURL.EmailService,
      data: reqLinkParams,
      headers: apiKey,
    };
    axios(config).then(({ data }): any => {
      setSendLink(true);
    });
  };

  const verifyPassword = (params: any) => {
    params = { ...params, id: Id }
    const config = {
      method: 'post',
      url: ApiURL.verifyPassword,
      data: params,
      headers: apiKey,
    };
    axios(config).then(({ data }): any => {
      if (data.msg === 'valid password') {
        setisLoginOpen(false);
        setisExpired(false);
        setisInValid({ status: false, attemptsLeft: 5 });
      }
      else if (data.msg === 'invalid password') {
        setisInValid({ status: true, attemptsLeft: data.attemptsLeft });
      }
      else if (data.msg === 'Link expired') {
        setisExpired(true);
        setisInValid({ status: false, attemptsLeft: 0 });
        setReqLinkParams({
          username: recipientEmail,
          recipient_email: [Response.owner_email],
          email_type: 'req_resend_link',
          workspace_name: Response.workspace_name,
          notes: '',
        });
        setResponse({
          owner_email: '',
          home_file_id: '',
          home_node_id: '',
          user_id: '',
          workspace_id: '',
          link_type: 1,
          owner_role: '',
          access_level: '',
          workspace_name: Response.workspace_name,
        });
        setLogo(logo);
        setId(0);
        setValidLink('Expired');
      }
    });
  };

  const downloadPermission = (
    nodeToken: string,
    token: string,
    params: any,
    user_name: any
  ) => {
    const {
      user_id,
      home_file_id,
      home_node_id,
      workspace_id,
      owner_email,
      workspace_name,
    } = params;

    const downloadParams = {
      token: `Bearer ${nodeToken}`,
      user_email: owner_email,
      user_id: user_id.toString(),
      user_name,
      workspace_id: workspace_id.toString(),
      workspace_name,
      home_file_id: home_file_id.toString(),
      home_node_id: home_node_id.toString(),
    };
    const config = {
      method: 'post',
      url: ApiURL.downloadPermissionLink,
      data: downloadParams,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };

    axios(config).then(({ data }): any => { });
    // .catch((error: any) => {
    //   // console.log('getContentInfo API Error....', error);
    // });
  };
  const getTransferSpec = (nodeToken: string, token: string, params: any) => {
    const { user_id, home_node_id, home_file_id, workspace_id } = params;
    const uploadConfig = {
      method: 'get',
      url: `${ApiURL.uploadTransferSpecLink}user_id=${user_id}&home_node_id=${home_node_id}&home_file_id=${home_file_id}&workspace_id=${workspace_id}`,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };

    axios(uploadConfig)
      .then(({ data }): any => {
        // dispatch(setUploadTransferSpec(data));
        // let transferSpec = data;
        data.token = `Bearer ${nodeToken}`;
        // console.log(data.token);
        downloadPermission(
          nodeToken,
          token,
          params,
          data.tags.aspera.files.user_name
        );
        settransferSpec(data);
        setLoading(false);
        // console.log(data);
      })
      .catch((error): any => {
        console.log(error);
      });
  };
  const getNodeToken = (token: string, params: any) => {
    const config = {
      method: 'get',
      url: `${ApiURL.nodeTokenLink}email=${params.owner_email}&home_node_id=${params.home_node_id}`,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    axios(config)
      .then(({ data }): any => {
        // console.log(data);
        setNodeToken(data.access_token);
        getTransferSpec(data.access_token, token, params);
        // dispatch(generateTransferSpec());
      })
      .catch((error): any => {
        console.log('Error generating Node Token:', error);
      });
  };
  const getWorkspaceLogo = (params: any) => {
    const { workspace_id, token, responseLogo } = params;
    const config = {
      method: 'get',
      url: ApiURL.AnRDetails,
      headers: { 'X-Amz-Security-Token': token, ...apiKey },
    };
    axios(config)
      .then(({ data }: any) => {
        if (data && !data.code && data.length) {
          const respData: any = data.filter(
            (option: any) => option.id === workspace_id.toString()
          );
          if (respData && respData.length) {
            const { image_url } = respData[0];
            setLogo(image_url);
          } else {
            setLogo(responseLogo);
          }
        } else {
          setLogo(responseLogo);
        }
      })
      .catch((err: any) => { });
  };
  const getAocToken = (params: any) => {
    const { owner_email, workspace_id, responseLogo } = params;
    const config = {
      method: 'get',
      url: ApiURL.AocTokenLink + owner_email,
      headers: apiKey,
    };

    axios(config)
      .then(({ data }): any => {
        setAocToken(data.access_token);
        getNodeToken(data.access_token, params);
        getWorkspaceLogo({
          workspace_id,
          token: data.access_token,
          responseLogo,
        });
      })
      .catch((error: any) => {
        console.log('access Token API Error => ', error);
      });
  };
  // const handleCancel = (e:any) => {
  //   // need to discus what should happen upon cancel and implemet handle cancel
  // }
  const postComments = (data: any) => {
    // const { currentUser, workspace } = getState().dashboard;
    const { notes, content_id, user_id, email, user_name } = data;
    const params = {
      user_id,
      content_id,
      comments: notes,
      email,
      user_name,
    };
    const config = {
      method: 'post',
      url: ApiURL.PostComments,
      data: params,
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => { })
      .catch((error: any) => {
        // console.log('handleRequestLink API Error.... ', error);
      });
  };
  const createEmailNotification = (notification: any) => {
    const config = {
      method: 'post',
      url: ApiURL.writeNotification,
      data: {
        email_ids: notification.email,
        user_image: '',
        user_name: notification.fullName,
        msg_body: notification.msg,
        fields_changed: notification.fieldsChange || '',
        content_id: notification.content_id,
        user_id: notification.user_id,
        action: notification.action,
      },
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => { })
      .catch((error: any) => {
        console.log('createNotification API Error....', error);
      });
  };
  const endTransfer = (params: any, insertParams: any) => {
    insertPackage(insertParams);
    const config = {
      method: 'post',
      url: ApiURL.endTransfer,
      data: { package_id: params },

      headers: { 'x-amz-security-token': AocToken, ...apiKey },
    };

    axios(config).then(({ data }): any => { });
    setuploadStatus(false);
    setopenAsperClient(false);
    setuploadMore(true);
  };

  const createNotification = (notification: any) => {
    const config = {
      method: 'post',
      url: ApiURL.writeNotification,
      data: {
        email_ids: [notification.email],
        user_image: '',
        user_name: notification.fullName,
        msg_body: notification.msg,
        fields_changed: notification.fieldsChange || '',
        content_id: notification.content_id,
        user_id: notification.user_id,
        action: notification.action,
      },
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => { })
      .catch((error: any) => {
        console.log('createNotification API Error....', error);
      });
  };
  const sendMail = (params: any) => {
    const config = {
      method: 'post',
      url: ApiURL.sendReqEmail,
      data: params,
      headers: apiKey,
    };

    axios(config)
      .then(({ data }): any => {
        if (!data.error) {
          //console.log(data);
        }
      })
      .catch((error: any) => {
        console.log('Who to notify mail API Error.... ', error);
      });
  };
  const insertPackage = (params: any) => {
    const data = {
      ...params,
      home_node_id: Response.home_node_id,
      token: `Bearer ${NodeToken}`,
    };

    const config = {
      method: 'post',
      url: ApiURL.insertPackage,
      data: data,
      headers: { 'x-amz-security-token': AocToken, ...apiKey },
    };

    axios(config)
      .then(({ data }): any => {
        if (!data.error) {
          //console.log(data);
        }
      })
      .catch((error: any) => {
        console.log('insertpack', error);
      });
  };

  const checkTransferStatus = (
    params: any,
    package_id: any,
    notification: any,
    insertParams: any
  ) => {
    const config = {
      method: 'post',
      url: ApiURL.checkTransferStatus,
      data: params,
      headers: { 'x-amz-security-token': AocToken, ...apiKey },
    };
    const {
      Msg,
      Action,
      content_id,
      email,
      fullName,
      user_id,
      sendMailParams,
      UploadEmailParams,
      UploadNotificationParams,
      UploadFolderNotificationParams,
      deleteContentID,
    } = notification;
    let noOfRetries = 0;
    let x = setInterval(() => {
      axios(config)
        .then(({ data }): any => {
          if (!data.error && data.status === 'completed') {
            clearInterval(x);
            endTransfer(package_id, insertParams);

            // const { breadcrumbList } = getState().dashboard;

            // const { cid, key } = breadcrumbList[breadcrumbList.length - 1];
            // dispatch(getDashboardRecords({ cid, name: '', navId: key })); // Reloading Table at current level
            // console.log(email.length, Msg.length, Msg.length && email.length);
            UploadFolderNotificationParams &&
              UploadFolderNotificationParams.email.length &&
              createNotification(UploadFolderNotificationParams);

            UploadNotificationParams &&
              UploadNotificationParams.email.length && setTimeout(() => {
                createEmailNotification(UploadNotificationParams);
              }, 1000);


            Msg.length &&
              email.length &&
              setTimeout(() => {
                createEmailNotification({
                  msg: Msg,
                  content_id,
                  action: Action,
                  email,
                  fullName,
                  user_id,
                });
              }, 2000);

            UploadEmailParams &&
              UploadEmailParams.recipient_email.length &&
              sendMail(UploadEmailParams);

            email.length &&
              sendMailParams.length &&
              sendMail(sendMailParams[0]);

            return data;
            // x = 0;
          } else if (
            !data.error &&
            (data.status.includes('waiting') || data.status.includes('running'))
            // &&
            // numOfRetries > 0
          ) {
          } else if (!data.error && data.status.includes('failed')) {
            clearInterval(x);
            if (content_id.join() === deleteContentID.join()) {
              DeleteOnFail({ content_id });
              setCreatePackage(true);
              setuploadApiResponse(false);
            }
            else {
              DeleteOnFail({ content_id: deleteContentID });
            }

            setuploadStatus(false);
            setopenAsperClient(false);
            setuploadMore(true);
            let newArr = fileNames;
            newArr = newArr.map((item: any) => ({
              ...item,
              uploadSuccess: false,
            }));
            setfileNames([...newArr]);
            const fileLength = newArr.filter(
              (item: any) => item.uploadSuccess === true
            );
            const oldFileLength = oldFileNames.filter(
              (item: any) => item.uploadSuccess === true
            );
            setSuccessCount(fileLength.length + oldFileLength.length)
            return data;
          } else {
            clearInterval(x);
            if (content_id.join() === deleteContentID.join()) {
              DeleteOnFail({ content_id });
              setCreatePackage(true);
              setuploadApiResponse(false);
            }
            else {
              DeleteOnFail({ content_id: deleteContentID });
            }

            setuploadStatus(false);
            setopenAsperClient(false);
            setuploadMore(true);
            let newArr = fileNames;
            newArr = newArr.map((item: any) => ({
              ...item,
              uploadSuccess: false,
            }));
            setfileNames([...newArr]);
            const fileLength = newArr.filter(
              (item: any) => item.uploadSuccess === true
            );
            const oldFileLength = oldFileNames.filter(
              (item: any) => item.uploadSuccess === true
            );
            setSuccessCount(fileLength.length + oldFileLength.length)
            return data;
          }
        })
        .catch((error: any) => {
          noOfRetries++;
          if (noOfRetries === 5) {
            clearInterval(x);
            if (content_id.join() === deleteContentID.join()) {
              DeleteOnFail({ content_id });
              setCreatePackage(true);
              setuploadApiResponse(false);
            }
            else {
              DeleteOnFail({ content_id: deleteContentID });
            }
            setuploadStatus(false);
            setopenAsperClient(false);
            setuploadMore(true);
            let newArr = fileNames;
            newArr = newArr.map((item: any) => ({
              ...item,
              uploadSuccess: false,
            }));
            setfileNames([...newArr]);
            const fileLength = newArr.filter(
              (item: any) => item.uploadSuccess === true
            );
            const oldFileLength = oldFileNames.filter(
              (item: any) => item.uploadSuccess === true
            );
            setSuccessCount(fileLength.length + oldFileLength.length)
          }
          console.log('Check transfer status API Error.... ', error);
        });
    }, 5000);
  };

  const shareAfterUpload = (data: any) => {
    const { user_id, home_node_id, workspace_id } = Response;
    const {
      content_id,
      home_file_id,
      files_name,
      share_to,
      recipients,
      email,
      ShareStatus,
      fullName,
      action,
      additional_metadata,
      UploadEmailParams,
      UploadNotificationParams,
      UploadFolderNotificationParams,
      deleteContentID,
    } = data;
    const ContentId = content_id.map((item: any) => item.toString());
    const params = {
      user_id,
      home_node_id,
      workspace_id,
      home_file_id,
      share_to,
      content_id: ContentId,
      node_token: NodeToken,
      upload_notification_recipients: email,
      download_notification_recipients: email,
      name: files_name[0],
      note: '',
      file_names: files_name,
      metadata: [],
      recipients,
      bcc_recipients: [],
      additional_metadata,
    };
    const config = {
      method: 'post',
      url: ApiURL.shareAssets,
      data: params,
      headers: { 'x-amz-security-token': AocToken, ...apiKey },
    };
    const noticeMsg =
      (ShareStatus === 'In Library' &&
        ((files_name.length > 1 &&
          `Shared ${files_name.length} assets with Library`) ||
          `Shared ${files_name} with Library`)) ||
      (ShareStatus === 'In Studio' &&
        ((files_name.length > 1 &&
          `Shared ${files_name.length} assets with Studio`) ||
          `Shared ${files_name} with Studio`)) ||
      '';
    const contentIdType = content_id.map((item: any) => ({
      id: item,
      type: (isFile && 'file') || 'folder',
    }));

    axios(config)
      .then(({ data }): any => {
        // console.log(data);
        if (!data.error) {
          // Success
          const insertParams = {
            package_id: data.package_id,
            package_file_id: data.contents_file_id,
            package_name: data.package_name,
            dropbox_id: data.dropbox_id,
            dropbox_name: data.dropbox_name,
            file_parent_id: '0',
            content_id: contentIdType,
          };
          // insertPackage(insertParams);

          const paramsCheckTransferStatus = {
            transfer_id: data.transfer_id,
            home_node_id: home_node_id,
            node_token: NodeToken,
          };

          const notification = {
            okMsg: '',
            errMsg: '',
            Msg: noticeMsg,
            Status: ShareStatus,
            content_id,
            email,
            user_id,
            fullName,
            Action: action,
            sendMailParams: [
              {
                username: fullName,
                recipient_email: email,
                email_type: 'notify',
                asset_name: files_name,
                share_to,
                notes: '',
              },
            ],
            UploadEmailParams,
            UploadNotificationParams,
            UploadFolderNotificationParams,
            deleteContentID,
          };

          checkTransferStatus(
            paramsCheckTransferStatus,
            data.package_id,
            notification,
            insertParams
          );
        } else {
          if (content_id.join() === deleteContentID.join()) {
            DeleteOnFail({ content_id });
            setCreatePackage(true);
            setuploadApiResponse(false);
          }
          else {
            DeleteOnFail({ content_id: deleteContentID });
          }
          setuploadStatus(false);
          setopenAsperClient(false);
          setuploadMore(true);
          let newArr = fileNames;
          newArr = newArr.map((item: any) => ({
            ...item,
            uploadSuccess: false,
          }));
          setfileNames([...newArr]);
          const fileLength = newArr.filter(
            (item: any) => item.uploadSuccess === true
          );
          const oldFileLength = oldFileNames.filter(
            (item: any) => item.uploadSuccess === true
          );
          setSuccessCount(fileLength.length + oldFileLength.length)
        }
      })
      .catch((err) => {
        if (content_id.join() === deleteContentID.join()) {
          DeleteOnFail({ content_id });
          setCreatePackage(true);
          setuploadApiResponse(false);
        }
        else {
          DeleteOnFail({ content_id: deleteContentID });
        }
        setuploadStatus(false);
        setopenAsperClient(false);
        setuploadMore(true);
        let newArr = fileNames;
        newArr = newArr.map((item: any) => ({
          ...item,
          uploadSuccess: false,
        }));
        setfileNames([...newArr]);
        const fileLength = newArr.filter(
          (item: any) => item.uploadSuccess === true
        );
        const oldFileLength = oldFileNames.filter(
          (item: any) => item.uploadSuccess === true
        );
        setSuccessCount(fileLength.length + oldFileLength.length)
        console.log('Api error in asset operations...', err);
      });
  };

  const handleDBUpload = (transfer: any) => {
    // console.log(transfer);

    const files_name: any = transfer.paths.map((file: any) =>
      file.source.split(/[\\/]/).slice(-1).pop()
    );

    const additional_metadata = { ...inputFields };

    additional_metadata.audio_type_array = inputFields.selectedOption.map(
      (item: any) => item.label
    );
    const {
      register_asset_due_date,
      mastering_due_date,
      audio_edit_due_date,
      selectedStudioOption,
      upc,
    } = additional_metadata;
    if (register_asset_due_date) {
      additional_metadata.register_asset_due_date =
        register_asset_due_date.toISOString();
    }
    if (mastering_due_date) {
      additional_metadata.mastering_due_date = mastering_due_date.toISOString();
    }
    if (audio_edit_due_date) {
      additional_metadata.audio_edit_due_date =
        audio_edit_due_date.toISOString();
    }
    if (selectedStudioOption && selectedStudioOption.length) {
      additional_metadata.meta_tasks = selectedStudioOption
        .map((option: any) => option.label)
        .join(',');
    }
    if (!upc.length) {
      additional_metadata.upc = 'null';
    }
    // console.log(additional_metadata);

    const user_email =
      (Response.owner_email) || LoggedUser.email;
    const recipient_user_name = recipientEmail.split('@')[0].replace('.', ' ')
      .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    const allow_access_to_workspace_users =
      (!!Response.access_level && 'Y') || 'N';
    const Insertparams = {
      token: `Bearer ${NodeToken}`,
      remote_host: transfer.remote_host,
      access_key: transferSpec.tags.aspera.node.access_key,
      user_email,
      user_id: transfer.tags.aspera.files.access_id,
      user_name:
        transfer.tags.aspera.files.user_name ||
        user_email.split('@')[0].replace('.', ' '),
      workspace_id: transfer.tags.aspera.files.workspace_id,
      home_file_id: folderCid.toString(),
      is_root_directory: false,
      allow_access_to_workspace_users,
      files_name,
      owner_role: Response.owner_role,
      external_token: ExternalToken,
      additional_metadata,
    };
    const Insertconfig = {
      method: 'post',
      url: ApiURL.InsertUpload,
      data: Insertparams,
      headers: apiKey,
    };
    axios(Insertconfig)
      .then(({ data }): any => {
        const comments = {
          notes,
          content_id: [...data.data.content_ids, folderCid],
          user_id: Response.user_id,
          email: user_email,
          user_name:
            recipient_user_name ||
            LoggedUser.name || (user_email.split('@')[0]
            ).replace('.', ' '),
        };
        notes.length &&
          data.data.content_ids &&
          data.data.content_ids.length &&
          postComments(comments);

        const UploadNotificationParams = {
          email: [user_email],
          fullName:
            recipient_user_name ||
            LoggedUser.name,
          msg: `Uploaded ${files_name}`,
          content_id: data.data.content_ids,
          user_id: Response.user_id,
          action: 'upload',
        };
        const UploadFolderNotificationParams = {
          msg: `Uploaded ${package_name}`,
          content_id: [folderCid],
          action: 'upload',
          email: [user_email],
          fullName:
            recipient_user_name ||
            LoggedUser.name,
          user_id: Response.user_id,
        };
        const UploadEmailParams = {
          username:
            recipient_user_name ||
            LoggedUser.name,
          recipient_email: [Response.owner_email],
          email_type: 'upload',
          workspace_name: Response.workspace_name,
        };

        const deleteContentID = ((oldFileNames.filter(
          (item: any) => item.uploadSuccess === true
        ).length > 0) && data.data.content_ids) || [folderCid];

        if (
          allow_access_to_workspace_users === 'Y' &&
          (Response.owner_role === userRole.LabelManager ||
            Response.owner_role === userRole.LabelUser) &&
          data.data.content_ids &&
          data.data.content_ids.length
        ) {
          shareAfterUpload({
            content_id: [folderCid],
            home_file_id: Response.home_file_id,
            files_name: [package_name],
            share_to: 'A_&_R',
            recipients: [],
            email: [Response.owner_email],
            ShareStatus: '',
            fullName:
              recipient_user_name ||
              LoggedUser.name,
            UploadEmailParams,
            UploadNotificationParams,
            UploadFolderNotificationParams,
            deleteContentID,
          });
        } else if (
          Response.owner_role === userRole.LibraryUser &&
          data.data.content_ids &&
          data.data.content_ids.length
        ) {
          shareAfterUpload({
            content_id: [folderCid],
            home_file_id: Response.home_file_id,
            files_name: [package_name],
            share_to: 'library',
            recipients: [{ id: '499404', type: 'user' }],
            email: [Response.owner_email],
            ShareStatus: 'In Library',
            action: 'sharetolibrary',
            fullName:
              recipient_user_name ||
              LoggedUser.name,
            UploadEmailParams,
            UploadNotificationParams,
            UploadFolderNotificationParams,
            deleteContentID,
          });
        } else if (
          Response.owner_role === userRole.StudioUser &&
          data.data.content_ids &&
          data.data.content_ids.length
        ) {
          shareAfterUpload({
            content_id: [folderCid],
            home_file_id: Response.home_file_id,
            files_name: [package_name],
            share_to: 'studio',
            ShareStatus: 'In Studio',
            recipients: [{ id: '499404', type: 'user' }],
            email: [Response.owner_email],
            action: 'sharetostudio',
            fullName:
              recipient_user_name ||
              LoggedUser.name,
            additional_metadata,
            UploadEmailParams,
            UploadNotificationParams,
            UploadFolderNotificationParams,
            deleteContentID,
          });
        } else {
          data.data.content_ids &&
            data.data.content_ids.length &&
            createNotification(UploadFolderNotificationParams);

          data.data.content_ids &&
            data.data.content_ids.length && setTimeout(() => {
              createNotification(UploadNotificationParams);
            }, 1000);

          data.data.content_ids &&
            data.data.content_ids.length &&
            sendMail(UploadEmailParams);

          setuploadStatus(false);
          setopenAsperClient(false);
          setuploadMore(true);
        }
        // console.log(response);
        // dispatch(getDashboardRecords({ cid, name: '', navId: key })); // Reloading Table
      })
      .catch((err) => {
        let newArr = fileNames;
        newArr = newArr.map((item: any) => ({
          ...item,
          uploadSuccess: false,
        }));
        setfileNames([...newArr]);
        const fileLength = newArr.filter(
          (item: any) => item.uploadSuccess === true
        );
        const oldFileLength = oldFileNames.filter(
          (item: any) => item.uploadSuccess === true
        );
        setSuccessCount(fileLength.length + oldFileLength.length)
        if (!oldFileLength.length) {
          DeleteOnFail({ content_id: [folderCid] });
          setCreatePackage(true);
          setuploadApiResponse(false);
        }
        setuploadMore(true);
        setuploadStatus(false);
        setopenAsperClient(false);
      });
  };

  const DeleteOnFail = (params: any) => {
    // const CID = params.content_id.map((item: any) => item.toString());
    // const Params = { content_id: CID };

    const config = {
      method: 'post',
      url: ApiURL.deleteCid,
      data: params,
      headers: apiKey,
    };

    axios(config)
      .then(({ data }): any => {
        setuploadMore(true);
        setuploadStatus(false);
        setopenAsperClient(false);
      })
      .catch((error: any) => {
        setuploadMore(true);
        setuploadStatus(false);
        setopenAsperClient(false);
      });
  };

  const getAllTransfersCallback = (transfersInfo: any) => {
    // const { iteration_token, result_count, transfers } = transfersInfo;
    const { transfers } = transfersInfo;
    for (let i = transfers.length - 1; i >= 0; i--) {
      if (transfers[i].uuid === uploadReqId) {
        // console.log(transfers[i].status, transfers[i].percentage);
        if (transfers[i].status === 'completed') {
          let removeEventListener = connectClient.removeEventListener();
          if (removeEventListener) {
            checkUploadSuccess();
            handleDBUpload(transfers[i].transfer_spec);
            setuploadApiResponse(true);
          }
        } else if (transfers[i].status === 'failed') {
          let removeEventListener = connectClient.removeEventListener();
          if (removeEventListener) {
            let newArr = fileNames;
            newArr = newArr.map((item: any) => ({
              ...item,
              uploadSuccess: false,
            }));
            setfileNames([...newArr]);
            const fileLength = newArr.filter(
              (item: any) => item.uploadSuccess === true
            );
            const oldFileLength = oldFileNames.filter(
              (item: any) => item.uploadSuccess === true
            );
            setSuccessCount(fileLength.length + oldFileLength.length)
            setuploadStatus(false);
            setopenAsperClient(false);
            if (!(fileLength.length + oldFileLength.length))
              setuploadApiResponse(false);
          }
        }
      }
      // console.log(transfers[i].status);
    }
    // console.log(transfersInfo);
  };

  const getAllTransfers = () => {
    const iterationToken = 0;
    /**
     * Get information about all of the transfers in Connect's activity window.
     */
    connectClient.getAllTransfers(
      {
        success: getAllTransfersCallback,
      },
      iterationToken
    );
  };

  const submitUpload = () => {
    // need to replace with api and aspera upload
    // set success only after success api
    // setuploadApiResponse(true);
    let newtransferSpec = { ...transferSpec };
    newtransferSpec.paths = fileNames;
    // console.log(fileNames);
    // transferSpec.tags = { ...upload_transfer_spec.tags };
    // transferSpec.tags.aspera = { ...upload_transfer_spec.tags.aspera };
    // transferSpec.tags.aspera.aspera_ui = {
    //   ...upload_transfer_spec.tags.aspera.aspera_ui,
    // };
    newtransferSpec.tags.aspera.aspera_ui.transfer_name = `Uploading ${fileNames[0]}`;
    setuploadStatus(true);
    if (createPackage) {
      createFolder(newtransferSpec, { package_name, artist, title, isUnique: true })
    } else {
      // console.log(newtransferSpec);
      const asperaConnectSettings = { allow_dialogs: false };
      asperaClientUpload(newtransferSpec, asperaConnectSettings)
    }
    setopenAsperClient(false);
  };

  const asperaClientUpload = (transferSpec: any, asperaConnectSettings: any) => {
    connectClient
      .startTransferPromise(transferSpec, asperaConnectSettings)
      .then((response: any) => {
        setuploadReqId(response.transfer_specs[0].uuid);
        setopenAsperClient(true);
        // setemail('');
        // setfileNames([]);
      })
      .catch((err: any) => {
        // submitUpload('failure');
        // setfileNames([]);
        // setemail('');
        // handleClose();
        setfileNames([
          { source: 'Unable to connect with Aspera, try again later' },
        ]);
        setuploadStatus(false);
        console.log('Unable to connect with Aspera, try again later', err);

      });
  }
  const extractFiles = (data: any) => {
    const { files } = data.dataTransfer;
    // const files = data.target.files;
    let newFileList: any = [];
    if (files.length > 0) {
      setOldFileNames([...oldFileNames, ...fileNames]);
      // getAllTransfers(0);
      files.map((file: any) => {
        const extensions = isFile
          ? file.name.split(/[\\/]/).slice(-1).pop().split('.').slice(-1).pop()
          : '';
        if (extensions !== 'exe' && extensions !== 'zip') {
          newFileList = [
            ...newFileList,
            { source: file.name, uploadSuccess: true },
          ];
        }
        return file;
      });
      //     for (let i = 0; i < files.length; i++) {
      //       newFileList.push({ source: files[i].name });
      //     }
      //  setPackage(files[0].name.split(/[\\/]/).slice(-2, -1)[0]);
      if (package_name === '') {
        let value = files[0].name.split(/[\\/]/).slice(-1)[0];
        if (isFile) {
          const lastIndexDot = value.lastIndexOf('.');
          value = value.slice(0, lastIndexDot);
        }
        handleInputChange(
          'package_name',
          value
        );
      }
      if (newFileList.length > 0) {
        setuploadMore(false);
      }
      // console.log(newFileList);
      setfileNames(newFileList);
      const fileLength = newFileList.filter(
        (item: any) => item.uploadSuccess === true
      );
      const oldFileLength = oldFileNames.filter(
        (item: any) => item.uploadSuccess === true
      );
      setSuccessCount(fileLength.length + oldFileLength.length);
    }
  };

  const upload = async () => {
    const options = {
      allowMultipleSelection: true,
      allowedFileTypes: [
        {
          filter_name: 'All files',
          extensions: ['*'],
        },
      ],
    };
    connectClient
      .showSelectFileDialogPromise(options)
      .then(extractFiles)
      .catch((error: any) => {
        console.error('Unable to select files', error);
        setfileNames([
          { source: 'Unable to connect with Aspera, try again later' },
        ]);
      });
  };
  const uploadFolder = () => {
    const options = {
      allowMultipleSelection: true,
      allowedFileTypes: [
        {
          filter_name: 'All files',
          extensions: ['*'],
        },
      ],
    };
    connectClient
      .showSelectFolderDialogPromise(options)
      .then(extractFiles)
      .catch((error: any) => {
        console.error('Unable to select folders', error);
        setfileNames([
          { source: 'Unable to connect with Aspera, try again later' },
        ]);
      });
  };

  useEffect(() => {
    const URL = window.location.href;
    if (URL.indexOf('?token=') !== -1) {
      const Token = URL.substring(URL.indexOf('?token=') + 7);

      setExternalToken(Token);

      const config = {
        url: ApiURL.ReqLink + '?token=' + Token,
        method: 'get',
        headers: apiKey,
      };
      axios(config)
        .then(({ data }) => {
          const {
            owner_email,
            workspace_logo,
            workspace_name,
            home_file_id,
            home_node_id,
            user_id,
            workspace_id,
            link_type,
            owner_role,
            access_level,
          } = data.data;
          setisLoginOpen(link_type === 1 && true)
          setValidLink('Valid');
          setId(data.data.id);
          setRecipientEmail(data.data.recipient_emails)
          setResponse({
            owner_email,
            workspace_name,
            home_file_id,
            home_node_id,
            user_id,
            workspace_id,
            link_type,
            owner_role,
            access_level,
          });
          getAocToken({
            owner_email,
            workspace_name,
            home_file_id,
            home_node_id,
            user_id,
            workspace_id,
            responseLogo: workspace_logo,
          });
        })
        .catch((err) => {
          const { msg, data } = err.response.data;
          if (msg === 'Link is expired') {
            setReqLinkParams({
              username: data.recipient_emails,
              recipient_email: [data.owner_email],
              email_type: 'req_resend_link',
              workspace_name: data.workspace_name,
              notes: '',
            });
            setResponse({
              owner_email: '',
              home_file_id: '',
              home_node_id: '',
              user_id: '',
              workspace_id: '',
              link_type: 1,
              owner_role: '',
              access_level: '',
              workspace_name: data.workspace_name,
            });
            setLogo(data.workspace_logo);
            setValidLink('Expired');
          } else if (msg === 'Link is removed') {
            setValidLink('Invalid');
          } else {
            setValidLink('Invalid');
          }

          setLoading(false);
        });
    } else {
      setLoading(false);
      setValidLink('Invalid');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (openAsperClient)
      connectClient.addEventListener('transfer', () => {
        getAllTransfers();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAsperClient]);

  const OpenUpload = () => {
    switch (isAsperaConnected) {
      case 'Connected':
        if (isFile) {
          upload();
        } else {
          uploadFolder();
        }
        break;
      case 'NoApplication':
        connectInstaller.showDownload();
        break;
      case 'NoExtension':
        connectInstaller.showExtensionInstall();
        break;
      default:
        connectInstaller.showDownload();
        break;
    }
  };

  const handleDone = () => {
    setfileNames([]);
    setOldFileNames([]);

    setInputFields({
      artist: '',
      title: '',
      package_name: '',
      audio_type_array: [],
      upc: '',
      notes: '',
      isrc: '',
      version: '',
      engineer: '',
      studio_name: '',
      producer: '',
      register_asset_due_date: null,
      studio_info: '',
      watermark_wbs: '',
      watermark_approved_by: '',
      watermark_job_description: '',
      audio_edit_due_date: null,
      audio_edit_job_description: '',
      audio_edit_send_ref_email: '',
      audio_edit_wbs: '',
      mastering_send_ref_email: '',
      mastering_due_date: null,
      mastering_wbs: '',
      check: false,
      selectedOption: [],
      isFile: true,
    });
    setuploadComplete(true);
    setuploadMore(false);
    setuploadPause(false);
  };

  const removeFiles = (file: any) => {
    let newArr = fileNames;
    newArr = newArr.filter((item: any) => item.source !== file);
    const fileLength = newArr.filter(
      (item: any) => item.uploadSuccess === true
    );
    const oldFileLength = oldFileNames.filter(
      (item: any) => item.uploadSuccess === true
    );
    setSuccessCount(fileLength.length + oldFileLength.length);
    setfileNames([...newArr]);
    if (newArr.length === 0)
      setuploadMore(true)
  };
  const removeOldFiles = (file: any) => {
    let newArr = oldFileNames;
    newArr = newArr.filter((item: any) => item.source !== file);
    const fileLength = fileNames.filter(
      (item: any) => item.uploadSuccess === true
    );
    const oldFileLength = newArr.filter(
      (item: any) => item.uploadSuccess === true
    );
    setSuccessCount(fileLength.length + oldFileLength.length);
    setOldFileNames([...newArr]);
  };

  const handleClearList = () => {
    setfileNames([]);
    setOldFileNames([]);
  };

  const handlePauseResume = () => {
    if (!uploadPause) {
      connectClient.stopTransfer(uploadReqId);
      setuploadPause(true);
    } else {
      connectClient.resumeTransfer(uploadReqId, {});
      setuploadPause(false);
    }
  };
  const checkUploadSuccess = () => {
    const fileLength = fileNames.filter(
      (item: any) => item.uploadSuccess === true
    );
    const oldFileLength = oldFileNames.filter(
      (item: any) => item.uploadSuccess === true
    );
    setSuccessCount(fileLength.length + oldFileLength.length);
  };

  const validateStudioUser = () => {
    const y: any = [];
    if (selectedStudioOption && selectedStudioOption.length) {
      selectedStudioOption.map((option: any) => {
        switch (option.label) {
          case Register:
            y.push(true);
            break;
          case Watermarking:
            y.push(regexPattern.email.test(watermark_approved_by));

            break;
          case Audio:
            y.push(
              regexPattern.email.test(audio_edit_send_ref_email) &&
              !!audio_edit_wbs.length
            );

            break;
          case Mastering:
            y.push(regexPattern.email.test(mastering_send_ref_email));

            break;
          case Other:
            y.push(!!notes.length);

            break;
          default:
            break;
        }
        return 0;
      });
    }
    const studioValidate = y.reduce((a: any, b: any) => a && b, true);

    return !(
      artist.length &&
      title.length &&
      package_name.length &&
      selectedStudioOption.length &&
      check &&
      fileNames.length &&
      studioValidate
    );
  };

  const createFolder =
    (upload_transfer_spec: any, details: any) => {

      const { package_name, artist, title, isUnique } = details;
      let name = package_name;
      if (!isUnique) {
        const options = { hour12: false };
        const today = (new Date()).toLocaleTimeString('es-ES', options);
        name = name + '-' + today;
      }
      const params = {
        token: `Bearer ${NodeToken}`, // Do not remove 'Bearer '
        remote_host: upload_transfer_spec.remote_host,
        access_key: upload_transfer_spec.tags.aspera.node.access_key,
        home_file_id: Response.home_file_id,
        folder_name: name,
        user_email: Response.owner_email,
        user_id: Response.user_id,
        user_name: Response.owner_email.split('@')[0].replace('.', ' '),
        workspace_id: Response.workspace_id,
        creating_at_root: true,
        owner_role: Response.owner_role,
        allow_access_to_workspace_users: (!!Response.access_level && 'Y') || 'N',
        additional_metadata: {
          artist,
          title,
          package_name: name,
        },
        external_token: ExternalToken,
      };

      const config = {
        method: 'post',
        url: ApiURL.createFolder,
        data: params,
        headers: apiKey,
      };

      axios(config)
        .then(({ data }): any => {
          if (!data.error && !data.hasOwnProperty('error')) {
            // console.log(newtransferSpec);
            setfolderCid(data.data.content_id)
            upload_transfer_spec.tags.aspera.node.file_id = data.data.content_id.toString();
            setCreatePackage(false);
            const asperaConnectSettings = { allow_dialogs: false };
            asperaClientUpload(upload_transfer_spec, asperaConnectSettings)
          }
          else {
            createFolder(upload_transfer_spec, { ...details, isUnique: false })
          }
        })
        .catch((error: any) => {
          console.log('createFolder API Error.... ', error);
          setCreatePackage(true);
          let newArr = fileNames;
          newArr = newArr.map((item: any) => ({
            ...item,
            uploadSuccess: false,
          }));
          setfileNames([...newArr]);
          const fileLength = newArr.filter(
            (item: any) => item.uploadSuccess === true
          );
          const oldFileLength = oldFileNames.filter(
            (item: any) => item.uploadSuccess === true
          );
          setSuccessCount(fileLength.length + oldFileLength.length)
          if (folderCid.length)
            DeleteOnFail({ content_id: [folderCid] });
          setuploadStatus(false);
          setopenAsperClient(false);
          setuploadApiResponse(false);
        });
    };

  const submitBttnDisabled =
    Response.owner_role === userRole.LabelManager ||
      Response.owner_role === userRole.LabelUser
      ? !(
        artist.length &&
        title.length &&
        package_name.length &&
        check &&
        fileNames.length
      )
      : Response.owner_role === userRole.LibraryUser
        ? !(
          artist.length &&
          title.length &&
          // upc.length &&
          // isrc.length &&
          check &&
          fileNames.length
        )
        : validateStudioUser();

  return (
    <>
      {uploadStatus && <ExternalModalPopup isOpen={uploadStatus} />}
      {!Loading && (
        <>
          <div className="extbackgroundGradient" />
          <div className="container extMainContent">
            <div className="row extHeadContainer">
              <div className="col-9 extHeaderLogo">
                {logo && validLink !== 'Invalid' && (
                  <Logo
                    logo={logo}
                    appName="Secure Upload Network"
                    appContext={Response.workspace_name}
                  />
                )}
                {validLink === 'Invalid' && (
                  <Logo logo={wmgLogo} appName="Secure Upload Network" />
                )}
              </div>
              <div className="col">
                <a
                  style={{ textDecoration: 'none' }}
                  className="extHeader"
                  href="mailto:WMGSUN.SUPPORT@wmg.com"
                >
                  Contact Us
                </a>
              </div>
              <div className="col" onClick={() => setisFAQ(true)}>
                <p className="extHeader extAddChev">FAQ</p>
              </div>
            </div>

            {validLink === 'Expired' && !isFAQ && (
              <ExpiredLink handleReqLink={handleReqLink} sendLink={sendLink} />
            )}
            {validLink === 'Invalid' && !isFAQ && <InvalidLink />}
            {isFAQ && <FAQ backButton={() => setisFAQ(false)} />}
            {validLink === 'Valid' && uploadComplete && !isFAQ && (
              <>
                {uploadApiResponse && (
                  <ExternalUploadSuccess Email={Response.owner_email} />
                )}
                {!uploadApiResponse && <ExternalUploadFailure />}
              </>
            )}
            {validLink === 'Valid' && !uploadComplete && !isFAQ && isLoginOpen && (
              <div className="row container">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col-6">
                    <ExternalLogin
                      verifyPassword={verifyPassword}
                      isInValid={isInValid}
                      isExpired={isExpired}
                    />
                  </div>
                </div>
              </div>
            )}
            {validLink === 'Valid' && !uploadComplete && !isFAQ && !isLoginOpen && (
              <div className="row container">
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-8">
                    {(Response.owner_role === LabelUser ||
                      Response.owner_role === LabelManager) && (
                        <LabelExternal
                          email={Response.owner_email}
                          uploadStatus={uploadStatus}
                          handleInputChange={handleInputChange}
                          inputFields={inputFields}
                        />
                      )}
                    {Response.owner_role === LibraryUser && (
                      <LibraryExternal
                        email={Response.owner_email}
                        uploadStatus={uploadStatus}
                        handleInputChange={handleInputChange}
                        inputFields={inputFields}
                      />
                    )}
                    {Response.owner_role === StudioUser && (
                      <StudioExternal
                        email={Response.owner_email}
                        uploadStatus={uploadStatus}
                        handleInputChange={handleInputChange}
                        inputFields={inputFields}
                        inputValidate={inputValidate}
                        setInputValidate={setInputValidate}
                        setInputFields={setInputFields}
                      />
                    )}
                    {(<div className="uploadfileLabels">
                      <InputGroup label="Select what you want to upload?">
                        <br />
                        <Radio
                          className="pr17"
                          checked={isFile}
                          label="Files"
                          name="filesAccessRadio"
                          onChange={() => {
                            handleInputChange('isFile', true);
                          }}
                          disabled={fileNames.length > 0}
                        />
                        <Radio
                          label="Folders"
                          checked={!isFile}
                          onChange={() => {
                            handleInputChange('isFile', false);
                          }}
                          name="filesAccessRadio"
                          disabled={fileNames.length > 0}
                        />
                      </InputGroup>
                    </div>)}
                    {!fileNames.length && !oldFileNames.length && (
                      <>
                        <div className="UploaderButton">
                          <Button
                            containerStyle={'outline'}
                            colorType={'info'}
                            onClick={OpenUpload}
                          >
                            Browse Files
                          </Button>
                        </div>
                        <div className="uploadInfo">
                          <Icon name={'circle-info'} prefix="fas" />
                          <p className="uploadInfoText">
                            Due to security reasons, no exe or zip files
                            allowed.
                          </p>
                        </div>
                      </>
                    )}
                    {(!!fileNames.length || !!oldFileNames.length) && (
                      <div className="uploadingFiles mb20">
                        {!uploadMore && !!successCount && (
                          <div>
                            <p className="col uploadInfoText">{`Uploading ${fileNames.length
                              } ${isFile ? 'file' : 'folder'}${fileNames.length > 1 ? 's' : ''
                              }.`}</p>
                          </div>
                        )}
                        {!uploadMore && !successCount && (
                          <div className="row">
                            <Icon
                              prefix="fas"
                              name={'triangle-exclamation'}
                              className=" col-1 redIcon"
                            />
                            <p className="col greenText uploadInfoText">{`${fileNames.length + oldFileNames.length
                              } ${isFile ? 'file' : 'folder'}${fileNames.length + oldFileNames.length > 1
                                ? 's'
                                : ''
                              } failed.`}</p>
                          </div>
                        )}
                        {uploadMore && (
                          <div className="container">
                            {!!successCount && (
                              <div className="row">
                                <Icon
                                  name={'circle-check'}
                                  className=" col-1 greenIcon"
                                />
                                <p className="col greenText uploadInfoText">{`${successCount} ${isFile ? 'file' : 'folder'
                                  }${successCount > 1 ? 's' : ''} uploaded.`}</p>
                              </div>
                            )}
                            {!successCount && (
                              <div className="row">
                                <Icon
                                  prefix="fas"
                                  name={'triangle-exclamation'}
                                  className=" col-1 redIcon"
                                />
                                <p className="col greenText uploadInfoText">{`${fileNames.length + oldFileNames.length
                                  } ${isFile ? 'file' : 'folder'}${fileNames.length + oldFileNames.length > 1
                                    ? 's'
                                    : ''
                                  } failed.`}</p>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="container">
                          {fileNames &&
                            fileNames.map((filename: any, index: number) => (
                              <div className="row" key={index}>
                                <p className="uploadFileNames col-10">
                                  {filename.source
                                    .split(/[\\/]/)
                                    .slice(-1)
                                    .pop()}
                                </p>
                                {!filename.uploadSuccess && (
                                  <div className="col addPad">
                                    <Icon
                                      prefix="fas"
                                      name={'triangle-exclamation'}
                                      className="redIcon mr10"
                                    />
                                    <Button
                                      containerStyle={'link'}
                                      className="col xmark redCancel"
                                      icon="xmark"
                                      disabled={uploadStatus}
                                      colorType={'secondary-black'}
                                      onClick={() =>
                                        removeFiles(filename.source)
                                      }
                                    ></Button>
                                  </div>
                                )}
                                {filename.uploadSuccess && (
                                  <>
                                    <div className="col" />
                                    <Button
                                      containerStyle={'link'}
                                      className="col xmark"
                                      icon="xmark"
                                      disabled={uploadStatus}
                                      colorType={'secondary-black'}
                                      onClick={() =>
                                        removeFiles(filename.source)
                                      }
                                    ></Button>
                                  </>
                                )}
                              </div>
                            ))}
                          {oldFileNames &&
                            oldFileNames.map((filename: any, index: number) => (
                              <div className="row" key={index}>
                                <p className="uploadFileNames col-10">
                                  {filename.source
                                    .split(/[\\/]/)
                                    .slice(-1)
                                    .pop()}
                                </p>
                                {!filename.uploadSuccess && (
                                  <div className="col addPad">
                                    <Icon
                                      prefix="fas"
                                      name={'triangle-exclamation'}
                                      className="redIcon mr10"
                                    />
                                    <Button
                                      containerStyle={'link'}
                                      className="col xmark redCancel"
                                      icon="xmark"
                                      disabled={uploadStatus}
                                      colorType={'secondary-black'}
                                      onClick={() =>
                                        removeOldFiles(filename.source)
                                      }
                                    ></Button>
                                  </div>
                                )}
                                {filename.uploadSuccess && (
                                  <>
                                    <div className="col" />
                                    <Button
                                      containerStyle={'link'}
                                      className="col xmark"
                                      icon="xmark"
                                      disabled={uploadStatus}
                                      colorType={'secondary-black'}
                                      onClick={() =>
                                        removeOldFiles(filename.source)
                                      }
                                    ></Button>
                                  </>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                    {uploadMore && (
                      <div className="row mx0">
                        {(!!fileNames.length || !!oldFileNames.length) && (
                          <>
                            <div className="col-3 removePadding">
                              {!!successCount && (
                                <div className="container">
                                  <div className="row">
                                    <p className="uploadFileNames">
                                      Uploads Completed.
                                    </p>
                                  </div>

                                  <div className="row">
                                    <Icon
                                      name={'circle-check'}
                                      className=" col-1 greenIcon"
                                    />
                                    <p className="col uploadInfoText">{`${successCount} ${isFile ? 'file' : 'folder'
                                      }${successCount > 1 ? 's' : ''
                                      } uploaded.`}</p>
                                  </div>
                                  {!!(
                                    (fileNames.length + oldFileNames.length) - successCount

                                  ) && (
                                      <div className="row">
                                        <Icon
                                          prefix="fas"
                                          name={'triangle-exclamation'}
                                          className=" col-1 redIcon"
                                        />
                                        <p className="col uploadInfoText">{`${(fileNames.length + oldFileNames.length) - successCount
                                          } ${isFile ? 'file' : 'folder'}${(fileNames.length + oldFileNames.length) - successCount > 1
                                            ? 's'
                                            : ''
                                          } failed.`}</p>
                                      </div>
                                    )}
                                </div>
                              )}

                              {!successCount && (
                                <div className="container">
                                  <div className="row">
                                    <Icon
                                      prefix="fas"
                                      name={'triangle-exclamation'}
                                      className=" col-1 redIcon"
                                    />
                                    <p className="col uploadInfoText">{`${fileNames.length + oldFileNames.length
                                      } ${isFile ? 'file' : 'folder'}${fileNames.length + oldFileNames.length > 1
                                        ? 's'
                                        : ''
                                      } failed.`}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-6" />
                          </>
                        )}
                        {!fileNames.length && !oldFileNames.length && (
                          <div className="row">
                            <div className="col-10" />

                            <Button
                              label="cancel"
                              className="col-1 extCancelBtn btnHeight"
                              containerStyle="outline"
                              colorType="info"
                            // onClick={() => setisLoginOpen(true)}
                            />

                            <Button
                              label="Upload"
                              className="col-1 btnHeight"
                              disabled={submitBttnDisabled}
                              onClick={submitUpload}
                            />
                          </div>
                        )}

                        {!!successCount && (
                          <>
                            <Button
                              label="Upload More"
                              className="col-2 extCancelBtn btnHeight"
                              containerStyle="link"
                              onClick={OpenUpload}
                              colorType="info"
                            />

                            <Button
                              colorType="primary"
                              onClick={handleDone}
                              className="col-1 btnHeight"
                            >
                              Done
                            </Button>
                          </>
                        )}
                        {!successCount &&
                          !!(oldFileNames.length + fileNames.length) && (
                            <>
                              {/* <div className="col-1" /> */}
                              <Button
                                label="Clear List"
                                className="col-2 extCancelBtn btnHeight"
                                containerStyle="link"
                                onClick={handleClearList}
                                colorType="info"
                              />
                              <Button
                                colorType="primary"
                                onClick={handleDone}
                                className="col-1 btnHeight"
                              >
                                Done
                              </Button>
                            </>
                          )}
                      </div>
                    )}
                    {!uploadMore && !uploadStatus && (
                      <div className="row">
                        <div className="col-10" />

                        <Button
                          label="cancel"
                          className="col-1 extCancelBtn btnHeight"
                          containerStyle="outline"
                          colorType="info"
                          onClick={() => setisLoginOpen(Response.link_type === 1 && true)}
                        />

                        <Button
                          label="Upload"
                          className="col-1 btnHeight"
                          disabled={submitBttnDisabled}
                          onClick={submitUpload}
                        />
                      </div>
                    )}
                    {!uploadMore && uploadStatus && !uploadPause && (
                      <div className="row">
                        <div className="col-8" />

                        <Button
                          label="Pause Uploads"
                          className="col-2 extCancelBtn btnHeight"
                          containerStyle="outline"
                          colorType="info"
                          onClick={handlePauseResume}
                        />

                        <Button
                          colorType="primary"
                          disabled={true}
                          className="col-2"
                        >
                          <>
                            <span>Uploading </span>
                            <Icon name="spinner-third" className="fa-spin" />
                          </>
                        </Button>
                      </div>
                    )}
                    {!uploadMore && uploadStatus && uploadPause && (
                      <div className="row">
                        <div className="col-10" />

                        <Button
                          label="Resume Uploads"
                          className="col-2 extCancelBtn btnHeight"
                          containerStyle="outline"
                          colorType="info"
                          onClick={handlePauseResume}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="bottom-position" />
          <div className="extfootbackgroundGradient" />
          <Footer />
        </>
      )}
      {Loading && (
        <div className="LoadingExternalAnimation">
          <Icon name="spinner-third" className="fa-spin fa-10x" />
        </div>
      )}
    </>
  );
};

export default ExternalUpload;

import { Button, InputGroup, Modal, Textarea } from '@wmg-ae/legato';
import React, { useState } from 'react';
import { fieldValidation } from '../../helper';

const RegisterDecline: React.FC<any> = ({
  handleClose,
  handleSubmit,
  isOpen,
  DeclineInRegistrationFile,
  currActionRow,
}) => {
  const [isDisabled, setisDisabled] = useState(true);
  const [notes, setnotes] = useState('');
  const handleInputText = (e: any) => {
    if (e.target.value) {
      setisDisabled(false);
    }
    setnotes(e.target.value);
  };

  const handleDecline = (e: any) => {
    DeclineInRegistrationFile({
      name: currActionRow.name,
      content_id: currActionRow.content_id,
      notes,
    });
    handleSubmit();
  };
  return (
    <Modal
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            colorType="primary"
            onClick={handleDecline}
            disabled={isDisabled}
          >
            Submit
          </Button>
        </div>
      }
      onClose={handleClose}
      shouldCloseOnOverlayClick
      title="Decline Audio Registration"
      isOpen={isOpen}
    >
      <div>
        <InputGroup label="Reason" validation={fieldValidation.required}>
          <Textarea
            onChange={handleInputText}
            placeholder="Enter additional information about why you are declining this asset from registration."
            size="lg"
          />
        </InputGroup>
      </div>
    </Modal>
  );
};

export default RegisterDecline;

import React from 'react';
import '@wmg-ae/legato/build/css/legato-wmg.css';
import Routes from './routes/Routes';

const App: React.FC<any> = () => {
  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;

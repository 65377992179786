export const AudioList = [
  { label: 'Multi-Track' },
  { label: 'Acapela' },
  { label: 'Production Flat' },
  { label: 'Production Master' },
  { label: 'EQ' },
  { label: 'Dollby Atmos' },
  { label: 'Sony 360 RA' },
  { label: 'Instrumental' },
  { label: 'Stem' },
];

export const FaqLinks = {
  here: 'https://www.ibm.com/docs/en/aspera-on-cloud?topic=SS5W4X/dita/content/system_requirements_and_browser_support.html',
  asperaConnect: 'https://www.ibm.com/aspera/connect/',
  browserExtension:
    'https://aspera.ibmaspera.com/help/connect_chrome_extension',
};

export const userRole = {
  LabelUser: 'LabelUser',
  LibraryUser: 'LibraryUser',
  StudioUser: 'StudioUser',
  LabelManager: 'LabelManager',
};

export const shareStudioOptions = [
  { label: 'Register Asset(s) | Final Audio' },
  { label: 'Watermark' },
  { label: 'Audio Edit' },
  { label: 'Mastering' },
  { label: 'Other' },
];
export const StudioOptions = {
  Register: 'Register Asset(s) | Final Audio',
  Watermarking: 'Watermark',
  Audio: 'Audio Edit',
  Mastering: 'Mastering',
  Other: 'Other',
};

import { Button, Modal } from '@wmg-ae/legato';
import React, { useEffect, useState } from 'react';

const RegiserInStudio: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  currActionRow,
}) => {
  const [msg, setmsg] = useState(
    'Are you sure you want to Approve this file for Registration?'
  );
  useEffect(() => {
    if (currActionRow.tasks && currActionRow.tasks.includes('Register')) {
      if (currActionRow.tasks.split(',').length > 1) {
        setmsg(
          `This file has ${
            currActionRow.tasks.split(',').length
          } other task(s) as well are you want to register it?`
        );
      }
    }
  }, [currActionRow]);

  return (
    <Modal
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            No, Cancel
          </Button>
          <Button colorType="primary" onClick={handleSubmit}>
            Yes, Register
          </Button>
        </div>
      }
      onClose={handleClose}
      shouldCloseOnOverlayClick
      title="Register Audio Confirmation"
      isOpen={isOpen}
    >
      <div>{msg}</div>
    </Modal>
  );
};

export default RegiserInStudio;

import { Icon } from '@wmg-ae/legato';
import React from 'react';
import WMG_Logo from '../../../assets/footer_logo.svg';

import './Footer.sass';

const Footer: React.FC = () => {
  return (
    <div className="footer_container">
      <hr className="footer_line" />
      <div className="footer_title">
        <img className="footer_wmg_logo" src={WMG_Logo} alt="WMG_logo" />
        <h3> WMG Secure Upload Network's</h3>
      </div>
      <div className="footer_copy">
        <Icon name="copyright" />
        <span className="footer_copy_text">
          Copyright 2021 Warner Music Group
        </span>
      </div>
    </div>
  );
};

export default Footer;

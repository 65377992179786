import React from 'react';
import uploadfailure from '../../assets/uploadfailed.svg';
import uploadSucces from '../../assets/uploadsuccess.svg';

const ExpiredLink: React.FC<any> = ({ handleReqLink, sendLink }) => {
  return (
    <>
      {!sendLink ? (
        <div className="extUploadMessage extUploadFailure">
          <img src={uploadfailure} alt="upload" />
          <h1 className="extH1">Link Expired</h1>
          <p className="extUploadpText InvalidFailureText">
            Sorry! this link has expired kindly request for a new Link
          </p>
          <h1 className="extInvalidRequest" onClick={handleReqLink}>
            Request Link
          </h1>
        </div>
      ) : (
        <div className="extUploadMessage extUploadFailure">
          <img src={uploadSucces} alt="upload" />
          <h1 className="extH1">Link Requested Successfully!</h1>
          <p className="extUploadpText InvalidFailureText">
            Request for new link has been sent successfully!
          </p>
        </div>
      )}
    </>
  );
};

export default ExpiredLink;

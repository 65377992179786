import {
  Button,
  Datepicker,
  Dropdown,
  Icon,
  Input,
  InputGroup,
  Modal,
  Textarea,
} from '@wmg-ae/legato';
import React, { useState } from 'react';
import { fieldValidation, regexPattern } from '../../helper';
const { required, error } = fieldValidation;
const ShareToExternal: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  externalRow,
}) => {
  const [notes, setNotes] = useState('');
  const [email, setEmail] = useState('');
  const [linkExpiryDate, setLinkExpiryDate] = useState('');
  const [inputValidate, setInputValidate] = useState<any>({
    email: required,
    linkExpiryDate: required,
  });
  const [LinkType, setLinkType] = useState('0');
  const [isShowInputBox, setsShowInputBox] = useState('No');
  const [isShowPassword, setisShowPassword] = useState(false);
  const [password, setPassword] = useState('');

  const submitShareExternal = () => {
    const apiParams = {
      access_level: '0',
      link_type: LinkType,
      notes,
      url: `${window.location.origin}/ext/package/`,
      email: email,
      email_type: 'external_link',
      expiry_date: linkExpiryDate,
      file_name: externalRow.name,
      content_id: externalRow.content_id,
      file_type: externalRow.type,
      password,
    };

    handleSubmit(apiParams);

    setTimeout(() => {
      setNotes('');
      setEmail('');
      setLinkExpiryDate('');
    }, 100);
  };

  const handleInputValidation = (field: any, value: any) => {
    switch (field) {
      case 'email': {
        setEmail(value);
        const isValidMail = regexPattern.email.test(value);
        setInputValidate({
          ...inputValidate,
          [field]: isValidMail ? required : error,
        });
        break;
      }
      case 'linkExpiryDate': {
        setLinkExpiryDate(value);
        setInputValidate({
          ...inputValidate,
          [field]: value instanceof Date ? required : error,
        });
      }
    }
  };
  const setRequirePassOption = (e: any) => {
    setsShowInputBox(e.title);
    if (e.title === 'Yes')
      setLinkType('1')
    else
      setLinkType('0')
  }
  const submitBttnDisabled = !(email.length && inputValidate.email === required) ||
    !(linkExpiryDate && inputValidate.linkExpiryDate === required) ||
    (isShowInputBox === 'Yes' && password.length <= 0)


  return (
    <Modal
      isOpen={isOpen}
      title="Share To External User"
      onClose={handleClose}
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            colorType="primary"
            disabled={submitBttnDisabled}
            onClick={submitShareExternal}
          >
            Share
          </Button>
        </div>
      }
    >
      <div className="modalBody">
        <div className="row">
          <div className="col">
            <InputGroup
              label="Recipient"
              validationIconPlacement="label"
              icon="error"
              validation={inputValidate.email}
            >
              <Input
                onChange={(e) => handleInputValidation('email', e.target.value)}
                placeholder="Enter recipient's email"
                size="md"
                suffixIcon="envelope"
                value={email}
              />
            </InputGroup>
          </div>
        </div>
        <div className="row pt20">
          <div className="col">
            <InputGroup label="Require Password ?"
              validation={isShowInputBox === 'Yes' ? fieldValidation.required : {}}
              validationIconPlacement='label'
              icon="error"
              className="test1"
            >
              {/* <Tooltip
                message="You can generate a private link be selecting 'yes'"
                colorType="dark"
                className="tooltip-index"
              > */}
              <div
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  marginLeft: '5px',
                }}
              >
                <Dropdown
                  onSelect={(e) => setRequirePassOption(e)}
                  list={[
                    { key: '1', title: 'No' },
                    { key: '2', title: 'Yes' },
                  ]}
                  placement={'bottomRight'}
                >
                  <Button
                    icon="chevron-down"
                    containerStyle={'link'}
                    colorType={'info'}
                    value={isShowInputBox}
                  // disabled={!!displayLinkURL}
                  >
                    {isShowInputBox}
                  </Button>
                </Dropdown>
              </div>
              {isShowInputBox === 'Yes' && (<div className="pass-wrapper">
                <Input
                  onChange={(e: any) =>
                    setPassword(e.target.value)
                  }
                  validation={fieldValidation.required}
                  placeholder="Enter Password"
                  type={(isShowPassword && 'text') || 'password'}
                  value={password}
                // disabled={!!displayLinkURL}
                />
                <Icon
                  className="icon"
                  name={(isShowPassword && 'eye-slash') || 'eye'}
                  prefix="fal"
                  onClick={() => setisShowPassword(!isShowPassword)}
                />
              </div>)}
            </InputGroup>
          </div>
        </div>
        <div className="row pt20">
          <div className="col">
            <InputGroup label="Set Link Expiry" validation={inputValidate.linkExpiryDate}>
              <div className="col-5">
                <Datepicker
                  containerStyle="outline"
                  minDate={new Date()}
                  placeholder="MM/DD/YYYY"
                  onChange={(e: any) => handleInputValidation('linkExpiryDate', e)}
                  selected={linkExpiryDate}
                  size="md"
                />
              </div>
            </InputGroup>
          </div>
        </div>
        <div className="row pt20">
          <div className="col">
            <InputGroup label="Notes" validation={undefined}>
              <Textarea
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                placeholder="Enter any additional information regarding file(s)"
                size="lg"
                value={notes}
              />
            </InputGroup>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareToExternal;

import React, { useEffect, useState } from 'react';
import { Button, Input, InputGroup, Modal, Textarea } from '@wmg-ae/legato';
import '../../../../shared-components/spacingStyles.sass';

import './modalStyles.sass';

const RequestRevision: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  currRow,
  RequestRevisionFile,
  userIdList,
}) => {
  const [notes, setNotes] = useState('');
  const [userName, setuserName] = useState('');
  const updateNotes = (evt: any) => {
    setNotes(evt.target.value);
  };

  useEffect(() => {
    userIdList.map((userId: any) => {
      if (
        (userId.email && userId.email.toLowerCase()) ===
        (currRow.user_email && currRow.user_email.toLowerCase())
      ) {
        setuserName(userId.name);
      }
      return userName;
    });
  }, [userIdList, userName, currRow.user_email]);

  const handleRequestRevision = () => {
    RequestRevisionFile({
      content_id: currRow.content_id,
      name: currRow.name,
      notes: notes,
      username: userName,
      recipient_emails: [currRow.recipient_emails || currRow.user_email],
      asset_name: [currRow.name],
      url: currRow.url,
      token: currRow.split_part,
    });
    handleSubmit();
  };

  return (
    <div>
      <Modal
        footer={
          <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
            <div style={{ flex: 1 }} />
            <Button
              colorType="secondary-black"
              containerStyle="outline"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button colorType="primary" onClick={handleRequestRevision}>
              Send Request
            </Button>
          </div>
        }
        isOpen={isOpen}
        onClose={handleClose}
        title="Request Revision"
        size={'lg'}
      >
        <div className="modal_header">
          <div>
            <div className="row file">
              <div className="col-6">
                <InputGroup label="File Name">
                  <Input readonly value={currRow.name} suffixIcon="file" />
                </InputGroup>
              </div>
            </div>

            <div className="row pt20">
              <div className="col">
                <InputGroup label="Recepient">
                  <Input
                    // onChange={handleInputEmail}
                    suffixIcon="envelope"
                    value={currRow.recipient_emails || currRow.user_email}
                    readonly
                  />
                </InputGroup>
              </div>
            </div>

            <div className="row pt20">
              <div className="col">
                <InputGroup label="Notes">
                  <Textarea
                    containerStyle="outline"
                    onChange={updateNotes}
                    placeholder="Enter any additional information regarding file(s) "
                    value={notes}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RequestRevision;

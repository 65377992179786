import React from 'react';
import EditFileContent from './EditFileContent';
// import { comments } from '../../constant';
const EditFileTab2: React.FC<any> = ({ comment }) => {
  return (
    <div className="pt20">
      {comment.map((content: any) => (
        <EditFileContent
          key={content.key}
          content={content}
          comments={content.comment}
        />
      ))}
    </div>
  );
};

export default EditFileTab2;

import { Icon, Label } from '@wmg-ae/legato';
import React from 'react';
import './ActivitiesTabContent.sass';
import './EditDetailContent.sass';
import '../../../../shared-components/spacingStyles.sass';
import { getActivityIcon, getActivityMsg, getTimeLapse, regexPattern } from '../../helper';

const ActivitiesTabContent: React.FC<any> = ({ activity }) => {
  const { action, showtime, title_name, fieldsChanged, fileitem } = activity; // add fileitem while setting the edit details under activities
  const createActivityMsg = (action: any) => {
    if (action === 'Invite collaborator/shared') {
      return title_name + getActivityMsg(action) + fileitem.split('invited')[0];
    } else if (action === 'sharetoexternal') {
      let sharedBy = fileitem.split('with');
      return getActivityMsg(action) + ((regexPattern.email.test(sharedBy[sharedBy.length-1].trim()) && '- ' + sharedBy[sharedBy.length-1]) || '')
    } else if (action === 'download') {
      return getActivityMsg(action) + fileitem.split('downloaded')[0];
    } else {
      return getActivityMsg(action) + title_name;
    }
  };
  return (
    <div className="row py15 mt10 activityRow">
      <div className="col-1">
        <div className="activityIconWrapper">
          <Icon name={getActivityIcon(action)} className="" />
        </div>
      </div>
      <div className="col-11 colactive">
        <div className="row colactiverow">
          <div className="col-10 pad0">
            <Label size="lg" text={createActivityMsg(action)} />
          </div>
          <div className="col-2 timeStyle textRight">
            {getTimeLapse(showtime)}
          </div>
        </div>
        {fieldsChanged && (
          <div className="row colactiverow">
            <div className="col contentFont pad0">
              {fieldsChanged} was updated.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivitiesTabContent;

import React from 'react';
import { Button, Modal } from '@wmg-ae/legato';

const VaultAsset: React.FC<any> = ({ isOpen, handleClose, handleSubmit }) => {
  return (
    <Modal
      isOpen={isOpen}
      size={'md'}
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            No, Cancel
          </Button>
          <Button colorType="primary" onClick={handleSubmit}>
            Yes, Vault
          </Button>
        </div>
      }
      icon="vault"
      onClose={handleClose}
      title="Vault Asset Confirmation"
    >
      <div className="modalBody">
        <p className="valutConfirmLable">
          Are you sure you want to vault this asset?
        </p>
      </div>
    </Modal>
  );
};

export default VaultAsset;

import React from 'react';
import uploadsuccess from '../../assets/uploadsuccess.svg';

const ExternalUploadSuccess: React.FC<any> = ({ Email }) => {
  return (
    <div className="extUploadMessage extUploadSuccess">
      <img src={uploadsuccess} alt="upload" />
      <p className="extUploadpText uploadSuccessText">
        {`Your file(s) has been uploaded and shared with ${Email} successfully! `}
      </p>
    </div>
  );
};

export default ExternalUploadSuccess;

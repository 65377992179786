export const headerColumns = [
  {
    dataIndex: 'filename',
    key: 'filename',
    title: 'Name',
    sortable: false,
  },
  {
    dataIndex: 'expirydate',
    key: 'expirydate',
    title: 'Expiry Date',
    sortable: true,
  },
  {
    dataIndex: 'sentBy',
    key: 'sentBy',
    title: 'Sent By',
    // width: '284px',
    sortable: false,
  },
  // {
  //   dataIndex: 'owner',
  //   key: 'owner',
  //   title: 'Owner',
  //   width: '230px',
  // },
  // {
  //   dataIndex: 'lastUpdate',
  //   key: 'lastUpdate',
  //   title: 'Last Updated',
  //   width: '155px',
  // },
  // {
  //   dataIndex: 'status',
  //   key: 'status',
  //   title: 'Status',
  //   width: '180px',
  // },
  {
    dataIndex: 'rowActions',
    key: 'rowActions',
    sortable: false,
    title: '',
    width: '130px',
  },
];
export const newMockData = [
  {
    id: '2387',
    content_id: 7923,
    parent_id: '0',
    name: 'txt_28_9_01_001.txt',
    type: 'file',
    label: null,
    user_email: 'Vasanthkumar.Cheepurupalli@wmg.com',
    user_id: 499416,
    user_name: 'Vasanthkumar Cheepurupalli',
    content_active: null,
    duration: null,
    status: 'In Library',
    content_type: 'text/plain',
    is_active: 'y',
    file_level: null,
    actions: null,
    collaborators: '',
    last_updated_timestamp: '2022-11-14T11:49:31.678Z',
    upload_timestamp: '2022-11-11T15:05:32.394Z',
    workspace_id: 73462,
    member_id: null,
    size: '8',
    home_file_id: 6284,
    request_assets_link_id: 157,
    is_shared: null,
    allow_access_to_workspace_users: 'N',
    package_id: 'R4L0nYUcyg',
    package_name:
      'Share to Library by Vasanthkumar.Cheepurupalli@wmg.com 2022-11-14T11:49:21.190Z',
    parent_package_file_id: 7964,
    dropbox_id: 12713,
    dropbox_name: 'Library',
    tasks: '',
    recipient_emails: 'neonstark99@gmail.com',
  },
];

import React from 'react';
import { Label } from '@wmg-ae/legato';
import './EditDetailContent.sass';
import './modalStyles.sass';
import '../../../../shared-components/spacingStyles.sass';
import { getTimeLapse } from '../../helper';

const EditFileContent: React.FC<any> = ({ content }) => {
  const { created_date, username, comment } = content;
  return (
    <div className="row py15 mt10 activityRow">
      {/* <div className="col-1">
        <div className="userImage">
          <img className="profile_img" src={userProfile} alt="" />
        </div>
      </div> */}
      <div className="col">
        <div className="row">
          <div className="col-10">
            <div className="row">
              <Label size="lg" text={username} />
            </div>
          </div>
          <div className="col-2 timeStyle textRight">
            {getTimeLapse(created_date)}
          </div>
        </div>
        {true && (
          <div className="row contentFont">
            <div className="col pt8">{comment}</div>
          </div>
        )}
      </div>
    </div>
  );
};
export default EditFileContent;

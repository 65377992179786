import React from 'react';
import { Button, Modal } from '@wmg-ae/legato';
import '../../../../shared-components/spacingStyles.sass';
import './modalStyles.sass';

const DeleteFile: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  sharedStatus,
  count,
}) => {
  const deleteMsg = 'Are you sure you want to delete this file?';
  const deleteShareMsg =
    'This file has been shared with other team or users, deleting it means they will loose this file as well. Do you still want to delete this file? ';
  return (
    <Modal
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            No, Cancel
          </Button>
          <Button colorType="danger" onClick={handleSubmit}>
            Yes, Delete
          </Button>
        </div>
      }
      icon="trash-can"
      onClose={handleClose}
      title="Delete Confirmation"
      isOpen={isOpen}
    >
      <div className="deleteFile">
        {count === 0 && !sharedStatus && <p>{deleteMsg}</p>}
        {count === 0 && sharedStatus && <p>{deleteShareMsg}</p>}
        {count > 0 && (
          <div className="bDtext">
            <p>You have selected {count} item(s) these might be,</p>
            <ul className="circle">
              <li>Shared with other teams or user</li>
              <li>Have multiple files or folder in them Deleting them</li>
            </ul>
            <p>
              Deleting them means everyone will loose these and all file/folder
              in them will also be lost. Are you sure you still want to delete
              them?
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DeleteFile;

import React, { useState } from 'react';
import { inviteCollaborators } from '../../constant.js';
import '../../../../shared-components/spacingStyles.sass';
import './modalStyles.sass';
import {
  Button,
  Dropdown,
  Icon,
  Input,
  Label,
  Modal,
  //   Select,
} from '@wmg-ae/legato';
import { fieldValidation, regexPattern } from '../../helper.js';

const InviteCollaborators: React.FC<any> = ({
  isOpen,
  handleClose,
  handleSubmit,
  postInviteCollaborator,
  row,
  userIdList,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const { content_id, name, user_id, user_name, user_email } = row;
  // console.log('row data', row.type);
  // const [uId, setUId] = useState<any>('');
  // const {email,source_id} = userIdList;
  // console.log(userIdList);
  // user_email, status, name
  const [emailValidation, setEmailValidation] = useState<any>([0]);
  const [inputList, setInputList] = useState([
    { username: '', user_id: '', access_level: 'Can View', email: '' },
  ]);

  const handleAddChange = () => {
    setInputList([
      ...inputList,
      { username: '', user_id: '', access_level: 'Can View', email: '' },
    ]);
    setEmailValidation([...emailValidation, 0]);
    // emailValidation.some((item: any) => item === fieldValidation.error || item === 0)
    // ? setIsDisabled(false)
    setIsDisabled(false);
  };

  const handleSaveUpdate = () => {
    postInviteCollaborator({
      content_id: content_id,
      file_name: name,
      inviteList: inputList,
      from_user_id: user_id,
      from_username: user_name,
      from_user_email: user_email,
    });
    handleSubmit();
  };

  const handleRemoveIcon = (key: number) => {
    // console.log('key === ', key);
    let finals = inputList.filter(
      (row: any, filterKey: number) => filterKey !== key
    );
    setInputList(finals);

    let finalsVali = emailValidation.filter(
      (row: any, filterKey: number) => filterKey !== key
    );
    setEmailValidation(finalsVali);
    finalsVali.some((item: any) => item === fieldValidation.error || item === 0)
      ? setIsDisabled(false)
      : setIsDisabled(true);
  };

  const handleInputChange = (index: number, e: any) => {
    const newValue = e.target.value;
    const newList = [...inputList];
    const newVali = [...emailValidation];
    newList[index].email = newValue;
    if ((regexPattern.wmgEmail.test(newList[index].email) || regexPattern.wmgTestEmail.test(newList[index].email))
      && userIdList.some((userId: any) =>
        (userId.email && userId.email.toLowerCase()) ===
        (newList[index].email && newList[index].email.toLowerCase())
      )) {
      newVali[index] = 1;
    } else {
      newVali[index] = fieldValidation.error;
    }
    setEmailValidation(newVali);
    newVali.some((item: any) => item === fieldValidation.error || item === 0)
      ? setIsDisabled(false)
      : setIsDisabled(true);
    userIdList.map((userId: any) => {
      if (
        (userId.email && userId.email.toLowerCase()) ===
        (newList[index].email && newList[index].email.toLowerCase())
      ) {
        newList[index].user_id = userId.source_id;
        newList[index].username = userId.name;
        newList[index].email = userId.email;
      }
      return newList;
    });
    setInputList(newList);
  };

  const handleSelect = (option: any, key: number) => {
    const newValue = option.title;
    const newList = [...inputList];
    newList[key].access_level = newValue;
    setInputList(newList);
  };
  // console.log('inputList === ', inputList);
  return (
    <div>
      <div>
        <Modal
          footer={
            <div
              style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}
            >
              <div style={{ flex: 1 }} />
              <Button
                colorType="secondary-black"
                containerStyle="outline"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                colorType="primary"
                onClick={handleSaveUpdate}
                disabled={!isDisabled}
              >
                Send Invite
              </Button>
            </div>
          }
          onClose={handleClose}
          shouldCloseOnOverlayClick
          title="Invite Collaborator"
          isOpen={isOpen}
          size={'lg'}
        >
          <div>
            <div className="row">
              {/* <div className="col-9"> */}
              {/* <InputGroup label="Collaborator" validation={emailValidation}> */}
              <Label size="lg" text="Collaborator" />
              {true &&
                inputList.map((inputfield, index) => {
                  // console.log('index === ', index);
                  return (
                    <div key={index}>
                      <div className="row pt20 iconRow">
                        <div className="col-9 pr0">
                          <Input
                            // value={inputfield.emailName}
                            placeholder="Enter collaborators email"
                            onChange={(e: any) => handleInputChange(index, e)}
                            value={inputList[index].email}
                            validation={emailValidation[index]}
                          />
                        </div>
                        <div className="col-2 pl0 padlet">
                          <Dropdown
                            onSelect={(e) => handleSelect(e, index)}
                            list={inviteCollaborators}
                            placement={'bottom'}
                          // defultSelectedItem={}
                          >
                            <Button
                              icon="chevron-down"
                              containerStyle={'outline'}
                              colorType={'secondary-black'}
                              value={inputList[index].access_level}
                              className="leftBorderButton"
                            // onChange={(e) => handleSelect(e)}
                            >
                              {inputList[index].access_level || 'Can View'}
                            </Button>
                          </Dropdown>
                        </div>
                        {!!index && (
                          <div
                            className="col ml5 pt15 iconCol"
                            onClick={() =>
                              // handleRemoveIcon(e, inputfield.idx)
                              handleRemoveIcon(index)
                            }
                          >
                            <Icon name="trash-can" />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              {/* </InputGroup> */}
            </div>

            <div className="row pt20">
              <div className="col">
                <Button
                  containerStyle={'link'}
                  icon="user-plus"
                  label="Add another"
                  onClick={() => handleAddChange()}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default InviteCollaborators;

import UserProfile from '../../assets/asperaLogo.svg';

export const definedRoles = {
  devLabelUser: 'ENT_Okta_WEASUN_Dev_LabelUser',
  devLibraryUser: 'ENT_Okta_WEASUN_Dev_LibraryUser',
  devStudioUser: 'ENT_Okta_WEASUN_Dev_StudioUser',
  devLabelManager: 'ENT_Okta_WEASUN_Dev_LabelManager',
  // Roles in Staging Env
  stageLabelUser: 'ENT_Okta_WEASUN_Staging_LabelUser',
  stageLibraryUser: 'ENT_Okta_WEASUN_Staging_LibraryUser',
  stageStudioUser: 'ENT_Okta_WEASUN_Staging_StudioUser',
  stageLabelManager: 'ENT_Okta_WEASUN_Staging_LabelManager',
  // Roles in Prod Env
  prodLabelUser: 'ENT_Okta_WEASUN_Prod_LabelUser',
  prodLibraryUser: 'ENT_Okta_WEASUN_Prod_LibraryUser',
  prodStudioUser: 'ENT_Okta_WEASUN_Prod_StudioUser',
  prodLabelManager: 'ENT_Okta_WEASUN_Prod_LabelManager',
};

export const headerColumns = [
  {
    dataIndex: 'artist',
    key: 'artist',
    title: 'Artist',
    width: '160px',
    sortable: true,
  },
  {
    dataIndex: 'title',
    key: 'title',
    title: 'Title',
    width: '160px',
    sortable: true,
  },
  {
    dataIndex: 'filename',
    key: 'filename',
    title: 'Package Name',
    width: '180px',
    sortable: true,
  },

  {
    dataIndex: 'sentBy',
    key: 'sentBy',
    title: 'Sent By',
    width: '250px',
    filterSearch: true,
  },
  {
    dataIndex: 'owner',
    key: 'owner',
    title: 'Owner',
    width: '119px',
    filterSearch: true,
    sortable: true,
  },
  {
    dataIndex: 'lastUpdate',
    key: 'lastUpdate',
    title: 'Last Updated',
    width: '154px',
    filterSearch: true,
    sortable: true,
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    width: '112px',
    filterSearch: true,
    sortable: true,
  },
  // { dataIndex: "comments", key: "comments", title: "Comments", width: "283px" },
  {
    dataIndex: 'rowActions',
    key: 'rowActions',
    sortable: false,
    title: '',
    width: '105px',
  },
];

export const studioColumns = [
  {
    dataIndex: 'artist',
    key: 'artist',
    title: 'Artist',
    width: '160px',
    sortable: true,
  },
  {
    dataIndex: 'title',
    key: 'title',
    title: 'Title',
    width: '160px',
    sortable: true,
  },
  {
    dataIndex: 'filename',
    key: 'filename',
    title: 'Package Name',
    width: '180px',
    sortable: true,
  },
  {
    dataIndex: 'task',
    key: 'task',
    title: 'Task',
    width: '167px',
    filterSearch: true,
    sortable: false,
  },
  {
    dataIndex: 'sentBy',
    key: 'sentBy',
    title: 'Sent By',
    width: '195px',
    filterSearch: true,
  },
  {
    dataIndex: 'owner',
    key: 'owner',
    title: 'Owner',
    filterSearch: true,
    sortable: true,
  },
  {
    dataIndex: 'lastUpdate',
    key: 'lastUpdate',
    title: 'Last Updated',
    width: '154px',
    filterSearch: true,
    sortable: true,
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    width: '112px',
    filterSearch: true,
    sortable: true,
  },
  {
    dataIndex: 'rowActions',
    key: 'rowActions',
    sortable: false,
    title: '',
    width: '105px',
  },
];
// For Local when API is not working
export const newMockData = [
  {
    id: '914',
    content_id: 2881,
    parent_id: '0',
    name: 'Krishna-Folder',
    type: 'folder',
    label: null,
    user_email: 'Krishnakant.Narayanshastri@wmg.com',
    user_id: 499417,
    user_name: 'Krishnakant Narayanshastri',
    content_active: null,
    duration: null,
    status: 'In Library',
    content_type: 'Mixed',
    is_active: 'y',
    file_level: null,
    actions: null,
    collaborators: null,
    last_updated_timestamp: '2022-09-14T09:20:31.070Z',
    upload_timestamp: '2022-09-14T09:20:31.070Z',
    workspace_id: 86138,
    member_id: null,
    size: 'Mixed',
    home_file_id: 618,
  },
  {
    id: '913',
    content_id: 2878,
    parent_id: '0',
    name: 'File1.txt',
    type: 'file',
    label: null,
    user_email: 'Krishnakant.Narayanshastri@wmg.com',
    user_id: 499417,
    user_name: 'Krishnakant Narayanshastri',
    content_active: null,
    duration: null,
    status: 'New',
    content_type: 'text/plain',
    is_active: 'y',
    file_level: null,
    actions: null,
    collaborators: null,
    last_updated_timestamp: '2022-09-14T09:15:24.167Z',
    upload_timestamp: '2022-09-14T09:15:24.167Z',
    workspace_id: 86138,
    member_id: null,
    size: '6',
    home_file_id: 618,
  },
];

export const mockData = [
  {
    lastUpdate: '18/07/2022',
    duration: 28,
    key: '1',
    filename: 'Aaron',
    comments: ' g some where',
    status: 'In Studio',
  },
  {
    lastUpdate: '18/07/2022',
    duration: 55,
    key: '3',
    filename: 'Mary',
    comments: 'bbb bb some where',
    status: 'For Review',
  },
  {
    lastUpdate: '18/07/2022',
    duration: 12,
    key: '4',
    filename: 'Toby',
    comments: 'www some where',
    status: 'In Review',
  },
  {
    lastUpdate: 'ee e some time 0',
    duration: 49,
    key: '5',
    filename: 'Toby 0 ',
    comments: 'www some where 0',
    status: 'In Library',
  },
  {
    lastUpdate: 'ee e some time 1',
    duration: 47,
    key: '6',
    filename: 'Toby 1 ',
    comments: 'www some where 1',
    status: 'Vaulted',
  },
  {
    lastUpdate: 'ee e some time 10',
    duration: 22,
    key: '15',
    filename: 'Toby 10 ',
    comments: 'www some where 10',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 11',
    duration: 9,
    key: '16',
    filename: 'Toby 11 ',
    comments: 'www some where 11',
    status: 'For Review',
  },
  {
    lastUpdate: 'ee e some time 12',
    duration: 20,
    key: '17',
    filename: 'Toby 12 ',
    comments: 'www some where 12',
    status: 'Vaulted',
  },
  {
    lastUpdate: 'ee e some time 13',
    duration: 68,
    key: '18',
    filename: 'Toby 13 ',
    comments: 'www some where 13',
    status: 'In Library',
  },
  {
    lastUpdate: 'ee e some time 14',
    duration: 72,
    key: '19',
    filename: 'Toby 14 ',
    comments: 'www some where 14',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 15',
    duration: 49,
    key: '20',
    filename: 'Toby 15 ',
    comments: 'www some where 15',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 16',
    duration: 38,
    key: '21',
    filename: 'Toby 16 ',
    comments: 'www some where 16',
    status: 'For Review',
  },
  {
    lastUpdate: 'ee e some time 17',
    duration: 25,
    key: '22',
    filename: 'Toby 17 ',
    comments: 'www some where 17',
    status: 'In Studio',
  },
  {
    lastUpdate: 'ee e some time 18',
    duration: 61,
    key: '23',
    filename: 'Toby 18 ',
    comments: 'www some where 18',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 19',
    duration: 18,
    key: '24',
    filename: 'Toby 19 ',
    comments: 'www some where 19',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 2',
    duration: 22,
    key: '7',
    filename: 'Toby 2 ',
    comments: 'www some where 2',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 20',
    duration: 44,
    key: '25',
    filename: 'Toby 20 ',
    comments: 'www some where 20',
    status: 'For Review',
  },
  {
    lastUpdate: 'ee e some time 21',
    duration: 9,
    key: '26',
    filename: 'Toby 21 ',
    comments: 'www some where 21',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 22',
    duration: 59,
    key: '27',
    filename: 'Toby 22 ',
    comments: 'www some where 22',
    status: 'In Library',
  },
  {
    lastUpdate: 'ee e some time 23',
    duration: 72,
    key: '28',
    filename: 'Toby 23 ',
    comments: 'www some where 23',
    status: 'Vaulted',
  },
  {
    lastUpdate: 'ee e some time 24',
    duration: 79,
    key: '29',
    filename: 'Toby 24 ',
    comments: 'www some where 24',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 25',
    duration: 59,
    key: '30',
    filename: 'Toby 25 ',
    comments: 'www some where 25',
    status: 'In Studio',
  },
  {
    lastUpdate: 'ee e some time 26',
    duration: 15,
    key: '31',
    filename: 'Toby 26 ',
    comments: 'www some where 26',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 27',
    duration: 63,
    key: '32',
    filename: 'Toby 27 ',
    comments: 'www some where 27',
    status: 'In Library',
  },
  {
    lastUpdate: 'ee e some time 28',
    duration: 33,
    key: '33',
    filename: 'Toby 28 ',
    comments: 'www some where 28',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 29',
    duration: 13,
    key: '34',
    filename: 'Toby 29 ',
    comments: 'www some where 29',
    status: 'Vaulted',
  },
  {
    lastUpdate: 'ee e some time 3',
    duration: 58,
    key: '8',
    filename: 'Toby 3 ',
    comments: 'www some where 3',
    status: 'For Review',
  },
  {
    lastUpdate: 'ee e some time 30',
    duration: 68,
    key: '35',
    filename: 'Toby 30 ',
    comments: 'www some where 30',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 31',
    duration: 29,
    key: '36',
    filename: 'Toby 31 ',
    comments: 'www some where 31',
    status: 'In Studio',
  },
  {
    lastUpdate: 'ee e some time 32',
    duration: 8,
    key: '37',
    filename: 'Toby 32 ',
    comments: 'www some where 32',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 33',
    duration: 20,
    key: '38',
    filename: 'Toby 33 ',
    comments: 'www some where 33',
    status: 'In Library',
  },
  {
    lastUpdate: 'ee e some time 34',
    duration: 56,
    key: '39',
    filename: 'Toby 34 ',
    comments: 'www some where 34',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 35',
    duration: 12,
    key: '40',
    filename: 'Toby 35 ',
    comments: 'www some where 35',
    status: 'Vaulted',
  },
  {
    lastUpdate: 'ee e some time 36',
    duration: 10,
    key: '41',
    filename: 'Toby 36 ',
    comments: 'www some where 36',
    status: 'In Studio',
  },
  {
    lastUpdate: 'ee e some time 37',
    duration: 14,
    key: '42',
    filename: 'Toby 37 ',
    comments: 'www some where 37',
    status: 'For Review',
  },
  {
    lastUpdate: 'ee e some time 38',
    duration: 49,
    key: '43',
    filename: 'Toby 38 ',
    comments: 'www some where 38',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 39',
    duration: 9,
    key: '44',
    filename: 'Toby 39 ',
    comments: 'www some where 39',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 4',
    duration: 54,
    key: '9',
    filename: 'Toby 4 ',
    comments: 'www some where 4',
    status: 'Vaulted',
  },
  {
    lastUpdate: 'ee e some time 5',
    duration: 24,
    key: '10',
    filename: 'Toby 5 ',
    comments: 'www some where 5',
    status: 'In Library',
  },
  {
    lastUpdate: 'ee e some time 6',
    duration: 17,
    key: '11',
    filename: 'Toby 6 ',
    comments: 'www some where 6',
    status: 'Registered',
  },
  {
    lastUpdate: 'ee e some time 7',
    duration: 70,
    key: '12',
    filename: 'Toby 7 ',
    comments: 'www some where 7',
    status: 'In Studio',
  },
  {
    lastUpdate: 'ee e some time 8',
    duration: 64,
    key: '13',
    filename: 'Toby 8 ',
    comments: 'www some where 8',
    status: 'For Review',
  },
  {
    lastUpdate: 'ee e some time 9',
    duration: 36,
    key: '14',
    filename: 'Toby 9 ',
    comments: 'www some where 9',
    status: 'In Library',
  },
  {
    lastUpdate: ' aaaa some time',
    duration: 34,
    key: '2',
    filename: 'Zoe',
    comments: '  aaaa some where',
    status: 'Registered',
  },
];

export const comments = [
  {
    userProfile: UserProfile,
    username: 'Comments added by John Doe',
    key: '1',
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a ",
    timegap: '30 min ago',
  },
  {
    userProfile: UserProfile,
    username: 'Comments added by John Doe',
    key: '2',
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a ",
    timegap: '30 min ago',
  },
  {
    userProfile: UserProfile,
    username: 'Comments added by John Doe',
    key: '3',
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a ",
    timegap: '30 min ago',
  },
  {
    username: 'Comments added by John Doe',
    userProfile: UserProfile,
    key: '4',
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a ",
    timegap: '30 min ago',
  },
  {
    username: 'Comments added by John Doe',
    userProfile: UserProfile,
    key: '5',
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a ",
    timegap: '30 min ago',
  },
  {
    username: 'Comments added by John Doe',
    userProfile: UserProfile,
    key: '6',
    comment:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a ",
    timegap: '30 min ago',
  },
];

export const notifications = [
  {
    userProfile: UserProfile,
    key: '1',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: 'Just Now',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '2',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '1 min ago',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '3',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '1 min ago',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '4',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '1 min ago',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '5',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '1 min ago',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '6',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '23 min ago',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '7',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '1 day ago',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '8',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '1 day ago',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '9',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '1 day ago',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '10',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '1 day ago',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '11',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '1 day ago',
    active: false,
    fileName: 'Foldername02',
  },
  {
    userProfile: UserProfile,
    key: '12',
    userName: 'John Doe',
    subTitle: 'Edit a File',
    fileAction: '1 day ago',
    active: false,
    fileName: 'Foldername02',
  },
];

export const accountOptions = [
  { key: '1', title: 'Account Settings' },
];

// DO NOT CHANGE THE ORDER UNNECESSARILY else required to update conditions in table component also.
export const allOptions = [
  { key: '1', title: 'View/Edit Details' },
  { key: '2', title: 'Invite Collaborator' },
  { key: '3', title: 'Request Revision' },
  { key: '4', title: 'Move to Folder' },
  { key: '5', title: 'Vault Asset' },
  { key: '6', title: 'Send for Approval' },
  { key: '7', title: 'Register' },
  { key: '8', title: 'Decline Register Request' },
  { key: '9', title: 'Approve' },
  { key: '10', title: 'Decline' },
];

// export const ellipseOptions = [
//   { key: '1', title: 'View/Edit Details' },
//   { key: '2', title: 'Invite Collaborator' },
//   { key: '3', title: 'Request Revision' },
//   { key: '4', title: 'Move to Folder' },
// ];

// export const libEllipseOptions = [
//   { key: '1', title: 'View/Edit Details' },
//   { key: '2', title: 'Move to Folder' },
//   { key: '3', title: 'Vault Asset' },
// ];

// export const StudioEllipseOptions = [
//   { key: '1', title: 'View/Edit Details' },
//   { key: '2', title: 'Move to Folder' },
//   { key: '3', title: 'Send for Approval' },
//   { key: '4', title: 'Register' },
//   { key: '5', title: 'Decline Register Request' },
// ];

// export const RegEllipseOptions = [
//   { key: '1', title: 'Approve' },
//   { key: '2', title: 'Decline' },
// ];

export const shareOptions = [
  { key: '1', title: 'Share to Studio' },
  { key: '2', title: 'Share to Library' },
  { key: '3', title: 'Share to External' },
];
// export const libShareOptions = [{ key: '1', title: 'Share to Studio' }];
// export const studioShareOptions = [{ key: '2', title: 'Share to Library' }];

export const shareStudioOptions = [
  { title: 'Register Asset(s) | Final Audio', isOpen: false },
  { title: 'Watermark', isOpen: false },
  { title: 'Audio Edit', isOpen: false },
  { title: 'Mastering', isOpen: false },
  { title: 'Other', isOpen: false },
];

export const StudioOptions = {
  Register: 'Register Asset(s) | Final Audio',
  Watermark: 'Watermark',
  Audio: 'Audio Edit',
  Mastering: 'Mastering',
  Other: 'Other',
};

export const inviteCollaborators = [
  { key: '1', title: 'Can Edit' },
  { key: '2', title: 'Can View' },
];

export const editFileOptions = [
  { id: '1', title: 'Info' },
  { id: '2', title: 'Activities' },
  { id: '3', title: 'Comments' },
];

export const MyLinkCloumns = [
  {
    dataIndex: 'RecipientEmail',
    key: 'RecipientEmail',
    title: 'Recipient Email',
    width: '320px',
    filterSearch: true,
    sortable: true,
  },
  {
    dataIndex: 'Sign',
    key: 'Sign',
    title: 'Require Password?',
    width: '149px',
    sortable: true,
  },
  {
    dataIndex: 'Permissions',
    key: 'Permissions',
    title: 'Access Permissions',
    width: '175px',
  },
  {
    dataIndex: 'Time',
    key: 'Time',
    title: 'Expiry Time',
    width: '154px',
    sortable: true,
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    width: '160px',
    filterSearch: true,
    sortable: true,
  },
  {
    dataIndex: 'Link',
    key: 'Link',
    title: 'Link',
    filterSearch: true,
    sortable: true,
    // width: '283px'
  },
  {
    dataIndex: 'rowActions',
    key: 'rowActions',
    sortable: false,
    title: '',
    width: '50px',
  },
];
export const MyLinkLibStudio = [
  {
    dataIndex: 'RecipientEmail',
    key: 'RecipientEmail',
    title: 'Recipient Email',
    width: '320px',
    filterSearch: true,
    sortable: true,
  },
  {
    dataIndex: 'Sign',
    key: 'Sign',
    title: 'Require Password?',
    width: '149px',
    sortable: true,
  },
  {
    dataIndex: 'Time',
    key: 'Time',
    title: 'Expiry Time',
    width: '154px',
    sortable: true,
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    width: '160px',
    filterSearch: true,
    sortable: true,
  },
  {
    dataIndex: 'Link',
    key: 'Link',
    title: 'Link',
    filterSearch: true,
    sortable: true,
    // width: '283px'
  },
  {
    dataIndex: 'rowActions',
    key: 'rowActions',
    sortable: false,
    title: '',
    width: '50px',
  },
];

export const MyLinkData = [
  {
    RecipientEmail: 'manhhachkt08@gmail.com',
    Permissions: 'Only Me',
    Sign: 'Yes',
    Time: 'Never',
    status: 'Active',
    Link: 'https://clifton.info',
  },
  {
    RecipientEmail: 'tienlapspktnd@gmail.com',
    Permissions: 'Everyone',
    Sign: 'No',
    Time: '4/16/2020',
    status: 'Expired',
    Link: 'https://www.myretail.com/products/elektricheskaya-zubnaya-shhetka-braun-oral-b-vitality-d100-413-1-crossaction-1-1-20065314',
  },
];

export const MyLinkActive = [
  { key: '1', title: 'Resend Link' },
  { key: '2', title: 'Copy Link' },
  { key: '3', title: 'Terminate Link' },
  { key: '4', title: 'Delete' },
];

export const MyLinkExpired = [
  { key: '2', title: 'Copy Link' },
  { key: '3', title: 'Generate Link' },
  { key: '4', title: 'Delete' },
];

export const MyLinkActiveDownload = [
  { key: '1', title: 'Terminate Link' },
  { key: '2', title: 'Delete' },
];

export const MyLinkExpiredDownload = [
  { key: '1', title: 'Delete' },
];

export const LinkTypeDownload = [
  { key: '1', title: 'Upload' },
  { key: '2', title: 'Download' },
];

export const activities = [
  {
    title_name: 'File details were updated by John Doe',
    fileitem: 'Filename, Artist, Studio, ISRC was updated.',
    key: '1',
    showtime: '30 min ago',
    fileicon: 'floppy-disk-pen',
  },
  {
    title_name: 'File was downloaded by John Doe',
    fileitem: '',
    key: '2',
    showtime: '30 min ago',
    fileicon: 'download',
  },
  {
    title_name: 'A revision was requested for this file by John Doe',
    fileitem: '',
    key: '3',
    showtime: '30 min ago',
    fileicon: 'file-import',
  },
  {
    title_name: 'File details were updated by John Doe',
    fileitem: 'Filename, Artist, Studio, ISRC was updated.',
    key: '4',
    showtime: '30 min ago',
    fileicon: 'floppy-disk-pen',
  },
  {
    title_name: 'File was shared to studio by John Doe',
    fileitem: '',
    key: '5',
    showtime: '30 min ago',
    fileicon: 'share-from-square',
  },
  {
    title_name: 'File was downloaded by John Doe',
    fileitem: '',
    key: '6',
    showtime: '30 min ago',
    fileicon: 'download',
  },
  {
    title_name: 'A revision was requested for this file by John Doe',
    fileitem: '',
    key: '7',
    showtime: '30 min ago',
    fileicon: 'file-import',
  },
];

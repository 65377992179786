import React, { useEffect, useState } from 'react';
import { Button, Modal, Tabs } from '@wmg-ae/legato';
import '../../../../shared-components/spacingStyles.sass';
import './modalStyles.sass';
import EditFileInfoTab from './EditFileInfoTab';
import { editFileOptions, shareStudioOptions, StudioOptions } from '../../constant';
import EditFileTab2 from './EditFileTab2';
import EditFileTab3 from './EditFileTab3';
import { ITabItem } from '@wmg-ae/legato/build/components/Tabs/types';
import { fieldValidation, regexPattern } from '../../helper';

const { required, error } = fieldValidation;

const EditFile: React.FC<any> = ({
  handleClose,
  isOpen,
  handleSubmit,
  row,
  comments,
  readComment,
  activitiesRecords,
  contentInfoDetails,
  currentUser,
  assetPermission,
}) => {
  // If row is undefined, prevent loading Info Tab, so given default true below
  const isFolder = row.type ? row.type.includes('folder') : true;
  const { Register, Watermark, Audio, Mastering } = StudioOptions;
  const [Tab, setTab] = useState(isFolder ? '2' : '1');
  const {
    user_email,
    status,
    name,
    duration,
    content_type,
    content_id,
    allow_access_to_workspace_users,
  } = row;
  const [disableSaveBttn, setDisableSaveBttn] = useState(true);
  const [changedFieldsName, setChangedFieldsName] = useState<any>([]);
  const [fileExtension, setFileExtension] = useState('');
  const [filename, setFileName] = useState('');

  const getslicedFilename = () => {
    let slicedFilename = row.name || '';
    if (row.name && row.name.includes('.')) {
      const lastIndexDot = row.name.lastIndexOf('.');
      slicedFilename = row.name.slice(0, lastIndexDot);
      const fileExtn = row.name.slice(lastIndexDot, row.name.length);
      setFileExtension(fileExtn);
    }
    return slicedFilename;
  };
  const [inputFields, setInputFields] = useState<any>({
    artist: '',
    title: '',
    upc: '',
    isrc: '',
    selectedStudioOption: [],
    regAssetUpc: '',
    regAssetIsrc: '',
    regAssetDueDate: '',
    regAssetStudioInfo: '',
    watermarkingWbs: '',
    watermarkingApprovedBy: '',
    watermarkingJD: '',
    audioEditJD: '',
    audioEditDueDate: '',
    audioEditSendRef: '',
    audioEditWbs: '',
    masteringSendRef: '',
    masteringDueDate: '',
    masteringWbs: '',
    version: '',
    studio: '',
    engineer: '',
    producer: '',
    notes: '',
    audio_type: [],
    package_name: ''
  });

  const [inputValidate, setInputValidate] = useState<any>({
    filename: required,
    artist: required,
    title: required,
    selectedStudioOption: required,
    watermarkingApprovedBy: required,
    audioEditSendRef: required,
    masteringSendRef: required,
  });

  const [optionsShareStudio, setoptionsShareStudio] =
    useState(shareStudioOptions);

  useEffect(() => {
    setDisableSaveBttn(true);
    setFileName(getslicedFilename());
    if (contentInfoDetails && contentInfoDetails.content_id === content_id.toString()) {
      let newArr = [...optionsShareStudio];
      let b: any = contentInfoDetails.tasks.split(',');
      // b = newVal.map((e: any) => e.title);
      newArr = newArr.map((element: any) => ({
        ...element,
        isOpen: b.includes(element.title),
      }));
      setoptionsShareStudio(newArr);
      setInputFields({
        ...inputFields,
        artist: contentInfoDetails.artist || '',
        title: contentInfoDetails.title || '',
        upc: (contentInfoDetails.upc && parseInt(contentInfoDetails.upc)) || '',
        isrc: contentInfoDetails.isrc || '',
        version: contentInfoDetails.version || '',
        studio: contentInfoDetails.studio_name || '',
        engineer: contentInfoDetails.engineer || '',
        producer: contentInfoDetails.producer || '',
        package_name: contentInfoDetails.package_name || '',
        notes: '',
        audio_type:
          (contentInfoDetails.audio_type &&
            contentInfoDetails.audio_type.map((option: any) => ({
              label: option,
            }))) ||
          [],
        selectedStudioOption: newArr.filter((item: any) => item.isOpen === true) || [],
        regAssetUpc: (contentInfoDetails.upc && parseInt(contentInfoDetails.upc)) || '',
        regAssetIsrc: contentInfoDetails.isrc || '',
        regAssetDueDate: (contentInfoDetails.register_asset_due_date && new Date(contentInfoDetails.register_asset_due_date)) || '',
        regAssetStudioInfo: contentInfoDetails.studio_info || '',
        watermarkingWbs: contentInfoDetails.watermark_wbs || '',
        watermarkingApprovedBy: contentInfoDetails.watermark_approved_by || '',
        watermarkingJD: contentInfoDetails.watermark_job_description || '',
        audioEditJD: contentInfoDetails.audio_edit_job_description || '',
        audioEditDueDate: (contentInfoDetails.audio_edit_due_date && new Date(contentInfoDetails.audio_edit_due_date)) || '',
        audioEditSendRef: contentInfoDetails.audio_edit_send_ref_email || '',
        audioEditWbs: contentInfoDetails.audio_edit_wbs || '',
        masteringSendRef: contentInfoDetails.mastering_send_ref_email || '',
        masteringDueDate: (contentInfoDetails.mastering_due_date && new Date(contentInfoDetails.mastering_due_date)) || '',
        masteringWbs: contentInfoDetails.mastering_wbs || '',
      });
    }
    //need to add audioType when API is ready.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentInfoDetails, row.name]);

  const validateFields = (fieldName: string, newVal: any) => {
    switch (fieldName) {
      case 'filename':
      case 'artist':
      case 'title': {
        // case 'isrc':
        setInputValidate({
          ...inputValidate,
          [fieldName]: newVal ? required : error,
        });
        break;
      }
      case 'audioEditSendRef':
      case 'masteringSendRef': {
        const isValidMail = regexPattern.email.test(newVal);
        setInputValidate({
          ...inputValidate,
          [fieldName]: isValidMail ? required : error,
        });
        break;
      }
      case 'watermarkingApprovedBy':
        const isValidMailWmg = regexPattern.wmgEmail.test(newVal);
        setInputValidate({
          ...inputValidate,
          [fieldName]: isValidMailWmg ? required : error,
        });
        break;
      // case 'upc': {
      //   const isNumVal = !isNaN(parseInt(newVal));
      //   setInputValidate({
      //     ...inputValidate,
      //     [fieldName]: isNumVal && parseInt(newVal) > 0 ? required : error,
      //   });
      //   break;
      // }
      default:
        break;
    }
  };

  const getFieldName = (name: string) => {
    if (name === 'upc' || name === 'isrc') {
      return name.toUpperCase();
    } else if (name === 'audio_type') {
      return 'Audio type';
    } else if (name === 'selectedStudioOption') {
      return 'Tasks';
    } else if (name === 'regAssetUpc') {
      return 'UPC';
    } else if (name === 'regAssetIsrc') {
      return 'ISRC';
    } else if (name === 'regAssetDueDate') {
      return 'Register Asset Due Date';
    } else if (name === 'regAssetStudioInfo') {
      return 'Register Asset Studio Info';
    } else if (name === 'watermarkingWbs') {
      return 'Watermarking WBS';
    } else if (name === 'watermarkingApprovedBy') {
      return 'Watermrking Approved By';
    } else if (name === 'watermarkingJD') {
      return 'Watermarkin Job Description';
    } else if (name === 'audioEditSendRef') {
      return 'Audio Edit Send Reference';
    } else if (name === 'audioEditWbs') {
      return 'Audio Edit WBS';
    } else if (name === 'audioEditDueDate') {
      return 'Audio Edit Due Date';
    } else if (name === 'audioEditJD') {
      return 'Audio Edit Job Description';
    } else if (name === 'masteringSendRef') {
      return 'Mastering Send Reference';
    } else if (name === 'masteringDueDate') {
      return 'Mastering Due Date';
    } else if (name === 'masteringWbs') {
      return 'Mastering WBS';
    } else if (name === 'package_name') {
      return 'Package name';
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  };

  const handleFieldsChange = (fieldName: string, newVal: any) => {
    let trimmedVal = newVal;
    // let formattedValue = newVal;
    switch (fieldName) {
      case 'regAssetDueDate':
      case 'audioEditDueDate':
      case 'masteringDueDate':
        break;
      case 'selectedStudioOption':
        let newArr = [...optionsShareStudio];
        let b: any = [];
        b = newVal.map((e: any) => e.title);
        newArr = newArr.map((element: any) => ({
          ...element,
          isOpen: b.includes(element.title),
        }));
        setoptionsShareStudio(newArr);
        break;
      case 'regAssetUpc':
      case 'upc':
      case 'audio_type':
        trimmedVal = newVal;
        break;
      case 'filename':
        let newFilename = newVal.trimStart();
        trimmedVal = newVal.trimStart();
        setFileName(newFilename);
        break;
      default:
        trimmedVal = newVal.trimStart();
        break;
    }
    // if (fieldName === 'upc') {
    //   trimmedVal = newVal;
    // } else if (fieldName === 'audio_type') {
    // } else if (fieldName === 'filename') {
    //   let newFilename = newVal.trimStart();
    //   trimmedVal = newVal.trimStart();
    //   setFileName(newFilename);
    // } else {
    //   trimmedVal = newVal.trimStart();
    // }

    const updatedFields = { ...inputFields, [fieldName]: trimmedVal };
    setInputFields(updatedFields);
    // Update fileds name which is changed/updated
    const displayName = getFieldName(fieldName);
    if (fieldName !== 'notes' && !changedFieldsName.includes(displayName)) {
      setChangedFieldsName([...changedFieldsName, displayName]);
    }

    const fieldsWithValidation = [
      'filename',
      'artist',
      'title',
      'watermarkingApprovedBy',
      'audioEditSendRef',
      'masteringSendRef'];
    if (fieldsWithValidation.includes(fieldName)) {
      validateFields(fieldName, trimmedVal);
    }
    setDisableSaveBttn(false);
  };

  const handleOptionChange = (e: any, index: number) => {
    let newArr = [...optionsShareStudio];
    const isChecked = e.target.checked;
    newArr[index].isOpen = isChecked;
    const removeOption = inputFields.selectedStudioOption.filter(
      (item: any) => item.title !== e.option
    );
    switch (e.option) {
      case Register:
        setInputFields({
          ...inputFields,
          regAssetUpc: '',
          regAssetIsrc: '',
          regAssetDueDate: '',
          regAssetStudioInfo: '',
          selectedStudioOption: removeOption,
        });
        break;
      case Watermark:
        setInputFields({
          ...inputFields,
          watermarkingWbs: '',
          watermarkingApprovedBy: '',
          watermarkingJD: '',
          selectedStudioOption: removeOption,
        });
        setInputValidate({
          ...inputValidate,
          watermarkingApprovedBy: required,
        });
        break;
      case Audio:
        setInputFields({
          ...inputFields,
          audioEditDueDate: '',
          audioEditJD: '',
          audioEditSendRef: '',
          audioEditWbs: '',
          selectedStudioOption: removeOption,
        });
        setInputValidate({
          ...inputValidate,
          audioEditWbs: required,
          audioEditSendRef: required,
        });
        break;
      case Mastering:
        setInputFields({
          ...inputFields,
          masteringSendRef: '',
          masteringDueDate: '',
          masteringWbs: '',
          selectedStudioOption: removeOption,
        });
        setInputValidate({
          ...inputValidate,
          masteringSendRef: required,
        });
        break;
      default:
        break;
    }
    setoptionsShareStudio(newArr);
    const displayName = getFieldName('selectedStudioOption');
    if (!changedFieldsName.includes(displayName)) {
      setChangedFieldsName([...changedFieldsName, displayName]);
    }
    setDisableSaveBttn(false);
  };

  const handleSave = () => {
    setDisableSaveBttn(true);
    handleSubmit({
      ...inputFields,
      filename: filename + fileExtension,
      fieldsChange: changedFieldsName.join(', '),
      audio_type: inputFields.audio_type.map((option: any) => option.label),
      user_role: (currentUser.isStudioUser && 'studio') || (currentUser.isLibraryUser && 'library')
        || (currentUser.isLabelUser && 'AnR') || (currentUser.isLabelManager && 'AnRManager')
    });
    setTimeout(() => {
      // setNotifyUsersTags([]);
      setInputFields({
        artist: '',
        title: '',
        upc: '',
        isrc: '',
        selectedStudioOption: [],
        regAssetUpc: '',
        regAssetIsrc: '',
        regAssetDueDate: '',
        regAssetStudioInfo: '',
        watermarkingWbs: '',
        watermarkingApprovedBy: '',
        watermarkingJD: '',
        audioEditJD: '',
        audioEditDueDate: '',
        audioEditSendRef: '',
        audioEditWbs: '',
        masteringSendRef: '',
        masteringDueDate: '',
        masteringWbs: '',
        version: '',
        studio: '',
        engineer: '',
        producer: '',
        notes: '',
        audio_type: [],
        package_name: ''
      });
    }, 100);
  };

  const isNotValidForm = !filename || !inputFields.artist || !inputFields.title ||
    // !notifyUsersTags.length ||
    (currentUser.isStudioUser && (!(inputFields.selectedStudioOption.length > 0) ||
      // (optionsShareStudio[0].isOpen && !inputFields.regAssetDueDate) ||
      (optionsShareStudio[1].isOpen &&
        !(
          inputValidate.watermarkingApprovedBy === required &&
          inputFields.watermarkingApprovedBy
        )) ||
      (optionsShareStudio[2].isOpen &&
        !(
          inputValidate.audioEditSendRef === required &&
          inputFields.audioEditSendRef
        )) ||
      // (optionsShareStudio[2].isOpen && !inputFields.audioEditWbs) ||
      (optionsShareStudio[3].isOpen &&
        !(
          inputValidate.masteringSendRef === required &&
          inputFields.masteringSendRef
        )) ||
      (optionsShareStudio[4].isOpen && !inputFields.notes)));
  // inputFields.upc &&
  // !isNaN(parseInt(inputFields.upc)) &&
  // parseInt(inputFields.upc) > 0 &&
  // inputFields.isrc &&
  // !isNaN(parseInt(inputFields.isrc)) &&
  // parseInt(inputFields.isrc) > 0;

  return (
    <Modal
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {Tab === '1' && (
            <Button
              colorType="primary"
              disabled={isNotValidForm || disableSaveBttn}
              onClick={handleSave}
            >
              Save or Update
            </Button>
          )}
        </div>
      }
      onClose={handleClose}
      shouldCloseOnOverlayClick
      title={
        (row.allow_access_to_workspace_users === 'Y' &&
          'Overview (Asset shared with Everyone in workspace)') ||
        'Overview'
      }
      isOpen={isOpen}
      size={'lg'}
    >
      <div>
        <div className="modalBody pr17">
          <Tabs
            mode={'in-page'}
            initialTabId={isFolder ? '2' : '1'}
            list={
              isFolder
                ? [editFileOptions[1], editFileOptions[2]]
                : editFileOptions
            }
            onClick={(clickedItem: ITabItem): void => setTab(clickedItem.id)}
          />
          {!isFolder && Tab === '1' && (
            <EditFileInfoTab
              owner={user_email}
              filename={name}
              fileType={content_type}
              duration={duration}
              status={status}
              inputFields={inputFields}
              inputValidate={inputValidate}
              newFilename={filename}
              handleFieldsChange={handleFieldsChange}
              setFileName={setFileName}
              currentUser={currentUser}
              allow_access_to_workspace_users={allow_access_to_workspace_users}
              assetPermission={assetPermission}
              handleOptionChange={handleOptionChange}
              optionsShareStudio={optionsShareStudio}
              StudioOptions={StudioOptions}
            />
          )}

          {Tab === '2' && (
            <EditFileTab3 activitiesRecords={activitiesRecords} />
          )}

          {Tab === '3' && (
            <EditFileTab2 comment={comments} readComment={readComment} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditFile;

import React from 'react';
import {
  Button,
  Datepicker,
  Input,
  InputGroup,
  Label,
  MultiSelect,
  Textarea,
} from '@wmg-ae/legato';
import { AudioList } from '../../../external/constant';
import { fieldValidation, regexPattern } from '../../helper';

const { required } = fieldValidation;

const EditFileInfoTab: React.FC<any> = ({
  status,
  filename,
  fileType,
  owner,
  duration,
  inputFields,
  inputValidate,
  newFilename,
  handleFieldsChange,
  currentUser,
  allow_access_to_workspace_users,
  assetPermission,
  handleOptionChange,
  optionsShareStudio,
  StudioOptions,
}) => {
  const { Register, Watermark, Audio, Mastering } = StudioOptions;
  const disableFields = assetPermission.access_level === 'view';
  const fileNameDisable = owner !== currentUser.email || !['New', 'Requested Revision'].includes(status) || allow_access_to_workspace_users === 'Y' || disableFields;
  const genericFileType = (fileType: any) => {
    switch (fileType.toLocaleLowerCase()) {
      case 'image/png':
      case 'image / x - icon':
      case 'image/bmp':
      case 'image/jpeg':
        return 'Image';
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'text/html':
      case 'text/plain':
      case 'application/xml':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'Document';
      case 'application/pdf':
        return 'PDF';
      case 'audio/x-wav':
      case 'audio/mp3':
      case 'audio/aiff':
      case 'audio/opus':
      case 'audio/raw':
      case 'audio/wma':
      case 'audio/msv':
      case 'audio/m4p':
      case 'audio/aax':
        return 'Audio';
      case 'video/mp4':
      case 'video/wmv':
      case 'video/amv':
      case 'video/flv':
      case 'video/svi':
        return 'Video';
      case 'mixed':
        return '-';
      case 'application/vnd.dart':
      case 'application/javascript':
      case 'application/x-x509-ca-cert':
      case 'application/x-sh':
      case 'application/rtf':
      case 'application/octet-stream':
      case 'application/x-msdownload':
      case 'application/json':
      default:
        return 'Other';
    }
  };

  return (
    <div>
      <div>
        <div className="row pt20">
          <div className="col-2">
            <div>
              <Label size={'lg'} text="File Type" />
            </div>
          </div>
          <div className="col-2">
            <div className="colgap">
              <Label size={'lg'} text="Duration" />
            </div>
          </div>
          <div className="col-6">
            <div className="colgap">
              <Label size={'lg'} text="Owner" />
            </div>
          </div>
          <div className="col-2">
            <div className="colgap">
              <Label size={'lg'} text="Status" />
            </div>
          </div>
        </div>
        <div className="row pt20">
          <div className="col-2">
            <div>{genericFileType(fileType)}</div>
          </div>
          <div className="col-2">
            <div className="colgap">{duration}</div>
          </div>
          <div className="col-6">
            <div className="colgap">{owner}</div>
          </div>
          <div className="col-2">
            <div className="colgap">
              {/* <Badge colorType={'primary'} text={status} size={'sm'} /> */}
              {status}
            </div>
          </div>
        </div>
        <div className="row pt20">
          <div>
            <InputGroup label="File Name" validation={{ required: true }}>
              <Input
                onChange={(e) => handleFieldsChange('filename', e.target.value)}
                // placeholder="Enter File Name"
                size="md"
                disabled={fileNameDisable}
                value={newFilename}
              />
            </InputGroup>
          </div>
        </div>
        <div className="row pt20">
          <div className="col-6">
            <InputGroup label="Artist" validation={inputValidate.artist}>
              <Input
                onChange={(e) => handleFieldsChange('artist', e.target.value)}
                placeholder="Enter Artist Name"
                size="md"
                value={inputFields.artist}
                disabled={disableFields}
              />
            </InputGroup>
          </div>
          <div className="col-6">
            <InputGroup label="Title" validation={inputValidate.title}>
              <Input
                onChange={(e) => handleFieldsChange('title', e.target.value)}
                placeholder="Enter Title"
                size="md"
                value={inputFields.title}
                disabled={disableFields}
              />
            </InputGroup>
          </div>
        </div>
        {currentUser.isStudioUser && (
          <>
            <div className="row pt20">
              <InputGroup
                label="Select what do you need from studio"
                validation={required}
              >
                <MultiSelect
                  isClearable
                  disabled={false}
                  value={inputFields.selectedStudioOption}
                  list={optionsShareStudio}
                  placeholder="Select at least one"
                  getTitle={(el: any) => el.title}
                  getKey={(el: any) => el.title}
                  // isFilterable={true}
                  onSelect={(key: any) => {
                    handleFieldsChange('selectedStudioOption', key);
                  }}
                />
              </InputGroup>
            </div>
            {/* Register Assets: */}
            {optionsShareStudio[0].isOpen && (
              <div className="sectionWrapper">
                <div className="row">
                  <div className="col-11">
                    <Label size="lg" text="Register Asset(s) | Final Audio" />
                  </div>
                  <div className="col textRight">
                    <Button
                      className="trashBttn"
                      colorType="danger"
                      containerStyle="link"
                      icon="trash-can"
                      size="sm"
                      onClick={() =>
                        handleOptionChange(
                          { target: { checked: false }, option: Register },
                          0
                        )
                      }
                    ></Button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 pt20">
                    <InputGroup label="UPC Number">
                      <Input
                        className="number"
                        maxLength={13}
                        onChange={(e) => {
                          (regexPattern.isDigit.test(e.target.value) ||
                            !e.target.value.length) &&
                            handleFieldsChange('regAssetUpc', e.target.value);
                        }}
                        placeholder="Enter UPC Number"
                        size="md"
                        value={inputFields.regAssetUpc}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-6 pt20">
                    <InputGroup label="ISRC Number">
                      <Input
                        maxLength={13}
                        onChange={(e) =>
                          handleFieldsChange('regAssetIsrc', e.target.value)
                        }
                        placeholder="Enter ISRC Number"
                        size="md"
                        value={inputFields.regAssetIsrc}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-6 pt20">
                    <InputGroup label="Due Date">
                      <Datepicker
                        placeholder="MM/DD/YYYY"
                        containerStyle="outline"
                        onChange={(d: any) =>
                          handleFieldsChange('regAssetDueDate', d)
                        }
                        selected={inputFields.regAssetDueDate}
                        size="md"
                        includeDateIntervals={[
                          {
                            start: new Date(),
                            end: new Date(
                              new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                            ),
                          },
                        ]}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-6 pt20">
                    <InputGroup label="Studio Information">
                      <Input
                        onChange={(e) =>
                          handleFieldsChange('regAssetStudioInfo', e.target.value)
                        }
                        placeholder="Enter studio information"
                        size="md"
                        value={inputFields.regAssetStudioInfo}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            )}

            {/* Watermarking: */}
            {optionsShareStudio[1].isOpen && (
              <div className="sectionWrapper">
                <div className="row">
                  <div className="col-11">
                    <Label size="lg" text="Watermark" />
                  </div>
                  <div className="col textRight">
                    <Button
                      className="trashBttn"
                      colorType="danger"
                      containerStyle="link"
                      icon="trash-can"
                      size="sm"
                      onClick={() =>
                        handleOptionChange(
                          { target: { checked: false }, option: Watermark },
                          1
                        )
                      }
                    ></Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 pt20">
                    <InputGroup label="WBS Number">
                      <Input
                        onChange={(e) =>
                          handleFieldsChange('watermarkingWbs', e.target.value)
                        }
                        placeholder="Enter WBS number"
                        size="md"
                        value={inputFields.watermarkingWbs}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-6 pt20">
                    {' '}
                    <InputGroup
                      label="Approved By"
                      validation={inputValidate.watermarkingApprovedBy}
                    >
                      <Input
                        onChange={(e) =>
                          handleFieldsChange(
                            'watermarkingApprovedBy',
                            e.target.value
                          )
                        }
                        placeholder="Enter approver's email"
                        size="md"
                        value={inputFields.watermarkingApprovedBy}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-12 pt20">
                    <InputGroup label="Job Description">
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          handleFieldsChange('watermarkingJD', e.target.value)
                        }
                        placeholder="Give information to studio about the task"
                        size="md"
                        value={inputFields.watermarkingJD}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            )}

            {/* Audio Edit: */}
            {optionsShareStudio[2].isOpen && (
              <div className="sectionWrapper">
                <div className="row">
                  <div className="col-11">
                    <Label size="lg" text="Audio Edit" />
                  </div>
                  <div className="col textRight">
                    <Button
                      className="trashBttn"
                      colorType="danger"
                      containerStyle="link"
                      icon="trash-can"
                      size="sm"
                      onClick={() =>
                        handleOptionChange(
                          { target: { checked: false }, option: Audio },
                          2
                        )
                      }
                    ></Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 pt20">
                    <InputGroup
                      label="Send Reference to"
                      validation={inputValidate.audioEditSendRef}
                    >
                      <Input
                        onChange={(e) =>
                          handleFieldsChange('audioEditSendRef', e.target.value)
                        }
                        placeholder="Enter email"
                        size="md"
                        value={inputFields.audioEditSendRef}
                      />
                    </InputGroup>
                  </div>

                  <div className="col-6 pt20">
                    <InputGroup label="WBS#" validation={undefined}>
                      <Input
                        onChange={(e) =>
                          handleFieldsChange('audioEditWbs', e.target.value)
                        }
                        placeholder="Enter WBS number for audio edit"
                        size="md"
                        value={inputFields.audioEditWbs}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-6 pt20">
                    <InputGroup label="Due Date">
                      <Datepicker
                        placeholder="MM/DD/YYYY"
                        containerStyle="outline"
                        onChange={(d: any) =>
                          handleFieldsChange('audioEditDueDate', d)
                        }
                        selected={inputFields.audioEditDueDate}
                        size="md"
                        includeDateIntervals={[
                          {
                            start: new Date(),
                            end: new Date(
                              new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                            ),
                          },
                        ]}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-6 pt20">
                    <InputGroup label="Job Description">
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          handleFieldsChange('audioEditJD', e.target.value)
                        }
                        placeholder="Enter your job description"
                        size="md"
                        value={inputFields.audioEditJD}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            )}

            {/* Mastering: */}
            {optionsShareStudio[3].isOpen && (
              <div className="sectionWrapper">
                <div className="row">
                  <div className="col-11">
                    <Label size="lg" text="Mastering" />
                  </div>
                  <div className="col textRight">
                    <Button
                      className="trashBttn"
                      colorType="danger"
                      containerStyle="link"
                      icon="trash-can"
                      size="sm"
                      onClick={() =>
                        handleOptionChange(
                          { target: { checked: false }, option: Mastering },
                          3
                        )
                      }
                    ></Button>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-6 pt20">
                    <InputGroup
                      label="Send Reference To"
                      validation={inputValidate.masteringSendRef}
                    >
                      <Input
                        onChange={(e) =>
                          handleFieldsChange('masteringSendRef', e.target.value)
                        }
                        placeholder="Enter email"
                        size="md"
                        value={inputFields.masteringSendRef}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-6 pt20">
                    <InputGroup label="Due Date">
                      <Datepicker
                        containerStyle="outline"
                        placeholder="MM/DD/YYYY"
                        onChange={(d: any) =>
                          handleFieldsChange('masteringDueDate', d)
                        }
                        selected={inputFields.masteringDueDate}
                        size="md"
                        includeDateIntervals={[
                          {
                            start: new Date(),
                            end: new Date(
                              new Date().setMonth(new Date().getMonth() + 3) // After 3 months
                            ),
                          },
                        ]}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-12 pt20">
                    <InputGroup label="WBS#">
                      <Input
                        onChange={(e) =>
                          handleFieldsChange('masteringWbs', e.target.value)
                        }
                        placeholder="Enter WBS number for mastering"
                        size="md"
                        value={inputFields.masteringWbs}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            )}</>)}
        {!currentUser.isStudioUser && (
          <>
            <div className="row pt20">
              <div className="col-6">
                <InputGroup label="UPC">
                  <Input
                    maxLength={13}
                    onChange={(e) => {
                      (regexPattern.isDigit.test(e.target.value) ||
                        !e.target.value.length) &&
                        handleFieldsChange('upc', e.target.value);
                    }}
                    placeholder="Enter UPC Number"
                    size="md"
                    value={inputFields.upc}
                    disabled={disableFields}
                  />
                </InputGroup>
              </div>
              <div className="col-6">
                <InputGroup label="ISRC">
                  <Input
                    maxLength={13}
                    onChange={(e) => handleFieldsChange('isrc', e.target.value)}
                    placeholder="Enter ISRC Number"
                    size="md"
                    value={inputFields.isrc}
                    disabled={disableFields}
                  />
                </InputGroup>
              </div>
            </div>
          </>)}
        <div className="row pt20">
          <div className="col-6">
            <InputGroup label="Version">
              <Input
                onChange={(e) => handleFieldsChange('version', e.target.value)}
                placeholder="Enter Version Number"
                size="md"
                value={inputFields.version}
                disabled={disableFields}
              />
            </InputGroup>
          </div>
          <div className="col-6">
            <InputGroup
              label="Audio Type"
              // validation={required}
              validationIconPlacement="label"
              icon="error"
              className="test1 col"
            >
              <MultiSelect
                value={inputFields.audio_type}
                list={AudioList}
                placeholder="Select Audio Type"
                getTitle={(el: any) => el.label}
                getKey={(el: any) => el.label}
                // isFilterable={true}
                onSelect={(key: any) => {
                  handleFieldsChange('audio_type', key);
                }}
                disabled={disableFields}
              />
            </InputGroup>
          </div>
        </div>
        <div className="row pt20">
          <div className="col-6">
            <InputGroup label="Studio">
              <Input
                onChange={(e) => handleFieldsChange('studio', e.target.value)}
                placeholder="Enter Studio Name"
                size="md"
                value={inputFields.studio}
                disabled={disableFields}
              />
            </InputGroup>
          </div>
          <div className="col-6">
            <InputGroup label="Engineer">
              <Input
                onChange={(e) => handleFieldsChange('engineer', e.target.value)}
                placeholder="Enter Engineer Name"
                size="md"
                value={inputFields.engineer}
                disabled={disableFields}
              />
            </InputGroup>
          </div>
        </div>
      </div><div className="row pt20">
        <div className="col">
          <InputGroup label="Package Name">
            <Input
              onChange={(e) => handleFieldsChange('package_name', e.target.value)}
              placeholder="Enter Package Name"
              size="md"
              value={inputFields.package_name}
              disabled={disableFields}
            />
          </InputGroup>
        </div></div>
      <div className="row pt20">
        <div className="col">
          <InputGroup label="Producer">
            <Input
              onChange={(e) => handleFieldsChange('producer', e.target.value)}
              placeholder="Enter Producer Name"
              size="md"
              value={inputFields.producer}
              disabled={disableFields}
            />
          </InputGroup>
        </div>
      </div>
      <div className="row pt20">
        <div className="col">
          <InputGroup label="Notes" validation={currentUser.isStudioUser && optionsShareStudio[4].isOpen ? required : {}}>
            <Textarea
              onChange={(e) => handleFieldsChange('notes', e.target.value)}
              placeholder="Enter Notes"
              size="md"
              value={inputFields.notes}
              disabled={disableFields}
            />
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

export default EditFileInfoTab;

import { Button, Modal } from '@wmg-ae/legato';
import React from 'react';
import AsperaLogo from '../../../../assets/asperaLogo.svg';

const AsperaConnect: React.FC<any> = ({ isOpen, handleClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      title={'Download Aspera Connect'}
      onClose={handleClose}
      size={'lg'}
    >
      <div className="modalAsBody">
        <p className="pText16">
          Please install the following in order to access all functionality of
          this application.
        </p>
        <div className="container-sm">
          <div className="row bg-primary-tint">
            <div className="col-1">
              <div className="imgContainer">
                <img src={AsperaLogo} alt="Aspera" />
              </div>
            </div>
            <div className="col-7">Aspera Connect</div>
            <div className="col marginAsperaButton">
              <Button colorType="primary" containerStyle={'outline'}>
                Install Aspera Connect
              </Button>
            </div>
          </div>
          <div className="big-ui">
            <hr className="dividing-line" />
          </div>
          <div className="row bg-primary-tint">
            <div className="col-1">
              <div className="imgContainer">
                <img src={AsperaLogo} alt="Aspera" />
              </div>
            </div>
            <div className="col-7">Aspera Connect Browser Extension</div>
            <div className="col marginAsperaEButton">
              <Button colorType="primary" containerStyle={'outline'}>
                Install Browser Extension
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AsperaConnect;

/* eslint-disable no-loop-func */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import UserProfile from '../../../assets/asperaLogo.svg';
import blackWMGlogo from '../../../assets/blackWMGlogo.svg';
import { sort_by_key } from '../helper';
const baseURL = process.env.REACT_APP_API_ENDPOINT;
const apiKey = { 'x-api-key': process.env.REACT_APP_GATEWAY_KEY || '' };
const ApiURL = {
  Logo: blackWMGlogo,
  Title: 'WMG Transfers',
  AnRToken: `${baseURL}/aoc-token?email=`,
  nodeToken: `${baseURL}/node-token?`,
  uploadTransferSpec: `${baseURL}/transfer-spec?type=send&`,
  downloadTransferSpec: `${baseURL}/transfer-spec?type=receive&`,
  workspaceTiles: `${baseURL}/libraryuserhomepage/workspaces`,
  AnRDetails: `${baseURL}/workspace`,
  AnRDashboard: `${baseURL}/asset-metadata?`,
  readNotification: `${baseURL}/notification?email=`,
  writeNotification: `${baseURL}/notification`,
  markNotificationRead: `${baseURL}/notification/msg?email=`,
  ReqLinkSubmit: `${baseURL}/external-link`,
  sendReqEmail: `${baseURL}/email-service`,
  DeleteLink: `${baseURL}/asset-operations`,
  createFolder: `${baseURL}/asset-operations/create`,
  MyLinks: `${baseURL}/external-link/links?owner_email=`,
  shareAssets: `${baseURL}/asset-operations`,
  vaultAsset: `${baseURL}/move-to-isilon`,
  moveFolder: `${baseURL}/transfer-spec/move-to-folder`,
  DelReqLink: `${baseURL}/external-link/link?id=`,
  TerminateReqLink: `${baseURL}//terminate-link?`,
  EmailList: 'https://api.ibmaspera.com/api/v1/core/contacts?',
  InsertUpload: `${baseURL}/asset-metadata`,
  EditComments: `${baseURL}/comments?contentid=`,
  PostComments: `${baseURL}/comments`,
  getActivities: `${baseURL}/contentactivity?contentid=`,
  postInviteCollaborators: `${baseURL}/collaborators`,
  getContentDetails: `${baseURL}/asset-metadata/content?content_id=`,
  updateContentDetails: `${baseURL}/asset-metadata/content`,
  approvePendingFile: `${baseURL}/change_status`,
  downloadPermissionLink: `${baseURL}/set-permissions`,
  profileLink: `${baseURL}/notification-setting`,
  assetRegister: `${baseURL}/asset_registration`,
  checkTransferStatus: `${baseURL}/check_transfer_status`,
  endTransfer: `${baseURL}/end_transfer`,
  insertPackage: `${baseURL}/insert-package-metadata`,
  assetRename: `${baseURL}/rename_asset`,
  getAssetPermission: `${baseURL}/asset_permission`,
  externalShareOperation: `${baseURL}/external-link`,
  genratePackageLink: `${baseURL}/package-link`,
  externalShareAsset: `${baseURL}/external_asset_sharing`,
  deleteCid: `${baseURL}/remove-asset`,
  ReqLink: `${baseURL}/external-link/`,
};

const alertType = { DANGER: 'danger', SUCCESS: 'success', WARNING: 'warning' };
const alertTitle = {
  REQ_ASSETS_SUCCESS: 'Asset Upload link sent successfully !',
  REQ_ASSETS_FAILED: 'Asset Upload link failed to be sent!',
  REQ_ASSETS_EMAIL_FAILED: 'Failed to resend link!',
  UPLOAD_SUCCESS: (count: any) => `${count} items uploaded successfully!`,
  EV_UPLOAD_SUCCESS: (count: any) =>
    `${count} asset uploaded and shared with everyone successfully!`,
  UPLOAD_FAILED: (count: any) => `${count} items failed to be uploaded!`,
  SHARE_LIB_SUCCESS: 'File shared with library successfully !',
  SHARE_LIB_FAILED: 'Failed to share with Library, try again later !',
  SHARE_STUDIO_SUCCESS: 'File shared with Studio successfully !',
  SHARE_STUDIO_FAILED: 'File failed to be shared with studio',
  DELETE_SUCCESS: 'File deleted successfully !',
  DELETE_FAILED: 'Failed to delete, try again later !',
  MY_LINK_COPIED_S: 'Link has been copied successfully',
  MY_LINK_RESENT_S: 'Link has been resent successfully',
  My_LINK_ACTIVE_S: 'Link has been activated successfully',
  MY_LINK_DELETE_S: 'Link has been deleted successfully',
  MY_LINK_TERMINATE_S: 'Link has been terminated successfully',
  MY_LINK_FAILURE: 'Please try again later',
  ACCOUNT_SUCCESS: 'Account Settings saved successfully!',
  ACCOUNT_FAILURE: 'Failed to save Account Settings!',
  CREATE_FOLDER_SUCCESS: (name: string) =>
    `Your Folder (${name}) was created and automatically selected.`,
  CREATE_FOLDER_FAILED: (name: string) =>
    `Your Folder (${name}) couldn't be created.`,
  MOVE_FOLDER_SUCCESS: (src: string, target: string) =>
    `${src} moved to ${target} successfully.`,
  MOVE_FOLDER_FAILED: (src: string, target: string) =>
    `${src} failed to move to ${target}!`,
  COLLABORATOR_SUCCESS: 'Invite sent successfully!',
  COLLABORATOR_FAILED: 'Failed to send invite!',
  UPDATE_CONTENT_SUCCESS: 'Content details updated successfully !',
  UPDATE_CONTENT_FAILED: 'Failed to update content details, try again later !',
  VAULT_SUCCESS: (name: string) => `${name} vaulted successfully`,
  VAULT_FAILED: (name: string) => `${name} failed to be vaulted`,
  Approve_Success: (name: string) => `${name} have been approved successfully!`,
  Approve_Failure: (name: string) => `Failed to approve ${name}!`,
  Decline_Studio_Success: (name: string) =>
    `${name} shared to studio successfully!`,
  Decline_Studio_Failure: (name: string) =>
    `Failed to share ${name} to studio!`,
  Decline_RequestRevision_Success: (name: string) =>
    `Revision requested for ${name} successfully!`,
  Decline_RequestRevision_Failure: (name: string) =>
    `Failed to sent ${name} for revision!`,
  SendForApproval_Success: (name: string) =>
    `${name} file has been sent to A&R for Review successfully!`,
  SendForApproval_Failure: (name: string) =>
    `Failed to sent ${name} to A&R for review!`,
  DeclineAssetRegistration_Success: (name: string) =>
    `${name} register request declined and sent to A&R successfully!`,
  DeclineAssetRegistration_Failure: (name: string) =>
    `Failed to decline register request for ${name}!`,
  REGISTER_SUCCESS: (name: string) =>
    `${name} has been registered successfully!`,
  REGISTER_FAILED: (name: string) => `Failed to be register ${name}! `,
  SHARE_EXTERNAL_SUCCESS: (name: string, to: string) =>
    `${name} has been shared to ${to} successfully!`,
  SHARE_EXTERNAL_FAILED: (name: string) => `Failed to share ${name}!`,
};

export interface AnRStore {
  comments: any[];
  activitiesRecords: any[];
  token: string;
  currentUser: any;
  notifications: any[];
  breadcrumbList: any[];
  id: string;
  labelLogo: string;
  labelTitle: string;
  dashboardRecords: any[];
  isDBLoading: boolean;
  workspaceList: any[];
  alertDetails: any;
  workspace: any;
  nodeToken: string;
  upload_transfer_spec: any;
  fileFilterKey: string;
  download_transfer_spec: any;
  myLinkRecords: any;
  emailList: any;
  userIdList: any;
  homeId: string;
  createFolderMsg: any;
  contentInfoDetails: any;
  validWorkspace: any;
  profileSettings: any;
  assetPermission: any;
  transferOpen: any;
  downloadCID: any[];
  uploadCount: number;
}

const initialState: AnRStore = {
  token: '',
  currentUser: { email: '', fullName: '', givenName: '' },
  notifications: [],
  breadcrumbList: [
    { key: 1, title: 'Assets', cid: 0, allow_access_to_workspace_users: 'Y' },
  ], // Default for root level
  id: '1',
  labelLogo: '',
  labelTitle: '',
  dashboardRecords: [],
  isDBLoading: false,
  workspaceList: [],
  alertDetails: { show: false, type: '', title: '' },
  workspace: {
    user_id: '',
    home_file_id: '',
    home_node_id: '',
    workspace_id: '',
  },
  nodeToken: '',
  upload_transfer_spec: {},
  fileFilterKey: '',
  download_transfer_spec: {},
  myLinkRecords: [],
  emailList: [],
  userIdList: [],
  comments: [],
  activitiesRecords: [],
  homeId: '',
  createFolderMsg: { title: '', icon: '' },
  contentInfoDetails: {},
  validWorkspace: true,
  profileSettings: {
    invited_to_workspace: 'y',
    received_package_deleted: 'y',
    invited_shared_inbox: 'y',
  },
  assetPermission: {},
  transferOpen: { isOpen: false, action: '' },
  downloadCID: [],
  uploadCount: 0,
};

export const { actions, reducer } = createSlice({
  name: 'dashboard reducer',
  initialState,
  reducers: {
    setToken: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.token = payload;
    },
    setNodeToken: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.nodeToken = payload;
    },
    setEmailList: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.emailList = payload.map((email: any) => ({ label: email }));
    },
    setuserIdList: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.userIdList = payload.map((userId: any) => userId);
    },
    updateCurrentUser: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
    },
    changeTab: (state: AnRStore, { payload }: PayloadAction<string>) => {
      state.id = payload;
    },
    addLabel: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.labelLogo = payload.logo;
      state.labelTitle = payload.title;
    },
    addWorkspace: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.workspace = { ...payload };
    },
    setUploadTransferSpec: (
      state: AnRStore,
      { payload }: PayloadAction<any>
    ) => {
      state.upload_transfer_spec = { ...payload };
      // state.upload_transfer_spec.token = `Bearer ${state.nodeToken}`;
    },
    setDownloadTransferSpec: (
      state: AnRStore,
      { payload }: PayloadAction<any>
    ) => {
      state.download_transfer_spec = { ...payload };
      // state.upload_transfer_spec.token = `Bearer ${state.nodeToken}`;
    },
    setDBLoadingFlag: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.isDBLoading = payload;
    },
    loadDbRecords: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.dashboardRecords = payload.records;
      // console.log(payload.cid, payload.navId);
      // if (payload.records.length) {
      if (!payload.browserBack && payload.navId) {
        // Breadcrumb Click/Folder Jump
        state.breadcrumbList = state.breadcrumbList.slice(0, payload.navId);
      } else if (!payload.browserBack && payload.cid) {
        // Folder Click from Dashboard Table
        state.breadcrumbList = [
          ...state.breadcrumbList,
          {
            key: state.breadcrumbList.length + 1,
            title: payload.folder,
            cid: payload.cid,
            allow_access_to_workspace_users:
              payload.allow_access_to_workspace_users,
          },
        ];
      } else if (payload.cid === 0) {
        state.breadcrumbList = [
          {
            key: 1,
            title: 'Assets',
            cid: 0,
            allow_access_to_workspace_users: 'Y',
          },
        ];
      }
      if (payload.browserBack) {
        state.breadcrumbList = [
          ...payload.backBread,
          {
            key: payload.backBread.length + 1,
            title: payload.folder,
            cid: payload.cid,
            allow_access_to_workspace_users:
              payload.allow_access_to_workspace_users,
          },
        ];
      }
      state.transferOpen = { isOpen: false, action: '' };
      // else {
      //   // Loading on root level/Page Load etc.
      //   state.breadcrumbList = [{ key: 1, title: 'Assets', cid: 0 }];
      // }
      // }
    },
    loadWorkspaceTiles: (state: AnRStore, { payload }: PayloadAction<any>) => {
      const { data, count } = payload;
      state.workspaceList = data.map((wsTile: any) => ({
        name: wsTile.name,
        home_file_id: wsTile.home_file_id,
        home_node_id: wsTile.home_node_id,
        workspace_id: wsTile.id,
        logo: wsTile.image_url,
        badgeCount: parseInt(count[wsTile.id] || '0'),
        user_id: wsTile.user_id,
      }));
    },
    loadMyLinks: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.myLinkRecords = payload.data;
    },
    toggleAlert: (state: AnRStore, { payload }: PayloadAction<any>) => {
      const { show, type, title } = payload;
      state.alertDetails = { show, type, title };
    },
    changeFilterKey: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.fileFilterKey = payload;
    },
    loadNotification: (state: AnRStore, { payload }: PayloadAction<any>) => {
      const newRecords = payload.map((alert: any) => {
        return {
          title: alert.user_name, // User Name to display
          msg: alert.notification,
          action: alert.action,
          // time: getTimeLapse(alert.created_date),
          timestamp: alert.created_date,
          messageid: alert.notificationid,
        };
      });
      state.notifications = newRecords;
    },

    loadComment: (state: AnRStore, { payload }: PayloadAction<any>) => {
      const newRecords = payload.map((comment: any, idx: any) => {
        return {
          username: `Comments added by ${comment.user_name}`,
          userProfile: UserProfile,
          key: `${idx + 1}`,
          comment: comment.comment,
          created_date: comment.created_date,
        };
      });
      state.comments = newRecords;
    },

    loadActivity: (state: AnRStore, { payload }: PayloadAction<any>) => {
      const newRecords = payload.map((activity: any, idx: any) => {
        return {
          key: `${idx + 1}`,
          title_name: activity.user_name,
          showtime: activity.created_date,
          fileitem: activity.notification,
          fileicon: activity.fileicon,
          action: activity.action,
          fieldsChanged: activity.fields_changed,
        };
      });
      state.activitiesRecords = newRecords;
    },
    changeHomeFileId: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.homeId = payload;
    },
    createFolderAlert: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.createFolderMsg = payload;
    },
    loadContentDetails: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.contentInfoDetails = payload;
    },
    setValidWorkspace: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.validWorkspace = payload;
    },
    setprofileSettings: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.profileSettings = payload;
    },
    setAssetPermission: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.assetPermission = payload;
    },
    rootBreadCrumList: (state: AnRStore) => {
      state.breadcrumbList = [
        {
          key: 1,
          title: 'Assets',
          cid: 0,
          allow_access_to_workspace_users: 'Y',
        },
      ];
    },
    setTransferOpen: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.transferOpen = payload;
    },
    setDownloadCID: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.downloadCID = [...state.downloadCID, payload];
    },
    removeDownloadCID: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.downloadCID = state.downloadCID.filter(
        (item: any) => item !== payload
      );
    },
    setUploadCount: (state: AnRStore, { payload }: PayloadAction<any>) => {
      state.uploadCount = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setToken,
  updateCurrentUser,
  changeTab,
  addLabel,
  loadDbRecords,
  setDBLoadingFlag,
  loadWorkspaceTiles,
  toggleAlert,
  addWorkspace,
  setNodeToken,
  setUploadTransferSpec,
  changeFilterKey,
  setDownloadTransferSpec,
  loadMyLinks,
  loadNotification,
  loadComment,
  loadActivity,
  setEmailList,
  setuserIdList,
  changeHomeFileId,
  createFolderAlert,
  loadContentDetails,
  setValidWorkspace,
  rootBreadCrumList,
  setprofileSettings,
  setAssetPermission,
  setTransferOpen,
  setDownloadCID,
  removeDownloadCID,
  setUploadCount,
} = actions;

export const getQueryEmails =
  (query: any, appId: any) => (dispatch: any, getState: any) => {
    const { token } = getState().dashboard;
    const config = {
      method: 'get',
      url:
        ApiURL.EmailList +
        `?embed[]=source&sort=score&aspera_app_type=packages&aspera_app_id=${appId}&include_only_user_personal_contacts=false&context=send_package&per_page=10000&q=${query}`,
      headers: { Authorization: `Bearer ${token}` },
    };
    axios(config)
      .then(({ data }): any => {
        const emails = data
          .map((d: any) => d.email)
          .filter((d: any) => d !== undefined);
        dispatch(setEmailList(emails));
        const userIds = data.filter((d: any) => d !== undefined); // TODO
        dispatch(setuserIdList(userIds));
        // if(emails && emails.length)
        //      {getQueryEmails('');}
      })
      .catch((error: any) => {
        // console.log('handleRequestLink API Error.... ', error);
        // const title = alertTitle.REQ_ASSETS_FAILED;
        // dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
      });
  };

export const getToken = (email: string) => async (dispatch: any) => {
  const config = {
    method: 'get',
    url: ApiURL.AnRToken + email,
    headers: apiKey,
  };

  axios(config)
    .then(({ data }): any => {
      dispatch(setToken(data.access_token));
      // setTimeout(() => dispatch(getQueryEmails('')));
    })
    .catch((error: any) => {
      dispatch(setToken(''));
    });
};

export const generateTransferSpec =
  (nodeToken: any) => (dispatch: any, getState: any) => {
    dispatch(downloadPermission(nodeToken));
    dispatch(getProfile());
    const { workspace, token } = getState().dashboard;
    const { user_id, home_file_id, home_node_id, workspace_id } = workspace;

    const uploadConfig = {
      method: 'get',
      url: `${ApiURL.uploadTransferSpec}user_id=${user_id}&home_node_id=${home_node_id}&home_file_id=${home_file_id}&workspace_id=${workspace_id}`,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    axios(uploadConfig).then(({ data }): any => {
      dispatch(setUploadTransferSpec(data));
    });

    const downloadConfig = {
      method: 'get',
      url: `${ApiURL.downloadTransferSpec}user_id=${user_id}&home_node_id=${home_node_id}&home_file_id=${home_file_id}&workspace_id=${workspace_id}`,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    axios(downloadConfig).then(({ data }): any => {
      dispatch(setDownloadTransferSpec(data));
    });
  };

export const getNodeToken = () => (dispatch: any, getState: any) => {
  const { workspace, currentUser, token } = getState().dashboard;

  // const role = currentUser.roles[0];
  // const roleSplit = role.split('_');
  // const roleName = roleSplit[roleSplit.length - 1]; // GET ROLE NAME DIRECTLY FROM REDUX STORE
  let isadmin = '';
  // if (roleName === 'LabelManager') {
  //   isadmin = 'admin';
  // }
  const config = {
    method: 'get',
    url: `${ApiURL.nodeToken}email=${currentUser.email}&home_node_id=${workspace.home_node_id}&role=${isadmin}`,
    headers: { 'x-amz-security-token': token, ...apiKey },
  };
  axios(config)
    .then(({ data }): any => {
      dispatch(setNodeToken(data.access_token));
      dispatch(generateTransferSpec(data.access_token));
    })
    .catch((error): any => {
      console.log('Error generating Node Token:', error);
    });
};

export const loadSelectedWorkspace = (workspace: any) => (dispatch: any) => {
  // dispatch(addWorkspace({ user_id, home_file_id, home_node_id, workspace_id }));
  dispatch(addWorkspace(workspace));
  if (workspace.user_id) {
    setTimeout(() => {
      dispatch(getNodeToken());
      dispatch(getQueryEmails('wmg', workspace.appId));
    }, 10);
  }
};

export const readNotification = () => (dispatch: any, getState: any) => {
  const { currentUser } = getState().dashboard;
  const config = {
    method: 'get',
    url: ApiURL.readNotification + currentUser.email,
    headers: apiKey,
  };

  axios(config)
    .then(({ data }): any => {
      dispatch(loadNotification(data.data));
    })
    .catch((error: any) => {
      console.log('readNotification API Error....', error);
    });
};

export const createNotification =
  (notification: any) => (dispatch: any, getState: any) => {
    const { currentUser, workspace } = getState().dashboard;
    const config = {
      method: 'post',
      url: ApiURL.writeNotification,
      data: {
        email_ids: [currentUser.email],
        user_image: '',
        user_name: currentUser.fullName,
        msg_body: notification.msg,
        fields_changed: notification.fieldsChange || '',
        content_id: notification.content_id,
        user_id: workspace.user_id,
        action: notification.action,
      },
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => {
        setTimeout(() => {
          dispatch(readNotification());
        }, 100);
      })
      .catch((error: any) => {
        console.log('createNotification API Error....', error);
      });
  };

export const createEmailNotification =
  (notification: any) => (dispatch: any, getState: any) => {
    const config = {
      method: 'post',
      url: ApiURL.writeNotification,
      data: {
        email_ids: notification.email,
        user_image: '',
        user_name: notification.fullName,
        msg_body: notification.msg,
        fields_changed: notification.fieldsChange || '',
        content_id: notification.content_id,
        user_id: notification.user_id,
        action: notification.action,
      },
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => {
        setTimeout(() => {
          dispatch(readNotification());
        }, 100);
      })
      .catch((error: any) => {
        console.log('createNotification API Error....', error);
      });
  };

export const markAsRead = (id: any) => (dispatch: any, getState: any) => {
  const { currentUser } = getState().dashboard;
  const config = {
    method: 'post',
    url:
      ApiURL.markNotificationRead + currentUser.email + '&notificationid=' + id,
    headers: apiKey,
  };
  axios(config)
    .then(({ data }): any => {
      setTimeout(() => {
        dispatch(readNotification());
      }, 100);
    })
    .catch((err) => {
      console.log('Error marking notification as read => ', err);
    });
};

// export const getWorkspaceTiles = (currUser: any) => (dispatch: any) => {
//   let params = '';
//   if (currUser.isLibraryUser) {
//     params = '?status=In Library';
//   }
//   if (currUser.isStudioUser) {
//     params = '?status=In Studio';
//   }
//   const config = {
//     method: 'get',
//     url: ApiURL.workspaceTiles + params,
//     headers: apiKey,
//   };

//   axios(config)
//     .then(({ data }): any => {
//       dispatch(setValidWorkspace(false));
//       dispatch(loadWorkspaceTiles(data.data));
//     })
//     .catch((error: any) => {
//       console.log('getWorkspaceTiles === ', error);
//     });
// };

export const getWorkspaceTiles =
  (currUser: any) => (dispatch: any, getState: any) => {
    let params = '';
    if (currUser.isLibraryUser) {
      params = '?status=In Library';
    }
    if (currUser.isStudioUser) {
      params = '?status=In Studio';
    }
    const { token } = getState().dashboard;
    const config = {
      method: 'get',
      url: ApiURL.AnRDetails,
      headers: { 'X-Amz-Security-Token': token, ...apiKey },
    };

    axios(config)
      .then(({ data }): any => {
        dispatch(getProfile(data[0].user_id));
        dispatch(setValidWorkspace(false));

        const config = {
          method: 'get',
          url: ApiURL.workspaceTiles + params,
          headers: apiKey,
        };

        axios(config)
          .then((response): any => {
            let countObject = {} as any;
            response.data.data.map(
              (item: any) =>
                (countObject[item.workspace_id.toString()] = item.count)
            );
            dispatch(loadWorkspaceTiles({ data: data.filter((item: any)=> item.member === true), count: countObject }));
          })
          .catch((error: any) => {
            console.log('getWorkspaceTilesCount === ', error);
          });
      })
      .catch((error: any) => {
        console.log('getWorkspaceTiles === ', error);
      });
  };
export const getDashboardRecords =
  (details: any) => (dispatch: any, getState: any) => {
    const { isDBLoading, currentUser, workspace } = getState().dashboard;
    if (!isDBLoading) {
      // Prevent hitting multiple API when already in progress
      dispatch(setDBLoadingFlag(true));

      const {
        cid,
        name,
        navId,
        browserBack,
        backBread,
        allow_access_to_workspace_users,
      } = details;
      const isRootLevel = !cid;
      const roleName = currentUser.roleName[0];

      let newParams = `role=${roleName}&workspace_id=${workspace.workspace_id}&type=`;
      newParams = isRootLevel
        ? `${newParams}root&home_file_id=${workspace.home_file_id}`
        : `${newParams}connect&content_id=${cid}`;

      if (roleName === 'LabelUser') {
        newParams = `${newParams}&user_email=${currentUser.email}`;
      }
      // workspace_id now need to send always
      // if (roleName === 'LabelManager') {
      //   newParams = `${newParams}&workspace_id=${workspace.workspace_id}`;
      // }
      const config = {
        method: 'get',
        url: ApiURL.AnRDashboard + newParams,
        headers: apiKey,
      };
      axios(config)
        .then(({ data }): any => {
          if (!isRootLevel) {
            dispatch(changeHomeFileId(cid.toString()));
          } else {
            dispatch(changeHomeFileId(workspace.home_file_id));
          }
          dispatch(readNotification());
          dispatch(
            loadDbRecords({
              cid,
              folder: name,
              navId,
              allow_access_to_workspace_users,
              records: sort_by_key(data.data, 'last_updated_timestamp'),
              browserBack,
              backBread,
            })
          );
          dispatch(setDBLoadingFlag(false));
        })
        .catch((error: any) => {
          console.log('getDashboardRecords API Error....', error);
          dispatch(setDBLoadingFlag(false));
          dispatch(setTransferOpen({ isOpen: false, action: '' }));
        });
    }
  };

export const getAnRLabelData = () => (dispatch: any, getState: any) => {
  const { token } = getState().dashboard;
  let defaultResp = { logo: ApiURL.Logo, title: ApiURL.Title };
  const config = {
    method: 'get',
    url: ApiURL.AnRDetails,
    headers: { 'X-Amz-Security-Token': token, ...apiKey },
  };

  axios(config)
    .then(({ data }: any) => {
      if (data && !data.code && data.length) {
        // Assigning 2nd workspace if available by default "WEA SUN TEST"

        data = data.filter((item: any) => item.member === true);
        
        const x = data.filter((item: any) => {
          return item.id === '73462';
          // return item.id === '86138';
        });
        let respData = data.length > 1 ? data[1] : data[0];
        if (x && x.length) {
          respData = x[0];
        }
        let {
          user_id,
          home_file_id,
          home_node_id,
          name,
          image_url,
          id,
          files_app,
        } = respData;
        dispatch(
          loadSelectedWorkspace({
            user_id,
            home_file_id,
            home_node_id,
            workspace_id: id,
            appId: files_app.id,
          })
        );
        setTimeout(() =>
          dispatch(
            getDashboardRecords({
              cid: 0,
              allow_access_to_workspace_users: 'Y',
            })
          )
        );
        defaultResp = { logo: image_url, title: name };
      }
      dispatch(addLabel(defaultResp));
    })
    .catch((error) => {
      dispatch(addLabel(defaultResp));
    });
};
export const getLibStudioData =
  (data: any) => (dispatch: any, getState: any) => {
    const { token } = getState().dashboard;
    const { name, workspace_id } = data;
    let defaultResp = { logo: ApiURL.Logo, title: name };
    const config = {
      method: 'get',
      url: ApiURL.AnRDetails,
      headers: { 'X-Amz-Security-Token': token, ...apiKey },
    };

    axios(config)
      .then(({ data }: any) => {
        if (data && !data.code && data.length) {
          const respData = data.filter(
            (option: any) => option.id === workspace_id.toString()
          );
          if (respData && respData.length) {
            let {
              user_id,
              home_file_id,
              home_node_id,
              name,
              image_url,
              id,
              files_app,
            } = respData[0];
            const workspace = {
              user_id,
              home_file_id,
              home_node_id,
              workspace_id: id,
              appId: files_app.id,
            };
            dispatch(loadSelectedWorkspace(workspace));
            dispatch(setValidWorkspace(true));
            setTimeout(() =>
              dispatch(
                getDashboardRecords({
                  cid: 0,
                  allow_access_to_workspace_users: 'Y',
                })
              )
            );
            defaultResp = { logo: image_url, title: name };
          } else {
            dispatch(
              loadDbRecords({
                records: [],
                cid: 0,
                allow_access_to_workspace_users: 'Y',
              })
            );
            dispatch(setValidWorkspace(false));
            dispatch(rootBreadCrumList);
          }
        }
        dispatch(addLabel(defaultResp));
      })
      .catch((error) => {
        dispatch(addLabel(defaultResp));
        dispatch(setTransferOpen({ isOpen: false, action: '' }));
      });
  };

export const updateLoggedInUser = (currUser: any) => (dispatch: any) => {
  dispatch(updateCurrentUser(currUser));
  // // Load Workspace Tiles for Library and Studio User
  // if (currUser.isLibraryUser || currUser.isStudioUser) {
  //   dispatch(getWorkspaceTiles(currUser));
  // }
};

export const getMyLinks =
  (link_type: any) => (dispatch: any, getState: any) => {
    const { currentUser, workspace } = getState().dashboard;
    const config = {
      method: 'get',
      url: ApiURL.MyLinks + currentUser.email + `&link_type=${link_type}`,
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => {
        const workspaceLinks = data.data.filter(
          (link: any) => link.workspace_id.toString() === workspace.workspace_id
        );
        dispatch(loadMyLinks({ data: workspaceLinks }));
      })
      .catch((error): any => {
        console.log('getMyLinks API error =>> ', error);
      });
  };

export const sendRequestAssetMail =
  (params: any, resent: boolean) => (dispatch: any) => {
    // console.log('Mail params === ', params);
    dispatch(toggleAlert({ show: false, type: '', title: '' }));
    const config = {
      method: 'post',
      url: ApiURL.sendReqEmail,
      data: params,
      headers: apiKey,
    };

    axios(config)
      .then(({ data }): any => {
        if (resent) {
          const title = alertTitle.MY_LINK_RESENT_S;
          dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
        } else {
          const title = alertTitle.REQ_ASSETS_SUCCESS;
          dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
          dispatch(
            createNotification({
              msg: 'Mail sent for requesting assets upload',
              content_id: [],
              action: 'mail',
            })
          );
        }
        dispatch(getMyLinks('upload'));
      })
      .catch((error: any) => {
        console.log('send mail API Error.... ', error);
        const title = alertTitle.REQ_ASSETS_EMAIL_FAILED;
        dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
      });
  };

export const handleRequestLink =
  (params: any) => (dispatch: any, getState: any) => {
    const { currentUser, labelLogo, labelTitle, workspace } =
      getState().dashboard;
    // console.log(new Date(params.expiry_date || new Date()).toISOString());
    const expiry_date = params.expiry_date
      ? new Date(
        params.expiry_date.getTime() -
        params.expiry_date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split('T')[0]
      : null;
    const { user_id, home_file_id, home_node_id, workspace_id } = workspace;
    const linkParams = {
      access_level: params.access_level,
      link_type: params.link_type,
      notes: params.notes,
      owner_email: currentUser.email,
      owner_username: currentUser.fullName,
      timelapse: params.timelapse,
      url: params.url,
      workspace_logo: labelLogo,
      workspace_name: labelTitle,
      recipient_email: params.recipient_email,
      expiry_date,
      user_id,
      home_node_id,
      workspace_id,
      home_file_id,
      owner_role: currentUser.roleName[0],
      password: params.password,
    };
    const mailParams = {
      email_type: params.email_type,
      recipient_email: [params.recipient_email],
      username: currentUser.fullName,
      notes:
        (params.notes.length &&
          '<br/><br/>Notes: ' + params.notes + '<br/><br/>') ||
        '',
    };

    const config = {
      method: 'post',
      url: ApiURL.ReqLinkSubmit,
      data: linkParams,
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => {
        dispatch(
          sendRequestAssetMail({ ...mailParams, link: data.link }, false)
        );
      })
      .catch((error: any) => {
        // console.log('handleRequestLink API Error.... ', error);
        const title = alertTitle.REQ_ASSETS_FAILED;
        dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
      });
  };

export const deleteFile = (data: any) => (dispatch: any, getState: any) => {
  const { breadcrumbList } = getState().dashboard;
  const { cid, key, allow_access_to_workspace_users } =
    breadcrumbList[breadcrumbList.length - 1];
  const { name, id } = data;
  //console.log(name);
  // var data = JSON.stringify({ id: ['2933', '2937', '2958'] });
  const config = {
    method: 'delete',
    url: ApiURL.DeleteLink,
    headers: apiKey,
    data: { id: id },
  };
  axios(config)
    .then(({ data }): any => {
      const title = alertTitle.DELETE_SUCCESS;
      dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
      dispatch(
        getDashboardRecords({
          cid,
          name: '',
          navId: key,
          allow_access_to_workspace_users,
        })
      );
      // Reloading Table at current level
      for (let i = 0; i < id.length; i++) {
        setTimeout(() => {
          dispatch(
            createNotification({
              msg: `Deleted ${name[i]}`,
              content_id: [id[i]],
              action: 'delete',
            })
          );
        }, i * 2000);
      }
    })
    .catch((error: any) => {
      const title = alertTitle.DELETE_FAILED;
      dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
    });
};

export const approveFile =
  (approveData: any) => (dispatch: any, getState: any) => {
    const { token, breadcrumbList, currentUser } = getState().dashboard;
    const { cid, key, allow_access_to_workspace_users } =
      breadcrumbList[breadcrumbList.length - 1];
    const data1 = {
      content_id: approveData.content_id,
      status: 'Approved',
    };
    // console.log(approveData.name);
    const config = {
      method: 'post',
      url: ApiURL.approvePendingFile,
      headers: { 'x-amz-security-token': token, ...apiKey },
      data: data1,
    };
    axios(config)
      .then(({ data }): any => {
        const title = alertTitle.Approve_Success(approveData.name);
        dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
        dispatch(
          getDashboardRecords({
            cid,
            name: '',
            navId: key,
            allow_access_to_workspace_users,
          })
        );
        dispatch(
          createEmailNotification({
            msg: `Approved updates on ${approveData.name}`,
            content_id: [approveData.content_id],
            action: 'Approve',
            email: [approveData.email],
            fullName: currentUser.fullName,
            user_id: approveData.user_id,
          })
        );
      })
      .catch((error: any) => {
        const title = alertTitle.Approve_Failure(approveData.name);
        dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
      });
  };

export const SendForApprovalFile =
  (data: any) => (dispatch: any, getState: any) => {
    dispatch(setTransferOpen({ isOpen: true, action: 'approve' }));
    const { token, nodeToken, workspace, currentUser, homeId } =
      getState().dashboard;
    const { user_id, home_node_id, workspace_id } = workspace;
    const { content_id, files_name, type, parentId } = data;
    const params = {
      user_id,
      home_node_id,
      workspace_id,
      home_file_id: homeId,
      share_to: 'A_&_R',
      content_id: [content_id],
      node_token: nodeToken,
      upload_notification_recipients: [],
      download_notification_recipients: [],
      name: files_name,
      note: '',
      file_names: [files_name],
      metadata: [],
      recipients: [],
      bcc_recipients: [],
    };
    const contentIdType = [
      {
        id: content_id,
        type: (type && 'file') || 'folder',
      },
    ];
    const file_parent_id = parentId;
    const config = {
      method: 'post',
      url: ApiURL.shareAssets,
      data: params,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    axios(config)
      .then(({ data }): any => {
        if (!data.error && !data.hasOwnProperty('errors')) {
          const insertParams = {
            package_id: data.package_id,
            package_file_id: data.contents_file_id,
            package_name: data.package_name,
            dropbox_id: data.dropbox_id,
            dropbox_name: data.dropbox_name,
            file_parent_id,
            content_id: contentIdType,
          };
          const paramsCheckTransferStatus = {
            transfer_id: data.transfer_id,
            home_node_id: home_node_id,
            node_token: nodeToken,
          };
          const notification = {
            okMsg: alertTitle.SendForApproval_Success(files_name),
            errMsg: alertTitle.SendForApproval_Failure(files_name),
            Msg: `Shared ${files_name} for approval `,
            Status: 'Pending Approval',
            Action: 'Send for Approval',
            content_id: [content_id],
            email: currentUser.email,
            fullName: currentUser.fullName,
            user_id,
            sendMailParams: [],
          };
          dispatch(
            checkTransferStatus(
              paramsCheckTransferStatus,
              data.package_id,
              notification,
              insertParams
            )
          );
        } else {
          dispatch(setTransferOpen({ isOpen: false, action: '' }));
          const title = alertTitle.SendForApproval_Failure(files_name);
          dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
        }
      })
      .catch((error: any) => {
        dispatch(setTransferOpen({ isOpen: false, action: '' }));

        const title = alertTitle.SendForApproval_Failure(files_name);
        dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
      });
  };

export const DeclineInRegistrationFile =
  (datum: any) => (dispatch: any, getState: any) => {
    const { token, breadcrumbList } = getState().dashboard;
    const { cid, key, allow_access_to_workspace_users } =
      breadcrumbList[breadcrumbList.length - 1];
    // console.log(datum);
    const data1 = {
      content_id: datum.content_id,
      status: 'In Review',
    };
    const config = {
      method: 'post',
      url: ApiURL.approvePendingFile,
      headers: { 'x-amz-security-token': token, ...apiKey },
      data: data1,
    };
    axios(config)
      .then(({ data }): any => {
        const title = alertTitle.DeclineAssetRegistration_Success(datum.name);
        dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
        dispatch(
          getDashboardRecords({
            cid,
            name: '',
            navId: key,
            allow_access_to_workspace_users,
          })
        );
        dispatch(
          postComments({
            notes: datum.notes,
            content_id: datum.content_id,
          })
        );
        dispatch(
          createNotification({
            msg: `Delined for registration ${datum.name}`,
            content_id: [datum.content_id],
            action: 'DeclineRegister',
          })
        );
      })
      .catch((error: any) => {
        const title = alertTitle.DeclineAssetRegistration_Failure(datum.name);
        dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
      });
  };

export const DeclineFile =
  (declineData: any) => (dispatch: any, getState: any) => {
    const { token, breadcrumbList, currentUser } = getState().dashboard;
    const { cid, key, allow_access_to_workspace_users } =
      breadcrumbList[breadcrumbList.length - 1];
    const data1 = {
      content_id: declineData.content_id,
      status: declineData.status,
    };
    const config = {
      method: 'post',
      url: ApiURL.approvePendingFile,
      headers: { 'x-amz-security-token': token, ...apiKey },
      data: data1,
    };
    axios(config)
      .then(({ data }): any => {
        dispatch(
          postComments({
            notes: declineData.notes,
            content_id: declineData.content_id,
          })
        );

        // dispatch(generateExpiredLink({ token: declineData.token, url: declineData.url }))

        let datap = {
          username: currentUser.fullName,
          recipient_email: declineData.recipient_email,
          email_type: 'req_revision',
          asset_name: declineData.asset_name,
          notes:
            (declineData.notes.length &&
              '<br/><br/>Notes: ' + declineData.notes) +
            (declineData.url &&
              declineData.token &&
              '<br/><br/>External Link: ' +
              declineData.url +
              '?token=' +
              declineData.token) || '',
        };
        const config = {
          method: 'post',
          url: ApiURL.sendReqEmail,
          headers: { 'x-amz-security-token': token, ...apiKey },
          data: datap,
        };
        axios(config)
          .then(({ data }): any => {
            dispatch(
              getDashboardRecords({
                cid,
                name: '',
                navId: key,
                allow_access_to_workspace_users,
              })
            );
            dispatch(
              createNotification({
                msg: `Requested revision for  ${declineData.name}`,
                content_id: [declineData.content_id],
                action: 'Requested Revision',
              })
            );
          })
          .catch((error: any) => {
            console.log('error occured at api side');
          });
        dispatch(
          getDashboardRecords({
            cid,
            name: '',
            navId: key,
            allow_access_to_workspace_users,
          })
        );
        const title = alertTitle.Decline_RequestRevision_Success(
          declineData.name
        );
        dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
      })
      .catch((error: any) => {
        const title = alertTitle.Decline_RequestRevision_Failure(
          declineData.name
        );
        dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
      });
  };

export const DeclineStudioFile =
  (studioDecline: any) => (dispatch: any, getState: any) => {
    dispatch(setTransferOpen({ isOpen: true, action: 'decline' }));
    const { content_id, name, notes, status, type, parent_id } = studioDecline;
    const { token, nodeToken, workspace, currentUser, homeId } =
      getState().dashboard;
    const { user_id, home_node_id, workspace_id } = workspace;

    const params = {
      user_id,
      home_node_id,
      workspace_id,
      home_file_id: homeId,
      share_to: 'A_&_R',
      content_id: [content_id],
      node_token: nodeToken,
      upload_notification_recipients: [],
      download_notification_recipients: [],
      name: name,
      note: '',
      file_names: [name],
      metadata: [],
      recipients: [],
      bcc_recipients: [],
    };
    const contentIdType = [
      {
        id: content_id,
        type: (type && 'file') || 'folder',
      },
    ];
    dispatch(
      postComments({
        notes: notes,
        content_id,
      })
    );
    const file_parent_id = parent_id;
    const config = {
      method: 'post',
      url: ApiURL.shareAssets,
      data: params,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    axios(config)
      .then(({ data }): any => {
        if (!data.error && !data.hasOwnProperty('errors')) {
          const insertParams = {
            package_id: data.package_id,
            package_file_id: data.contents_file_id,
            package_name: data.package_name,
            dropbox_id: data.dropbox_id,
            dropbox_name: data.dropbox_name,
            file_parent_id,
            content_id: contentIdType,
          };
          const paramsCheckTransferStatus = {
            transfer_id: data.transfer_id,
            home_node_id: home_node_id,
            node_token: nodeToken,
          };
          const notification = {
            okMsg: alertTitle.Decline_Studio_Success(name),
            errMsg: alertTitle.Decline_Studio_Failure(name),
            Msg: `Declined updates on ${studioDecline.name}`,
            Status: status,
            Action: 'declineForStudio',
            content_id: [content_id],
            email: currentUser.email,
            fullName: currentUser.fullName,
            user_id,
            sendMailParams: [],
          };
          dispatch(
            checkTransferStatus(
              paramsCheckTransferStatus,
              data.package_id,
              notification,
              insertParams
            )
          );
        } else {
          dispatch(setTransferOpen({ isOpen: false, action: '' }));
          const title = alertTitle.Decline_Studio_Failure(name);
          dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
        }
      })
      .catch((error: any) => {
        dispatch(setTransferOpen({ isOpen: false, action: '' }));
        const title = alertTitle.Decline_Studio_Failure(name);
        dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
      });
  };

export const RequestRevisionFile =
  (studioDecline: any) => (dispatch: any, getState: any) => {
    dispatch(setTransferOpen({ isOpen: true, action: 'requestrevision' }));
    const { token, breadcrumbList, currentUser } = getState().dashboard;
    const { cid, key, allow_access_to_workspace_users } =
      breadcrumbList[breadcrumbList.length - 1];

    const data1 = {
      content_id: studioDecline.content_id,
      status: 'Requested Revision',
    };
    const config = {
      method: 'post',
      url: ApiURL.approvePendingFile,
      headers: { 'x-amz-security-token': token, ...apiKey },
      data: data1,
    };

    // dispatch(generateExpiredLink({ token: studioDecline.token, url: studioDecline.url }))

    axios(config)
      .then(({ data }): any => {
        let datap = {
          username: currentUser.fullName,
          recipient_email: studioDecline.recipient_emails,
          email_type: 'req_revision',
          asset_name: studioDecline.asset_name,
          notes:
            (studioDecline.notes.length &&
              '<br/><br/>Notes: ' + studioDecline.notes) +
            (studioDecline.url &&
              studioDecline.token &&
              '<br/><br/>External Link: ' +
              studioDecline.url +
              '?token=' +
              studioDecline.token) || '',
        };
        const config = {
          method: 'post',
          url: ApiURL.sendReqEmail,
          headers: { 'x-amz-security-token': token, ...apiKey },
          data: datap,
        };
        axios(config)
          .then(({ data }): any => {
            dispatch(
              getDashboardRecords({
                cid,
                name: '',
                navId: key,
                allow_access_to_workspace_users,
              })
            );
            dispatch(
              createNotification({
                msg: `Requested revision for  ${studioDecline.name}`,
                content_id: [studioDecline.content_id],
                action: 'Requested Revision',
              })
            );
          })
          .catch((error: any) => {
            console.log('error occured at api side');
          });
      })
      .catch((error: any) => {
        const title = alertTitle.Decline_Studio_Failure(studioDecline.name);
        dispatch(setTransferOpen({ isOpen: false, action: '' }));
        dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
      });
  };

export const submitUpload = (data: any) => (dispatch: any, getState: any) => {
  const {
    upload,
    email,
    fileNames,
    homeId,
    folderCid,
    folderFileName,
    allow_access_to_workspace_users,
    currentLevel,
    type,
    additional_metadata,
  } = data;
  const {
    labelTitle,
    currentUser,
    nodeToken,
    upload_transfer_spec,
    workspace,
    uploadCount,
  } = getState().dashboard;
  if (uploadCount) {
    setUploadCount(uploadCount - 1);
    if (upload === 'success') {
      const files_name = fileNames.map((file: any) =>
        file.source.split(/[\\/]/).slice(-1).pop()
      );
      const Insertparams = {
        token: `Bearer ${nodeToken}`,
        remote_host: upload_transfer_spec.remote_host,
        access_key: upload_transfer_spec.tags.aspera.node.access_key,
        user_email: currentUser.email,
        user_id: upload_transfer_spec.tags.aspera.files.access_id,
        user_name:
          upload_transfer_spec.tags.aspera.files.user_name ||
          currentUser.fullName,
        workspace_id: upload_transfer_spec.tags.aspera.files.workspace_id,
        home_file_id: folderCid.toString(),
        is_root_directory: folderCid.toString() === workspace.home_file_id,
        files_name,
        allow_access_to_workspace_users,
        owner_role: currentUser.roleName[0],
        external_token: '',
        additional_metadata,
      };

      const Insertconfig = {
        method: 'post',
        url: ApiURL.InsertUpload,
        data: Insertparams,
        headers: apiKey,
      };
      axios(Insertconfig)
        .then(({ data }): any => {
          const comments = {
            notes: additional_metadata.notes,
            content_id: [...data.data.content_ids, folderCid],
          };
          additional_metadata.notes.length &&
            data.data.content_ids &&
            data.data.content_ids.length &&
            dispatch(postComments(comments));
          const msg =
            (files_name.length > 1 && `Uploaded ${files_name.length} assets`) ||
            `Uploaded ${files_name}`;
          const userEmail = (email
            .join('|')
            .toLowerCase()
            .split('|')
            .includes(currentUser.email.toLowerCase()) &&
            email) || [...email, currentUser.email];
          const UploadNotificationParams = {
            msg,
            content_id: data.data.content_ids,
            action: 'upload',
            email: userEmail,
            fullName: currentUser.fullName,
            user_id: upload_transfer_spec.tags.aspera.files.access_id,
          };
          const UploadFolderNotificationParams = {
            msg: `Uploaded ${folderFileName}`,
            content_id: [folderCid],
            action: 'upload',
            email: userEmail,
            fullName: currentUser.fullName,
            user_id: upload_transfer_spec.tags.aspera.files.access_id,
          };
          const UploadEmailParams = {
            username: currentUser.fullName,
            recipient_email: userEmail,
            email_type: 'upload',
            workspace_name: labelTitle,
          };
          const deleteOnFail = true;
          if (
            allow_access_to_workspace_users === 'Y' &&
            (currentUser.isLabelUser || currentUser.isLabelManager) &&
            data.data.content_ids &&
            data.data.content_ids.length
          ) {
            dispatch(
              shareAfterUpload({
                content_id: [folderCid],
                home_file_id: homeId,
                files_name: [folderFileName],
                share_to: 'A_&_R',
                recipients: [],
                email,
                ShareStatus: '',
                fullName: currentUser.fullName,
                Action: '',
                type,
                okMsg: alertTitle.EV_UPLOAD_SUCCESS(fileNames.length),
                errMsg: alertTitle.UPLOAD_FAILED(fileNames.length),
                UploadNotificationParams,
                UploadFolderNotificationParams,
                UploadEmailParams,
                deleteOnFail,
              })
            );
          } else if (
            currentUser.isLibraryUser &&
            data.data.content_ids &&
            data.data.content_ids.length
          ) {
            dispatch(
              shareAfterUpload({
                content_id: [folderCid],
                home_file_id: homeId,
                files_name: [folderFileName],
                share_to: 'library',
                recipients: [{ id: '499404', type: 'user' }],
                email,
                ShareStatus: 'In Library',
                fullName: currentUser.fullName,
                Action: 'sharetolibrary',
                type,
                okMsg: alertTitle.UPLOAD_SUCCESS(fileNames.length),
                errMsg: alertTitle.UPLOAD_FAILED(fileNames.length),
                UploadNotificationParams,
                UploadFolderNotificationParams,
                UploadEmailParams,
                deleteOnFail,
              })
            );
          } else if (
            currentUser.isStudioUser &&
            data.data.content_ids &&
            data.data.content_ids.length
          ) {
            dispatch(
              shareAfterUpload({
                content_id: [folderCid],
                home_file_id: homeId,
                files_name: [folderFileName],
                share_to: 'studio',
                recipients: [{ id: '499404', type: 'user' }],
                email,
                ShareStatus: 'In Studio',
                fullName: currentUser.fullName,
                Action: 'sharetostudio',
                type,
                okMsg: alertTitle.UPLOAD_SUCCESS(fileNames.length),
                errMsg: alertTitle.UPLOAD_FAILED(fileNames.length),
                UploadNotificationParams,
                UploadFolderNotificationParams,
                UploadEmailParams,
                deleteOnFail,
              })
            );
          } else {
            if (
              userEmail &&
              userEmail.length &&
              data.data.content_ids &&
              data.data.content_ids.length
            ) {
              dispatch(createEmailNotification(UploadFolderNotificationParams));
              setTimeout(() => {
                dispatch(createEmailNotification(UploadNotificationParams));
              }, 1000);
            }
            if (
              email &&
              email.length &&
              data.data.content_ids &&
              data.data.content_ids.length
            ) {
              const config = {
                method: 'post',
                url: ApiURL.sendReqEmail,
                data: UploadEmailParams,
                headers: apiKey,
              };

              axios(config)
                .then(({ data }): any => {
                  // dispatch(
                  //   createNotification({
                  //     msg: `Assets uploaded successfully`,
                  //     content_id: data.data.content_ids,
                  //   })
                  // );
                })
                .catch((error: any) => {
                  console.log('send mail API Error.... ', error);
                  // const title = alertTitle.REQ_ASSETS_EMAIL_FAILED;
                  // dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
                });
            }
            const { breadcrumbList } = getState().dashboard;
            const { cid, key } = breadcrumbList[breadcrumbList.length - 1];
            if (cid === currentLevel.cid && key === currentLevel.key)
              dispatch(
                getDashboardRecords({
                  cid,
                  name: '',
                  navId: key,
                  allow_access_to_workspace_users,
                })
              ); // Reloading Table only if in current level
            const title = alertTitle.UPLOAD_SUCCESS(fileNames.length);
            dispatch(
              toggleAlert({ show: true, type: alertType.SUCCESS, title })
            );
          }
        })
        .catch((err: any) => {
          folderCid && dispatch(DeleteOnFail({ content_id: [folderCid] }));
          dispatch(setTransferOpen({ isOpen: false, action: '' }));
          const title = alertTitle.UPLOAD_FAILED(fileNames.length);
          dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
        });
    } else {
      folderCid && dispatch(DeleteOnFail({ content_id: [folderCid] }));
      dispatch(setTransferOpen({ isOpen: false, action: '' }));

      const title = alertTitle.UPLOAD_FAILED(fileNames.length);
      dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
    }
  } else {
    if (upload === 'failure') {
      folderCid && dispatch(DeleteOnFail({ content_id: [folderCid] }));
      dispatch(setTransferOpen({ isOpen: false, action: '' }));

      const title = alertTitle.UPLOAD_FAILED(fileNames.length);
      dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
    }
  }
};

// This action is common for Share to Library, Studio, Bulk Share to Library
export const submitShareContent =
  (data: any) => (dispatch: any, getState: any) => {
    dispatch(
      setTransferOpen({
        isOpen: true,
        action:
          (data.sharingTo === 'library' && 'sharelibrary') || 'sharestudio'
      })
    );
    const { workspace, token, nodeToken, currentUser } = getState().dashboard;
    const { user_id, home_file_id, home_node_id, workspace_id } = workspace;
    const {
      emailList,
      name,
      file_names,
      content_id,
      allFields,
      sharingTo,
      contentIdType,
      homeId,
    } = data;
    // const { cid, key } = breadcrumbList[breadcrumbList.length - 1];
    const {
      SHARE_LIB_SUCCESS,
      SHARE_LIB_FAILED,
      SHARE_STUDIO_SUCCESS,
      SHARE_STUDIO_FAILED,
    } = alertTitle;
    const isShareToLib = sharingTo === 'library';
    const succTitle = isShareToLib ? SHARE_LIB_SUCCESS : SHARE_STUDIO_SUCCESS;
    const failTitle = isShareToLib ? SHARE_LIB_FAILED : SHARE_STUDIO_FAILED;
    const noticeMsg =
      (file_names.length > 1 &&
        `Shared ${file_names.length} assets with ${
          isShareToLib ? 'Library' : 'Studio'
        }`) ||
      `Shared ${file_names} with ${isShareToLib ? 'Library' : 'Studio'}`;
    const noticeShareAction = isShareToLib ? 'sharetolibrary' : 'sharetostudio';
    const params = {
      user_id,
      home_file_id,
      home_node_id,
      workspace_id,
      share_to: sharingTo,
      node_token: nodeToken,
      note: allFields.notes,
      file_names,
      content_id,
      name: `${name}${currentUser.email} ${new Date().toISOString()}`,
      upload_notification_recipients: emailList,
      download_notification_recipients: emailList,
      recipients: [{ id: '499404', type: 'user' }],
      bcc_recipients: [], // Always empty
    };
    let metadata: any = []; // Send blank array in Share Library
    let additional_metadata: any = {}; // Send empty object in Share Library

    if (sharingTo === 'studio') {
      let dueDate = new Date();
      if (allFields.regAssetDueDate) {
        dueDate = allFields.regAssetDueDate; //TODO: DECIDE AND SEND MAX DATE from selected
      } else {
        dueDate.setDate(dueDate.getDate() + 7); // if no date, send 1 week future date.
      }

      metadata = [
        {
          input_type: 'single-text',
          name: 'Artist',
          values: [allFields.artistName],
        },
        { input_type: 'single-text', name: 'Title', values: [allFields.title] },
        {
          input_type: 'single-text',
          name: 'UPC',
          values: [allFields.regAssetUpc],
        },
        {
          input_type: 'date',
          name: 'Due Date',
          values: [dueDate ? dueDate.toISOString() : ''],
        },
        { input_type: 'textbox', name: 'Comments', values: [allFields.notes] },
      ];
      additional_metadata = {
        artist: allFields.artistName,
        title: allFields.title,
        upc: allFields.regAssetUpc,
        isrc: allFields.regAssetIsrc,
        register_asset_due_date: allFields.regAssetDueDate
          ? allFields.regAssetDueDate.toISOString()
          : '',
        studio_info: allFields.regAssetStudioInfo,
        watermark_wbs: allFields.watermarkingWbs,
        watermark_approved_by: allFields.watermarkingApprovedBy,
        watermark_job_description: allFields.watermarkingJD,
        audio_edit_due_date: allFields.audioEditDueDate
          ? allFields.audioEditDueDate.toISOString()
          : '',
        audio_edit_job_description: allFields.audioEditJD,
        audio_edit_send_ref_email: allFields.audioEditSendRef,
        audio_edit_wbs: allFields.audioEditWbs,
        mastering_send_ref_email: allFields.masteringSendRef,
        mastering_due_date: allFields.masteringDueDate
          ? allFields.masteringDueDate.toISOString()
          : '',
        mastering_wbs: allFields.masteringWbs,
        notes: allFields.notes,
        notify_users: emailList,
        meta_tasks: allFields.selectedStudioOption
          .map((e: any) => e.title)
          .join(','),
      };
    }

    const finalParams = { ...params, metadata, additional_metadata };
    const config = {
      method: 'post',
      url: ApiURL.shareAssets,
      data: finalParams,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    // const contentIdType = content_id.map((item: any, index: any) => ({
    //   id: item,
    //   type: type[index] ,
    // }));
    const file_parent_id = homeId;
    axios(config)
      .then(({ data }): any => {
        if (data.hasOwnProperty('errors')) {
          dispatch(setTransferOpen({ isOpen: false, action: '' }));
          const title = failTitle;
          dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
        } else {
          const userEmail = (emailList
            .join('|')
            .toLowerCase()
            .split('|')
            .includes(currentUser.email.toLowerCase()) &&
            emailList) || [...emailList, currentUser.email];

          const insertParams = {
            package_id: data.package_id,
            package_file_id: data.contents_file_id,
            package_name: data.package_name,
            dropbox_id: data.dropbox_id,
            dropbox_name: data.dropbox_name,
            file_parent_id,
            content_id: contentIdType,
          };
          // dispatch(insertPackage(insertParams));
          // Success
          const paramsCheckTransferStatus = {
            transfer_id: data.transfer_id,
            home_node_id: home_node_id,
            node_token: nodeToken,
          };
          //console.log(data, 'data');
          const notification = {
            okMsg: succTitle,
            errMsg: failTitle,
            Msg: noticeMsg,
            Status: isShareToLib ? 'In Library' : 'In Studio',
            content_id,
            email: userEmail,
            Action: noticeShareAction,
            fullName: currentUser.fullName,
            user_id,
            sendMailParams: [
              {
                username: currentUser.fullName,
                recipient_email: userEmail,
                email_type: 'notify',
                asset_name: file_names,
                share_to: sharingTo,
                notes:
                  (allFields.notes.length &&
                    '<br/><br/>Notes: ' + allFields.notes) ||
                  '',
              },
            ],
          };
          dispatch(
            checkTransferStatus(
              paramsCheckTransferStatus,
              data.package_id,
              notification,
              insertParams
            )
          );

          allFields.notes.length &&
            dispatch(
              postComments({
                notes: allFields.notes,
                content_id,
              })
            );
        }
      })
      .catch((err) => {
        const title = failTitle;
        dispatch(setTransferOpen({ isOpen: false, action: '' }));
        dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
        console.log('API failed to share content, error ==> ', err);
      });
  };

export const updateFilterKey = (key: string) => (dispatch: any) => {
  dispatch(changeFilterKey(key));
};

export const deleteReqLink = (data: any) => (dispatch: any) => {
  const { id, linkType } = data;
  const config = {
    method: 'get',
    url: ApiURL.DelReqLink + id + `&link_type=${linkType}`,
    headers: apiKey,
  };
  axios(config)
    .then(({ data }): any => {
      dispatch(getMyLinks(linkType));
      dispatch(
        toggleAlert({
          show: true,
          type: alertType.SUCCESS,
          title: alertTitle.MY_LINK_DELETE_S,
        })
      );
    })
    .catch((error: any) => {
      // console.log('handleRequestLink API Error.... ', error);
      // const title = alertTitle.REQ_ASSETS_FAILED;
      dispatch(
        toggleAlert({
          show: true,
          type: alertType.DANGER,
          title: alertTitle.MY_LINK_FAILURE,
        })
      );
    });
};

export const terminateReqLink = (data: any) => (dispatch: any) => {
  const { id, owner_email, linkType } = data;
  const config = {
    method: 'get',
    url:
      ApiURL.TerminateReqLink +
      `id=${id}&owner_email=${owner_email}&link_type=${linkType}`,
    headers: apiKey,
  };
  axios(config)
    .then(() => {
      dispatch(getMyLinks(linkType));
      dispatch(
        toggleAlert({
          show: true,
          type: alertType.SUCCESS,
          title: alertTitle.MY_LINK_TERMINATE_S,
        })
      );
    })
    .catch((error: any) => {
      // console.log('handleRequestLink API Error.... ', error);
      // const title = alertTitle.REQ_ASSETS_FAILED;
      dispatch(
        toggleAlert({
          show: true,
          type: alertType.DANGER,
          title: alertTitle.MY_LINK_FAILURE,
        })
      );
    });
};

export const copyLink = () => (dispatch: any) => {
  dispatch(
    toggleAlert({
      show: true,
      type: alertType.SUCCESS,
      title: alertTitle.MY_LINK_COPIED_S,
    })
  );
};

export const activateLink = () => (dispatch: any) => {
  dispatch(
    toggleAlert({
      show: true,
      type: alertType.SUCCESS,
      title: alertTitle.My_LINK_ACTIVE_S,
    })
  );
};

export const readComment = (contentid: string) => (dispatch: any) => {
  const config = {
    method: 'get',
    url: ApiURL.EditComments + (contentid || '1002'),
    headers: apiKey,
  };

  axios(config)
    .then(({ data }): any => {
      dispatch(loadComment(data.data));
    })
    .catch((error: any) => {
      console.log('readComment API Error....', error);
    });
};

export const postComments = (data: any) => (dispatch: any, getState: any) => {
  const { currentUser, workspace } = getState().dashboard;
  const { notes, content_id } = data;
  const params = {
    user_id: workspace.user_id,
    content_id,
    comments: notes,
    email: currentUser.email,
    user_name: currentUser.fullName,
  };
  const config = {
    method: 'post',
    url: ApiURL.PostComments,
    data: params,
    headers: apiKey,
  };
  axios(config)
    .then(({ data }): any => {
      // console.log(data);
      dispatch(readComment(content_id[0]));
    })
    .catch((error: any) => {
      // console.log('handleRequestLink API Error.... ', error);
      const title = alertTitle.REQ_ASSETS_FAILED;
      dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
    });
};

export const createFolder =
  (details: any) => (dispatch: any, getState: any) => {
    const {
      currentUser,
      workspace,
      nodeToken,
      upload_transfer_spec,
      homeId,
      breadcrumbList,
    } = getState().dashboard;
    const { cid, key, allow_access_to_workspace_users } =
      breadcrumbList[breadcrumbList.length - 1];
    const { name, artist, title, allow_access } = details;
    const params = {
      token: `Bearer ${nodeToken}`, // Do not remove 'Bearer '
      remote_host: upload_transfer_spec.remote_host,
      access_key: upload_transfer_spec.tags.aspera.node.access_key,
      home_file_id: homeId,
      folder_name: name,
      user_email: currentUser.email,
      user_id: workspace.user_id,
      user_name: currentUser.fullName,
      workspace_id: workspace.workspace_id,
      creating_at_root: !cid,
      owner_role: currentUser.roleName[0],
      allow_access_to_workspace_users: allow_access,
      additional_metadata: {
        artist,
        title,
      },
    };

    const config = {
      method: 'post',
      url: ApiURL.createFolder,
      data: params,
      headers: apiKey,
    };

    axios(config)
      .then(({ data }): any => {
        dispatch(
          getDashboardRecords({
            cid,
            name: '',
            navId: key,
            allow_access_to_workspace_users,
          })
        ); // Reloading Table at current level
        const title = alertTitle.CREATE_FOLDER_SUCCESS(name);
        dispatch(createFolderAlert({ title, icon: 'check' }));
        setTimeout(() => {
          dispatch(createFolderAlert({ title: '', icon: '' }));
        }, 6000);
      })
      .catch((error: any) => {
        console.log('createFolder API Error.... ', error);
        const title = alertTitle.CREATE_FOLDER_FAILED(name);
        dispatch(createFolderAlert({ title, icon: 'hexagon-exclamation' }));
        setTimeout(() => {
          dispatch(createFolderAlert({ title: '', icon: '' }));
        }, 7500);
      });
  };

export const readActivity = (contentid: string) => (dispatch: any) => {
  const config = {
    method: 'get',
    url: ApiURL.getActivities + (contentid || '1002'),
    headers: apiKey,
  };

  axios(config)
    .then(({ data }): any => {
      dispatch(loadActivity(data.data));
    })
    .catch((error: any) => {
      console.log('readComment API Error....', error);
    });
};

export const postInviteCollaborator =
  (collaboratorData: any) => (dispatch: any, getState: any) => {
    const {
      currentUser,
      // nodeToken,
      workspace,
      // token,
      breadcrumbList,
      labelTitle,
    } = getState().dashboard;
    dispatch(setTransferOpen({ isOpen: true, action: 'collaborator' }));
    const { cid, key, allow_access_to_workspace_users } =
      breadcrumbList[breadcrumbList.length - 1];
    const to_user = collaboratorData.inviteList.map((user: any) => ({
      user_id: user.user_id,
      username: user.username,
      access_level:
        (user.access_level === 'Can Edit' && 'edit') ||
        (user.access_level === 'Can View' && 'view'),
      email: user.email,
    }));
    const receipient_emails = collaboratorData.inviteList.map(
      (user: any) => user.email
    );

    const data = {
      // node_token: nodeToken,
      content_id: collaboratorData.content_id,
      to_user,
      from_user_id: collaboratorData.from_user_id,
      from_username: collaboratorData.from_username,
      from_user_email: collaboratorData.from_user_email,
      file_name: collaboratorData.file_name,
      home_node_id: workspace.home_node_id,
      workspace_id: workspace.workspace_id,
      workspace_name: labelTitle,
    };

    const config = {
      method: 'post',
      url: ApiURL.postInviteCollaborators,
      headers: apiKey,
      data: data,
    };
    axios(config)
      .then(({ data }): any => {
        const title = alertTitle.COLLABORATOR_SUCCESS;
        dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
        dispatch(
          getDashboardRecords({
            cid,
            name: '',
            navId: key,
            allow_access_to_workspace_users,
          })
        );
        // Reloading Table at current level
        to_user.map((user: any) =>
          dispatch(
            createEmailNotification({
              msg: `${currentUser.fullName} invited you as a collaborator on ${collaboratorData.file_name}`,
              content_id: [collaboratorData.content_id],
              action: 'Invite collaborator/shared',
              email: [user.email],
              fullName: user.username,
              user_id: user.user_id,
            })
          )
        );
        const mailParams = {
          email_type: 'collaborator',
          recipient_email: receipient_emails,
          username: currentUser.fullName,
          workspace_name: labelTitle,
          filename: collaboratorData.file_name,
        };
        dispatch(sendExternalShareMail({ ...mailParams }));
      })
      .catch((error: any) => {
        dispatch(setTransferOpen({ isOpen: false, action: '' }));
        console.log('inviteCollaborator API Error....', error);
        if (error) {
          // console.log(error.code);
          const title = alertTitle.COLLABORATOR_FAILED;
          dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
        }
      });
  };

export const downloadSuccess =
  (data: any) => (dispatch: any, getState: any) => {
    const { downloadCID } = getState().dashboard;
    let { content_id, downloadCount } = data[0];
    if (!Array.isArray(content_id)) {
      content_id = [content_id];
    }

    if (downloadCID.includes(content_id[0])) {
      // dispatch(setDownloadCID(content_id[0]));
      dispatch(removeDownloadCID(content_id[0]));
      let noticeMsg =
        (content_id.length > 1 && `Downloaded ${content_id.length} assets`) ||
        `Downloaded ${downloadCount}`;

      dispatch(
        createNotification({
          msg: noticeMsg,
          content_id,
          action: 'internaldownload',
        })
      );
      // setTimeout(()=> {dispatch(removeDownloadCID(content_id[0]))},100);
    }
  };

export const getContentInfo = (id: string) => (dispatch: any) => {
  dispatch(loadContentDetails({}));
  const config = {
    method: 'get',
    url: ApiURL.getContentDetails + id,
    headers: apiKey,
  };

  axios(config).then(({ data }): any => {
    // console.log('details... == ', data);
    if (data.data && data.data[0]) {
      dispatch(loadContentDetails(data.data[0]));
    }
  });
  // .catch((error: any) => {
  //   // console.log('getContentInfo API Error....', error);
  // });
};

export const updateContentDetails =
  (details: any) => (dispatch: any, getState: any) => {
    console.log(details, 'details')
    const params = {
      content_id: details.content_id,
      file_name: details.filename,
      artist: details.artist,
      title: details.title,
      upc: (details.upc && parseInt(details.upc)) || 0,
      isrc: details.isrc,
      version: details.version,
      studio: details.studio,
      engineer: details.engineer,
      producer: details.producer,
      fields_changed: details.fieldsChange,
      audio_type_array: details.audio_type,
      package_name: details.package_name,
      user_role: details.user_role,
      additional_metadata: {
        upc: (details.regAssetUpc && parseInt(details.regAssetUpc)) || 0,
        isrc: details.regAssetIsrc,
        register_asset_due_date: details.regAssetDueDate
          ? details.regAssetDueDate.toISOString()
          : '',
        studio_info: details.regAssetStudioInfo,
        watermark_wbs: details.watermarkingWbs,
        watermark_approved_by: details.watermarkingApprovedBy,
        watermark_job_description: details.watermarkingJD,
        audio_edit_due_date: details.audioEditDueDate
          ? details.audioEditDueDate.toISOString()
          : '',
        audio_edit_job_description: details.audioEditJD,
        audio_edit_send_ref_email: details.audioEditSendRef,
        audio_edit_wbs: details.audioEditWbs,
        mastering_send_ref_email: details.masteringSendRef,
        mastering_due_date: details.masteringDueDate
          ? details.masteringDueDate.toISOString()
          : '',
        mastering_wbs: details.masteringWbs,
        meta_tasks: details.selectedStudioOption
          .map((e: any) => e.title)
          .join(','),
      }

    };
    const config = {
      method: 'post',
      url: ApiURL.updateContentDetails,
      data: params,
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => {
        // Content details metadata updated successfully.
        if (details.fieldsChange.includes('Filename')) {
          dispatch(
            renameAsset({
              content_id: details.content_id,
              new_name: details.filename,
            })
          );
        }
        const title = alertTitle.UPDATE_CONTENT_SUCCESS;
        if (details.notes.length) {
          dispatch(
            postComments({
              notes: details.notes,
              content_id: [details.content_id],
            })
          );
        }
        const { breadcrumbList } = getState().dashboard;
        const { cid, key, allow_access_to_workspace_users } =
          breadcrumbList[breadcrumbList.length - 1];
        dispatch(
          getDashboardRecords({
            cid,
            name: '',
            navId: key,
            allow_access_to_workspace_users,
          })
        ); // Reloading Table at current level
        dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
        dispatch(
          createNotification({
            msg: `Edited details of ${details.filename}`,
            content_id: [details.content_id],
            action: 'edit',
            fieldsChange: details.fieldsChange,
          })
        );
      })
      .catch((error: any) => {
        const title = alertTitle.UPDATE_CONTENT_FAILED;
        dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
      });
  };

export const updateVaultStatus =
  (details: any) => (dispatch: any, getState: any) => {
    const { workspace, token, nodeToken, currentUser } = getState().dashboard;
    const { user_id, home_file_id, home_node_id, workspace_id } = workspace;

    const params = {
      user_id,
      user_email: currentUser.email,
      home_node_id: home_node_id,
      workspace_id: workspace_id,
      home_file_id: home_file_id,
      content_id: details.cid,
      name: `Asset moved to Vault by ${
        currentUser.email
        } on ${new Date().toISOString()}`,
      file_names: [details.fname],
      node_token: nodeToken,
    };
    const config = {
      method: 'post',
      url: ApiURL.vaultAsset,
      data: params,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    const okMsg = alertTitle.VAULT_SUCCESS(details.fname);
    const errMsg = alertTitle.VAULT_FAILED(details.fname);

    axios(config)
      .then(({ data }): any => {
        // REMOVE Console later
        // console.log('success, data === ', data);
        if (!data.error) {
          // Success
          const { breadcrumbList } = getState().dashboard;
          const { cid, key, allow_access_to_workspace_users } =
            breadcrumbList[breadcrumbList.length - 1];
          dispatch(
            getDashboardRecords({
              cid,
              name: '',
              navId: key,
              allow_access_to_workspace_users,
            })
          ); // Reloading Table at current level
          dispatch(
            createNotification({
              msg: `Vaulted ${details.fname}`,
              content_id: [details.cid],
              action: 'vault',
            })
          );
          dispatch(
            toggleAlert({ show: true, type: alertType.SUCCESS, title: okMsg })
          );
        } else {
          // Failed
          dispatch(
            toggleAlert({ show: true, type: alertType.DANGER, title: errMsg })
          );
        }
      })
      .catch((err) => {
        // console.log('API failed to vault content, error ==> ', err);
        dispatch(
          toggleAlert({ show: true, type: alertType.DANGER, title: errMsg })
        );
      });
  };

export const moveToNewFolder = (opt: any) => (dispatch: any, getState: any) => {
  dispatch(setTransferOpen({ isOpen: true, action: 'transfer' }));
  const { workspace, token, nodeToken } = getState().dashboard;
  const { home_file_id, home_node_id } = workspace;

  const params = {
    home_node_id: home_node_id, // take current/parent from redux
    source_root_id: home_file_id, // parent folder(or common parent folder when creting new folder) id where source file is present , example-root folder(asset) // home file id of parent folder.
    destination_root_id: opt.targetCid, // target folder content id
    source_asset_name: [opt.srcName], // source filename or folder name
    source_asset_id: [opt.srcCid], // content id of source file/folder,
    move_to_root: false, // Looks like this will be false most of the time. Check for Assets
    node_token: `Bearer ${nodeToken}`,
  };

  const config = {
    method: 'post',
    url: ApiURL.moveFolder,
    data: params,
    headers: { 'x-amz-security-token': token, ...apiKey },
  };

  axios(config)
    .then(({ data }): any => {
      const { breadcrumbList } = getState().dashboard;
      const { cid, key, allow_access_to_workspace_users } =
        breadcrumbList[breadcrumbList.length - 1];
      dispatch(
        getDashboardRecords({
          cid,
          name: '',
          navId: key,
          allow_access_to_workspace_users,
        })
      ); // Reloading Table at current level
      dispatch(
        createNotification({
          msg: `Asset ${opt.srcName} moved to ${opt.targetName}`,
          content_id: [opt.srcCid],
          action: 'moveFolder',
        })
      );
      const title = alertTitle.MOVE_FOLDER_SUCCESS(opt.srcName, opt.targetName);
      dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
    })
    .catch((err) => {
      console.log('Failed to move to folder, error ==> ', err);
      const title = alertTitle.MOVE_FOLDER_FAILED(opt.srcName, opt.targetName);
      dispatch(setTransferOpen({ isOpen: false, action: '' }));
      dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
    });
};

export const downloadPermission =
  (nodeToken: any) => (dispatch: any, getState: any) => {
    const { token, workspace, labelTitle, currentUser } = getState().dashboard;
    const { user_id, home_file_id, home_node_id, workspace_id } = workspace;

    const params = {
      token: `Bearer ${nodeToken}`,
      user_email: currentUser.email,
      user_id,
      user_name: currentUser.fullName,
      workspace_id,
      workspace_name: labelTitle,
      home_file_id,
      home_node_id,
    };
    const config = {
      method: 'post',
      url: ApiURL.downloadPermissionLink,
      data: params,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };

    axios(config).then(({ data }): any => {});
    // .catch((error: any) => {
    //   // console.log('getContentInfo API Error....', error);
    // });
  };

export const getProfile =
  (userId = '') =>
    (dispatch: any, getState: any) => {
      const { workspace, currentUser, validWorkspace, workspaceList } =
        getState().dashboard;
      let user_id = workspace.user_id;
      if (!validWorkspace) {
        user_id = workspaceList[0].user_id;
      }
      if (userId.length) {
        user_id = userId;
      }

      const params = `?user_email=${currentUser.email}&user_id=${user_id}`;
      const config = {
        method: 'get',
        url: ApiURL.profileLink + params,
        headers: apiKey,
      };
      axios(config).then(({ data }): any => {
        dispatch(setprofileSettings(data.data[0]));
      });
    };
export const postProfileDetails =
  (data: any) => (dispatch: any, getState: any) => {
    const { workspace, currentUser, validWorkspace, workspaceList } =
      getState().dashboard;
    let user_id = workspace.user_id;
    if (!validWorkspace) {
      user_id = workspaceList[0].user_id;
    }
    const params = {
      user_id,
      user_email: currentUser.email,
      ...data,
    };
    const config = {
      method: 'post',
      url: ApiURL.profileLink,
      data: params,
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => {
        getProfile();
        const title = alertTitle.ACCOUNT_SUCCESS;
        dispatch(toggleAlert({ show: true, type: alertType.SUCCESS, title }));
      })
      .catch((err: any) => {
        const title = alertTitle.ACCOUNT_FAILURE;
        dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
      });
  };

export const modifyReqSubmit =
  (params: any) => (dispatch: any, getState: any) => {
    const { currentUser } = getState().dashboard;

    const config = {
      method: 'post',
      url: ApiURL.ReqLinkSubmit + '/link',
      data: params,
      headers: apiKey,
    };
    const mailParams = {
      email_type: params.email_type,
      recipient_email: [params.recipient_email],
      username: currentUser.fullName,
      link: params.link,
      notes:
        (params.notes.length &&
          '<br/><br/>Notes: ' + params.notes + '<br/><br/>') ||
        '',
    };
    axios(config).then(({ data }): any => {
      dispatch(getMyLinks('upload'));
      dispatch(sendRequestAssetMail(mailParams, false));
    });
  };

export const clearInfoTab = () => (dispatch: any) => {
  dispatch(loadContentDetails({}));
};

export const registerAssets =
  (details: any) => (dispatch: any, getState: any) => {
    dispatch(setTransferOpen({ isOpen: true, action: 'register' }));
    const { workspace, token, nodeToken, currentUser } = getState().dashboard;
    const { user_id, home_node_id, home_file_id } = workspace;

    const params = {
      user_id,
      home_file_id: home_file_id,
      content_id: [details.cid],
      user_email: currentUser.email,
      name: details.name,
      note: details.note,
      file_names: [details.fname],
      node_token: nodeToken,
    };
    const config = {
      method: 'post',
      url: ApiURL.assetRegister,
      data: params,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    const errMsg = alertTitle.REGISTER_FAILED(details.fname);

    axios(config)
      .then(({ data }): any => {
        if (!data.error) {
          // Success
          const paramsCheckTransferStatus = {
            transfer_id: data.transfer_id,
            home_node_id: home_node_id,
            node_token: nodeToken,
          };
          //console.log(data, 'data');
          const notification = {
            okMsg: alertTitle.REGISTER_SUCCESS(details.fname),
            errMsg: alertTitle.REGISTER_FAILED(details.fname),
            Msg: `Registered ${details.fname}`,
            Status: 'Registered',
            Action: 'register',
            content_id: [details.cid],
            email: [currentUser.email],
            fullName: currentUser.fullName,
            user_id,
            sendMailParams: [],
          };
          dispatch(
            checkTransferStatus(
              paramsCheckTransferStatus,
              data.package_id,
              notification,
              undefined
            )
          );
          // if (
          //  true
          // ) {
          //   var endTransferStatus = dispatch(
          //     endTransfer({ package_id: data.package_id })
          //   );
          //   console.log(endTransferStatus, 'boss');
          //   if (endTransferStatus === 'success') {
          //     dispatch(
          //       updateRegisterStatus({
          //         content_id: details.cid,
          //         status: 'Registered',
          //       })
          //     );
          //     dispatch(getDashboardRecords({ cid, name: '', navId: key })); // Reloading Table at current level
          //     dispatch(
          //       createNotification({
          //         msg: `Registered ${details.fname}`,
          //         content_id: [details.cid],
          //         action: 'register',
          //       })
          //     );
          //     dispatch(
          //       toggleAlert({
          //         show: true,
          //         type: alertType.SUCCESS,
          //         title: okMsg,
          //       })
          //     );
          //   } else {
          //     // dispatch(
          //     //   toggleAlert({
          //     //     show: true,
          //     //     type: alertType.DANGER,
          //     //     title: errMsg,
          //     //   })
          //     // );
          //   }
          // } else {
          //   // dispatch(endTransfer({ package_id: data.package_id }));
          //   // dispatch(
          //   //   toggleAlert({ show: true, type: alertType.DANGER, title: errMsg })
          //   // );
          // }
        } else {
          dispatch(setTransferOpen({ isOpen: false, action: '' }));
          dispatch(
            toggleAlert({ show: true, type: alertType.DANGER, title: errMsg })
          );
          // dispatch(
          //   toggleAlert({ show: true, type: alertType.DANGER, title: errMsg })
          // );
        }
      })
      .catch((err) => {
        console.log(err, 'error');
        dispatch(setTransferOpen({ isOpen: false, action: '' }));
        dispatch(
          toggleAlert({ show: true, type: alertType.DANGER, title: errMsg })
        );
      });
  };

export const updateRegisterStatus =
  (details: any) => (dispatch: any, getState: any) => {
    const { token } = getState().dashboard;
    const data1 = {
      content_id: details.content_id,
      status: details.status,
    };
    const config = {
      method: 'post',
      url: ApiURL.approvePendingFile,
      headers: { 'x-amz-security-token': token, ...apiKey },
      data: data1,
    };
    axios(config)
      .then(({ data }): any => {
        // dispatch(getDashboardRecords({ cid, name: '', navId: key }));
        const { breadcrumbList } = getState().dashboard;

        const { cid, key, allow_access_to_workspace_users } =
          breadcrumbList[breadcrumbList.length - 1];
        dispatch(
          getDashboardRecords({
            cid,
            name: '',
            navId: key,
            allow_access_to_workspace_users,
          })
        );
      })
      .catch((error: any) => {
        dispatch(setTransferOpen({ isOpen: false, action: '' }));
        console.log('error', error);
      });
  };

export const shareAfterUpload =
  (data: any) => (dispatch: any, getState: any) => {
    const { token, nodeToken, workspace, currentUser } = getState().dashboard;
    const { user_id, home_node_id, workspace_id } = workspace;
    const {
      content_id,
      home_file_id,
      files_name,
      share_to,
      recipients,
      email,
      ShareStatus,
      fullName,
      Action,
      type,
      okMsg,
      errMsg,
      UploadNotificationParams,
      UploadFolderNotificationParams,
      UploadEmailParams,
    } = data;
    const ContentId = content_id.map((item: any) => item.toString());
    const params = {
      user_id,
      home_node_id,
      workspace_id,
      home_file_id,
      share_to,
      content_id: ContentId,
      node_token: nodeToken,
      upload_notification_recipients: email,
      download_notification_recipients: email,
      name: files_name[0],
      note: '',
      file_names: files_name,
      metadata: [],
      recipients,
      bcc_recipients: [],
    };
    const DeleteOnFail = data.deleteOnFail;
    const userEmail = (email
      .join('|')
      .toLowerCase()
      .split('|')
      .includes(currentUser.email.toLowerCase()) &&
      email) || [...email, currentUser.email];
    const noticeMsg =
      (ShareStatus === 'In Library' &&
        ((files_name.length > 1 &&
          `Shared ${files_name.length} assets with Library`) ||
          `Shared ${files_name} with Library`)) ||
      (ShareStatus === 'In Studio' &&
        ((files_name.length > 1 &&
          `Shared ${files_name.length} assets with Studio`) ||
          `Shared ${files_name} with Studio`)) ||
      '';
    const config = {
      method: 'post',
      url: ApiURL.shareAssets,
      data: params,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    const contentIdType = content_id.map((item: any) => ({
      id: item,
      type: (type && 'file') || 'folder',
    }));
    const file_parent_id =
      (home_file_id === workspace.home_file_id && '0') || home_file_id;
    axios(config)
      .then(({ data }): any => {
        if (!data.error) {
          // Success
          //shareAfterUpload;

          const insertParams = {
            package_id: data.package_id,
            package_file_id: data.contents_file_id,
            package_name: data.package_name,
            dropbox_id: data.dropbox_id,
            dropbox_name: data.dropbox_name,
            file_parent_id,
            content_id: contentIdType,
          };
          // dispatch(insertPackage(insertParams));

          const paramsCheckTransferStatus = {
            transfer_id: data.transfer_id,
            home_node_id: home_node_id,
            node_token: nodeToken,
          };

          const notification = {
            okMsg,
            errMsg,
            Msg: noticeMsg,
            Status: ShareStatus,
            content_id,
            email: userEmail,
            Action,
            fullName,
            user_id,
            sendMailParams: [
              {
                username: fullName,
                recipient_email: userEmail,
                email_type: 'notify',
                asset_name: files_name,
                share_to,
                notes: '',
              },
            ],
            UploadNotificationParams,
            UploadFolderNotificationParams,
            UploadEmailParams,
            DeleteOnFail,
          };
          dispatch(
            checkTransferStatus(
              paramsCheckTransferStatus,
              data.package_id,
              notification,
              insertParams
            )
          );
        } else {
          DeleteOnFail && dispatch(DeleteOnFail({ content_id }));
          dispatch(setTransferOpen({ isOpen: false, action: '' }));
        }
        // console.log(data);
      })
      .catch((err) => {
        DeleteOnFail && dispatch(DeleteOnFail({ content_id }));
        dispatch(setTransferOpen({ isOpen: false, action: '' }));
        console.log(err);
      });
  };

export const sendMail = (params: any) => (dispatch: any) => {
  const config = {
    method: 'post',
    url: ApiURL.sendReqEmail,
    data: params,
    headers: apiKey,
  };

  axios(config)
    .then(({ data }): any => {
      if (!data.error) {
        //console.log(data);
      }
    })
    .catch((error: any) => {
      console.log('Who to notify mail API Error.... ', error);
    });
};
export const checkTransferStatus =
  (params: any, package_id: any, notification: any, insertParams: any) =>
    (dispatch: any, getState: any) => {
      const { token } = getState().dashboard;
      //const { user_id, home_node_id, home_file_id } = workspace;

      // const okMsg = alertTitle.REGISTER_SUCCESS(details.fname);
      // const errMsg = alertTitle.REGISTER_FAILED(details.fname);
      const config = {
        method: 'post',
        url: ApiURL.checkTransferStatus,
        data: params,
        headers: { 'x-amz-security-token': token, ...apiKey },
      };
      const {
        okMsg,
        errMsg,
        Msg,
        Status,
        Action,
        content_id,
        email,
        fullName,
        user_id,
        sendMailParams,
        generateLink,
        details,
        UploadNotificationParams,
        UploadFolderNotificationParams,
        UploadEmailParams,
      } = notification;
      let noOfRetries = 0;
      let x = setInterval(() => {
        axios(config)
          .then(({ data }): any => {
            if (!data.error && data.status === 'completed') {
              clearInterval(x);

              // UploadNotificationParams &&
              //   UploadNotificationParams.email.length &&
              //   dispatch(setTransferOpen(false));

              dispatch(endTransfer(package_id, insertParams));

              Status &&
                dispatch(
                  updateRegisterStatus({
                    content_id,
                    status: Status,
                  })
                );
              const { breadcrumbList } = getState().dashboard;

              const { cid, key, allow_access_to_workspace_users } =
                breadcrumbList[breadcrumbList.length - 1];
              dispatch(
                getDashboardRecords({
                  cid,
                  name: '',
                  navId: key,
                  allow_access_to_workspace_users,
                })
              ); // Reloading Table at current level
              // console.log(email.length, Msg.length, Msg.length && email.length);
              UploadFolderNotificationParams &&
                UploadFolderNotificationParams.email.length &&
                dispatch(createEmailNotification(UploadFolderNotificationParams));

              UploadNotificationParams &&
                UploadNotificationParams.email.length &&
                setTimeout(() => {
                  dispatch(createEmailNotification(UploadNotificationParams));
                }, 1000);

              Msg &&
                Msg.length &&
                email.length &&
                setTimeout(() => {
                  dispatch(
                    createEmailNotification({
                      msg: Msg,
                      content_id,
                      action: Action,
                      email,
                      fullName,
                      user_id,
                    })
                  );
                }, 2000);

              UploadEmailParams &&
                UploadEmailParams.recipient_email.length &&
                dispatch(sendMail(UploadEmailParams));

              email &&
                email.length &&
                sendMailParams.length &&
                dispatch(sendMail(sendMailParams[0]));

              generateLink &&
                package_id &&
                dispatch(generateExternalLink(details, package_id));

              okMsg &&
                okMsg.length &&
                dispatch(
                  toggleAlert({
                    show: true,
                    type: alertType.SUCCESS,
                    title: okMsg,
                  })
                );

              return data;
              // x = 0;
            } else if (
              !data.error &&
              (data.status.includes('waiting') || data.status.includes('running'))
            ) {
            } else if (!data.error && data.status.includes('failed')) {
              clearInterval(x);
              dispatch(setTransferOpen({ isOpen: false, action: '' }));
              errMsg &&
                errMsg.length &&
                dispatch(
                  toggleAlert({
                    show: true,
                    type: alertType.DANGER,
                    title: errMsg,
                  })
                );
              notification.DeleteOnFail && dispatch(DeleteOnFail({ content_id }));

              return data;
            } else {
              clearInterval(x);
              dispatch(setTransferOpen({ isOpen: false, action: '' }));
              errMsg &&
                errMsg.length &&
                dispatch(
                  toggleAlert({
                    show: true,
                    type: alertType.DANGER,
                    title: errMsg,
                  })
                );
              notification.DeleteOnFail && dispatch(DeleteOnFail({ content_id }));

              return data;
            }
          })
          .catch((error: any) => {
            noOfRetries++;
            if (noOfRetries === 5) {
              clearInterval(x);
              dispatch(setTransferOpen({ isOpen: false, action: '' }));
              errMsg &&
                errMsg.length &&
                dispatch(
                  toggleAlert({
                    show: true,
                    type: alertType.DANGER,
                    title: errMsg,
                  })
                );
              notification.DeleteOnFail && dispatch(DeleteOnFail({ content_id }));
            }
            console.log('Check transfer status API Error.... ', error);
          });
      }, 5000);
    };
export const endTransfer =
  (params: any, insertParams: any) => (dispatch: any, getState: any) => {
    insertParams && dispatch(insertPackage(insertParams));
    // const source = axios.CancelToken.source();
    // setTimeout(() => {
    //   source.cancel();
    // }, 30000);
    // try {
    //   let abortController = new AbortController();
    //   const timeout = setTimeout(() => {
    //     abortController.abort();
    //     console.log('Aborted');
    //   }, 30000);
    const { token } = getState().dashboard;
    const config = {
      method: 'post',
      url: ApiURL.endTransfer,
      data: { package_id: params },
      // signal: abortController.signal,
      // cancelToken: source.token,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };

    axios(config).then(({ data }): any => {
      // if (!data.error && data.status === 204) {
      //   return 'success';
      // } else {
      //   return 'failure';
      // }
      //clearTimeout(timeout);
    });
    //   } catch (error: any) {
    //     console.log('End transfer API Error.... ', error);
    //   }
  };

export const insertPackage =
  (params: any) => (dispatch: any, getState: any) => {
    const { token, workspace, nodeToken } = getState().dashboard;
    const data = {
      ...params,
      home_node_id: workspace.home_node_id,
      token: `Bearer ${nodeToken}`,
    };

    const config = {
      method: 'post',
      url: ApiURL.insertPackage,
      data: data,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };

    axios(config)
      .then(({ data }): any => {
        if (!data.error) {
          //console.log(data);
        }
      })
      .catch((error: any) => {
        console.log('insertpack', error);
      });
  };

export const renameAsset = (details: any) => (dispatch: any, getState: any) => {
  const { token, workspace, nodeToken, breadcrumbList } = getState().dashboard;
  const { home_node_id } = workspace;
  const { cid, key, allow_access_to_workspace_users } =
    breadcrumbList[breadcrumbList.length - 1];
  const params = {
    home_node_id: home_node_id,
    new_name: details.new_name,
    content_id: details.content_id,
    token: `Bearer ${nodeToken}`,
  };

  const config = {
    method: 'put',
    url: ApiURL.assetRename,
    data: params,
    headers: { 'x-amz-security-token': token, ...apiKey },
  };

  axios(config)
    .then(({ data }): any => {
      if (!data.error) {
        dispatch(
          getDashboardRecords({
            cid,
            name: '',
            navId: key,
            allow_access_to_workspace_users,
          })
        );
        //console.log(data);
      }
    })
    .catch((error: any) => {
      console.log('error in renaming', error);
    });
};

export const getAssetPermission =
  (data: any) => (dispatch: any, getState: any) => {
    dispatch(setAssetPermission({}));
    let { content_id, user_id } = data;
    const { workspace } = getState().dashboard;
    if (!user_id.length || user_id === undefined) {
      user_id = workspace.user_id;
    }

    const params = `?user_id=${user_id}&content_id=${content_id}`;
    const config = {
      method: 'get',
      url: ApiURL.getAssetPermission + params,
      headers: apiKey,
    };

    axios(config).then(({ data }): any => {
      if (data.data) {
        dispatch(setAssetPermission(data.data));
      }
    });
  };

export const submitShareExternal =
  (details: any) => (dispatch: any, getState: any) => {
    dispatch(setTransferOpen({ isOpen: true, action: 'shareexternal' }));
    const { workspace, nodeToken, token } = getState().dashboard;
    const { user_id, home_file_id, home_node_id, workspace_id } = workspace;
    const shareAssetExternalParams = {
      user_id,
      home_node_id,
      workspace_id,
      share_to: details.email,
      content_id: details.content_id,
      home_file_id,
      name: `External file share ${
        details.file_name
        } ${new Date().toISOString()}`,
      note: details.notes,
      file_names: [details.file_name],
      node_token: nodeToken,
    };

    const config = {
      method: 'post',
      url: ApiURL.externalShareAsset,
      data: shareAssetExternalParams,
      headers: { 'x-amz-security-token': token, ...apiKey },
    };
    axios(config)
      .then(({ data }): any => {
        if (!data.error && !data.hasOwnProperty('errors')) {
          const paramsCheckTransferStatus = {
            transfer_id: data.transfer_id,
            home_node_id: home_node_id,
            node_token: nodeToken,
          };
          const errMsg = alertTitle.SHARE_EXTERNAL_FAILED(details.file_name);
          const generateLink = true;
          const notification = {
            errMsg,
            generateLink,
            details,
          };
          if (data.transfer_id === '-1') {
            const { breadcrumbList } = getState().dashboard;
            const { cid, key, allow_access_to_workspace_users } =
              breadcrumbList[breadcrumbList.length - 1];

            dispatch(
              getDashboardRecords({
                cid,
                name: '',
                navId: key,
                allow_access_to_workspace_users,
              })
            );

            data.package_id &&
              dispatch(generateExternalLink(details, data.package_id));
          } else {
            dispatch(
              checkTransferStatus(
                paramsCheckTransferStatus,
                data.package_id,
                notification,
                undefined
              )
            );
          }
        } else {
          dispatch(setTransferOpen({ isOpen: false, action: '' }));
          const title = alertTitle.SHARE_EXTERNAL_FAILED(details.file_name);
          dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
        }
      })
      .catch((error: any) => {
        dispatch(setTransferOpen({ isOpen: false, action: '' }));
        const title = alertTitle.SHARE_EXTERNAL_FAILED(details.file_name);
        dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
      });
  };

export const generateExternalLink =
  (details: any, package_id: any) => (dispatch: any, getState: any) => {
    const { currentUser, labelLogo, labelTitle, workspace } =
      getState().dashboard;
    const expiry_date = details.expiry_date
      ? new Date(
        details.expiry_date.getTime() -
        details.expiry_date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split('T')[0]
      : null;
    const { user_id, home_file_id, home_node_id, workspace_id } = workspace;
    const linkParams = {
      content_id: details.content_id,
      access_level: details.access_level,
      link_type: details.link_type,
      notes: details.notes,
      owner_email: currentUser.email,
      owner_username: currentUser.fullName,
      timelapse: details.timelapse,
      url: details.url,
      workspace_logo: labelLogo,
      workspace_name: labelTitle,
      recipient_email: details.email,
      expiry_date,
      user_id,
      home_node_id,
      workspace_id,
      home_file_id,
      package_id,
      file_name: details.file_name,
      file_type: details.file_type,
      password: details.password,
    };
    const mailParams = {
      email_type: details.email_type,
      recipient_email: [details.email],
      username: currentUser.fullName,
      notes:
        (details.notes.length &&
          '<br/><br/>Notes: ' + details.notes + '<br/><br/>') ||
        '',
    };

    const okMsg = alertTitle.SHARE_EXTERNAL_SUCCESS(
      details.file_name,
      details.email
    );
    const errMsg = alertTitle.SHARE_EXTERNAL_FAILED(details.file_name);

    const config = {
      method: 'post',
      url: ApiURL.genratePackageLink,
      data: linkParams,
      headers: apiKey,
    };
    axios(config)
      .then(({ data }): any => {
        if (!data.error && !data.hasOwnProperty('errors') && package_id) {
          data.link &&
            dispatch(
              sendExternalShareMail({
                ...mailParams,
                link: data.link,
                okMsg,
                errMsg,
              })
            );
          dispatch(
            createNotification({
              msg: `Shared ${details.file_name} with ${details.email}`,
              content_id: [details.content_id],
              action: 'sharetoexternal',
            })
          );
          details.notes.length &&
            dispatch(
              postComments({
                notes: details.notes,
                content_id: [details.content_id],
              })
            );
        } else {
          dispatch(setTransferOpen({ isOpen: false, action: '' }));
          const title = alertTitle.SHARE_EXTERNAL_FAILED(details.file_name);
          dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
        }
      })
      .catch((error: any) => {
        dispatch(setTransferOpen({ isOpen: false, action: '' }));
        const title = alertTitle.SHARE_EXTERNAL_FAILED(details.file_name);
        dispatch(toggleAlert({ show: true, type: alertType.DANGER, title }));
      });
  };

export const sendExternalShareMail = (params: any) => (dispatch: any) => {
  const {
    email_type,
    recipient_email,
    username,
    notes,
    link,
    workspace_name,
    filename,
    okMsg,
    errMsg,
  } = params;
  const mailParams = {
    email_type,
    recipient_email,
    username,
    notes,
    link,
    workspace_name,
    filename,
  };
  const config = {
    method: 'post',
    url: ApiURL.sendReqEmail,
    data: mailParams,
    headers: apiKey,
  };

  axios(config)
    .then(({ data }): any => {
      dispatch(setTransferOpen({ isOpen: false, action: '' }));
      okMsg &&
        okMsg.length &&
        dispatch(
          toggleAlert({ show: true, type: alertType.SUCCESS, title: okMsg })
        );
    })
    .catch((error: any) => {
      dispatch(setTransferOpen({ isOpen: false, action: '' }));
      errMsg &&
        errMsg.length &&
        dispatch(
          toggleAlert({ show: true, type: alertType.DANGER, title: errMsg })
        );
    });
};

export const DeleteOnFail = (params: any) => (dispatch: any) => {
  // const CID = params.content_id.map((item: any) => item.toString());
  // const Params = { content_id: CID };

  const config = {
    method: 'post',
    url: ApiURL.deleteCid,
    data: params,
    headers: apiKey,
  };

  axios(config)
    .then(({ data }): any => {
      console.log(data);
      dispatch(setTransferOpen({ isOpen: false, action: '' }));
    })
    .catch((error: any) => {
      dispatch(setTransferOpen({ isOpen: false, action: '' }));
    });
};

// const generateExpiredLink = (params: any) => (dispatch: any) => {
//   const config = {
//     url: ApiURL.ReqLink + '?token=' + params.token,
//     method: 'get',
//     headers: apiKey,
//   };
//   axios(config)
//     .then(({ data }) => {

//     })
//     .catch((err) => {
//       const { msg, data } = err.response.data;
//       if (msg === 'Link is expired') {
//         const apiParams = {

//           access_level: '0',
//           link_type: '0',
//           notes: '',
//           // timelapse: new Date(),
//           url: params.url,
//           // recipient_email: receipentTags[0], // need to change from array to String
//           recipient_email: '',
//           email_type: 'link',
//           expiry_date: new Date().getDate() + 365,
//         }
//         dispatch(modifyReqSubmit({ ...apiParams, id: data.id, link: data.link }))
//       }
//     })
// }

export default reducer;

import { Button, Modal } from '@wmg-ae/legato';
import React from 'react'

const MyLinkDelete: React.FC<any> = ({ isOpen, handleClose, handleSubmit }) => {
  const deleteMsg =
    'Deleteing this link means that it will be removed from system and no one will be able to use it. Are you sure you want to delete link?';
  
  return (
    <Modal
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }} />
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={handleClose}
          >
            No, Cancel
          </Button>
          <Button colorType="danger" onClick={handleSubmit}>
            Yes, Delete
          </Button>
        </div>
      }
      icon="trash-can"
      onClose={handleClose}
      title="Delete Confirmation"
      isOpen={isOpen}
    >
      
        <p className='DeleteLinkMsg'>{deleteMsg}</p>
    
    </Modal>
  );
};

export default MyLinkDelete
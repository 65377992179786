import React, { useEffect, useMemo, useRef } from 'react';
import { Button, Icon, Table, Dropdown, Tooltip } from '@wmg-ae/legato';
import { TableApi, TTableSortDirection } from '@wmg-ae/legato/build/components/Table/types';
import { getContentIcon } from '../helper';
import { allOptions, shareOptions, studioColumns } from '../constant';

import './DashboardTabs.sass';
import { StudioOptions } from '../../external/constant';

const DashboardTable: React.FC<any> = ({
  currentUser,
  tableColumns,
  dashboardRecords,
  handleActionMenu,
  onFolderClick,
  handleSelectedRows,
  selectedAnRRecords,
  handleBulkDownload,
  bulkShareLibrary,
  handleBulkDelete,
  isDeleteAllow,
  isShareAllow,
  download_transfer_spec,
  nodeToken,
}) => {
  const tableRef = useRef<TableApi>(null);
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      if (tableRef.current) {
        tableRef.current.clearSort();
        tableRef.current.clearFilters();
        if (!(dashboardRecords.some((item: any) => selectedAnRRecords.includes(item.id))) && selectedAnRRecords.length) {
          tableRef.current && tableRef.current.changeSelectedRows([]);
        }
      }
    } else {
      if (dashboardRecords.length) {
        didMount.current = true;
        tableRef.current && tableRef.current.clearFilters();
        tableRef.current && tableRef.current.changeSelectedRows([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRef, didMount, dashboardRecords]);

  const { Register, Watermarking, Audio, Mastering } = StudioOptions;
  const getTableColums = () => {
    if (currentUser.isStudioUser) return studioColumns;
    return tableColumns;
  };

  const getFilteredList = (status: any, owner: any) => {
    // 0 is Share to Studio, 1 is Share to Library, 2 is Share to External
    let newList: any = [];
    if (currentUser.isLibraryUser) {
      newList = status === 'In Studio' ? [] : [shareOptions[0]];
    } else if (currentUser.isStudioUser) {
      newList = status === 'In Library' ? [] : [shareOptions[1]];
    } else {
      newList = shareOptions.filter(
        (opt: any) =>
          (status === 'In Library' &&
            opt.title !== 'Share to Library' &&
            opt.title !== 'Share to External') ||
          (status === 'In Studio' &&
            opt.title !== 'Share to Studio' &&
            opt.title !== 'Share to External') ||
          (status !== 'In Library' &&
            status !== 'In Studio' &&
            opt.title !== 'Share to External')
      );
    }
    if (currentUser.fullName === owner && status !== 'Registered') {
      newList = [...newList, shareOptions[2]];
    }
    return newList;
  };

  // const getFilteredList = (status: any) => {
  //   // 0 is Share to Studio, 1 is Share to Library
  //   let newList: any = [];
  //   if (currentUser.isLibraryUser) {
  //     newList = status === 'In Studio' ? [] : [shareOptions[0]];
  //   } else if (currentUser.isStudioUser) {
  //     newList = status === 'In Library' ? [] : [shareOptions[1]];
  //   } else {
  //     newList = shareOptions.filter(
  //       (opt: any) =>
  //         (status === 'In Library' && opt.title !== 'Share to Library') ||
  //         (status === 'In Studio' && opt.title !== 'Share to Studio') ||
  //         (status !== 'In Library' && status !== 'In Studio')
  //     );
  //   }
  //   return newList;
  // };

  const filteredEllipseOptions = (rowData: any) => {
    if (currentUser.isLibraryUser) {
      // LIBRARY USER
      if (rowData.status === 'Vaulted' || rowData.status === 'Mixed') {
        // 'View/Edit Details', 'Move to Folder'
        return [allOptions[0], allOptions[3]];
      } else {
        // 'View/Edit Details', 'Move to Folder', 'Vault Asset'
        return [allOptions[0], allOptions[3], allOptions[4]];
      }
    } else if (currentUser.isStudioUser) {
      // STUDIO USER
      const haveRegister = rowData.tasks && rowData.tasks.includes('Register');
      if (rowData.status === 'Pending Approval') {
        // 'View/Edit Details',
        return [allOptions[0]];
      } else if (rowData.status === 'Approved') {
        // 'View/Edit Details', 'Invite Collaborator', 'Move to Folder'
        let newList = [allOptions[0], allOptions[1], allOptions[3]];
        // If Task contains Register then include 'Register' and 'Decline Register Request'
        if (haveRegister) newList = [...newList, allOptions[6], allOptions[7]];
        return newList;
      } else {
        let newList = [
          allOptions[0],
          allOptions[1],
          allOptions[3],
          allOptions[5],
        ];
        // 'View/Edit Details', 'Invite Collaborator', 'Move to Folder', 'Send for Approval'
        // If Task contains Register then include 'Register' and 'Decline Register Request'
        if (haveRegister && rowData.status !== 'Registered')
          newList = [...newList, allOptions[6], allOptions[7]];
        return newList;
      }
    } else {
      // A&R LABEL USER / A&R MANAGER USER
      if (rowData.status === 'Pending Approval') {
        // 'Approve', 'Decline'
        return [allOptions[8], allOptions[9]];
      } else if (rowData.status === 'Requested Revision') {
        // 'View/Edit Details', 'Invite Collaborator', 'Move to Folder'
        // return [allOptions[0], allOptions[1], allOptions[3]];

        // 'View/Edit Details'  // Condition updated later to show only View/Edit Details
        return [allOptions[0]];
      } else {
        // 'View/Edit Details', 'Invite Collaborator', 'Request Revision', 'Move to Folder'
        return [allOptions[0], allOptions[1], allOptions[2], allOptions[3]];
      }
    }
  };

  const folderClick = (isFolder: boolean, row: any, idx: number) => {
    if (isFolder) {
      onFolderClick({
        name: row.filename,
        cid: row.content_id,
        allow_access_to_workspace_users: row.allow_access_to_workspace_users,
        idx,
      });
    }
  };

  const getFilteredTasks = (tasks: any) => {
    const task = (tasks && tasks.split(',')) || [''];
    if (task && task.length > 1) {
      switch (task[0]) {
        case Register:
          return { text: 'Register ', length: task.length - 1 };
        case Watermarking:
          return { text: 'Watermar ', length: task.length - 1 };
        case Audio:
          return { text: 'Audio ', length: task.length - 1 };
        case Mastering:
          return { text: 'Masterin ', length: task.length - 1 };
      }
    } else if (task && task.length === 1 && task[0] === Register)
      return { text: 'Register Asset(s)', length: 0 };
    return { text: task[0], length: 0 };
  };

  let tableColumnnsUpdated = getTableColums().map((column: any) => {
    const newCoulmn = {
      dataIndex: column.dataIndex,
      key: column.key,
      title: column.title,
      width: column.width,
      render:
        column.key === 'filename'
          ? (value: any, row: any, index: any) => {
            const isFolder = row.type.includes('folder');
            let splitvalue = value;
            if (value && !isFolder && value.includes('.')) {
              const lastIndexDot = value.lastIndexOf('.');
              splitvalue = value.slice(0, lastIndexDot);
            }
            return (
              <div className="row">
                <div className="col contentIconWrapper">
                  <span onClick={() => folderClick(isFolder, row, index)}>
                    <Icon
                      className={`contentIconStyle ${isFolder ? 'iconPointer' : ''
                        }`}
                        name={getContentIcon(isFolder, row.share)}
                        prefix={`${
                          (row.allow_access_to_workspace_users === 'Y' &&
                            'fas') ||
                          'fal'
                        }`}
                      />
                    </span>
                  </div>
                  <div
                    className={`col contentlabel ${
                      isFolder ? 'iconPointer' : ''
                    }`}
                  onClick={() => folderClick(isFolder, row, index)}
                >
                  {splitvalue}
                </div>
              </div>
            );
          }
          : column.key === 'task'
            ? (value: any, row: any, index: any) => {
              const { text, length } = getFilteredTasks(value);
              return (
                <div className="task">
                  {text}{' '}
                  {!!length && (
                    <Tooltip
                      className="taskTooltip"
                      colorType="dark"
                      message={value}
                    >
                      <span className="blueMore">+ {length} more</span>
                    </Tooltip>
                  )}
                </div>
              );
            }
            : undefined,
      sorterFunction: column.key === 'lastUpdate' ? (key: React.Key, direction: TTableSortDirection) => {
        let sortOrder = direction === 'ASC' ? 1 : -1;
        return function (a: any, b: any) {
          let result = new Date(a[key]) < new Date(b[key]) ? -1 : new Date(a[key]) > new Date(b[key]) ? 1 : 0;
          return result * sortOrder;
        };
      } : undefined,
      sortable: column.sortable,
      filterSearch: column.filterSearch,
    };
    return newCoulmn;
  });
  let tableRecords = useMemo(
    () =>
      dashboardRecords.map((row: any, idx: number) => {
        // const isFolder = row.type === 'folder' || row.type === 'sfolder';

        const deleteAccess =
          row.status !== 'Registered' &&
          (row.user_email === currentUser.email || currentUser.isLabelManager);
        const shareList = getFilteredList(row.status, row.user_name);
        const shareAccess = row.status === 'Requested Revision';
        const task = row.tasks || '';
        const newRow = {
          key: row.id,
          type: row.type,
          parent_id: row.parent_id,
          content_id: row.content_id,
          filename: row.name,
          sentBy: row.recipient_emails || row.user_email || '',
          task,
          share: row.is_shared,
          allow_access_to_workspace_users: row.allow_access_to_workspace_users,
          // fileType: row.content_type,
          // duration: row.duration || '',
          artist: row.artist || '',
          title: row.title || '',
          owner: row.user_name,
          lastUpdate: row.last_updated_timestamp
            ? new Date(row.last_updated_timestamp).toLocaleDateString()
            : '',
          status: row.status,
          download_transfer_spec,
          nodeToken,
          user_id: row.user_id,
          dropbox_name: row.dropbox_name,
          dropbox_id: row.dropbox_id,
          package_name: row.package_name,
          package_id: row.package_id,
          parent_package_file_id: row.parent_package_file_id,
          workspace_id: row.workspace_id,
          rowActions: (
            <div className="container">
              <div className="row rowActionsIcons">
                {!deleteAccess && <div className="col"></div>}
                <div className="col">
                  {!!shareList.length && !shareAccess && (
                    <Dropdown
                      className='lgt-dropdown'
                      list={shareList}
                      placement={'bottomRight'}
                      onSelect={(key: any) =>
                        handleActionMenu(key.title, row, idx)
                      }
                    >
                      <Icon
                        className="ellipseIconStyle"
                        name="share-from-square"
                        prefix="fal"
                      />

                      {/* <Button
                  className="trashBttn"
                  colorType="secondary-black"
                  containerStyle="link"
                  icon="share-from-square"
                  // size="sm"
                ></Button> */}
                    </Dropdown>
                  )}
                </div>
                <div
                  className="col"
                  onClick={() => {
                    download_transfer_spec &&
                      handleActionMenu('Download', row, idx);
                  }}
                >
                  <Icon
                    className="ellipseIconStyle"
                    name="download"
                    prefix="fal"
                  />
                </div>
                {deleteAccess && (
                  <div
                    className="col"
                    onClick={() => handleActionMenu('Delete', row, idx)}
                  >
                    <Icon
                      className="ellipseIconStyle"
                      name="trash-can"
                      prefix="fal"
                    />
                    {/* <Button
                  // className="trashBttn"
                  colorType="secondary-black"
                  containerStyle="link"
                  icon="trash-can"
                  onClick={() => handleActionMenu('Delete', row, idx)}
                ></Button> */}
                  </div>
                )}

                <div className="col">
                  <Dropdown
                    className='lgt-dropdown'
                    list={filteredEllipseOptions(row)}
                    placement={'bottomLeft'}
                    onSelect={(key: any) =>
                      handleActionMenu(key.title, row, idx)
                    }
                  >
                    <Icon
                      className="ellipseIconStyle"
                      name="ellipsis-h"
                      prefix="fal"
                    />
                    {/* <Button
                  className="trashBttn"
                  colorType="secondary-black"
                  containerStyle="link"
                  icon="ellipsis-h"
                ></Button> */}
                  </Dropdown>
                </div>
              </div>
            </div>
          ),
        };
        return newRow;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, dashboardRecords, download_transfer_spec, nodeToken]
  );

  return (
    <div>
      <>
        {!!selectedAnRRecords.length && (
          <div className="row bulkActionRow">
            <div className="col-3">
              <Button
                label={selectedAnRRecords.length + ' selected'}
                className="upload_buttons"
              />
            </div>

            <div className="col-9 textRight actionBttns">
              {!isShareAllow && !currentUser.isLibraryUser && (
                <Tooltip
                  message="One or more file(s) you have selected is in Requested Revision, please unselect them to share to library."
                  title=""
                  className="upload_buttons"
                  colorType="dark"
                >
                  <Button label="Share to Library" onClick={bulkShareLibrary} />
                </Tooltip>
              )}
              {isShareAllow && !currentUser.isLibraryUser && (
                <Button
                  label="Share to Library"
                  className="upload_buttons"
                  onClick={bulkShareLibrary}
                />
              )}
              <Button
                label="Download"
                className="upload_buttons"
                onClick={handleBulkDownload}
              />
              {!isDeleteAllow &&
                !currentUser.isLibraryUser &&
                !currentUser.isStudioUser && (
                  <Tooltip
                    message="You are not the owner of file(s) you have selected, so you cannot delete them."
                    title=""
                    className="upload_buttons"
                    colorType="dark"
                  >
                    <Button label="Delete" onClick={handleBulkDelete} />
                  </Tooltip>
                )}
              {isDeleteAllow &&
                !currentUser.isLibraryUser &&
                !currentUser.isStudioUser && (
                  <Button
                    label="Delete"
                    className="upload_buttons"
                    onClick={handleBulkDelete}
                  />
                )}
            </div>
          </div>
        )}
        <div>
          <Table
            columns={tableColumnnsUpdated}
            data={tableRecords}
            defaultSort={{ direction: 'DESC', key: 'lastUpdate' }}
            condensed={true}
            // onRowExpand={() => {}}
            // onExpand={undefined}
            // onHeaderRow={undefined}
            transparent={false}
            // getSelectedRowsKeys={function noRefCheck() {}}
            getSelectedRowsKeys={handleSelectedRows}
            // renderExpandable={undefined}
            // renderExpandable
            pagination={{
              position: ['bottomCenter'],
            }}
            rowKey={(row: any) => {
              return row.key;
            }}
            ref={tableRef}
          // striped='odd'
          // getSelectedRowsKeys={function noRefCheck() {}}
          />
        </div>
      </>
    </div>
  );
};

export default DashboardTable;

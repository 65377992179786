import React from 'react';
import './AnRTabContent.sass';
import DashboardTable from './DashboardTable';
import MyLinks from './MyLinks';
import { headerColumns } from '../constant';

const AnRTabContent: React.FC<any> = ({
  currentUser,
  tab,
  dashboardRecords,
  handleActionMenu,
  onFolderClick,
  getMyLinks,
  myLinkRecords,
  deleteLink,
  handleSelectedRows,
  selectedAnRRecords,
  handleBulkDownload,
  bulkShareLibrary,
  sendRequestAssetMail,
  username,
  copyLink,
  activateLink,
  handleLinkSubmit,
  handleBulkDelete,
  isDeleteAllow,
  isShareAllow,
  handleModifyReqSubmit,
  terminateReqLink,
  download_transfer_spec,
  nodeToken,
}) => {
  return (
    <div className="tab_content_container">
      {tab === '1' && (
        <DashboardTable
          currentUser={currentUser}
          tableColumns={headerColumns}
          dashboardRecords={dashboardRecords}
          handleActionMenu={handleActionMenu}
          onFolderClick={onFolderClick}
          handleSelectedRows={handleSelectedRows}
          selectedAnRRecords={selectedAnRRecords}
          handleBulkDownload={handleBulkDownload}
          bulkShareLibrary={bulkShareLibrary}
          handleBulkDelete={handleBulkDelete}
          isDeleteAllow={isDeleteAllow}
          isShareAllow={isShareAllow}
          download_transfer_spec={download_transfer_spec}
          nodeToken={nodeToken}
        />
      )}
      {tab === '2' && (
        <MyLinks
          getMyLinks={getMyLinks}
          myLinkRecords={myLinkRecords}
          deleteLink={deleteLink}
          sendRequestAssetMail={sendRequestAssetMail}
          terminateReqLink={terminateReqLink}
          username={username}
          copyLink={copyLink}
          activateLink={activateLink}
          handleLinkSubmit={handleLinkSubmit}
          currentUser={currentUser}
          handleModifyReqSubmit={handleModifyReqSubmit}
        />
      )}
      {tab === '3' && <div>Deleted Files(10)</div>}
    </div>
  );
};

export default AnRTabContent;

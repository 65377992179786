import { Button } from '@wmg-ae/legato';
import React from 'react';

import { FaqLinks } from './constant';

const FAQ: React.FC<any> = ({ backButton }) => {
  const { here, asperaConnect, browserExtension } = FaqLinks;

  return (
    <div className="row container">
      <div className="row">
        <div className="col-2"></div>
        <div className="col-8">
          <h1 className="headfaq">Frequently Asked Questions</h1>
          <p className="FaqSub">
            Please note the WMG SUN support distro has not been created yet.
          </p>

          <p className="FaqQues">What browsers are supported by WMG SUN?</p>
          <p className="FaqAns">
            We highly recommend using Chrome or Firefox. A full list of
            up-to-date browsers supported by WMG SUN can be found&nbsp;
            <a className="faqHelpLink" href={here}>
              here
            </a>
          </p>
          <p className="FaqQues">
            Can I send packages without installing Aspera Connect and browser
            extensions?
          </p>
          <p className="FaqAns">
            Yes however, your transfer speeds will be greatly reduced. We highly
            recommend you install both&nbsp;
            <a className="faqHelpLink" href={asperaConnect}>
              Aspera Connect
            </a>
            &nbsp; and the&nbsp;
            <a className="faqHelpLink" href={browserExtension}>
              browser extension
            </a>
            &nbsp; for best possible experience.
          </p>
          <p className="FaqQues">Can I forward submission links?</p>
          <p className="FaqAns">
            If you have been given a Public Link, yes. Anyone in possession can
            upload packages. If you have a Private Link, the link is only
            accessible by you. Please check with WMG staff or email the WMG SUN
            support team for verification.
          </p>
          <p className="FaqQues">Is there a file size limitation for upload?</p>
          <p className="FaqAns">
            No. WMG SUN can handle any file size or number of files.
          </p>
          <p className="FaqQues">Can I pause and/or restart transfers?</p>
          <p className="FaqAns">
            Yes, by accessing your transfer monitor, you can see the status of
            your uploads and downloads. From there you can pause or restart your
            uploads or downloads.
          </p>
          <p className="FaqQues">
            I’m still having issues. Who can I contact for assistance?
          </p>
          <p className="FaqAns">
            You can reach out to the WMG SUN team for further troubleshooting.
          </p>
          <Button
            className="buttonfaq"
            label="Back To Upload Page"
            containerStyle={'outline'}
            size="lg"
            colorType="secondary-black"
            onClick={backButton}
          />
        </div>
      </div>
    </div>
  );
};
export default FAQ;
